import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { alertService, adminService } from '../../services/Index';
import NotificationSettings from '../../models/NotificationSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { IDicScope } from '../../models/Dictionary';

import { NotificationSettingEdit } from './NotificationSettingEdit';
import { NotificationSettingUserEdit } from './NotificationSettingUserEdit';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

interface INotificationSettingsProps {
    allScopes: IDicScope[];
    scope: IDicScope;
}
const NotificationSettingsManagement = ({ scope, allScopes }: INotificationSettingsProps) => {
    const { authUserData } = useContext(LoggedUserDataContext);
    const [notificationSettings, setNotificationSettings] = useState<Array<NotificationSettings>>();
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        var filteredScope= allScopes.filter((s) => { return s.hasOwnProperty('scopeEntityId') && s.scopeEntityId!==undefined});
        adminService.getScopedNotificationSetting(filteredScope).then(rp => {

            const notDescending = [...rp].sort((a, b) => a.notificationSettingDictionaryId - b.notificationSettingDictionaryId);
            setNotificationSettings(notDescending);
        }
        ).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
            }
        );
    }, []);
    function resetSettings() {
        var filteredScope= allScopes.filter((s) => { return s.hasOwnProperty('scopeEntityId') && s.scopeEntityId!==undefined});
        adminService.getScopedNotificationSetting(filteredScope).then(rp => {
            const notDescending = [...rp].sort((a, b) => a.notificationSettingDictionaryId - b.notificationSettingDictionaryId);
            setNotificationSettings(notDescending);
            const notificationNextRunDateNew = 
            rp.map(n => {
                return {...n, modifiedBy: authUserData.currUserData.profileId}
            });
            adminService.updateNotificationSettingsNextRunDate(notificationNextRunDateNew).then(rp => {
                
            }
            ).catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true });
                }
            );
        }
        ).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
            }
        );
        
    }
    function updateSelectedSetting(data: NotificationSettings) {
        let notDescending = notificationSettings!.map(x => {
            if (x.notificationSettingDictionaryId === data.notificationSettingDictionaryId) { return data }
            return x;
        });
        notDescending = [...notDescending].sort((a, b) => a.notificationSettingDictionaryId - b.notificationSettingDictionaryId);
        setNotificationSettings(notDescending);
    }

    return (
        <div className="container">
            <p className="component-header">{t("ALL.Alerts.SettingsList")}</p>
            <hr></hr>
            <div className="row table-row" >
                <div className="col-md-12">

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <tbody>

                                        {notificationSettings && notificationSettings.map(n => <>
                                            {scope.scopeTypeId !== 3 &&
                                                <tr key={n.id}>
                                                    <td>
                                                        <NotificationSettingEdit setting={n} setSelectedItem={updateSelectedSetting} scope={scope} refresh={resetSettings} />
                                                    </td>
                                                </tr>
                                            }
                                            {scope.scopeTypeId === 3 && n.recipients.includes(authUserData.currUserData.roleEnum) === true &&
                                                <tr key={n.id}>
                                                    <td>
                                                        <NotificationSettingUserEdit setting={n} setSelectedItem={updateSelectedSetting} scope={scope} refresh={resetSettings} />
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                        )}
                                        {!notificationSettings &&
                                            <tr key="spinner">
                                                <td colSpan={5} className="text-center">
                                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                                </td>
                                            </tr>
                                        }
                                        {notificationSettings && !notificationSettings.length &&
                                            <tr key="no-data">
                                                <td colSpan={5} className="text-center">
                                                    <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                </td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
    );

}

export { NotificationSettingsManagement };

