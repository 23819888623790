
import logo from '../assets/images/backIcon.png';
import { useMsal } from "@azure/msal-react";
import { useContext } from 'react';
import { MultiLanguageSupportContext } from '../ctx/MultiLanguageSupportProvider';
import { Link } from '@mui/material';
import { graphTokenRequest } from '../msal/Config';


export const InitialScreen = () => {
    const { instance } = useMsal();
    const { t } = useContext(MultiLanguageSupportContext);
    const handleLogin = () => {
        instance.loginRedirect(graphTokenRequest).catch(e => {
            console.log(e);
            return;
        });
    }

    return (
        <div className="initialScreen">
            <div>
                <img src={logo} className="appIcon" alt="logo" />
            </div>
            <div>
                <Link onClick={() => handleLogin()} style={{cursor:"pointer", textDecoration: "none"}}>{t("ALL.Common.SignIn")}</Link>
            </div>
        </div>);
}