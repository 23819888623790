import { useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { adminService, alertService } from '../../services/Index';

import AdminSetting from '../../models/AdminSetting';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useNavigate, useParams} from 'react-router-dom';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

const EditSetting=()=> {
    const {authUserData} = useContext(LoggedUserDataContext);
    let navigate = useNavigate();
    let { id } = useParams();
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        adminService.getSingleSetting(parseInt(id!)).then(i => {
            setValue("id", i.id);
            setValue("settingKey", i.settingKey);
            setValue("valueText", i.valueText);
            setValue("info", i.info);
            setValue("valueNumber", i.valueNumber);
            setValue("valueUnit", i.valueUnit);
            setValue("title", i.title);
        }).catch(
            (rp: any) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );

    }, [id]);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t("ALL.Validation.Title"))
    });

    const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data: AdminSetting) {
        return updateSetting(parseInt(id!), data);
    }


    function updateSetting(id: number, data: AdminSetting) {
        data.valueNumber=data.valueNumber===""?null:data.valueNumber;

        data.modifiedBy = authUserData.currUserData.profileId;
        data.id = id;
        return adminService.postUpdateSetting(data)
            .then(() => {
                alertService.success(t("ALL.Settings.Updated"), { keepAfterRouteChange: true });
                navigate("../all-settings")
            })
            .catch(
                (rp: any) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    return (
        <div className="container">
            <p className="component-header">{t("ALL.Settings.Edit")}</p>
            <hr></hr>
            <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>{t("ALL.Settings.Edit")}</label>
                        <input name="title" type="text" ref={register} className={`form-control ${errors.title ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.title?.message}</div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-3">
                        <label>{t("ALL.Settings.ValueNumber")}</label>
                        <input name="valueNumber" type="text" ref={register} className={`form-control ${errors.valueNumber ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.valueNumber?.message}</div>
                    </div>
                    <div className="form-group col-sm-3">
                        <label>{t("ALL.Settings.Unit")}</label>
                        <select name="valueUnit" ref={register} className={`form-control ${errors.valueUnit ? 'is-invalid' : ''}`}>
                            <option value=""></option>
                            <option value="Days">Days</option>
                            <option value="B">B</option>
                            <option value="KB">KB</option>
                            <option value="MB">MB</option>
                            <option value="GB">GB</option>
                            <option value="TB">TB</option>
                        </select>
                        <div className="invalid-feedback">{errors.valueUnit?.message}</div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>{t("ALL.Settings.ValueText")}</label>
                        <input name="valueText" type="text" ref={register} className={`form-control ${errors.valueText ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.valueText?.message}</div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label>{t("ALL.Settings.ValueInfo")}</label>
                        <textarea name="info" rows={5} ref={register} className={`form-control ${errors.info ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.info?.message}</div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-4 justify-content-md-center">
                        <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            {t("ALL.Button.Save")}
                        </button>
                    </div>
                </div>

            </form>
        </div>
    );
}

export { EditSetting };