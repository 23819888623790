
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEye, faUserInjured } from '@fortawesome/pro-light-svg-icons'

import { useContext, useEffect, useState } from 'react';
import TaskTemplate, { ITaskTemplateViewModel, mapTaskTemplateToVieModel } from '../../models/TaskTemplate';

import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { alertService, tasksService } from '../../services/Index';

import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from '@mui/material';
import { AddEditTaskTemplate } from './AddEditTaskTemplate';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormControlLabel from '@mui/material/FormControlLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ListTaskTemplates = ({taskTagsOptions, casePhaseOptions, taskPlanOptions}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<any>({})
    const [taskTemplates, setTaskTemplates] = useState<ITaskTemplateViewModel[]>();
    const [filteredTaskTemplates, setFilteredTaskTemplates] = useState<ITaskTemplateViewModel[]>([]);
    const [loading, setLoading] = useState(true);
    const { authUserData } = useContext(LoggedUserDataContext);
    const { caseData } = useContext(CaseDataContext);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [personalFilter, setPersonalFilter] =useState(false);

    const handleAccordinationChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    useEffect(() => {
        setLoading(true);
        if (caseData.selectedCaseId !== undefined) {
            tasksService.getAllInsurerRelatedTaskTemplates(authUserData.currUserData.insurerId!).then((rp) => {//, authUserData.currUserData.insurerId!).then((rp) => {
                let response = rp.map((data) => {
                    return mapTaskTemplateToVieModel(data)
        
                  })
                setTaskTemplates(response);
                setLoading(false);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }

    }, []);
    useEffect(() => {
        if(taskTemplates!==undefined && taskTemplates.length>0) {
            filterTaskTemplate(personalFilter);
        }
    }, [taskTemplates])
    
    const filterTaskTemplate = (filterVal) => {
        if(filterVal) {
            let tempArray = taskTemplates?.filter(obj => obj.isPrivate==="True" && obj?.ownerContact?.id===authUserData.currUserData?.contactId);
            setFilteredTaskTemplates(tempArray?tempArray:[])
        } else {
            setFilteredTaskTemplates(taskTemplates?taskTemplates:[])
        }
    }
    function handlePersonalFilterChecked (e) {
        setPersonalFilter(e.target.checked)
        filterTaskTemplate(e.target.checked)
    }
    const handleClose = () => {
        setExpanded(false);
    };
    const addTaskTemplate = (data: TaskTemplate) => {
        setExpanded(false);
        let template = mapTaskTemplateToVieModel(data)
        setTaskTemplates(taskTemplates => [template, ...taskTemplates!]);
    }
    function editTemplate(template: ITaskTemplateViewModel) {
        setSelectedTaskTemplate(template);
        setExpanded('panel');
    }
    function editTaskTemplate(template: TaskTemplate) {
        setTaskTemplates(taskTemplates!.map(x => {
            if (x.id === template.id) { return mapTaskTemplateToVieModel(template) }
            return x;
        }));
        setExpanded(false);
    }
    function deleteTaskTemplate(taskTemplateId: number) {
        setTaskTemplates(taskTemplates!.map(x => {
            if (x.id === taskTemplateId) { x.isDeleting = true; }
            return x;
        }));

        tasksService.deleteTaskTemplate(taskTemplateId).then((rp) => {
            alertService.success(t("ALL-Tasks.Messages.Template.Deleted"), { keepAfterRouteChange: true });

            setTaskTemplates(taskTemplates => taskTemplates!.filter(x => x.id !== taskTemplateId));

        }).catch(
            (rp) => {

                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setTaskTemplates(taskTemplates!.map(x => {
                    if (x.id === taskTemplateId) { x.isDeleting = true; }
                    return x;
                }));
            }

        );

    }

    return (
        <div className="col-md-12">
            <div className="card">
                {!filteredTaskTemplates &&
                    <div className="loader">{t("ALL.Common.Loading")}</div>
                }
                {filteredTaskTemplates && <div>
                    <Accordion expanded={expanded === 'panel'} onChange={handleAccordinationChange('panel')}>
                        <AccordionSummary
                            expandIcon={!expanded?<AddCircleOutlineIcon />:<KeyboardArrowDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            {Object.entries(selectedTaskTemplate).length === 0 ? t("ALL.Tasks.Button.AddTemplate") : t("ALL.Tasks.Button.EditTemplate")}
                        </AccordionSummary>
                       
                        <AccordionDetails>
                            <AddEditTaskTemplate
                                taskTemplate={selectedTaskTemplate}
                                close={handleClose}
                                addTemplate={addTaskTemplate}
                                editTemplate={editTaskTemplate}
                                cleanSelectedNode={setSelectedTaskTemplate}
                                taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>}
                {!expanded &&
                    <div className="card-body">
                        <div className="table-responsive">
                            <FormControlLabel
                                control={
                                <Checkbox checked={personalFilter}  onChange={e => {
                                    handlePersonalFilterChecked(e);
                                }} name="Personal" />
                                }
                                label={t("ALL.Tasks.Templates.PersonalTemplates")}
                            />
                            <table className="table table-striped">
                                <thead className="text-primary">
                                    <tr>
                                        <th>{t("ALL.Tasks.Templates.Title")}</th>
                                        <th>{t("ALL.Tasks.Templates.Owner")}</th>
                                        <th>{t("ALL.Tasks.Templates.Private")}</th>
                                        <th>{t("ALL.Tasks.Templates.Functions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTaskTemplates && filteredTaskTemplates.map(tt => <>
                                        <tr key={tt.id}>
                                            <td>{tt.templateTitle}</td>
                                            <td>{tt.ownerContact?.displayName!==" "?tt.ownerContact?.displayName:tt.ownerContact?.email}</td>
                                            <td>{tt.isPrivate==="True" ? `${t("ALL.Button.Yes")}` : `${t("ALL.Button.No")}`}</td>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                <button onClick={() => editTemplate(tt)} className="btn btn-md btn-icon-grey">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                                <button onClick={() => deleteTaskTemplate(tt.id)} className="btn btn-md btn-icon-red">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                    )}
                                    {!filteredTaskTemplates &&
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    {filteredTaskTemplates && !filteredTaskTemplates.length &&
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                <div className="p-2">{t("ALL.Tasks.Templates.NoData")}</div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>

        </div >



    );

}

export { ListTaskTemplates };