
import React, { useContext, useEffect, useState } from 'react';
import '../../assets/css/tabcomponent.css';
import '@asseinfo/react-kanban/dist/styles.css';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { faBurgerSoda, faCar, faListCheck, faHeadSideMedical } from '@fortawesome/pro-light-svg-icons'
import { addDays, addMonths } from 'date-fns';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { useNavigate } from 'react-router-dom';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { AddEditBooking } from './AddEditBooking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BookingsManagement } from './BookingsManagement';
import { adminService, alertService, caseManagerService } from '../../services/Index';
import { ICaseBookingViewModel, mapBookingsToVieModel } from '../../models/CaseBooking';
import CaseBookingSetting, { ICaseBookingSettingViewModel } from '../../models/CaseBookingSetting';
import { helperFunctions } from '../../helpers/HelperFunctions';
import { AppAccessContext } from '../../ctx/AppAccessProvider ';
import moment from 'moment';
import { StandardReportHeader } from '../list/StandardReportHeader';


interface IBookingsTabComponentProps {
  caseId?: number
}
function BookingsTabComponent({ caseId }: IBookingsTabComponentProps) {
  let navigate = useNavigate();

  const { authUserData } = useContext(LoggedUserDataContext);
  const { caseData } = useContext(CaseDataContext);
  const [loading, setLoading] = useState(true);
  const { language,t } = useContext(MultiLanguageSupportContext);
  const noCaseId = caseId === undefined ? caseData.selectedCaseId : caseId;
  const [bookingsData, setBookingsData] = useState<Array<ICaseBookingViewModel>>([]);
  const [bookingSettingData, setBookingSettingData] = useState<ICaseBookingSettingViewModel>();
  const [selectedTab, setSelectedTab] = useState(-1);
  const [bookingTagsOptions, setBookingTagsOptions] = useState<any[]>([]);
  const [taxTagsOptions, setTaxTagsOptions] = useState<any[]>([]);
  const [bookingCategoryTagsOptions, setBookingCategoryTagsOptions] = useState<any[]>([]);
  const [bookingTitleTagsOptions, setBookingTitleTagsOptions] = useState<any[]>([]);
  const [selectedBooking, setSelectedBooking] = useState<ICaseBookingViewModel|undefined>();
  const { setAppAccess } = useContext(AppAccessContext);
  const [calendarIsShown, setCalendarVisibility] = useState<boolean>(false);
  const [calendarState, setCalendarState] = useState([
      {
          startDate: addMonths(new Date(), -3),
          endDate: addDays(new Date(), 1),
          key: 'selection'
      }
  ]);
  moment.locale(language);

  useEffect(() => {
    if (noCaseId === undefined) {
      navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);//ToCHeck why .. does not work
    }
    setAppAccess({
      hasAccessToPath: (authUserData.currUserData.relatedData.filter(data => data.id === noCaseId).length > 0),
  });
    if (noCaseId !== undefined) {
      var getBookingTagsDic = adminService.getScopedDictionaries(4, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }], authUserData.currUserData.profileLanguage.toUpperCase());
      var getTaxTagsDic = adminService.getScopedDictionaries(6, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }], authUserData.currUserData.profileLanguage.toUpperCase());
      var getBookingCategoryTagsDic = adminService.getScopedDictionaries(7, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }], authUserData.currUserData.profileLanguage.toUpperCase());
      var getBookingTitleTagsDic = adminService.getScopedDictionaries(5, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }], authUserData.currUserData.profileLanguage.toUpperCase());
      setLoading(true);
      Promise.all([getBookingTagsDic, getTaxTagsDic, getBookingCategoryTagsDic, getBookingTitleTagsDic]).then(async (tagDicResponse) => {
        setBookingTagsOptions(helperFunctions.getUniqueDictionaryPerLanguage(tagDicResponse[0], "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
        setTaxTagsOptions(helperFunctions.getUniqueDictionaryPerLanguage(tagDicResponse[1], "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
        setBookingCategoryTagsOptions(helperFunctions.getUniqueDictionaryPerLanguage(tagDicResponse[2], "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
        setBookingTitleTagsOptions(helperFunctions.getUniqueDictionaryPerLanguage(tagDicResponse[3], "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
        setCalendarVisibility(!calendarIsShown);
        caseManagerService.getCaseBookingsByCaseIdInDateRange(noCaseId,moment(calendarState[0].startDate).format('DD.MM.YYYY'), moment(calendarState[0].endDate).format('DD.MM.YYYY')).then((rp) => {
          let response = rp.map((data) => {
            return mapBookingsToVieModel(data, t, tagDicResponse[0], tagDicResponse[1], tagDicResponse[2], authUserData.currUserData.profileLanguage)

          })
          setBookingsData(response);
          setLoading(false);
        }).catch(
          (rp) => {
            alertService.error(t(rp.message), { keepAfterRouteChange: true });
            setLoading(false);
          }
        );
        caseManagerService.getSingleCaseBookingSetting(noCaseId).then(data => {
          setBookingSettingData(data);
        }).catch(
          (rp) => {
            alertService.error(t("ALL.Bookings.Messages.MissingSettings"), { keepAfterRouteChange: true })
          }
        );
      }).catch(
        (rp) => {
          alertService.error(t(rp.message), { keepAfterRouteChange: true });
          setLoading(false);
        }
      );
    }
  }, [selectedTab]);
  const deleteBooking = (bookingId: number) => {
    setBookingsData((oldData)=> {return oldData!.map(x => {
      if (x.id === bookingId) { x.isDeleting = true; }
      return x;
    })});
    caseManagerService.deleteCaseBooking(bookingId).then((rp) => {
      alertService.success(t("ALL.Bookings.Messages.BookingDeleted"), { keepAfterRouteChange: true });
      setBookingsData((oldData)=> {return oldData?.filter(item => item.id !== bookingId)});
    }).catch(
      (rp) => {
        alertService.error(t(rp.message), { keepAfterRouteChange: true });
        setBookingsData((oldData)=> {return oldData!.map(x => {
          if (x.id === bookingId) { x.isDeleting = false; }
          return x;
        })});
      }

    );
  }
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedBooking(undefined);
  };
  const bookingProps = {
    bookingTags: bookingTagsOptions,
    taxTags: taxTagsOptions,
    titleTags: bookingTitleTagsOptions,
    categoryTags: bookingCategoryTagsOptions,
    bookingSettings: bookingSettingData,
    booking: selectedBooking,
    setSelectedTab:setSelectedTab

  };
  const bookingManagementProps = {
    bookings: bookingsData,
    loading: loading,
    bookingSettings: bookingSettingData,
    deleteBooking: deleteBooking,
    setSelectedBooking: setSelectedBooking,
    setSelectedTab:setSelectedTab,
    calendarState:calendarState,
    changeDateRange:changeDateRange,
    generateReport:generateReport
  };
  function changeDateRange(ranges: any) {
    console.log(ranges);
    setCalendarVisibility(!calendarIsShown);
    caseManagerService.getCaseBookingsByCaseIdInDateRange(noCaseId!,moment(calendarState[0].startDate).format('DD.MM.YYYY'), moment(calendarState[0].endDate).format('DD.MM.YYYY')).then((rp) => {
      let response = rp.map((data) => {
        return mapBookingsToVieModel(data, t, bookingTagsOptions, taxTagsOptions, bookingCategoryTagsOptions, authUserData.currUserData.profileLanguage)

      })
      setBookingsData(response);
      setLoading(false);
    }).catch(
      (rp) => {
        alertService.error(t(rp.message), { keepAfterRouteChange: true });
        setLoading(false);
      }
    );
    setCalendarState([ranges.selection]);

}
function generateReport(checked) {
  setLoading(true);
  var content = document.getElementById("bookings")!.outerHTML;
  caseManagerService.generateRaport(content, checked, authUserData.currUserData.profileLanguage).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ReflectionReport.pdf');
      document.body.appendChild(link);
      link.click();
      setLoading(false);
  });

}
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <AppBar position="static">
            <Tabs value={selectedTab}
              onChange={handleChange}
              TabIndicatorProps={{ style: { background: 'White' } }}
              textColor="inherit"
              aria-label="full width tabs example" centered >
              <Tab icon={<FontAwesomeIcon icon={faListCheck} style={{ fontSize: "24px" }} />} value={-1}/>
              <Tab icon={<FontAwesomeIcon icon={faHeadSideMedical} style={{ fontSize: "24px" }} />} value={0}/>
              <Tab icon={<FontAwesomeIcon icon={faBurgerSoda} style={{ fontSize: "24px" }} />} value={1}/>
              <Tab icon={<FontAwesomeIcon icon={faCar} style={{ fontSize: "24px" }} />} value={2}/>
            </Tabs>
          </AppBar>
          {selectedTab === -1 && bookingsData && bookingSettingData && <>                  
            <BookingsManagement {...bookingManagementProps} />
            </>
            }
          {selectedTab === 0 && bookingSettingData && <AddEditBooking bookingType={0} {...bookingProps} />}
          {selectedTab === 1 && bookingSettingData && <AddEditBooking bookingType={1} {...bookingProps} />}
          {selectedTab === 2 && bookingSettingData && <AddEditBooking bookingType={2} {...bookingProps} />}
        </div>
      </div>
    </div>

  );
}

export default BookingsTabComponent;
