import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useContext, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
interface IProps {
    calendarState: any;
    changeDateRange: any;
    headerTitle: string,
    headerClass: string
}

export const StandardListHeader = ({ headerClass,calendarState, changeDateRange, headerTitle }: IProps) => {
    const { t, locale } = useContext(MultiLanguageSupportContext);
    const [calendarIsShown, setCalendarVisibility] = useState<boolean>(false);
    return (<>
        <div className={`card-header card-header-primary ${headerClass}`}>
            <h4 className="card-title ">{t(headerTitle) + ` ${moment(calendarState[0].startDate).format('L')}-${moment(calendarState[0].endDate).format('L')}`}</h4>
            <div className="card-category">
                <div className="btn-group">
                    <button className="btn btn-md btn-icon-grey mr-1" onClick={() => setCalendarVisibility(!calendarIsShown)}><FontAwesomeIcon icon={faCalendarAlt} /> {t("ALL.Button.DateFilter")}</button>
                </div>
            </div>
        </div>
        <Modal isOpen={calendarIsShown} style={{ maxWidth: "700px" }}>
            <ModalBody>
                <DateRangePicker
                    onChange={changeDateRange}
                    ////showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={calendarState}
                    direction="horizontal"
                    calendarStartDay={1}
                    locale={locale}
                />
            </ModalBody>
            <ModalFooter>
                <button onClick={() => setCalendarVisibility(!calendarIsShown)} className="btn" style={{ color: "#fff", margin: "0 auto", backgroundColor: "#091C53" }}>
                    {t("ALL.Button.Close")}
                </button>
            </ModalFooter>
        </Modal>
    </>

    );
}