import { useContext, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Case from '../../models/Case';

import moment from 'moment';
import 'moment/min/locales';

import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { TextField } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { DatePicker } from '@mui/x-date-pickers';


interface ICasePropertiesProps {
    caseDetails: Case,
    updateCase: (data: Case) => void;
}
const CaseProperties = ({ caseDetails, updateCase }: ICasePropertiesProps) =>{
    const { locale, t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        setValue("dailyAllowanceAmount", caseDetails.dailyAllowanceAmount);
        setValue("incapacityOfWork", moment(caseDetails.incapacityOfWork).year().toString() === "1" ? moment() : moment.parseZone(caseDetails.incapacityOfWork));
        setValue("endOfIntegration", moment(caseDetails.endOfIntegration).year().toString() === "1" ? null : moment.parseZone(caseDetails.endOfIntegration));
        setValue("startOfIntegration", moment(caseDetails.startOfIntegration).year().toString() === "1" ? moment() : moment.parseZone(caseDetails.startOfIntegration));
        setValue("endDailyAllowances", moment(caseDetails.endDailyAllowances).year().toString() === "1" ? null : moment.parseZone(caseDetails.endDailyAllowances));
        setValue("delayDays", caseDetails.delayDays);
        setValue("referenceNumberOfInsurance", caseDetails.referenceNumberOfInsurance);
        setValue("reflectionRemiderEnabled", caseDetails.reflectionRemiderEnabled);
        setValue("mainGoal", caseDetails.mainGoal);

    }, []);

    const validationSchema = Yup.object().shape({
        referenceNumberOfInsurance: Yup.string()
            .required(("INS.Validation.InsurerRefNumber")),
        delayDays: Yup.string()
            .required(t("CAM.Validation.DelayDays")),
        dailyAllowanceAmount: Yup.string()
            .required(t("CAM.Validation.AllowancesAmount")),
        startOfIntegration: Yup.string()
            .required(t("CAM.Validation.IntergrationStartDate")),
        incapacityOfWork: Yup.string()
            .required(t("CAM.Validation.WorkIncapacityDate")),
        mainGoal: Yup.string()
            .required(t("CAM.Validation.MainGoal")),

    });

    const { register, handleSubmit, control, setValue, getValues, errors, formState } = useForm({
        defaultValues: {
            dailyAllowanceAmount: "",
            incapacityOfWork: "",
            endOfIntegration: null,
            startOfIntegration: null,
            endDailyAllowances: null,
            delayDays: "",
            referenceNumberOfInsurance: "",
            reflectionRemiderEnabled: false,
            mainGoal: ""
        },
        resolver: yupResolver(validationSchema)
    });
    function onSubmit(data: Case) {
        return updateCase(data);
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <h4 className="card-title">{t("CAM.Case.Edit")}</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="form-group col-sm-12">
                                        <label>{t("INS.Common.InsurerRefNumber")}</label>
                                        <input name="referenceNumberOfInsurance" type="text" ref={register} className={`form-control ${errors.referenceNumberOfInsurance ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.referenceNumberOfInsurance?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.IncapacityOfWork")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    //readOnly={true}
                                                    //inputVariant="outlined"
                                                    //id="date-picker-dialog"
                                                    // inputFormat="dd/MM/yyyy"
                                                    // mask={"dd/MM/yyyy"} 
                                                    className="form-control"
                                                    value={getValues("incapacityOfWork")}
                                                    onChange={value => setValue("incapacityOfWork", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>)}
                                            name="incapacityOfWork"
                                            control={control}

                                        />
                                        <div className="invalid-feedback">{errors.incapacityOfWork?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.DelayDays")}</label>
                                        <input name="delayDays" type="number" min="0" ref={register} className={`form-control ${errors.delayDays ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.delayDays?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.AllowancesAmount")}</label>
                                        <input name="dailyAllowanceAmount" type="text" ref={register} className={`form-control ${errors.dailyAllowanceAmount ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.dailyAllowanceAmount?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.AllowancesEnd")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    //readOnly={true}
                                                    //inputVariant="outlined"
                                                    //id="date-picker-dialog"
                                                    //inputFormat="dd.MM.yyyy"
                                                    className="form-control"
                                                    value={getValues("endDailyAllowances")}
                                                    onChange={value => setValue("endDailyAllowances", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>)}
                                            name="endDailyAllowances"
                                            control={control}

                                        />
                                        <div className="invalid-feedback">{errors.endDailyAllowances?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.IntegrationStartDate")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    //readOnly={true}
                                                    //inputVariant="outlined"
                                                    //id="date-picker-dialog"
                                                    //inputFormat="dd.MM.yyyy"
                                                    className="form-control"
                                                    value={getValues("startOfIntegration")}
                                                    onChange={value => setValue("startOfIntegration", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>)}
                                            name="startOfIntegration"
                                            control={control}

                                        />
                                        <div className="invalid-feedback">{errors.startOfIntegration?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CAM.Case.IntegrationEndDate")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    //readOnly={true}
                                                    //inputVariant="outlined"
                                                    //id="date-picker-dialog"
                                                    //inputFormat="dd.MM.yyyy"
                                                    className="form-control"
                                                    value={getValues("endOfIntegration")}
                                                    onChange={value => setValue("endOfIntegration", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>)}
                                            name="endOfIntegration"
                                            control={control}

                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-12">
                                        <label>{t("CAM.Case.IntegrationMainGoal")}*</label>
                                        <textarea name="mainGoal" rows={5} ref={register} className={`form-control ${errors.mainGoal ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.mainGoal?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group row justify-content-center">
                                    <div className="col-sm-6">
                                        <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            {t("CAM.Case.Update")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export { CaseProperties };