import BaseService from '../BaseService';


export const webService = {
    getActiveCalculationProperties,
    postCreateCalculationProperties,
    getAllClientCalculations,
    getDetailCostCalculation,
    getDetailCostCalculationInDateRange

};

async function postCreateCalculationProperties(data: any) {

    let res = BaseService.update<any>("CostCalculator/CreateCalculationProperties", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function getDetailCostCalculation(): Promise<any> {
    let res = BaseService.getAll<any>("CostCalculator/GetDetailCostCalculation").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getDetailCostCalculationInDateRange(startDate:string,endDate:string,): Promise<any> {
    let res = BaseService.get<any>("CostCalculator/GetDetailCostCalculationInDateRange?",`startDateIn=${startDate}&endDateIn=${endDate}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getActiveCalculationProperties(): Promise<any> {
    let res = BaseService.getAll<any>("CostCalculator/GetActiveCalculationProperties").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllClientCalculations(): Promise<any> {
    let res = BaseService.getAll<any>("CostCalculator/GetAllClientCalculations").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
