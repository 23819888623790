import Response from "../models/Response";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { apiConfig } from "../msal/Config";
import LogedUserProps from "./props/LogedUserProps";

export default class BaseService {
    private static async getHeaders() {

        var header: AxiosRequestConfig = {
            headers: {
                'Authorization': `Bearer ${LogedUserProps.apiToken}`,
                'Content-Type': 'application/json'
            }
        }
        return header;
    }
    private static _baseURL: string = apiConfig.resourceUri;

    public static async getAll<T>(url: string): Promise<Response> {
        let header = await this.getHeaders();
        let res = await axios.get<Array<T>>(this._baseURL + url, header)
            .then((response: { data: T[]; }) => {
                return new Response(true, response.data as Array<T>, "Success", "");
            })
            .catch(function (error: AxiosError) {
                if (error.response!.status === 401) {

                    window.location.reload();
                }
                return new Response(false, null, "Error", error);
            });
        return res;
    }
    public static async webGetAll<T>(url: string): Promise<Response> {
        let header = await this.getHeaders();
        let res = await axios.get<Array<T>>(this._baseURL + url, header)
            .then((response: { data: T[]; }) => {
                return new Response(true, response.data as Array<T>, "Success", "");
            })
            .catch(function (error: AxiosError) {
                if (error.response!.status === 401) {

                    window.location.reload();
                }
                return new Response(false, null, "Error", error);
            });
        return res;
    }
    public static async uploadFile<T>(url: string, obj: T): Promise<Response> {
        let res = axios.post(this._baseURL + url, obj, {
            headers: {
                'Authorization': `Bearer ${LogedUserProps.apiToken}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        })
            .then((response: { data: any; }) => {
                return new Response(true, response.data, "Success", "");
            })
            .catch(function (error: AxiosError) {
                if (error.response!.status === 401) {

                    window.location.reload();
                }
                return new Response(false, null, "Error", error);
            });
        return res;
    }
    public static async downloadFile<T>(url: string, param: any): Promise<Response> {
        let res = axios.get<T>(this._baseURL + url + param, {
            headers: {
                'Authorization': `Bearer ${LogedUserProps.apiToken}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        })
            .then((response: { data: any; }) => {
                return new Response(true, response.data, "Success", "");
            })
            .catch(function (error: AxiosError) {
                if (error.response!.status === 401) {

                    window.location.reload();
                }
                return new Response(false, null, "Error", error);
            });
        return res;
    }

    public static async get<T>(url: string, param: any): Promise<Response> {
        let header = await this.getHeaders();
        let res = axios.get<T>(this._baseURL + url + param, header)
            .then((response: { data: any; }) => {
                return new Response(true, response.data, "Success", "");
            })
            .catch(function (error: AxiosError) {
                if (error.response!.status === 401) {

                    window.location.reload();
                }
                return new Response(false, null, "Error", error);

            });
        return res;
    }
    public static async delete(url: string, param: any): Promise<Response> {
        let header = await this.getHeaders();
        let res = axios.get(this._baseURL + url + param, header).then((response: any) => {

            return new Response(true, null, "Success", "");
        }).catch(function (error: AxiosError) {
            if (error.response!.status === 401) {
                //window.location.reload(false);
                window.location.reload();
            }
            return new Response(false, null, "Error", error);
        });
        return res;
    }
    public static async create<T>(url: string, obj: T): Promise<Response> {
        let header = await this.getHeaders();
        let res = axios.post(this._baseURL + url, obj, header)
            .then((response: { data: any; }) => {
                return new Response(true, response.data, "Success", "");
            }).catch(function (error: AxiosError) {
                if (error.response!.status === 401) {

                    window.location.reload();
                }
                return new Response(false, null, "Error", error);
            });
        return res;
    }
    public static async post<T>(url: string, obj: T): Promise<Response> {
        let header = await this.getHeaders();
        let res = axios.post(this._baseURL + url, obj, header)
            .then((response: { data: any; }) => {
                return new Response(true, response.data, "Success", "");
            }).catch(function (error: AxiosError) {
                if (error.response!.status === 401) {

                    window.location.reload();
                }
                return new Response(false, null, "Error", error);
            });
        return res;
    }
    public static async update<T>(url: string, obj: T): Promise<Response> {
        let header = await this.getHeaders();
        let res = axios.post(this._baseURL + url, obj, header)
            .then((response: { data: any; }) => {
                return new Response(true, response.data, "Success", "");
            }).catch(function (error: AxiosError) {
                if (error.response!.status === 401) {

                    window.location.reload();
                }
                return new Response(false, null, "Error", error);
            });
        return res;
    }
}