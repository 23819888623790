import React, { useContext, useState, useEffect } from "react";
import "./Dropzone.css";
import { faCloudUploadAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiLanguageSupportContext } from "../../../ctx/MultiLanguageSupportProvider";
type IDropzoneProps = {
  disabled: boolean;
  onFilesAddedProps: any;
};
interface IDropzoneState {
  hightlight: boolean;
}
export const Dropzone = ({ disabled, onFilesAddedProps }: IDropzoneProps) => {

  const fileInputRef: any = React.createRef();
  const [dropZoneState, setDropZoneState] = useState<IDropzoneState>({ hightlight: false });
  const { t } = useContext(MultiLanguageSupportContext);


  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const openFileDialog = () => {
    if (disabled) return;
    fileInputRef.current.click();
  }


  const onFilesAdded = (evt: any) => {
    if (disabled) return;
    const files = evt.target.files;
    if (onFilesAddedProps) {
      const array = fileListToArray(files);
      onFilesAddedProps(array);
    }
  }

  const onDragOver = (event: any) => {
    event.preventDefault();
    if (disabled) return;
    setDropZoneState({
      ...dropZoneState, hightlight: true
    });
  }

  const onDragLeave = (event: any) => {
    setDropZoneState({
      ...dropZoneState, hightlight: false
    });
  }

  const onDrop = (event: any) => {
    event.preventDefault();
    if (disabled) return;
    const files = event.dataTransfer.files;
    if (onFilesAddedProps) {
      const array = fileListToArray(files);
      onFilesAddedProps(array);
    }
    setDropZoneState({
      ...dropZoneState, hightlight: false
    });
  }

  const fileListToArray = (list: any) => {
    const array = Array();
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  const isMobile = width <= 768;

  return (isMobile ?

    <div>
      <div className="inputWrapper" >
        <label className="labelMobile"> Upload Files
          <input ref={fileInputRef} className="fileInputMobile" type="file" multiple onChange={onFilesAdded} />
        </label>
      </div>
    </div>

    :
    <div
      className={`Dropzone ${dropZoneState.hightlight ? "Highlight" : ""}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{ cursor: disabled ? "default" : "pointer" }}
    >
      <input
        ref={fileInputRef}
        className="FileInput"
        type="file"
        multiple
        onChange={onFilesAdded}
      />
      <FontAwesomeIcon icon={faCloudUploadAlt} />
      <span>{t("ALL.DMS.UploadFiles")}</span>
    </div>

  );
}

