import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faBurgerSoda, faCar, faCircleXmark, faEdit, faEye, faHeadSideMedical, faTrash } from '@fortawesome/pro-light-svg-icons';
import { AppraisalListData } from './ApprisalListData';
import { Link } from 'react-router-dom';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

interface IAppraisalListProps {
    list:any[]
}
export const AppraisalList = ({list}:IAppraisalListProps) => {
    const { language, t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [tab, setTab] = useState('desc');
    moment.locale(language);
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <h4 className="card-title">{t("CLN.ResilienceProfile.ProfilesOverview")}</h4>
                            <div className="col-sm-1">
                                <FontAwesomeIcon icon={faBold} style={{ fontSize: "32px", color: "blue" }} />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <th>{t("CLN.ResilienceProfile.ApprovalDateEMP")}</th>
                                        <th>{t("CLN.ResilienceProfile.ApprovalDateCLN")}</th>
                                        <th>{t("CLN.ResilienceProfile.ApprovalDateDOC")}</th>
                                        <th>{t("CLN.ResilienceProfile.AppraisalTitle")}</th>
                                        <th>{t("CLN.ResilienceProfile.AppraisalStatus")}</th>
                                        <th>{t("ALL.Button.Actions")}</th>
                                    </thead>
                                    <tbody>
                                        {list.map(a =>
                                            <tr key={a.id}>
                                                <td>{a.appraisalTitle}</td>
                                                <td>{a.approvalDateEmployer.format("DD/MM/YYYY")}</td>
                                                <td>{a.approvalDatePatient.format("DD/MM/YYYY")}</td>
                                                <td>{a.approvalDoctor.format("DD/MM/YYYY")}</td>
                                                <td>{a.status}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                    <Link to={`/${authUserData.currUserData.roleType.toLowerCase()}/appraisal-info/${a.id}`} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faEdit} /></Link>
                                                    <button className="btn btn-md btn-icon-red">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}
