import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBold, faBurgerSoda, faCar, faCircleXmark, faEdit, faEye, faHeadSideMedical, faTrash } from '@fortawesome/pro-light-svg-icons';
import { AppraisalListData } from './ApprisalListData';
import { Link } from 'react-router-dom';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';


export const PhisicalRequierements = () => {
    const { language, t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const changePossible = authUserData.currUserData.rolePath === "employeer";
    moment.locale(language);
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <div className="row  justify-content-between">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label className="col-sm-5 col-form-label">{t("CLN.ResilienceProfile.ProfileSelection")}</label>
                                        <div className="col-sm-1">
                                            <FontAwesomeIcon icon={faBold} style={{ fontSize: "32px", color: "blue" }} />
                                        </div>
                                        <div className="col-sm-6">
                                            <select name="rate" className={`form-control `}>
                                                <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.1")}</option>
                                                <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.2")}</option>
                                                <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.3")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    {changePossible &&
                                        <button className="btn btn-md btn-icon-red">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            {changePossible &&
                                <div className="row  justify-content-between">
                                    <div className="col-sm-6">

                                        <label>{t("CLN.ResilienceProfile.NewRequirementFromList")}</label>
                                        <div className="form-group row">
                                            <div className="col-sm-8">
                                                <select name="rate" className={`form-control `}>
                                                    <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.1")}</option>
                                                    <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.2")}</option>
                                                    <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.3")}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>{t("CLN.ResilienceProfile.NewRequirementText")}</label>
                                        <div className="form-group row">
                                            <div className="col-sm-8">
                                                <input name="titleAppraisal" type="text" className={`form-control `} />
                                            </div>

                                            <button className="btn btn-md btn-icon-grey">
                                                <FontAwesomeIcon icon={faAdd} />
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            }
                            <hr></hr>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <th>{t("CLN.ResilienceProfile.SectionTable.Requirement")}</th>
                                        <th>{t("CLN.ResilienceProfile.SectionTable.Status")}</th>
                                        <th>{t("CLN.ResilienceProfile.SectionTable.Limitation")}</th>
                                        <th>{t("ALL.Button.Actions")}</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>R1</td>
                                            <td>Possible</td>
                                            <td></td>
                                            {changePossible &&
                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                    <button className="btn btn-md btn-icon-red">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>R2</td>
                                            <td>Not Possible</td>
                                            <td></td>
                                            {changePossible &&
                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                    <button className="btn btn-md btn-icon-red">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>R3</td>
                                            <td>Limited Possible</td>
                                            <td></td>
                                            {changePossible &&
                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                    <button className="btn btn-md btn-icon-red">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            }
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}
