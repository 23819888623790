import { Route, Routes } from 'react-router-dom';
import { EditContact } from '../casemanager/EditContact';
import { CaseManagement } from '../admin/CaseManagement';
import { ManageOwner } from './ManageOwner';
import { ManageMembers } from './ManageMembers';
import { ManageContacts } from '../casemanager/ManageContacts';
import { Branding } from './Branding';
import { AddCase } from '../casemanager/AddCase';
import { LicenseReport } from '../admin/license/LicenseReport';
import ViewCase from '../casemanager/ViewCase';
import { TemplateManagement } from './formtemplates/TemplateManagement';
import { AddEditFormTemplate } from './formtemplates/AddEditFormTemplate';
import { useContext } from 'react';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { TaskRulesManagement } from '../../components/tasks/rulemanagement/TaskRulesManagement';
import { NotificationSettingsManagement } from '../../components/notifications/NotificationSettingsManagement';
import { DictionaryManagement } from '../../components/dictionary/DictionaryManagement';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';


function InsurerPanel() {
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    return (
        <Routes>
            <Route path={`/`} element={<CaseManagement insurerId={authUserData.currUserData.relatedData[0].id} role="insurer" />} />
            <Route path={`/all-cases`} element={<CaseManagement insurerId={authUserData.currUserData.relatedData[0].id} role="insurer" />} />
            <Route path={`/add-case`} element={<AddCase/>}/>
            <Route path={`/manage-owners`} element={<ManageOwner/>}/>
            <Route path={`/manage-contacts/:id`} element={<ManageContacts/>}/>
            <Route path={`/manage-members`} element={<ManageMembers/>}/>
            <Route path={`/license-report/:insurerId`} element={<LicenseReport/>}/>
            <Route path={`/edit-contact/:id`} element={<EditContact/>}/>
            <Route path={`/view-case/:id/:notId`} element={<ViewCase/>}/>
            <Route path={`/brand-insurer/:id`} element={<Branding/>}/>
            <Route path={`/manage-form-templates`} element={<TemplateManagement/>}/>
            <Route path={`/edit-goal-template/:id`} element={<AddEditFormTemplate/>}/>
            <Route path={`/add-goal-template`} element={<AddEditFormTemplate/>}/>
            <Route path={`/task-rules`} element={<TaskRulesManagement/>}/>
            <Route path="/manage-dictionary" element={<DictionaryManagement scopeId={1} scopeEntityId={authUserData.currUserData.relatedData[0].id} title={t("ALL.Dictionary.ServiceProviderTitle")}/>} />
            <Route path={`/manage-notification-settings`} element={<NotificationSettingsManagement allScopes={[{scopeTypeId:0,scopeEntityId:-1},{scopeTypeId:1,scopeEntityId:authUserData.currUserData.relatedData[0].id}]}scope={{scopeTypeId:1,scopeEntityId:authUserData.currUserData.relatedData[0].id}} />} />
        </Routes>
    );
}
export { InsurerPanel };