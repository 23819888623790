import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Tabs, Tab } from "react-bootstrap";
import { alertService, notesService } from '../../services/Index';

import { ListNotes } from './ListNotes';
import Note from '../../models/Note';
import { useNavigate, useParams } from "react-router-dom";
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { AppAccessContext } from '../../ctx/AppAccessProvider ';
interface INotesManagementProps {
    caseId?: number,
    role: string
}
function NotesManagement({ role, caseId }: INotesManagementProps) {
    const { caseData, setCaseData } = useContext(CaseDataContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [contactNotes, setContactNotes] = useState<Array<any>>();
    const [sharedNotes, setSharedNotes] = useState<Array<any>>();
    let navigate = useNavigate();
    let { id } = useParams();
    const notCaseId = id ? parseInt(id) : caseId === undefined ? caseData.selectedCaseId : caseId;
    const [tabKey, setTabKey] = useState('Contact');
    const { t } = useContext(MultiLanguageSupportContext);
    const { setAppAccess } = useContext(AppAccessContext);
    useEffect(() => {
        if (notCaseId === undefined) {
            navigate(`/${role.toLowerCase()}`);
        }
        setAppAccess({
            hasAccessToPath: (authUserData.currUserData.relatedData.filter(data => data.id === notCaseId).length > 0),
        });
        if (notCaseId !== undefined) {
            if (caseData.selectedCaseId === undefined) {
                setCaseData({
                    ...caseData,
                    selectedCaseId: notCaseId
                });
            }
            notesService.getContactCaseNotes(authUserData.currUserData.profileId, notCaseId).then((rp) => {
                setContactNotes(rp);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
            notesService.getSharedNotesCaseNotes(authUserData.currUserData.profileId, notCaseId).then((rp) => {
                setSharedNotes(rp);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
    }, []);

    function deleteNote(noteId: number) {

        if (tabKey === 'Contact') {
            setContactNotes(contactNotes!.map(x => {
                if (x.id === noteId) { x.isDeleteing = true; }
                return x;
            }));
        } else {
            setSharedNotes(sharedNotes!.map(x => {
                if (x.id === noteId) { x.isDeleteing = true; }
                return x;
            }));
        }
        notesService.deleteNote(noteId).then((rp) => {
            alertService.success(t("ALL.Notes.Deleted"), { keepAfterRouteChange: true });
            if (tabKey === 'Contact') {
                setContactNotes(m => m!.filter(x => x.id !== noteId));
            } else {
                setSharedNotes(m => m!.filter(x => x.id !== noteId));
            }
        }).catch(
            (rp) => {

                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                if (tabKey === 'Contact') {
                    setContactNotes(contactNotes!.map(x => {
                        if (x.id === noteId) { x.isDeleteing = false; }
                        return x;
                    }));
                } else {
                    setSharedNotes(sharedNotes!.map(x => {
                        if (x.id === noteId) { x.isDeleteing = false; }
                        return x;
                    }));
                }
            }
        );
    }
    function addNote(note: Note) {
        if (tabKey === 'Contact') {
            setContactNotes(notes => [note, ...contactNotes!]);
        } else {
            setSharedNotes(notes => [note, ...sharedNotes!]);
        }

    }
    function editNote(note: Note) {
        if (tabKey === 'Contact') {

            // setContactNotes(contactNotes!.map(x => {
            //     if (x.id === note.id) { x.isDeleteing = true; }
            //     return x;
            // }));

            setContactNotes(contactNotes => contactNotes!.map(x => {
                if (x.id === note.id) { x = note }
                return x;
            }));
        } else {
            setSharedNotes(sharedNotes!.map(x => {
                if (x.id === note.id) { x.isDeleteing = true; }
                return x;
            }));
        }

    }
    return (
        <div className="container">
            <p className="component-header">{t("ALL.Notes.List")}</p>
            <hr></hr>
            <Tabs activeKey={tabKey}
                onSelect={(k: any) => { setTabKey(k); }}>
                <Tab eventKey="Contact" title={t("ALL.Notes.MyNotes")}>
                    <ListNotes add={addNote} edit={editNote} deleteNote={deleteNote} notes={contactNotes} contactNotes={true} />
                </Tab>
                <Tab eventKey="Shared" title={t("ALL.Notes.NotesSharedBy")}>
                    <ListNotes add={addNote} edit={editNote} deleteNote={deleteNote} notes={sharedNotes} contactNotes={false} />
                </Tab>
            </Tabs>
        </div>

    );
}

export { NotesManagement };