import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { clientService, alertService, notificationService } from '../../services/Index';
import Reflection from '../../models/Reflection';
import moment from 'moment';
import 'moment/min/locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { Twemoji } from 'react-emoji-render';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';


const ViewReflection=()=> {
    const { caseData } = useContext(CaseDataContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    const { t } = useContext(MultiLanguageSupportContext);
    const { id, notId } = useParams();
    const [reflectionItem, setReflection] = useState<Reflection>();
    const {notificationsDetails, setNotifications} = useContext(NotificationContext);

    useEffect(() => {
        clientService.getSingleReflection(parseInt(id!)).then(i => {
            setReflection({
                caseId: i.caseId,
                taskExecuted: i.taskExecuted,
                healthStateDurnigWork: i.healthStateDurnigWork,
                healthStateAfterWork: i.healthStateAfterWork,
                remarks: i.remarks,
                mood:i.mood,
                workingTimesMorningStart: i.workingTimesMorningStart !== null ? moment(new Date(i.workingTimesMorningStart)).format('HH:mm') : "-",
                workingTimesMorningEnd: i.workingTimesMorningEnd !== null ? moment(new Date(i.workingTimesMorningEnd)).format('HH:mm') : "-",
                workingTimesAfternoonStart: i.workingTimesAfternoonStart !== null ? moment(new Date(i.workingTimesAfternoonStart)).format('HH:mm') : "-",
                workingTimesAternoonEnd: i.workingTimesAternoonEnd !== null ? moment(new Date(i.workingTimesAternoonEnd)).format('HH:mm') : "-",
                created: i.created,
                workingTime: i.workingTime,
                reflectionDate: moment(i.reflectionDate).format("L")

            });
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        if (notId !== "No") {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber:notificationsDetails.notificationNumber-1
            })
        }
    }, [notId]);


    return (
        <div className="container">
            {reflectionItem && <>
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{t("CLN.Reflection.Title")}</h4>
                                <p>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                    {/* <Link to={notId !== "No" && caseData.caseNumber === undefined ? "../" : `../dashboards/${reflectionItem.caseId}`} className="back-btn">{t("ALL.Button.Back")}</Link> */}
                                    <Link to={notId !== "No" && caseData.caseNumber === undefined ? "../" : `/client/show-reflections`} className="back-btn">{t("ALL.Button.Back")}</Link>    
                                </p>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.Date")}</label>
                                        <div>
                                            <label>{reflectionItem.reflectionDate}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row justify-content-md-left">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.MorningStartTime")}</label>
                                        <div>{reflectionItem.workingTimesMorningStart}</div>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.MorningEndTime")}</label>
                                        <div>{reflectionItem.workingTimesMorningEnd}</div>
                                    </div>
                                </div>
                                <div className="form-row justify-content-md-left">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.AfternoonStartTime")}</label>
                                        <div>{reflectionItem.workingTimesAfternoonStart}</div>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.AfternoonEndTime")}</label>
                                        <div>{reflectionItem.workingTimesAternoonEnd}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.TaskPerformed")}</label>
                                        <div>
                                            <label>{reflectionItem.taskExecuted}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.HealthStateDuringWork")}</label>
                                        <div>{t(`CLN.Reflection.HealthState.${reflectionItem.healthStateDurnigWork}`)}</div>

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.HealthStateAfterWork")}</label>
                                        <div>{t(`CLN.Reflection.HealthState.${reflectionItem.healthStateAfterWork}`)}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label-bold">{t("CLN.Reflection.Help.Mood")}</label>

                                        <div className="cc-selector">
                                            <label className={`form-check-label emoji-cc ${reflectionItem.mood?.toString()==="1"?"active":""}`}><Twemoji text="😄" /></label>

                                            <label className={`form-check-label emoji-cc ${reflectionItem.mood?.toString()==="2"?"active":""}`}><Twemoji text="🙂" /></label>
             
                                            <label className={`form-check-label emoji-cc ${reflectionItem.mood?.toString()==="3"?"active":""}`}><Twemoji text="😐" /></label>
                                       
                                            <label className={`form-check-label emoji-cc ${reflectionItem.mood?.toString()==="4"?"active":""}`}><Twemoji text="😔" /></label>
                                            
                                            <label className={`form-check-label emoji-cc ${reflectionItem.mood?.toString()==="5"?"active":""}`}><Twemoji text="😢" /></label>
                                            
                                        </div>
                                 
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-4">
                                        <label className="col-form-label-bold">{t("CLN.Reflection.GeneralRemarks")}</label>
                                        <div>{reflectionItem.remarks}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>}
        </div>

    );
}

export { ViewReflection };

