
import React, { useContext, useEffect, useState } from 'react';
import '../../assets/css/tabcomponent.css';
import '@asseinfo/react-kanban/dist/styles.css';


import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab } from "react-bootstrap";
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';


import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkplaceManagement } from './WorkplaceManagement';
import { AddEditAppraisal } from './AddEditAppraisal';
import { AppraisalListData } from './ApprisalListData';
import { PhisicalRequierements } from './PhisicalRequierements';


function AppraisalInfo() {
  let navigate = useNavigate();
  const [tab, setTab] = useState('desc');

  const [apprisal, setApprisal] = useState<any>();
  const { t } = useContext(MultiLanguageSupportContext);
  const { authUserData } = useContext(LoggedUserDataContext);
  const { id } = useParams();


  const changeActiveTab = (tabName) => {
    setTab(tabName);

  }
  useEffect(() => {
    setApprisal(AppraisalListData.find(s=> s.id === parseInt(id!)));

  }, [id]);
  return (
    <div className="container">
      <Tabs
        activeKey={tab}
        onSelect={(t) => changeActiveTab(t)}>
        <Tab eventKey="desc" title={t("CLN.ResilienceProfile.SectionGeneral")}><AddEditAppraisal/></Tab>
        <Tab eventKey="physicalReq" title={t("CLN.ResilienceProfile.SectionPhysical")}><PhisicalRequierements/></Tab>
        <Tab eventKey="mentalReq" title={t("CLN.ResilienceProfile.SectionMental")}></Tab>
        <Tab eventKey="workplaceReq" title={t("CLN.ResilienceProfile.SectionWorkplace")}></Tab>
      </Tabs>
    </div>

  );
}

export default AppraisalInfo;
