import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { clientService, alertService, notificationService } from '../../services/Index';
import Reflection from '../../models/Reflection';
import clock from '../../assets/images/clock.png';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/min/locales';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { ToolTip } from '../../components/ToolTip';
import { helperFunctions } from '../../helpers/HelperFunctions';
import { Twemoji } from 'react-emoji-render';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { AppSettingsContext } from '../../ctx/AppSettingsProvider';

const AddEditReflection = () => {
    const { appSettings } = useContext(AppSettingsContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const { locale, t, mask } = useContext(MultiLanguageSupportContext);
    let navigate = useNavigate();
    const { id } = useParams();
    const isAddMode: boolean = !id;
    const [reflectionItem, setReflection] = useState<Reflection>();
    const [morningStart, setMorningStart] = useState<Date | null>(null);
    const [morningEnd, setMorningEnd] = useState<Date | null>(null);
    const [afternoonStart, setAfternoonStart] = useState<Date | null>(null);
    const [afternoonEnd, setAfternoonEnd] = useState<Date | null>(null);
    const [reflectionDate, setReflectionDate] = useState<Date | null>(null);

    const { caseData } = useContext(CaseDataContext);
    var dt = new Date();
    dt = appSettings.length > 0 ? new Date(dt.setDate(dt.getDate() - appSettings.filter(s => s.key === "ReflectionPastDaysAllowed")[0].valueNumber)) : dt;
    const minDate = isAddMode ? dt : null;
    const maxDate = isAddMode ? new Date() : null;
    useEffect(() => {
        if (caseData.caseNumber === undefined) {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }
        else {
            if (!isAddMode) {
                clientService.getSingleReflection(parseInt(id!)).then(i => {
                    setReflectionDate(new Date(i.reflectionDate));
                    setReflection({
                        caseId: i.caseId,
                        taskExecuted: i.taskExecuted,
                        healthStateDurnigWork: i.healthStateDurnigWork,
                        healthStateAfterWork: i.healthStateAfterWork,
                        mood: i.mood,
                        remarks: i.remarks,
                        workingTimesMorningStart: new Date(i.workingTimesMorningStart),
                        workingTimesMorningEnd: new Date(i.workingTimesMorningEnd),
                        workingTimesAfternoonStart: new Date(i.workingTimesAfternoonStart),
                        workingTimesAternoonEnd: new Date(i.workingTimesAternoonEnd),
                        created: i.created,
                        workingTime: i.workingTime,
                        reflectionDate: i.reflectionDate,
                        workTimeAfternoonMinutes: i.workTimeAfternoonMinutes,
                        workTimeMorningMinutes: i.workTimeMorningMinutes
                    });
                    setValue("taskExecuted", i.taskExecuted);
                    setValue("healthStateDurnigWork", i.healthStateDurnigWork);
                    setValue("healthStateAfterWork", i.healthStateAfterWork);
                    setValue("mood", i.mood ? i.mood.toString() : i.mood);
                    setValue("remarks", i.remarks);
                    setValue("workingTimesMorningStart", i.workingTimesMorningStart === null ? null : new Date(i.workingTimesMorningStart));
                    setValue("workingTimesMorningEnd", i.workingTimesMorningEnd === null ? null : new Date(i.workingTimesMorningEnd));
                    setValue("workingTimesAfternoonStart", i.workingTimesAfternoonStart === null ? null : new Date(i.workingTimesAfternoonStart));
                    setValue("workingTimesAternoonEnd", i.workingTimesAternoonEnd === null ? null : new Date(i.workingTimesAternoonEnd));
                    setValue("reflectionDate", i.reflectionDate === null ? null : new Date(i.reflectionDate));
                    setMorningStart(i.workingTimesMorningStart === null ? null : new Date(i.workingTimesMorningStart));
                    setMorningEnd(i.workingTimesMorningEnd === null ? null : new Date(i.workingTimesMorningEnd));
                    setAfternoonStart(i.workingTimesAfternoonStart === null ? null : new Date(i.workingTimesAfternoonStart));
                    setAfternoonEnd(i.workingTimesAternoonEnd === null ? null : new Date(i.workingTimesAternoonEnd));

                }).catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );
            }
            else {
                setMorningStart(null);
                setMorningEnd(null);
                setAfternoonStart(null);
                setAfternoonEnd(null);
                setReflectionDate(new Date());
                reset();
            }
        }
    }, [id]);

    const validationSchema = Yup.object().shape({
        taskExecuted: Yup.string().required(t("CLN.Validation.Task")),
        healthStateDurnigWork: Yup.string().required(t("CLN.Validation.HealthStateDuringWork")),
        healthStateAfterWork: Yup.string().required(t("CLN.Validation.HealthStateAfterWork")),
    });

    const { register, handleSubmit, reset, setValue, getValues, errors, formState, control } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            workingTimesMorningStart: null,
            workingTimesMorningEnd: null,//moment.parseZone(new Date()),
            workingTimesAfternoonStart: null,//moment.parseZone(new Date()),
            workingTimesAternoonEnd: null,//moment.parseZone(new Date()),
            reflectionDate: new Date(),
            caseId: -1,
            taskExecuted: '',
            healthStateDurnigWork: 0,
            healthStateAfterWork: 0,
            mood: "0",
            remarks: '',
            created: '',
            workingTime: '',
        }
    });

    function onSubmit(data: Reflection) {
        return isAddMode
            ? createReflection(data)
            : updateReflection(parseInt(id!), data);
    }
    function setDate(date: moment.Moment, time: moment.Moment) {
        var m = moment().utc();
        console.log(m);
        date = moment.parseZone(date);
        time = moment.parseZone(time);
        var newDate = m.set({
            year: date.get('year'),
            month: date.get('month'),
            day: date.get('day'),
            hour: time.get('hour'),
            minute: time.get('minute')
        });
        console.log(time.toISOString() + "|" + newDate.toISOString() + "|" + date.toISOString());
        return newDate;
    }

    function createReflection(data: any) {
        if (!(moment(data.reflectionDate).isSameOrAfter(minDate, 'day') && moment(data.reflectionDate).isSameOrBefore(maxDate, 'day'))) {
            alertService.error(t("CLN.Validation.ReflectionDate"), { keepAfterRouteChange: true })
            return false;
        }
        if ((data.workingTimesMorningStart === null && data.workingTimesAfternoonStart === null) || (data.workingTimesMorningEnd === null && data.workingTimesAternoonEnd === null)) {
            alertService.error(t("CLN.Validation.WorkingStartAtLeastMorningOrAfternoon"), { keepAfterRouteChange: true })
            return false;
        }
        if ((data.workingTimesAfternoonStart !== null && data.workingTimesMorningStart !== null && data.workingTimesMorningStart > data.workingTimesAfternoonStart) ||
            (data.workingTimesAfternoonEnd !== null && data.workingTimesMorningStart && data.workingTimesMorningStart > data.workingTimesAfternoonEnd) ||
            (data.workingTimesAternoonEnd !== null && data.workingTimesMorningEnd && data.workingTimesMorningEnd > data.workingTimesAternoonEnd) ||
            (data.workingTimesAfternoonStart !== null && data.workingTimesMorningEnd && data.workingTimesMorningEnd > data.workingTimesAfternoonStart)) {
            alertService.error(t("CLN.Validation.WorkingAfternoonBeforeMorning"), { keepAfterRouteChange: true })
            return false;
        }
        if ((data.workingTimesMorningStart !== null && data.workingTimesMorningStart > data.workingTimesMorningEnd) || (data.workingTimesMorningEnd === null && data.workingTimesAternoonStart > data.workingTimesAternoonEnd)) {
            alertService.error(t("CLN.Validation.WorkingStartBeforeEnd"), { keepAfterRouteChange: true })
            return false;
        }
        var forbidenWordsArray = [
            caseData.caseClient.name?.toLowerCase(), caseData.caseClient.surname?.toLowerCase(), , caseData.caseClient.mail?.toLowerCase(),
            caseData.caseDoctor.name?.toLowerCase(), caseData.caseDoctor.surname?.toLowerCase(), caseData.caseDoctor.mail?.toLowerCase(),
            caseData.caseEmployeer.name?.toLowerCase(), caseData.caseEmployeer.surname?.toLowerCase(), caseData.caseEmployeer.mail?.toLowerCase(),
            caseData.caseCaseManager.name?.toLowerCase(), caseData.caseCaseManager.surname?.toLowerCase(), caseData.caseCaseManager.mail?.toLowerCase()
        ]
        if (helperFunctions.nameContentCheckerIsInvalid(data.taskExecuted, forbidenWordsArray) || helperFunctions.nameContentCheckerIsInvalid(data.remarks, forbidenWordsArray)) {
            alertService.error(t("ALL.Validation.NameDetection"), { keepAfterRouteChange: true })
            return false;
        }
        data.caseId = authUserData.currUserData.relatedData[0].id;
        data.workingTimesMorningStart = !data.workingTimesMorningStart ? data.workingTimesMorningStart : setDate(data.reflectionDate, data.workingTimesMorningStart);
        data.workingTimesMorningEnd = !data.workingTimesMorningEnd ? data.workingTimesMorningEnd : setDate(data.reflectionDate, data.workingTimesMorningEnd);
        data.workingTimesAfternoonStart = !data.workingTimesAfternoonStart ? data.workingTimesAfternoonStart : setDate(data.reflectionDate, data.workingTimesAfternoonStart);
        data.workingTimesAternoonEnd = !data.workingTimesAternoonEnd ? data.workingTimesAternoonEnd : setDate(data.reflectionDate, data.workingTimesAternoonEnd);
        data.createdBy = authUserData.currUserData.profileId;
        return clientService.postNewReflection(data)
            .then((rp) => {
                alertService.success(t("CLN.Reflection.Added"), { keepAfterRouteChange: true });
                navigate('../show-reflections');
                notificationService.postNewNotifications(
                    {
                        caseId: authUserData.currUserData.relatedData[0].id,
                        senderContactId: authUserData.currUserData.profileId,
                        notificationStatus: "New",
                        notificationTitle: t("ALL.Notifications.TitleReflection"),
                        notificationMessage: t("ALL.Notifications.MessageReflection"),
                        notificationItemUrl: `view-reflection/${rp.Data.id}`,
                        notificationDate: moment(),
                        notificationSettingDictionaryId: 1,
                        scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: caseData.insurerId }, { scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId! }],
                        templateId: 601

                    })
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    function updateReflection(id: number, data: any) {
        if ((data.workingTimesMorningStart === null && data.workingTimesAfternoonStart === null) || (data.workingTimesMorningEnd === null && data.workingTimesAternoonEnd === null)) {
            alertService.error(t("CLN.Validation.WorkingStartAtLeastMorningOrAfternoon"), { keepAfterRouteChange: true })
            return false;
        }
        if ((data.workingTimesAfternoonStart !== null && data.workingTimesMorningStart !== null && data.workingTimesMorningStart > data.workingTimesAfternoonStart) ||
            (data.workingTimesAfternoonEnd !== null && data.workingTimesMorningStart && data.workingTimesMorningStart > data.workingTimesAfternoonEnd) ||
            (data.workingTimesAternoonEnd !== null && data.workingTimesMorningEnd && data.workingTimesMorningEnd > data.workingTimesAternoonEnd) ||
            (data.workingTimesAfternoonStart !== null && data.workingTimesMorningEnd && data.workingTimesMorningEnd > data.workingTimesAfternoonStart)) {
            alertService.error(t("CLN.Validation.WorkingAfternoonBeforeMorning"), { keepAfterRouteChange: true })
            return false;
        }
        if ((data.workingTimesMorningStart !== null && data.workingTimesMorningStart > data.workingTimesMorningEnd) || (data.workingTimesMorningEnd === null && data.workingTimesAternoonStart > data.workingTimesAternoonEnd)) {
            alertService.error(t("CLN.Validation.WorkingStartBeforeEnd"), { keepAfterRouteChange: true })
            return false;
        }
        var forbidenWordsArray = [
            caseData.caseClient.name?.toLowerCase(), caseData.caseClient.surname?.toLowerCase(), , caseData.caseClient.mail?.toLowerCase(),
            caseData.caseDoctor.name?.toLowerCase(), caseData.caseDoctor.surname?.toLowerCase(), caseData.caseDoctor.mail?.toLowerCase(),
            caseData.caseEmployeer.name?.toLowerCase(), caseData.caseEmployeer.surname?.toLowerCase(), caseData.caseEmployeer.mail?.toLowerCase(),
            caseData.caseCaseManager.name?.toLowerCase(), caseData.caseCaseManager.surname?.toLowerCase(), caseData.caseCaseManager.mail?.toLowerCase()
        ]
        if (helperFunctions.nameContentCheckerIsInvalid(data.taskExecuted, forbidenWordsArray) || helperFunctions.nameContentCheckerIsInvalid(data.remarks, forbidenWordsArray)) {
            alertService.error(t("ALL.Validation.NameDetection"), { keepAfterRouteChange: true })
            return false;
        }
        data.id = id;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.workingTimesMorningStart = !data.workingTimesMorningStart ? data.workingTimesMorningStart : setDate(data.reflectionDate, data.workingTimesMorningStart);
        data.workingTimesMorningEnd = !data.workingTimesMorningEnd ? data.workingTimesMorningEnd : setDate(data.reflectionDate, data.workingTimesMorningEnd);
        data.workingTimesAfternoonStart = !data.workingTimesAfternoonStart ? data.workingTimesAfternoonStart : setDate(data.reflectionDate, data.workingTimesAfternoonStart);
        data.workingTimesAternoonEnd = !data.workingTimesAternoonEnd ? data.workingTimesAternoonEnd : setDate(data.reflectionDate, data.workingTimesAternoonEnd);
        return clientService.postUpdateReflection(data)
            .then(() => {
                alertService.success(t("CLN.Reflection.Updated"), { keepAfterRouteChange: true });
                navigate('../show-reflections');
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    return (
        <div className="container">
            {!reflectionItem && !isAddMode &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {(reflectionItem && !isAddMode || isAddMode) &&
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{isAddMode ? t("CLN.Reflection.Add") : t("CLN.Reflection.Edit")}</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("CLN.Reflection.Date")}*</label>
                                        <div className="col-sm-6">
                                            <Controller
                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                    <DatePicker
                                                        minDate={minDate}
                                                        maxDate={maxDate}
                                                        value={reflectionDate}
                                                        onChange={(date) => {
                                                            setValue('reflectionDate', date);
                                                            setReflectionDate(date);
                                                        }}
                                                        mask={mask}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>)}
                                                name="reflectionDate"
                                                control={control}

                                            />

                                            <FontAwesomeIcon icon={faInfoCircle} id="TooltipReflectionDate" className="info-icon" />
                                            <ToolTip infoText={t("CLN.Reflection.Help.ReflectionDate")} target="TooltipReflectionDate" />

                                        </div>

                                    </div>
                                    <div className="form-group row justify-content-center">
                                        <div className="col-lg-10 col-md-12">
                                            <div className="card card-profile">
                                                <h6 className="card-title" style={{position: "absolute", bottom: "95%", left: "10%"}}>{t("CLN.Reflection.WorkingTimes")}</h6>
                                                <div className="card-avatar"><img className="img" src={clock} /></div>
                                                <div className="card-body">
                                                    <div className="form-group row">
                                                        <div className="form-group col-sm-6">
                                                            <div>
                                                                <label>{t("CLN.Reflection.MorningStartTime")}</label>
                                                            </div>
                                                            <Controller className="form-control"
                                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                                    <TimePicker
                                                                        ampm={false}
                                                                        openTo="hours"
                                                                        views={['hours', 'minutes']}
                                                                        //value={getValues("workingTimesMorningStart") === null || getValues("workingTimesMorningStart") === undefined ? null : getValues("workingTimesMorningStart")}
                                                                        value={morningStart}
                                                                        onChange={value => {
                                                                            if (value !== null) {
                                                                                setMorningStart(value);
                                                                                setValue("workingTimesMorningStart", value)
                                                                                console.log(value);
                                                                            }
                                                                        }}
                                                                        minutesStep={5}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                        //minTime={new Date(0, 0, 0, 1)}
                                                                        //maxTime={new Date(0, 0, 0, 11, 55) && morningEnd}
                                                                        maxTime={morningEnd}
                                                                    />
                                                                </LocalizationProvider>)}
                                                                name="workingTimesMorningStart"
                                                                control={control}
                                                            />
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <div>
                                                                <label>{t("CLN.Reflection.MorningEndTime")}</label>
                                                            </div>
                                                            <Controller
                                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                                    <TimePicker
                                                                        ampm={false}
                                                                        openTo="hours"
                                                                        views={['hours', 'minutes']}
                                                                        //value={getValues("workingTimesMorningEnd") === null || getValues("workingTimesMorningEnd") === undefined ? null : getValues("workingTimesMorningEnd")}
                                                                        value={morningEnd}
                                                                        onChange={value => {
                                                                            if (value !== null) {
                                                                                setMorningEnd(value);
                                                                                setValue("workingTimesMorningEnd", value)
                                                                                console.log(value);
                                                                            }
                                                                        }}
                                                                        minutesStep={5}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                        minTime={morningStart}
                                                                    //minTime={new Date(0, 0, 0, 1) && morningStart}
                                                                    //maxTime={new Date(0, 0, 0, 11, 55)}
                                                                    />
                                                                </LocalizationProvider>)}
                                                                name="workingTimesMorningEnd"
                                                                control={control}
                                                            />
                                                            <FontAwesomeIcon icon={faInfoCircle} id="TooltipMorning" className="info-icon" />
                                                            <ToolTip infoText={t("CLN.Reflection.Help.Morning")} target="TooltipMorning" />
                                                        </div>

                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="form-group col-sm-6">
                                                            <div><label>{t("CLN.Reflection.AfternoonStartTime")}</label></div>
                                                            <Controller className="form-control"
                                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                                    <TimePicker
                                                                        ampm={false}
                                                                        openTo="hours"
                                                                        views={['hours', 'minutes']}
                                                                        //value={getValues("workingTimesAfternoonStart") === null || getValues("workingTimesAfternoonStart") === undefined ? null : getValues("workingTimesAfternoonStart")}
                                                                        value={afternoonStart}
                                                                        onChange={value => {
                                                                            if (value !== null) {
                                                                                setAfternoonStart(value);
                                                                                setValue("workingTimesAfternoonStart", value);
                                                                                console.log(value);
                                                                            }
                                                                        }}
                                                                        minutesStep={5}
                                                                        // minTime={new Date(0, 0, 0, 12)}
                                                                        // maxTime={new Date(0, 0, 0, 23, 55) && afternoonEnd}
                                                                        minTime={morningEnd}
                                                                        maxTime={afternoonEnd}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                    />
                                                                </LocalizationProvider>)}
                                                                name="workingTimesAfternoonStart"
                                                                control={control}
                                                            />
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <div><label>{t("CLN.Reflection.AfternoonEndTime")}</label></div>
                                                            <Controller
                                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                                    <TimePicker
                                                                        ampm={false}
                                                                        openTo="hours"
                                                                        views={['hours', 'minutes']}
                                                                        //value={getValues("workingTimesAternoonEnd") === null || getValues("workingTimesAternoonEnd") === undefined ? null : getValues("workingTimesAternoonEnd")}
                                                                        value={afternoonEnd}
                                                                        onChange={value => {
                                                                            if (value !== null) {
                                                                                setAfternoonEnd(value);
                                                                                setValue("workingTimesAternoonEnd", value);
                                                                                console.log(value);
                                                                            }
                                                                        }}
                                                                        minutesStep={5}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                        minTime={afternoonStart}
                                                                    // minTime={new Date(0, 0, 0, 12) && afternoonStart}
                                                                    // maxTime={new Date(0, 0, 0, 23, 55)}
                                                                    />
                                                                </LocalizationProvider>)}
                                                                name="workingTimesAternoonEnd"
                                                                control={control}
                                                            />
                                                            <FontAwesomeIcon icon={faInfoCircle} id="TooltipAfternoon" className="info-icon" />
                                                            <ToolTip infoText={t("CLN.Reflection.Help.Afternoon")} target="TooltipAfternoon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("CLN.Reflection.TaskPerformed")}*</label>
                                        <div className="col-sm-6">
                                            <textarea name="taskExecuted" rows={5} ref={register} className={`form-control ${errors.taskExecuted ? 'is-invalid' : ''}`} />
                                            <div className="invalid-feedback">{errors.taskExecuted?.message}</div>
                                        </div>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipTaskExecuted" className="info-icon" />
                                        <ToolTip infoText={t("CLN.Reflection.Help.TaskExecuted")} target="TooltipTaskExecuted" />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("CLN.Reflection.HealthStateDuringWork")}*</label>
                                        <div className="col-sm-6">
                                            <select name="healthStateDurnigWork" ref={register} className={`form-control ${errors.healthStateDurnigWork ? 'is-invalid' : ''}`}>
                                                <option value={10}>{t("CLN.Reflection.HealthState.10")}</option>
                                                <option value={9}>{t("CLN.Reflection.HealthState.9")}</option>
                                                <option value={8}>{t("CLN.Reflection.HealthState.8")}</option>
                                                <option value={7}>{t("CLN.Reflection.HealthState.7")}</option>
                                                <option value={6}>{t("CLN.Reflection.HealthState.6")}</option>
                                                <option value={5}>{t("CLN.Reflection.HealthState.5")}</option>
                                                <option value={4}>{t("CLN.Reflection.HealthState.4")}</option>
                                                <option value={3}>{t("CLN.Reflection.HealthState.3")}</option>
                                                <option value={2}>{t("CLN.Reflection.HealthState.2")}</option>
                                                <option value={1}>{t("CLN.Reflection.HealthState.1")}</option>

                                            </select>
                                            <div className="invalid-feedback">{errors.healthStateDurnigWork?.message}</div>
                                        </div>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipHealthStateDuringWork" className="info-icon align-middle" />
                                        <ToolTip infoText={t("CLN.Reflection.Help.HealthStateDuringWork")} target="TooltipHealthStateDuringWork" />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("CLN.Reflection.HealthStateAfterWork")}*</label>
                                        <div className="col-sm-6">
                                            <select name="healthStateAfterWork" ref={register} className={`form-control ${errors.healthStateAfterWork ? 'is-invalid' : ''}`}>
                                                <option value={10}>{t("CLN.Reflection.HealthState.10")}</option>
                                                <option value={9}>{t("CLN.Reflection.HealthState.9")}</option>
                                                <option value={8}>{t("CLN.Reflection.HealthState.8")}</option>
                                                <option value={7}>{t("CLN.Reflection.HealthState.7")}</option>
                                                <option value={6}>{t("CLN.Reflection.HealthState.6")}</option>
                                                <option value={5}>{t("CLN.Reflection.HealthState.5")}</option>
                                                <option value={4}>{t("CLN.Reflection.HealthState.4")}</option>
                                                <option value={3}>{t("CLN.Reflection.HealthState.3")}</option>
                                                <option value={2}>{t("CLN.Reflection.HealthState.2")}</option>
                                                <option value={1}>{t("CLN.Reflection.HealthState.1")}</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.healthStateAfterWork?.message}</div>
                                        </div>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipHealthStateAfterWork" className="info-icon" />
                                        <ToolTip infoText={t("CLN.Reflection.Help.HealthStateAfterWork")} target="TooltipHealthStateAfterWork" />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("CLN.Reflection.Help.Mood")}</label>
                                        <div className={`col-sm-6 ${errors.mood ? 'is-invalid-radio' : ''}`}>
                                            <div className="cc-selector">
                                                <input name="mood" type="radio" value={1} ref={register} id="veryhappy" />
                                                <label className="form-check-label emoji-cc" htmlFor="veryhappy"><Twemoji text="😄" /></label>

                                                <input name="mood" type="radio" value="2" ref={register} id="happy" />
                                                <label className="form-check-label  emoji-cc" htmlFor="happy"><Twemoji text="🙂" /></label>
                                                <div className="invalid-feedback">{errors.mood?.message}</div>

                                                <input name="mood" type="radio" value={3} ref={register} id="neutral" />
                                                <label className="form-check-label emoji-cc" htmlFor="neutral"><Twemoji text="😐" /></label>
                                                <div className="invalid-feedback">{errors.mood?.message}</div>

                                                <input name="mood" type="radio" value="4" ref={register} id="sad" />
                                                <label className="form-check-label emoji-cc" htmlFor="sad"><Twemoji text="😔" /></label>
                                                <div className="invalid-feedback">{errors.mood?.message}</div>

                                                <input name="mood" type="radio" value="5" ref={register} id="tear" />
                                                <label className="form-check-label emoji-cc" htmlFor="tear"><Twemoji text="😢" /></label>
                                                <div className="invalid-feedback">{errors.mood?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("CLN.Reflection.GeneralRemarks")}</label>
                                        <div className="col-sm-6">
                                            <textarea name="remarks" rows={5} ref={register} className={`form-control ${errors.remarks ? 'is-invalid' : ''}`} />
                                            <div className="invalid-feedback">{errors.remarks?.message}</div>
                                        </div>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipRemarks" className="info-icon" />
                                        <ToolTip infoText={t("CLN.Reflection.Help.Remarks")} target="TooltipRemarks" />

                                    </div>
                                    <div className="form-group row justify-content-center">
                                        <div className="col-sm-6">
                                            <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                {t("ALL.Button.Save")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    );
}

export { AddEditReflection };

