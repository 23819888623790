import { useContext, useState, useEffect } from "react";
import "./Upload.css";
import Progress from "../progress/Progress";
import axios from "axios";
import { alertService } from "../../../services/AlertService";
import { apiConfig } from "../../../msal/Config";
import { notificationService } from "../../../services/Index";
import Note from "../../../models/Note";
import { Alert } from "reactstrap";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { MultiLanguageSupportContext } from "../../../ctx/MultiLanguageSupportProvider";
import { Dropzone } from "../dropzone/Dropzone";
import { LoggedUserDataContext } from "../../../ctx/LoggedUserDataProvider";
import { CaseDataContext } from "../../../ctx/CaseDataProvider";
import { AppSettingsContext } from "../../../ctx/AppSettingsProvider";
import LogedUserProps from "../../../services/props/LogedUserProps";
import moment from "moment";

interface IUploadProps {
  updateList: any,
  caseId: number,
  uploadType: string,
  clear: boolean,
  docList: any
}
export const Upload = ({ updateList, caseId, uploadType, clear, docList }: IUploadProps) => {
  const { appSettings } = useContext(AppSettingsContext);
  const { t } = useContext(MultiLanguageSupportContext);
  const { authUserData } = useContext(LoggedUserDataContext);
  const { caseData } = useContext(CaseDataContext);
  const [files, setFiles] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [successfullUploaded, setSuccessfullUploaded] = useState<boolean>(false);
  const [clearData, setClear] = useState<any>(clear);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const updateFile = () => {
    setModal(!modal);
  }
  const removeLastAddedFile = () => {
    files.pop();
    setFiles(f => f.splice(-1));
    setModal(!modal);
  }
  const onFilesAdded = (filesAdded: any[]) => {

    const types = (appSettings.filter(s => s.key === "PossibleFileExtentions")[0].valueText).split(",")
    const size = appSettings.filter(s => s.key === "MaxSingleFileSize")[0].valueNumber;
    for (var x = 0; x < filesAdded.length; x++) {
      var file = filesAdded[x];
      var duplicateFile = docList.filter(f => f.documentName === file.name)
      var duplicateFileAdd = files.filter(f => f.name === file.name)
      file.validationError = "";
      if (duplicateFile.length > 0) {
        setModal(true);
        setIsDisabled(true);
        file.fileId = duplicateFile[0].id;
        file.validationError += t("ALL.DMS.FileDuplicate") + " " + file.name + "\n";
      }
      if (duplicateFileAdd.length > 0) {
        file.validationError += t("ALL.DMS.FileDuplicate") + " " + file.name.split('.').pop() + "\n";
        setIsDisabled(true);
      }
      if (types.every(type => !(file.name.toLowerCase().endsWith(type.toLowerCase())))) {
        file.validationError += t("ALL.DMS.FileTypeNotSupported") + " " + file.name.split('.').pop() + "\n";
        setIsDisabled(true);
      }
      if (file.size > size) {
        file.validationError += t("ALL.DMS.FileToLarge")  + " " + Math.round((((file.size - size) / 1024))) + "kb";;
        setIsDisabled(true);
      }

    };
    setFiles(f => f.concat(filesAdded));
  }

  const uploadFiles = () => {

    setUploadProgress([]);
    setUploading(true);
    const promises: any[] = [];
    files.filter(f => f.validationError === "").forEach((file: any) => {
      promises.push(sendRequest(file));
    });

    Promise.all(promises).then((rp) => {
      alertService.success(t("ALL.DMS.DocumentAdded"), { keepAfterRouteChange: true });
      setSuccessfullUploaded(false);
      setUploading(false);
      setFiles([]);

    })
      .catch(
        (rp) => {
          alertService.error(t(rp.message), { keepAfterRouteChange: true });
          setSuccessfullUploaded(true);
          setUploading(false);
        });



  }
  const readFileDataAsBase64 = async (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function () {
        resolve(this.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  }

  const sendRequest = async (file: any) => {
    var bytes = await readFileDataAsBase64(file);
    const data = {
      fileId: file.fileId,
      fileBase64: bytes,
      documentName: file.name,
      documentType: file.type,
      documentSize: file.size,
      modifiedById: authUserData.currUserData.profileId,
      createdById: authUserData.currUserData.profileId,
      caseId: caseId,
      documentUploadType: uploadType

    }
    console.log(file.type);
    return axios.post(apiConfig.resourceUri + "Document/UploadFile", data, {
      headers: {
        'Authorization': `Bearer ${LogedUserProps.apiToken}`,
        'Content-Type': 'application/json'
      },
      onUploadProgress: ProgressEvent => {
        const copy = [...uploadProgress];
        copy[file.name] = {
          state: "pending",
          percentage: (ProgressEvent.loaded / ProgressEvent.total) * 100
        };
        setUploadProgress(copy);
      },
    }).then((rp: { data: any; }) => {
      updateList(rp);
      alertService.success(t("ALL.DMS.FileUploaded"), { keepAfterRouteChange: true });
      //setFiles([]);
      //setSuccessfullUploaded(false);
      //setUploading(false);
      if (uploadType === "Case") {
        notificationService.postNewNotifications(
          {
            caseId: caseId,
            senderContactId: authUserData.currUserData.profileId,
            notificationStatus: "New",
            notificationTitle: t("ALL.Notifications.TitleDocument"),
            notificationMessage: t("ALL.Notifications.MessageDocument"),
            notificationItemUrl: `document-management/${rp.data.caseId}`,
            notificationDate: moment(),
            notificationSettingDictionaryId: 16,
            scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: rp.data.insurerId }, { scopeTypeId: 2, scopeEntityId: rp.data.caseId }],
            templateId: 616
          })
      }
    })
      .catch(
        (rp) => alertService.error(t(rp.response.data.massage), { keepAfterRouteChange: true })
      );
  }

  const renderProgress = (file: { name: string | number; }) => {
    const progress = uploadProgress[file.name];

    if (uploading) {
      return (<>
        <div className="ProgressWrapper">
          <Progress progress={progress ? progress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity:
                progress && progress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      </>
      );
    }
  }

  const renderActions = () => {
    return (<>
      {(files.length > 0) &&

        <button
          onClick={() => {
            setFiles([]);
            setSuccessfullUploaded(false);
            setIsDisabled(false);
          }
          }
        >
          {t("ALL.Button.Clear")}
        </button>
      }
      <button
        disabled={files.length < 0 || uploading || isDisabled}
        onClick={uploadFiles}
      >
        {(files.length < 0 || uploading) && <span className="spinner-border spinner-border-sm mr-1"></span>}
        {t("ALL.Button.Upload")}
      </button>

    </>
    );
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  const isMobile = width <= 768;

  return (
    <div className="Upload">
      <div className="Content">
        {/* 
          <div>
            <Dropzone
              onFilesAddedProps={onFilesAdded}
              disabled={uploading || successfullUploaded}
            />
          </div>
          <div className="Files">
            {files.map((file: { name: any, validationError: any; }) => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {renderProgress(file)}
                  {file.validationError !== "" &&
                    <Alert color="danger">
                      {file.validationError}
                    </Alert>
                  }
                </div>
              );
            })}
          </div>
       */}
      </div>
      <div className="row" style={{ "marginLeft": "0px" }}>
        <div className="column">
          <Dropzone
            onFilesAddedProps={onFilesAdded}
            disabled={uploading || successfullUploaded}
          />
        </div>
        <div className="column">
          {files.map((file: { name: any, validationError: any; }) => {
            return (
              <div key={file.name} className="Row">
                <span className="Filename">{file.name}</span>
                {renderProgress(file)}
                {file.validationError !== "" && 
                  file.validationError.split('\n').map(i => {
                    return i!=="" &&  <Alert color="danger"><p>{i}</p></Alert>
                  })
                }
              </div>
            );
          })}
        </div>
      </div>
      <div className="Actions">{renderActions()}</div>
      <Modal isOpen={modal}>
        <ModalBody>
          {t("ALL.DMS.FileExists")}</ModalBody>
        <ModalFooter>
          <Button style={{ backgroundColor: "#091C53" }} onClick={updateFile}>{t("ALL.Button.OK")}</Button>{' '}
          <Button onClick={removeLastAddedFile}>{t("ALL.Button.Cancel")}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

