import { createContext, FC, ReactNode, useEffect, useState } from 'react';

interface INotifications{
    notifications:any[]
    notificationNumber:number 
}
const defaultNotifications : INotifications = {
    notifications:[],
    notificationNumber:0 
};

export interface INotificationsProps {
    notificationsDetails: INotifications,
    setNotifications: (notif: INotifications) => void;
}

const NotificationContext = createContext<INotificationsProps>
    ({
        notificationsDetails:defaultNotifications,
        setNotifications:(notific:INotifications) => console.log(notific),
    
    });

type Props = {
    children?: ReactNode
};
const NotificationProvider: FC<Props> = ({ children }) => {
    const [notificationsDetails, setNotifications] = useState(defaultNotifications);

    useEffect(() => {
        setNotifications(notificationsDetails);
    }, [notificationsDetails]);

    return (
        <NotificationContext.Provider value={{ notificationsDetails, setNotifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export { NotificationProvider, NotificationContext };
