
import { useContext } from 'react';
import logo from '../assets/images/backIcon.png';
import { MultiLanguageSupportContext } from '../ctx/MultiLanguageSupportProvider';
interface IProps {
    licenseValid: boolean;
    hasAccess: boolean;
    statusCode: number;
    appMessage:string;
}
export const AccessDenied = ({ appMessage,licenseValid, hasAccess, statusCode }: IProps) => {
    const { t } = useContext(MultiLanguageSupportContext);
    var message = "";
    if (!hasAccess) {
        switch (statusCode){
            case 200: message = t("ALL.Messages.RequestAccess"); break;
            case 404: message = t("ALL.Messages.AppResourceNotFound"); break;
            case 403: message = t("ALL.Messages.AccessDenied"); break;
            case 500: message = t("ALL.Messages.ServiceNotAvailable"); break;
            case 400: message = t(appMessage); break;
            default: message=`Application error: ${appMessage}`;
        }
        
    }
    else if(!licenseValid){
        message=t("ALL.License.Expired")
    }

    return (<div className="initialScreen">
        <div><img
            src={logo}
            className="appIcon" alt="logo" /></div>
        <div>{message}</div>
    </div>);


}