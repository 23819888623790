import { useContext, useEffect, useState } from 'react';
import { alertService, contactService } from '../../services/Index';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useNavigate } from 'react-router-dom';
const filter = createFilterOptions<OptionType>(
  { stringify: (option) => option.title + option.display }
);
interface IContactAutoCompleteProps {
  role: string,
  caseId?: number,
  insurerId?: number,
  control: Control,
  error: boolean,
  caseContact: boolean,
  contactValue?: OptionType,
}
export default function ContactAutoComplete({ error, role, caseId, control, caseContact, insurerId, contactValue }: IContactAutoCompleteProps) {
  const [value, setOptValue] = useState<OptionType | null>(!contactValue ? null : contactValue);
  let navigate = useNavigate();
  const [roleContacts, setRoleContacts] = useState<OptionType[]>();
  const { t } = useContext(MultiLanguageSupportContext);
  useEffect(() => {
    if (caseContact) {
      if (caseId !== undefined) {
        contactService.getInsurerCaseRoleContacts(role, caseId!).then(
          (rp) => {
            setRoleContacts(rp?.map(c => ({ title: c.email, display: `${c.name !== null ? c.name : ""} ${c.surname !== null ? c.surname + " | " : ""}` })))
          }
        ).catch(
          (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
      }
      else {
        navigate("./");
      }
    }
    else {
      contactService.getInsurerRoleContacts(role, insurerId!).then(
        (rp) => {
          setRoleContacts(rp?.map(c => ({ title: c.email, display: `${c.name !== null ? c.name : ""} ${c.surname !== null ? c.surname + " | " : ""}` })))
        }
      ).catch(
        (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
      );

    }
  }, [role]);

  return (<>
    {!roleContacts && <div className="spinner-border spinner-border-lg align-center"></div>}
    {roleContacts && <>
      <label>{t("ALL.Common.EnterOrSelect")}</label>
      <Controller
        render={(props) => (
          <Autocomplete
            autoSelect
            {...props}
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setOptValue({
                  title: newValue,
                });
                props.onChange(newValue)
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setOptValue({
                  title: newValue.inputValue,
                });
                props.onChange(newValue.inputValue)
              } else {
                setOptValue(newValue);
                props.onChange(newValue?.title);
              }

            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  display: '',
                  title: t("ALL.Button.Add") + ` "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id={role}
            options={roleContacts}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.display + option.title}</li>}
            style={{}}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} error={error!}
                helperText={error!}
                variant="outlined" />
            )}
          />)}
        name="userEmail"
        control={control}

      />
    </>
    }
  </>
  );
}

interface OptionType {
  inputValue?: string;
  title: string;
  display?: string;
}
