import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
//import 'assets/css/app.css';
import { BrowserRouter } from "react-router-dom";
import { InitialScreen } from "./components/InitialAppScreen";
import Index from "./containers/Index";
import { AppAccessProvider } from "./ctx/AppAccessProvider ";
import { AppSettingsProvider } from "./ctx/AppSettingsProvider";
import { LoggedUserDataProvider } from "./ctx/LoggedUserDataProvider";

const App = () => {
    return (
        <BrowserRouter>
            <AuthenticatedTemplate>
                <LoggedUserDataProvider>
                    <AppSettingsProvider>
                        <AppAccessProvider>
                            <Index />
                        </AppAccessProvider>
                    </AppSettingsProvider>
                </LoggedUserDataProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <InitialScreen />
            </UnauthenticatedTemplate>
        </BrowserRouter>
    );
}
export default App;