import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { msalConfig } from '../../msal/Config';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { CaseDataContext } from '../../ctx/CaseDataProvider';


export const UserLogin = () => {
    let navigate = useNavigate();
    const { caseData } = useContext(CaseDataContext);
    const { instance, accounts } = useMsal();
    const { t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const handleLogout = () => {
        const logoutRequest = {
            account: accounts[0],
            mainWindowRedirectUri: msalConfig.auth.redirectUri,
            postLogoutRedirectUri: msalConfig.auth.redirectUri,
        }

        instance.logoutRedirect(logoutRequest);
    }
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (<div>
        {authUserData.currUserData !== undefined && <div>
            <Tooltip title={!authUserData.currUserData.name ? accounts[0]!==undefined?accounts[0].username:"" : `${authUserData.currUserData.name} ${authUserData.currUserData.surname}`}>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        {!authUserData.currUserData.profileImg ?
                            <Avatar sx={{ width: 32, height: 32 }}>{
                                !authUserData.currUserData.name ? "CD" :
                                    `${authUserData.currUserData.profileEmail.charAt(0)} ${authUserData.currUserData.profileEmail.charAt(1)}`
                            }</Avatar> : <img src={authUserData.currUserData.profileImg} alt="ProfilePicture" style={{ width: "30px", borderRadius: "50%", marginRight: "5px" }} />}
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Box>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {authUserData.currUserData.rolePath === 'insurer' ?
                    <MenuItem onClick={() => navigate(`/${authUserData.currUserData.rolePath}/brand-insurer/${authUserData.currUserData.relatedData[0].id}`)} >
                        <Avatar /> {authUserData.currUserData.name!=="" && authUserData.currUserData.name!=null?`${authUserData.currUserData.name} ${authUserData.currUserData.surname}`:authUserData.currUserData.profileEmail}
                    </MenuItem>
                    :
                    <MenuItem>
                        <Avatar /> {authUserData.currUserData.name!=="" && authUserData.currUserData.name!=null?`${authUserData.currUserData.name} ${authUserData.currUserData.surname}`:authUserData.currUserData.profileEmail}
                    </MenuItem>
                }
                <MenuItem onClick={() => navigate(`/${authUserData.currUserData.rolePath}/edit-contact/${authUserData.currUserData.contactId}`)} >
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    {t("ALL.Common.ProfileEdit")}
                </MenuItem>
                
                <Divider />
                {authUserData.currUserData.rolePath !== 'insurer' && authUserData.currUserData.rolePath !== 'admin'&& caseData.selectedCaseId !== undefined &&<div>
                <MenuItem onClick={() => {
                    authUserData.currUserData.roleType==="CaseManager"?navigate(`/${authUserData.currUserData.rolePath}/manage-notification-settings/user`):navigate(`/${authUserData.currUserData.rolePath}/manage-notification-settings`)}} >
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    {t("ALL.Alerts.Management")}
                </MenuItem>
                <Divider />
                </div>}
                
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t("ALL.Common.ProfileLogOut")}
                </MenuItem>
            </Menu>
        </div>}
    </div>
    );
}

