import { useContext, useState } from 'react';
import CaseContact from '../../models/CaseContact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfoCircle, faPaperPlane, faSpinner } from '@fortawesome/pro-light-svg-icons'
import { alertService, caseManagerService } from '../../services/Index';
import { msalConfig } from '../../msal/Config';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

interface ICaseContact {
    caseContact: CaseContact,
    groupId: string,
    groupName: string,
    caseId?: number,
    role?: string
}

const CaseContactActions = ({ caseContact, caseId, groupId, groupName }: ICaseContact) => {
    const [welcomeSent, setWelcomeSent] = useState<boolean>(caseContact.welcomeSent);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [invitatinPending, setInvitatinPending] = useState<boolean>(caseContact.invited);
    const [caseInvited, setCaseInvited] = useState<boolean>(caseContact.caseInvited);
    const [isLoading, setLoading] = useState(false);
    const [adAddInProgress, setAdAddInProgress] = useState(false);
    const { t } = useContext(MultiLanguageSupportContext);
    function inviteMember(id: number, mail: string) {
        setLoading(true);
        var data =
        {
            userId: id,
            userEmail: mail,
            createdBy: authUserData.currUserData.profileId,
            modifiedBy: authUserData.currUserData.profileId,
            groupId: groupId,
            redirectUrl: msalConfig.auth.redirectUri,
            insurerId: authUserData.currUserData.insurerId,
            roleType: groupName!.replace(/\s/g, ''),
            caseId: caseId,
        }
        caseManagerService.inviteContactToCase(data).then((rp) => {

            alertService.success(t("CAM.Case.MemberInvited"), { keepAfterRouteChange: true });
            setWelcomeSent(rp.welcomeSent);
            setInvitatinPending(rp.invited);
            setCaseInvited(rp.caseInvited);
            setAdAddInProgress(false);
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                if (rp.message.indexOf("Active Directory") > 0) {
                    setAdAddInProgress(true);
                    setLoading(false);
                }
            }
        );
    }
    return (
        <div className="form-row">
            <div className="form-group col-sm-8">
            {adAddInProgress && <>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                    <span className="info-text" >{t("ALL.Messages.RegisterUserProgress")}</span>
                    <hr></hr>
                </>}
            </div>
            <div className="form-group col-sm-4 justify-content-end">
                
                <p>{invitatinPending} {welcomeSent} {caseInvited}</p>
                {((!invitatinPending && !welcomeSent) || (!welcomeSent && invitatinPending && !caseInvited)) &&
                    <>
                        <button type="submit" onClick={() => inviteMember(caseContact.contact.id!, caseContact.contact.email)} className={`btn btn-action-${groupName!.replace(/\s/g, '').toLowerCase()} mr-1`} disabled={isLoading}>
                            <FontAwesomeIcon icon={faPaperPlane} />{isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>} {t("ALL.Button.Invite")}
                        </button>
                    </>
                }
                {!welcomeSent && invitatinPending && caseInvited &&
                    <button className={`btn btn-action-${groupName!.replace(/\s/g, '').toLowerCase()} mr-1`} disabled={true}>
                        <FontAwesomeIcon icon={faSpinner} />{t("ALL.Button.InvitationPending")}
                    </button>

                }
                {welcomeSent && invitatinPending &&
                    <button className={`btn-no-pointer btn btn-action-${groupName!.replace(/\s/g, '').toLowerCase()} mr-1`} disabled={true}>
                        <FontAwesomeIcon icon={faCheckCircle} />{t("ALL.Common.Invited")}
                    </button>

                }


            </div>
        </div>

    );
}

export { CaseContactActions };