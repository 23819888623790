import { useState, useEffect, useContext } from 'react';
import { adminService, alertService, caseManagerService } from '../../services/Index';
import { faTrash, faUsers,faCreditCard} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/min/locales';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { AddEditLicenseAssignement } from './license/AddEditLicenseAssignement';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { IModalData } from '../../interfaces/IModalData';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';

interface ICaseManagementProps {
    insurerId?:number,
    role:string,
}

function CaseManagement({insurerId,role}:ICaseManagementProps) {
    const { t } = useContext(MultiLanguageSupportContext);
    const { caseData, setCaseData } = useContext(CaseDataContext);
    const {authUserData} = useContext(LoggedUserDataContext);
    const [cases, setCases] = useState<any[]>();
    const [modalIsOpen, setModalVisibility] = useState(false);
    const [selectedCase, setSelectedCase] = useState<any>({})
    const toggle = () => setModalVisibility(!modalIsOpen);
    const [confirmModalVisibility, setConfirmModalVisibility] = useState<boolean>(false);
    const [selectedCaseId, setSelectedCaseId] = useState<number>();
    const [modalData, setModalData]= useState<IModalData>();
    moment.locale(authUserData.currUserData.profileLanguage);

    function deleteCaseEntry(caseId:number) {
        setCases(cases!.map(x => {
            if (x.caseData.id === caseId) { x.isDeleteing = true; }
            return x;
        }));
        adminService.deleteCase(caseId,).then(() => {
            alertService.success(t("CAM.Case.Removed"), { keepAfterRouteChange: true });
            setCases((oldData)=> {return oldData?.filter(x => x.caseData.id !== caseId)});
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    setCases(cases!.map(x => {
                        if (x.caseData.id === caseId) { x.isDeleteing = false; }
                        return x;
                    }));
            }
        );
    }
    const confirmModal = (userRes) => {
        if (userRes === 1) {
            deleteCaseEntry(selectedCaseId!)
        }
        setConfirmModalVisibility(false);
    }

    const deleteCase = (c) => {
        setConfirmModalVisibility(true)
        setModalData(
            {
                title:t("CAM.Case.DeleteCaseConfirmationTitle").replace('#caseNumber',c.caseNumber),
                message:t("CAM.Case.DeleteCaseConfirmationMessage").replace('#caseNumber',c.caseNumber)
            }
        )
        setSelectedCaseId(c.caseData.id);
    }

    function manageLicenseAssignement(c) {
        setSelectedCase(c);
        setModalVisibility(true);
    }

    function getCases(){
        caseManagerService.getAllCasesWithDetails(insurerId?`?insurerId=${insurerId}`:"").then(
            (c) => {
              if(insurerId!==undefined ){
                setCaseData(
                    {
                        ...caseData,
                        caseInsurerReference:authUserData.currUserData.relatedData[0].insurerPrefix,
                        insurerProfileImage:c.length>0?c[0].insurerProfileImage:null,
                    });
              }

                setCases(c)
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    useEffect(() => {
        getCases();

    }, []);
    return (
        <div className="container">
            <DeleteConfirmationModal modalVisibility={confirmModalVisibility} setModalVisibility={setConfirmModalVisibility} confirmModal={confirmModal} type={"error"} modalData={modalData}/>
            
            <p className="component-header">{t("CAM.Case.Overview")}</p>
            <hr></hr>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{t("INS.Common.InsurerRefNumber")}</th>
                        <th>{t("ALL.Dictionary.Role.Client")}</th>
                        <th>{t("CAM.Case.CaseDeskNumber")}</th>
                        <th>{t("CAM.Case.CreateDate")}</th>
                        <th>{t("ALL.Dictionary.Role.CaseManager")}</th>
                        <th className="text-center">{t("ALL.Button.Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {cases && cases.map(c =>
                        <tr key={c.caseData.id}>
                            <td>{c.caseData.referenceNumberOfInsurance}</td>
                            <td>{c.clientData ? c.clientData.displayName : "-"}</td>
                            <td>{c.caseNumber}</td>
                            <td>{moment(c.caseData.created).format("L")}</td>
                            <td>{c.caseManagerData ? c.caseManagerData.displayName : "-"}</td>
                            <td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                                <Link to={`/${role}/manage-contacts/${c.caseData.id}`} className="btn btn-md btn-icon-blue mr-1"><FontAwesomeIcon icon={faUsers} /></Link>
                                <button onClick={() => manageLicenseAssignement(c)} className="btn btn-md btn-icon-grey">
                                        {!c.validLicense?<FontAwesomeIcon icon={faCreditCard} className="btn-icon-red"/>:<>
                                            {c.validLicenseEdition==="DEMO"?<FontAwesomeIcon icon={faCreditCard} className="btn-icon-light-blue"/>:<FontAwesomeIcon icon={faCreditCard} className="btn-icon-green"/>}
                                        </>                                            
                                        }
                                </button>      
                                <button onClick={() => deleteCase(c)} className="btn btn-md btn-icon-red" disabled={c.isDeleteing}>
                                    <FontAwesomeIcon icon={faTrash} /> {c.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                </button>
                            </td>
                        </tr>
                    )}
                    {!cases &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {cases && !cases.length &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <Modal isOpen={modalIsOpen} style={{ maxWidth: "700px" }} toggle={toggle}>
                <ModalBody>
                    <AddEditLicenseAssignement selectedCase={selectedCase} close={setModalVisibility} reloadCase={getCases}/>
                </ModalBody>
            </Modal>
        </div>
    );

}
export { CaseManagement };