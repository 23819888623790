import moment from "moment";
import { MajorContactInfo } from "./Contact";

export default class CaseBooking {
    public id: number;
    public caseId: number;
    public bookingType: number;
    public travelCalculationType: number;
    public title: string;
    public description: string;
    public bookingDate: Date;
    public chargedMinutes: number;
    public chargedDistance: number;
    public quantity:number;
    public totalHours:number;
    public bookingTags: string[];
    public amount: number;
    public currency: number;
    public taxTag: string;
    public bookingCategoryTag: string;
    public sumOfAmounts: number;
    public sumOfTaxes: number;
    public hourlyRate: number;
    public pricePerDistance: number;
    public distanceUnit: number;

    public owner: MajorContactInfo;

    public createdBy: string;
    public created: string;
    public modifiedBy: string;
    public modified: string;

    constructor(id: number, caseId: number, bookingType: number, travelCalculationType: number, title: string, description: string, bookingDate: Date, chargedMinutes: number, bookingTags: string[], amount: number, currency: number, taxTag: string,bookingCategoryTag: string, sumOfAmounts: number, sumOfTaxes: number, hourlyRate: number, pricePerDistance: number, distanceUnit: number, chargedDistance: number,quantity:number,totalHours:number, owner: MajorContactInfo,createdBy: string, modifiedBy: string, created: string, modified: string) {
        this.id = id;
        this.caseId = caseId;
        this.bookingType = bookingType;
        this.travelCalculationType = travelCalculationType;
        this.title = title;
        this.description = description;
        this.bookingDate = bookingDate;
        this.chargedMinutes = chargedMinutes;
        this.chargedDistance=chargedDistance;
        this.bookingTags = bookingTags;
        this.amount = amount;
        this.totalHours=totalHours;
        this.quantity=quantity;
        this.currency = currency;
        this.taxTag = taxTag;
        this.bookingCategoryTag=bookingCategoryTag;
        this.sumOfAmounts = sumOfAmounts;
        this.sumOfTaxes = sumOfTaxes;
        this.hourlyRate = hourlyRate;
        this.pricePerDistance = pricePerDistance;
        this.distanceUnit = distanceUnit;
        this.owner = owner;

        this.createdBy = createdBy;
        this.created = created;
        this.modifiedBy = modifiedBy;
        this.modified = modified;
    }
}
export interface ICaseBookingViewModel {
    id?: number;
    caseId: number;
    bookingType: number;
    travelCalculationType: number;
    title: string;
    description: string;
    bookingDate: Date;
    bookingDateString:string;
    chargedMinutes: number;
    chargedDistance: number;
    quantity:number;
    totalHours:number;
    bookingTags: string[];
    bookingTagsTitles: string;
    amount: number;
    currency: number;
    taxTag: string;
    taxTagString:string;
    bookingCategoryTag: string;
    bookingCategoryTagString:string;
    sumOfAmounts: number;
    sumOfTaxes: number;
    hourlyRate: number;
    pricePerDistance: number;
    distanceUnit: number;
    owner: MajorContactInfo;
    userEmail: MajorContactInfo;
    isDeleting?: boolean;
    created?: Date;
    createdBy?: MajorContactInfo;
    modified?: Date;
    modifiedBy?: MajorContactInfo;
}
export const mapBookingsToVieModel = (data: any, t: (str: string) => string, bookingTagsOptions: any,taxOptions:any,bookingCategoryTag:any,userLng: string): ICaseBookingViewModel => {
    var bookingCategoryTag = bookingCategoryTag!.filter((tag) => data.bookingCategoryTag === tag.bookingCategoryTag);
    var bookingCategoryTitle = bookingCategoryTag.length > 0 ? bookingCategoryTag[0].title : "";
    var taxTag = taxOptions!.filter((tag) => data.taxTag === tag.dicGuid);
    var taxTitle = taxTag.length > 0 ? taxTag[0].title : "";
    var bookingTags = bookingTagsOptions!.filter((tag) => {
        if (data.bookingTags.includes(tag.dicGuid) && userLng === (tag.dicItemLng).toLowerCase()) {
            return tag;
        }
    });
    var bookingTagsTitles = bookingTags.length > 0 ? bookingTags.map(function(elem){
        return elem.title;
    }).join(","): "";
    moment.locale(userLng);
    return {

        id:data.id,
        caseId:data.caseId,
        bookingType:data.bookingType,
        travelCalculationType:data.travelCalculationType,
        title:data.title,
        description:data.description,
        bookingDate:data.bookingDate,
        bookingDateString:moment(data.bookingDate).format("L"),
        chargedMinutes:data.chargedMinutes,
        chargedDistance:data.chargedDistance,
        bookingTags:data.bookingTags,//bookingTags,
        bookingTagsTitles:bookingTagsTitles,
        amount:data.amount,
        currency:data.currency,
        taxTag:data.taxTag,
        taxTagString:taxTitle,
        bookingCategoryTag:data.bookingCategoryTag,
        bookingCategoryTagString:bookingCategoryTitle,
        sumOfAmounts:data.sumOfAmounts,
        sumOfTaxes:data.sumOfTaxes,
        hourlyRate:data.hourlyRate,
        pricePerDistance:data.pricePerDistance,
        distanceUnit:data.distanceUnit,
        quantity:data.quantity,
        totalHours:data.totalHours,
        owner: data.owner,
        userEmail:data.owner,
        isDeleting:false,
        
        createdBy: data.createdBy,
        created: data.created,
        modifiedBy: data.modifiedBy,
        modified: data.modified,

    }
}

