import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { defaultUserData, ILoggedUserData, ILoggedUserProps } from '../interfaces/ILoggedUser';


const LoggedUserDataContext = createContext<ILoggedUserProps>({
    authUserData: defaultUserData,
    setAuthUserData: (user: ILoggedUserData) => console.log(user),

});

type Props = {
    children?: ReactNode
};
const LoggedUserDataProvider: FC<Props> = ({ children }) => {
    const [authUserData, setAuthUserData] = useState(defaultUserData);

    useEffect(() => {
        setAuthUserData(authUserData);
    }, [authUserData]);

    return (
        <LoggedUserDataContext.Provider
            value={{ authUserData, setAuthUserData }}
        >
            {children}
        </LoggedUserDataContext.Provider>
    );
};

export { LoggedUserDataProvider, LoggedUserDataContext };
