import  { useContext, useEffect, useRef, useState } from 'react'
import { alertService, clientService } from '../../services/Index';

import moment from 'moment';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2'
ChartJS.register(...registerables);


interface DataSet {
  label: string;
  data: any;
  fill: boolean;
  backgroundColor: string;
  borderColor: string;
  yAxisID: string;
}
interface IProps {
  filteredCases: Array<any>
}
function getRandomRgb() {
  var num = Math.round(0xffffff * Math.random());
  var r = num >> 16;
  var g = num >> 8 & 255;
  var b = num & 255;
  return 'rgb(' + r + ', ' + g + ', ' + b + ')';
}
const MultiCaseTrendLineChart = ({ filteredCases }: IProps) => {
  const { t } = useContext(MultiLanguageSupportContext);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState<{ datasets: any,labels:any}>({
    labels: [],
    datasets: [],
  });
  const options = {
    responsive: true,
    scales: {
      yAxes: {
          ticks: {
            //beginAtZero: true,
            callback: function (val, index) {
              return Number.isInteger(val)?t(`CLN.Reflection.HealthState.${val}`):"";
            },
          },
        },
        x: {
          ticks: {
            beginAtZero: true,
            callback: function (val, index) {
              return "";
            },
          },
        },
      
      
    }

  };
  useEffect(() => {
    var casesId=filteredCases.map(c => (`casesId=${c.caseData.id}`)).toString();
    clientService.getCasesReflectionMajorInfo(casesId.replaceAll(",","&")).then((rp) => {
        var labels=new Array<any>();
        var dataset=new Array<DataSet>();
        
        filteredCases.map(c=>{
          var color=getRandomRgb();
            var caseResponse=rp.filter(r=>r.caseId===c.caseData.id)
            labels.push(caseResponse[0].caseReflectionTrends.map(r => (moment(r.reflection.reflectionDate).format("DD.MM.YYYY"))));
            dataset.push({
              label: c.caseNumber,
              data: caseResponse[0].caseReflectionTrends.map(t=>t.reflectionTrend),
              fill: false,
              backgroundColor: color,
              borderColor: color,
              yAxisID: 'yAxes',
            })
        });
        const chart = chartRef.current;
        if (chart) {
          setChartData({
          labels: labels.flat(),
          datasets: dataset,
        })
      }

      })
      .catch(
        (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
      );
      return () => {
        setChartData({
          labels: [],
          datasets: [],
        });
      };

  }, [filteredCases]);
  return (
    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="card-header card-header-primary header-top">
          <h4 className="card-title">{t("ALL.DashBoard.ChartMultiTrendReflectionHeader")}</h4>
          <p className="card-category">{t("ALL.DashBoard.ChartMultiTrendReflectionHeaderSub")}</p>
        </div>
        <div className="card-body">
        <Chart
              ref={chartRef}
              type='line'
              options={options}
              data={chartData}
            />
        </div>
      </div>
    </div>

  )
};


export default MultiCaseTrendLineChart;