
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/pro-light-svg-icons'

import { useContext, useEffect, useState } from 'react';
import TaskTemplate from '../../../models/TaskTemplate';

import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';
import { alertService, tasksService } from '../../../services/Index';

import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { AddEditTaskRuleGenTemplate } from './AddEditTaskRuleGenTemplate';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ListTaskRuleGenTemplates = ({ planTags, selectedRuleTemp, ruleTemplateAdd, ruleTemplateDelete, ruleTemplateUpdate, ruleTemplates, addTaskRule, editTaskRule, deleteTaskRule }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [selectedRuleTemplate, setSelectedRuleTemplate] = useState<any>({})
    const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>();
    const [loading, setLoading] = useState(true);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleAccordinationChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    useEffect(() => {
        setLoading(true);
        let scopeReqData = [{
            "scopeTypeId": 0,
            "scopeEntityId": authUserData.currUserData.insurerId!
        }];

        tasksService.getScopedRuleGeneratorTemplates(scopeReqData).then((rp) => {
            setTaskTemplates(rp);
            setLoading(false);
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }, []);
    const handleClose = () => {
        setExpanded(false);
        
    };
    const addRuleTemplate = (template: TaskTemplate) => {
        setExpanded(false);
        ruleTemplateAdd(template);
        setTaskTemplates(taskTemplates => [template, ...taskTemplates!]);
    }
    function editTemplate(template: TaskTemplate) {
        setSelectedRuleTemplate(template);
        setExpanded('panel');
    }
    function editRuleTemplate(template: TaskTemplate) {
        setTaskTemplates(taskTemplates!.map(x => {
            if (x.id === template.id) { return template }
            return x;
        }));
        ruleTemplateUpdate(template);
        setExpanded(false);
    }
    function deleteRuleTemplate(taskTemplateId: number) {
        ruleTemplateDelete(taskTemplateId);
        setTaskTemplates(taskTemplates!.map(x => {
            if (x.id === taskTemplateId) { x.isDeleting = true; }
            return x;
        }));

        tasksService.deleteTaskGeneratorRuleTemplate(taskTemplateId).then((rp) => {
            alertService.success(t("ALL-Tasks.Messages.Template.Deleted"), { keepAfterRouteChange: true });
            setTaskTemplates(taskTemplates => taskTemplates!.filter(x => x.id !== taskTemplateId));

        }).catch(
            (rp) => {

                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setTaskTemplates(taskTemplates!.map(x => {
                    if (x.id === taskTemplateId) { x.isDeleting = true; }
                    return x;
                }));
            }

        );

    }
    return (
        <div className="col-md-12">
            <div className="card">
                {!taskTemplates &&
                    <div className="loader">{t("ALL.Common.Loading")}</div>
                }
                {taskTemplates && <div>
                    <Accordion expanded={expanded === 'panel'} onChange={handleAccordinationChange('panel')}>
                        <AccordionSummary
                            expandIcon={!expanded?<AddCircleOutlineIcon />:<KeyboardArrowDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            {Object.entries(selectedRuleTemplate).length === 0 ? t("ALL.Tasks.Generator.Templates.AddRuleSet") : t("ALL.Tasks.Button.EditTemplate")}
                        </AccordionSummary>

                        <AccordionDetails>
                            <AddEditTaskRuleGenTemplate
                                ruleTemplate={selectedRuleTemplate}
                                close={handleClose}
                                addTemplate={addRuleTemplate}
                                editTemplate={editRuleTemplate}
                                cleanSelectedNode={setSelectedRuleTemplate}
                                planTags={planTags}
                                ruleTemplates={ruleTemplates}
                                addTaskRule={addTaskRule}
                                editTaskRule={editTaskRule}
                                deleteTaskRule={deleteTaskRule}
                                selectedRuleTemp={selectedRuleTemp}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>}
                {!expanded &&
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="text-primary">
                                    <tr>
                                        <th>{t("ALL.Tasks.Generator.Templates.RuleSet")}</th>
                                        <th>{t("ALL.Button.ShowMore")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {taskTemplates && taskTemplates.map(tt => <>
                                        <tr key={tt.id}>
                                            <td>{tt.templateTitle}</td>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                <button onClick={() => editTemplate(tt)} className="btn btn-md btn-icon-grey">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                                <button onClick={() => deleteRuleTemplate(tt.id)} className="btn btn-md btn-icon-red">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                    )}
                                    {!taskTemplates &&
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    {taskTemplates && !taskTemplates.length &&
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                <div className="p-2">{t("ALL.Tasks.Templates.NoData")}</div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>

        </div >



    );

}

export { ListTaskRuleGenTemplates };