import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { MultiLanguageSupportContext } from "../ctx/MultiLanguageSupportProvider";
import { helperFunctions } from "../helpers/HelperFunctions";
import { Button } from "react-bootstrap";

const DeleteConfirmationModal = ({ modalVisibility, setModalVisibility, confirmModal, type, modalData }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const htmlString=helperFunctions.sanitizeHtml(modalData?.message);
    const toggle = () => setModalVisibility(!modalVisibility);
    return (
        <Modal isOpen={modalVisibility} toggle={toggle}>
            <ModalHeader closeButton>
                <p>{modalData?.title}</p>
            </ModalHeader>
            <ModalBody><div dangerouslySetInnerHTML={{ __html: htmlString }} /></ModalBody>
            <ModalFooter>
                <Button variant="danger" onClick={() => confirmModal(1)}>                    
                    {t('ALL.Button.Yes')}
                </Button>
                <Button variant="default" onClick={toggle}>
                    {t('ALL.Button.Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteConfirmationModal;