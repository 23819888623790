import * as React from 'react';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, Menu } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faExpand, faMoneyBills } from '@fortawesome/pro-light-svg-icons';
import { useContext} from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

export default function SumDetails({bookingSettings}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useContext(MultiLanguageSupportContext);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
      <FontAwesomeIcon icon={faMoneyBills} style={{ fontSize: "32px", color: "grey" }} />
      <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft:"10px",fontSize: "20px", color: "grey" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
      <MenuList>
        <MenuItem>
          <ListItemText><b>{`${t("ALL.Bookings.Sum.BudgetAmount")}: `}</b>{`${bookingSettings?.budgetAmount}`}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText><b>{`${t("ALL.Bookings.Sum.BookingAmount")}: `}</b>{`${bookingSettings?.sumOfAmounts}`}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText><b>{`${t("ALL.Bookings.Sum.AmountAlert")}: `}</b>{`${bookingSettings?.budgetAmountAlert}`}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText><b>{`${t("ALL.Bookings.Sum.BookingTax")}: `}</b>{`${bookingSettings?.sumOfTaxes}`}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText><b>{`${t("ALL.Bookings.Sum.BudgetHours")}: `}</b>{`${bookingSettings?.budgetHours}`}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText><b>{`${t("ALL.Bookings.Sum.BookingHours")}: `}</b>{`${bookingSettings?.sumOfHoursCharged}`}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText><b>{`${t("ALL.Bookings.Sum.RemainingHours")}: `}</b>{`${bookingSettings?.budgetHours!-bookingSettings?.sumOfHoursCharged!}`}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText><b>{`${t("ALL.Bookings.Sum.HoursAlert")}: `}</b>{`${bookingSettings?.budgetHoursAlert}`}</ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
    </div>
  );
}
