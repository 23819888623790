import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/min/locales';
import License from '../../../models/License';
import { alertService, licenseService } from '../../../services/Index';

import { LicenseManagement } from './LicenseManagement';
import { Alert } from 'reactstrap';
import { List, ListItem, ListItemText, TextField } from '@mui/material';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
interface IAddEditLicenseProps {
    reSetLicense?: any;
    insurerId?: number;
    close?: any;
}
function AddEditLicense({ close, insurerId }: IAddEditLicenseProps) {
    let { id } = useParams();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { locale, t, mask } = useContext(MultiLanguageSupportContext);
    let navigate = useNavigate();
    const isAddMode: boolean = !id;
    const [massage, setAlertMassage] = useState<string>();
    const [insureLicenseAssignement, setInsurerLicenseAssignement] = useState<any[]>();

    const columWidth = isAddMode ? "col-sm-12" : "col-sm-6"
    const validationSchema = Yup.object().shape({
        edition: Yup.string().required(t("ALL.License.Validation.Edition")).nullable(),
        licenseValidFrom: Yup.string().required(t("ALL.License.Validation.DateStart")).nullable(),
        licenseValidTo: Yup.string().required(t("ALL.License.Validation.DateTo")).nullable(),
        currency: Yup.string().required(t("ALL.License.Validation.Currency")).nullable(),
        amountTotal: Yup.string().required(t("ALL.License.Validation.Amount")).nullable(),
        orderDate: Yup.string().required(t("ALL.License.Validation.DateOrder")).nullable(),
    });
    const { register, handleSubmit, reset, setValue, getValues, control, errors, formState } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            insurerId: null,
            edition: null,
            licenseValidFrom: null,
            licenseValidTo: null,
            currency: null,
            amountTotal: null,
            orderDate: moment.parseZone(new Date()),

        }
    });

    function onSubmit(data: License) {
        return isAddMode
            ? createLicense(data)
            : updateLicense(parseInt(id!), data);
    }

    function createLicense(data: License) {
        if (((data.licenseValidFrom !== undefined || data.licenseValidFrom !== null) && (data.licenseValidTo !== undefined || data.licenseValidTo !== null) && (new Date(data.licenseValidFrom) > new Date(data.licenseValidTo)))) {
            alertService.success(t("ALL.License.Validation.StartBeforeEnd"), { keepAfterRouteChange: true });
            return false;
        }
        data.createdBy = authUserData.currUserData.profileId;
        data.insurerId = insurerId!;
        data.licenseValidFrom = new Date(data.licenseValidFrom);
        data.licenseValidTo = new Date(data.licenseValidTo);
        data.orderDate = new Date(data.orderDate);
        return licenseService.postNewLicense(data)
            .then((rp) => {
                alertService.success(t("ALL.License.CreationSuccess"), { keepAfterRouteChange: true });
                close(false);
                reset();
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    function updateLicense(id: number, data: License) {
        if (data.licenseValidFrom !== undefined && data.licenseValidFrom !== null && data.licenseValidTo !== undefined && data.licenseValidTo !== null && new Date(data.licenseValidFrom) > new Date(data.licenseValidTo)) {
            alertService.success(t("ALL.License.Validation.StartBeforeEnd"), { keepAfterRouteChange: true });
            return false;
        }
        data.id = id;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.licenseValidFrom = new Date(data.licenseValidFrom);
        data.licenseValidTo = new Date(data.licenseValidTo);
        data.orderDate = new Date(data.orderDate);
        return licenseService.postUpdateLicense(data)
            .then((rp) => {
                alertService.success(t("ALL.License.UpdateSuccess"), { keepAfterRouteChange: true });
                navigate(`../manage-license`);
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    const [license, setLicense] = useState<License>();

    useEffect(() => {
        if (!isAddMode) {
            licenseService.getSingleLicense(parseInt(id!)).then(i => {
                setValue("insurerId", i.insurerId);
                setValue("edition", i.edition);
                setValue("insurerPrefix", i.insurerPrefix);
                setValue("licenseValidFrom", i.licenseValidFrom);
                setValue("licenseValidTo", i.licenseValidTo);
                setValue("currency", i.currency);
                setValue("amountTotal", i.amountTotal);
                setValue("orderDate", i.orderDate);
                setLicense({
                    insurerId: i.insurerId,
                    insurerPrefix: i.insurerPrefix,
                    edition: i.edition,
                    licenseValidFrom: i.licenseValidFrom,
                    licenseValidTo: i.licenseValidTo,
                    currency: i.currency,
                    amountTotal: i.amountTotal,
                    orderDate: i.orderDate
                });
                var insurerid = i.insurerId
                licenseService.getAllInsurerLicensesAssignementsAsync(insurerid, parseInt(id!)).then(rp => {
                    setInsurerLicenseAssignement(rp);
                }).catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );

            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

        }
    }, [id]);

    return (
        <div className="container">
            {massage && <Alert color="danger">
                {t(`${massage}`)}
            </Alert>}
            {!isAddMode && <> <p className="component-header">{isAddMode ? t("ALL.License.Add") : t("ALL.License.Edit")}</p>
                <hr></hr></>}

            <div className=" white-container">

                <div className="form-row">
                    <div className={columWidth}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {!isAddMode &&
                                <div className="form-row">
                                    <label>{`${t("ALL.License.Title")} ${license?.insurerPrefix}-${id}`}</label>
                                </div>}
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <label>{t("ALL.License.Edition")}</label>
                                    <select name="edition" ref={register} className={`form-control ${errors.edition ? 'is-invalid' : ''}`}>
                                        <option value="DEMO">DEMO</option>
                                        <option value="PRO">PRO</option>
                                        {/*<option value="ENT">ENT</option>
                                            <option value="TEAMS">TEAMS</option>*/}
                                    </select>
                                    <div className="invalid-feedback">{errors.edition?.message}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-sm-6">
                                    <label>{t("ALL.License.DateStart")}*</label>
                                    <Controller
                                        render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                            <DatePicker
                                                disabled={insureLicenseAssignement && insureLicenseAssignement!.length > 0}
                                                value={getValues("licenseValidFrom")}
                                                onChange={value => setValue("licenseValidFrom", value)}
                                                renderInput={(params) => <TextField {...params} />}
                                                mask={mask}
                                            />
                                        </LocalizationProvider>)}
                                        name="licenseValidFrom"
                                        control={control}

                                    />
                                    <div className="invalid-feedback">{errors.licenseValidFrom?.message}</div>
                                </div>
                                <div className="form-group col-sm-6">
                                    <label>{t("ALL.License.DateEnd")}*</label>
                                    <Controller
                                        render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                            <DatePicker
                                                disabled={insureLicenseAssignement && insureLicenseAssignement!.length > 0}
                                                value={getValues("licenseValidTo")}
                                                onChange={value => setValue("licenseValidTo", value)}
                                                renderInput={(params) => <TextField {...params} />}
                                                mask={mask}
                                            />
                                        </LocalizationProvider>)}
                                        name="licenseValidTo"
                                        control={control}

                                    />
                                    <div className="invalid-feedback">{errors.licenseValidTo?.message}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-sm-6">
                                    <label>{t("ALL.License.Currency")}*</label>
                                    <select name="currency" ref={register} className={`form-control ${errors.currency ? 'is-invalid' : ''}`}>
                                        <option value="CHF">CHF</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.edition?.message}</div>
                                </div>
                                <div className="form-group col-sm-6">
                                    <label>{t("ALL.License.Amount")}*</label>
                                    <input name="amountTotal" type="number" min={1} ref={register} className={`form-control ${errors.amountTotal ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.amountTotal?.message}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-sm-6">
                                    <label>{t("ALL.License.DateOrder")}*</label>
                                    <Controller
                                        render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                            <DatePicker
                                                readOnly={true}
                                                maxDate={isAddMode ? moment.utc() : undefined || undefined}
                                                value={getValues("orderDate")}
                                                onChange={value => setValue("orderDate", value)}
                                                renderInput={(params) => <TextField {...params} />}
                                                mask={mask}
                                            />
                                        </LocalizationProvider>)}
                                        name="orderDate"
                                        control={control}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-sm-4 justify-content-md-center">
                                    <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                        {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        {t("ALL.Button.Save")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {insureLicenseAssignement && <>
                        <div className="col-sm-4">
                            {insureLicenseAssignement.length === 0 ? <label>{t("ALL.License.AssignmentNo")}</label> : <>
                                <label>{t("ALL.License.Assignment")}</label>
                                <List>
                                    {insureLicenseAssignement!.map(item => (
                                        <ListItem>
                                            <ListItemText primary={`${item.caseName}`} secondary={`${moment(item.assignmentStartDate).format("L")}-${moment(item.assignmentEndDate).format("L")}`} />
                                        </ListItem>
                                    ))}
                                </List></>}
                        </div></>}
                </div>
            </div>
            <div style={{ marginTop: "50px" }}>
                <LicenseManagement insurerId={isAddMode ? insurerId : license?.insurerId} />
            </div>
        </div >
    );
}

export { AddEditLicense };