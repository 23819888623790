import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { alertService } from '../../services/Index';
import { adminService } from '../../services/casedesk/admin/AdminService';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Dictionary } from '../../models/Dictionary';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { Checkbox, FormControlLabel, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import moment from 'moment';
import { MenuProps, useStyles } from "./DictionaryUtils";
import { Link, useParams, useNavigate } from 'react-router-dom';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPalette } from '@fortawesome/pro-light-svg-icons';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { TwitterPicker } from "react-color";
import DictionaryTranslationManagement from './DictionaryTranslationManagement';

interface IAddEditDictionaryDataProps {
    dictionaryLabel?: any;
    close: any,
    edit: any,
    add: any,
    cleanSelectedNode: any,

    view: boolean,
    translation: boolean,
    translationList: any,
    editMode: boolean,
    selectedTopic: number,
    scopeEntityId: number,
    scopeId: number
}
const AddEditDictionaryData = ({ translationList, cleanSelectedNode, view, add, dictionaryLabel, close, edit, translation, editMode, selectedTopic, scopeEntityId, scopeId }: IAddEditDictionaryDataProps) => {
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const isAddMode: boolean = Object.entries(dictionaryLabel).length === 0
    const [tagColour, setTagColour] = useState<string>("#0693e3");
    const [showOverlay, setShowOverlay] = useState(false);
    const [insurerPrefix, setInsurerPrefix] = useState("");

    //const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const twitterStyle = {
        default: {
            input: {
                display: "none"
            },
            hash: {
                display: "none"
            }
        }
    };

    const updateColor = (e) => {
        setTagColour(e.hex);
        setShowOverlay(!showOverlay)
    }
    useEffect(() => {

        if (scopeId === 1) {
            setLoader(true);
            adminService.getSingleInsurer(scopeEntityId).then((rp) => {
                setInsurerPrefix(rp.insurerPrefix);
                setLoader(false);
                setFormProperties();

            }).catch((rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setLoader(false);
            });
        }
        else{
            setFormProperties();
        }
        
    }, [dictionaryLabel]);

    const setFormProperties=()=>{
        if (!isAddMode) {
            setValue("title", dictionaryLabel.title);
            setValue("description", dictionaryLabel.description);
            setValue("dicTopicId", dictionaryLabel.dicTopicId);
            setValue("language", dictionaryLabel.dicItemLng);
            setValue("labelColorId", dictionaryLabel.dicItemColorId);
            setTagColour(dictionaryLabel.dicItemColorId);
        }
    }
    const validationSchemaIsNumber = Yup.object().shape({
        title: Yup.string()
                .required(t("ALL.Dictionary.Validation.Title"))
                .matches(/^\d+(\.\d+)?$/,t("ALL.Dictionary.Messages.TitleTypeErrorNumber"))              
    });
    const validationSchemaIsText = Yup.object().shape({
        title: Yup.string().required(t("ALL.Dictionary.Validation.Title"))
    });

    const { register, handleSubmit, control, reset, setValue, getValues, errors, formState } = useForm({
        defaultValues: {
            title: "",
            description: "",
            dicTopicId: selectedTopic,
            language: (authUserData.currUserData.profileLanguage).toUpperCase(),
            labelColorId: "#0693e3"
        },
        resolver: yupResolver(selectedTopic===6?validationSchemaIsNumber:validationSchemaIsText)
    });

    const onSubmit = async (data: Dictionary) => {
        return isAddMode
            ? await createDictionaryItem(data)
            : await updateDictionaryItem(data);
    }

    const createDictionaryItem = async (data: Dictionary) => {
        let tempData = {
            "title": data.title,
            "sort": 1,
            "scopeTypeId": scopeId,
            "scopeEntityId": scopeEntityId,
            "insurer": insurerPrefix,
            "dicItemLng": (authUserData.currUserData.profileLanguage).toUpperCase(),
            "dicTopicId": selectedTopic ? selectedTopic : 0,
            "dicItemColorId": tagColour,
            "description": data.description,
            "createdBy": authUserData.currUserData.profileId
        }

        await adminService.postNewDictionaryItem(tempData)
            .then((rp) => {
                alertService.success(t("ALL.Dictionary.Messages.LabelAdded"), { keepAfterRouteChange: true });
                add(rp.Data);
                reset();
                close(false);
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }
            );
        cleanSelectedNode({});
    }
    const updateDictionaryItem = async (data: Dictionary) => {
        let tempData = {
            "id": dictionaryLabel.id,
            "title": data.title,
            "sort": 1,
            "scopeTypeId": scopeId,
            "scopeEntityId": scopeEntityId,
            "dicItemLng": (authUserData.currUserData.profileLanguage).toUpperCase(),
            "dicTopicId": selectedTopic ? selectedTopic : 0,
            "dicGuid": dictionaryLabel.dicGuid,
            "dicItemColorId": dictionaryLabel.dicItemColorId,
            "description": data.description,
            "modifiedBy": authUserData.currUserData.profileId,
            "modified": new Date()
        }
        await adminService.postUpdateDictionaryItem(tempData)
            .then((rp) => {
                alertService.success(t("ALL.Dictionary.Messages.TagUpdated"), { keepAfterRouteChange: true });
                edit(rp);
                reset();
                close(false);
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }

            );
        cleanSelectedNode({});
    }

    return (
        <div className="container">
            {loader && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {!loader &&
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        {translation ?
                            <div className="card">
                                <div className="card-header card-header-warning header-top">
                                    <h4 className="card-title">{isAddMode ? t("ALL.Dictionary.Button.Details") : t("ALL.Dictionary.TitleTranslate")}
                                        <span>
                                            <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-sm-right" onClick={close}>
                                                <FontAwesomeIcon icon={faCircleXmark} />
                                            </button>
                                        </span>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <DictionaryTranslationManagement updateSelectedDictionary={edit} translationList={translationList} closeModal={close} />
                                </div>
                                <div className="form-group row justify-content-center">
                                    <div className="form-group col-sm-4">
                                        <button className="btn btn-grey" onClick={(event) => {
                                            event.preventDefault();
                                            reset();
                                            cleanSelectedNode({});
                                            close(false)
                                        }}>
                                            {t("ALL.Button.Close")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="card">
                                <div className="card-header card-header-warning header-top">
                                    <h4 className="card-title">{isAddMode ? t("ALL.Dictionary.Button.Add") : view ? t("ALL.Dictionary.Button.Details") : t("ALL.Dictionary.Button.Edit")}
                                        <span>
                                            <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-sm-right" onClick={close}>
                                                <FontAwesomeIcon icon={faCircleXmark} />
                                            </button>
                                        </span>
                                    </h4>

                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-row">
                                            <div className="form-group col-sm-8">
                                                {scopeId === 1 &&
                                                    <div className="form-row">
                                                        <div className="form-group col-sm-8">
                                                            <label>{t("ALL.Dictionary.Role.Insurer")}</label>
                                                            <input name="insurer" type="text" className="form-control" value={insurerPrefix} readOnly />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-row">
                                                    <div className="form-group col-sm-8">
                                                        <label>{t("ALL.Dictionary.Topic.Title")}</label>
                                                        <select name="dicTopicId" ref={register} className={`form-control ${errors.dicTopicId ? 'is-invalid' : ''}`} disabled={true}>
                                                            <option value={0}>{t("ALL.Dictionary.Topic.Options.0")}</option>
                                                            <option value={1}>{t("ALL.Dictionary.Topic.Options.1")}</option>
                                                            <option value={2}>{t("ALL.Dictionary.Topic.Options.2")}</option>
                                                            <option value={3}>{t("ALL.Dictionary.Topic.Options.3")}</option>
                                                            <option value={4}>{t("ALL.Dictionary.Topic.Options.4")}</option>
                                                            <option value={5}>{t("ALL.Dictionary.Topic.Options.5")}</option>
                                                            <option value={6}>{t("ALL.Dictionary.Topic.Options.6")}</option>
                                                            <option value={7}>{t("ALL.Dictionary.Topic.Options.7")}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.dicTopicId?.message}</div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-sm-6">
                                                        <label>{t("ALL.Dictionary.Language")}</label>
                                                        <input name="language" type="text" ref={register} disabled={true} />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-sm-8">
                                                        <label>{t("ALL.Dictionary.Item")}*</label>
                                                        <input name="title" type="text" ref={register} className={`form-control ${errors.title ? 'is-invalid' : ''}`} />
                                                        <div className="invalid-feedback">{errors.title?.message}</div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-sm-8">
                                                        <label>{t("ALL.Dictionary.Description")}</label>
                                                        <textarea name="description" rows={5} ref={register} className={`form-control ${errors.description ? 'is-invalid' : ''}`} />
                                                        <div className="invalid-feedback">{errors.description?.message}</div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-sm-12"
                                                        style={{ "display": 'flex', "alignItems": "center" }}>
                                                        {!editMode && <>
                                                            <label>{t("ALL.Dictionary.Color")}</label>
                                                            <OverlayTrigger
                                                                show={showOverlay}
                                                                trigger="click"
                                                                overlay={
                                                                    <Popover id="popover-contained" placement="right">
                                                                        <Popover.Header as="h3">Tag Colour</Popover.Header>
                                                                        <Popover.Body>
                                                                            <TwitterPicker
                                                                                name="dicItemColorId"
                                                                                styles={twitterStyle}
                                                                                triangle="hide"
                                                                                // colors={palette}
                                                                                width="212px"
                                                                                // onChange={(e) => updateColor(e)}
                                                                                onChange={(event) => updateColor(event)}
                                                                            />
                                                                        </Popover.Body>
                                                                    </Popover >
                                                                }
                                                                rootClose >
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setShowOverlay(!showOverlay)
                                                                    }}
                                                                    className="btn btn-md btn-icon-grey">
                                                                    <FontAwesomeIcon icon={faPalette} />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </>}
                                                        <label>{t("ALL.Dictionary.ColorSelected")}</label>
                                                        <div style={{ paddingLeft: "5px" }}>
                                                            <div style={{
                                                                "height": "15px", "width": "18px",
                                                                "border": "1px solid #ffffff00",
                                                                "marginRight": "5px",
                                                                "backgroundColor": tagColour,
                                                                "display": 'inline-block'
                                                            }}> </div>
                                                            {/* <span>{dictionaryLabel.title}</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row justify-content-center">
                                            <div className="form-group col-sm-4">
                                                <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    {t("ALL.Button.Save")}
                                                </button>
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <button className="btn btn-grey" onClick={(event) => {
                                                    event.preventDefault();
                                                    reset();
                                                    cleanSelectedNode({});
                                                    close(false)
                                                }}>
                                                    {t("ALL.Button.Cancel")}
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </div >
            }
        </div >

    );
}
export { AddEditDictionaryData };
