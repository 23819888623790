import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { alertService, formTemplateService } from '../../../services/Index';
import { faList } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormTemplate from '../../../models/FormTemplate';
import { AddEditFormTemplate } from './AddEditFormTemplate';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';
import { faTrash } from '@fortawesome/pro-light-svg-icons'


function TemplateManagement() {
    const { authUserData } = useContext(LoggedUserDataContext);
    const [templates, setTemplates] = useState<FormTemplate[]>();
    const [modalIsOpen, setModalVisibility] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<any>({})
    const toggle = () => setModalVisibility(!modalIsOpen);
    function addLicense(templateId) {
        setSelectedTemplate(templateId);
        setModalVisibility(true);
    }
    const { t } = useContext(MultiLanguageSupportContext);
    function getTemplates() {
        formTemplateService.getInsurerFormTemplatesAsync(authUserData.currUserData.relatedData[0].id).then(x => setTemplates(x)).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
    function deleteTemplate(templateId: number) {
        formTemplateService.deleteFormTemplate(templateId).then(() => {
            alertService.success(t("ALL.FormTemplate.Removed"), { keepAfterRouteChange: true });
            setTemplates(m => m!.filter(x => x.id !== templateId));
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
            }
        );
    }
    useEffect(() => {
        getTemplates();
    }, []);
    return (
        <div className="container">

            <p className="component-header">{t("ALL.FormTemplates.HeadTitle")}</p>
            <hr></hr>
            <div className=" white-container">
                <AddEditFormTemplate reSetTemplates={getTemplates} />
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: '15%' }}>{t("ALL.FormTemplates.Title")}</th>
                            <th style={{ width: '20%' }}>{t("ALL.Button.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates && templates.map(s =>
                            <tr key={s.id}>
                                <td>{s.templateTitle}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {/* <Link to={`insurer/edit-goal-template/${s.id}`} className="btn btn-md btn-icon-blue mr-1"><FontAwesomeIcon icon={faList} /></Link> */}
                                    <Link to={`/${authUserData.currUserData.roleType.toLowerCase()}/edit-goal-template/${s.id}`} className="btn btn-md btn-icon-blue mr-1"><FontAwesomeIcon icon={faList} /></Link>
                                    <button
                                        onClick={() => deleteTemplate(s.id)}
                                        className="btn btn-md btn-icon-red">
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                        )}
                        {!templates &&
                            <tr>
                                <td colSpan={6} className="text-center">
                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                </td>
                            </tr>
                        }
                        {templates && !templates.length &&
                            <tr>
                                <td colSpan={6} className="text-center">
                                    <div className="p-2">{t("ALL.Common.NoData")}</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );

}

export { TemplateManagement };