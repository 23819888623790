import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { LocalStorageKeys } from "./local-storage.keys";
import { defaultLanguage } from "./supported-languages";
const DETECTION_OPTIONS = {
    order: ['navigator']
};
export const lng = localStorage.getItem(LocalStorageKeys.appLang) || defaultLanguage;
i18n.use(initReactI18next)
    .use(Backend)
    .init({
        react: {
            useSuspense: false
        },
        lng,
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        detection: DETECTION_OPTIONS,
        fallbackLng: defaultLanguage,
        // whitelist: supportedLanguages,
        interpolation: {
            escapeValue: false,
        },
    });
i18n.changeLanguage();
export default i18n;
