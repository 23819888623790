import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { alertService } from '../../../services/Index';
import { tasksService } from '../../../services/casedesk/tasks/TaskService';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import TaskGeneratorRule from '../../../models/TaskGeneratorRule';
import { CaseDataContext } from '../../../ctx/CaseDataProvider';
import { Checkbox, FormControlLabel, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { LoggedUserDataContext } from '../../../ctx/LoggedUserDataProvider';
import moment from 'moment';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPalette } from '@fortawesome/pro-light-svg-icons';
import { resipientsOptionsTempCamOnly, useStyles } from "../../notifications/SettingsUtils";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { ToolTip } from '../../ToolTip';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

interface IAddEditDictionaryDataProps {
    taskRuleData?: any;
    close: any,
    edit: any,
    add: any,
    cleanSelectedNode: any,
    ruleTemplateId?: number,
    view: boolean,
    planTags: any,
    selectedRuleTemp: any,
    taskTemplatesDropdownData: any
}
const AddEditTaskRules = ({ planTags, ruleTemplateId, cleanSelectedNode, view, add, taskRuleData, close, edit, selectedRuleTemp, taskTemplatesDropdownData }: IAddEditDictionaryDataProps) => {

    const classes = useStyles();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { locale, t } = useContext(MultiLanguageSupportContext);
    const isAddMode: boolean = Object.entries(taskRuleData).length === 0
    const [contactsControllOpen, setContactsControllOpen] = useState(false);
    const [contacts, setContacts] = useState<string[]>([]);
    const [templateSelected, setTemplateSelected] = useState();

    useEffect(() => {
        if (!isAddMode) {
            setValue("ruleTitle", taskRuleData.ruleTitle);
            setValue("startTagId", taskRuleData.startTagId);
            setValue("targetContact", taskRuleData.targetContact);
            setValue("dueTagId", taskRuleData.dueTagId);
            setValue("startToDueDays", taskRuleData.startToDueDays);
            setValue("dueTime", taskRuleData.dueTime);
            setContacts(taskRuleData.targetContact);
            setValue("taskGeneratorRuleTemplateId", taskRuleData.taskGeneratorRuleTemplateId)
        }
    }, [taskRuleData]);

    const validationSchema = Yup.object().shape({
        ruleTitle: Yup.string().required(t("ALL.Tasks.Generator.Rules.ValidationTitle")),
    });

    const { register, handleSubmit, control, reset, setValue, getValues, errors, formState: { isSubmitting } } = useForm({
        defaultValues: {
            ruleTitle: "",
            startTagId: "",
            targetContact: "",
            dueTagId: "",
            startToDueDays: "",
            dueTime: moment(),
            taskGeneratorRuleTemplateId: ruleTemplateId ? ruleTemplateId : selectedRuleTemp //ruletempid
        },
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: TaskGeneratorRule) => {
        return isAddMode
            ? await createTaskRule(data)
            : await updateTaskRule(data);
    }

    const createTaskRule = async (data: TaskGeneratorRule) => {
        let tempData = {
            "ruleTitle": data.ruleTitle,
            "startTagId": data.startTagId,
            "dueTagId": data.dueTagId,
            "scopeTypeId": 1,
            "scopeEntityId": authUserData.currUserData.insurerId!,
            "startToDueDays": data.startToDueDays,
            "dueTime": data.dueTime,
            "targetContact": contacts,
            "createdBy": authUserData.currUserData.profileId,
            "taskGeneratorRuleTemplateId": ruleTemplateId ? ruleTemplateId : selectedRuleTemp
        }
        await tasksService.postNewTaskGeneratorRule(tempData)
            .then((rp) => {
                alertService.success(t("ALL.Tasks.Generator.Rules.Added"), { keepAfterRouteChange: true });
                add(rp);
                reset();
                close(false);
                cleanSelectedNode({});
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }
            );
    }
    const updateTaskRule = async (data: TaskGeneratorRule) => {

        let tempData = {
            "id": taskRuleData.id,
            "ruleTitle": data.ruleTitle,
            "startTagId": data.startTagId,
            "dueTagId": data.dueTagId,
            "scopeTypeId": 1,
            "scopeEntityId": authUserData.currUserData.insurerId!, //insurer id
            "startToDueDays": data.startToDueDays,
            "dueTime": data.dueTime,
            "targetContact": contacts,
            "modifiedBy": authUserData.currUserData.profileId,
            "modified": new Date(),
            "taskGeneratorRuleTemplateId": ruleTemplateId ? ruleTemplateId : selectedRuleTemp
        }


        await tasksService.postUpdateTaskGeneratorRule(tempData)
            .then((rp) => {
                alertService.success(t("ALL.Tasks.Generator.Rules.Updated"), { keepAfterRouteChange: true });
                edit(rp);
                reset();
                close(false);
                cleanSelectedNode({});
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }

            );
    }

    const MenuProps = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    const handleContactChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setContacts(contacts.length === resipientsOptionsTempCamOnly.length ? [] : resipientsOptionsTempCamOnly);
            return;
        }
        setContacts(value);
    };

    const handleSelectTemplate = (e) => {
        console.log("handleSelectTemplate", e.target.value);
        setTemplateSelected(e.target.value)
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    {
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-ruleTitle">{isAddMode ? t("ALL.Tasks.Generator.Rules.Add") : view ? t("ALL.Tasks.Generetor.Rules.Details") : t("ALL.Tasks.Generator.Rules.Edit")}
                                    <span>
                                        <button className="btn btn-md btn-icon-grey mr-1 float-sm-right" onClick={close}>
                                            <FontAwesomeIcon icon={faCircleXmark} />
                                        </button>
                                    </span>
                                </h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="form-group col-sm-8">
                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <label>{t("ALL.Tasks.Generator.Templates.RuleSet")}</label>
                                                    <select name="taskGeneratorRuleTemplateId" ref={register} className={`form-control ${errors.taskGeneratorRuleTemplateId ? 'is-invalid' : ''}`} disabled={selectedRuleTemp == -1 && !ruleTemplateId ? false : true} onChange={(e) => handleSelectTemplate(e)}>
                                                        {taskTemplatesDropdownData.map((option) => (
                                                            <option value={option.id}>{option.templateTitle}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.taskGeneratorRuleTemplateId?.message}</div>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <label>{t("ALL.Tasks.Generator.Rules.RuleTitle")}</label>
                                                    <input name="ruleTitle" type="text" ref={register} className={`form-control ${errors.ruleTitle ? 'is-invalid' : ''}`} />
                                                    <div className="invalid-feedback">{errors.ruleTitle?.message}</div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <label>{t("ALL.Tasks.Generator.Rules.StartTag")}</label>
                                                    {/* <div style={{ "display": "flex", "justifyContent": "spaceBetween"}}> */}
                                                        <select name="startTagId" ref={register} className={`form-control ${errors.startTagId ? 'is-invalid' : ''}`} >
                                                            {planTags.length>0 ? 
                                                                planTags.map((option) => (
                                                                <option value={option.dicGuid}>{option.title}</option>
                                                                )) 
                                                                :
                                                                <option value="ALL.Dictionary.Controls.NoItemAvailable" disabled >{t("ALL.Dictionary.Controls.NoItemAvailable")}</option>
                                                            }
                                                        </select>                                                        
                                                    {/* </div> */}
                                                    <div className="invalid-feedback">{errors.startTagId?.message}</div>
                                                </div>
                                                <div className="form-group col-sm-2" style={{"paddingTop": "5%"}}>
                                                    <FontAwesomeIcon icon={faInfoCircle} id="TooltipRuleStartTag" className="info-icon" style={{"paddingLeft": "3%"}} />
                                                    <ToolTip infoText={t("ALL.Dictionary.Controls.TaskRuleStartTags")} target="TooltipRuleStartTag" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <label>{t("ALL.Tasks.Generator.Rules.DueTag")}</label>
                                                    {/* <div style={{ "display": "flex", "justifyContent": "spaceBetween"}}> */}
                                                        <select name="dueTagId" ref={register} className={`form-control ${errors.dueTagId ? 'is-invalid' : ''}`} >
                                                            {planTags.length>0 ? planTags.map((option) => (
                                                                <option value={option.dicGuid}>{option.title}</option>
                                                            )) : 
                                                            <option value="ALL.Dictionary.Controls.NoItemAvailable" disabled >{t("ALL.Dictionary.Controls.NoItemAvailable")}</option>
                                                            }
                                                        </select>
                                                        
                                                    {/* </div> */}
                                                    <div className="invalid-feedback">{errors.dueTagId?.message}</div>
                                                </div>
                                                <div className="form-group col-sm-2" style={{"paddingTop": "5%"}}>
                                                    <FontAwesomeIcon icon={faInfoCircle} id="TooltipRuleDueTag" className="info-icon" style={{"paddingLeft": "3%"}} />
                                                    <ToolTip infoText={t("ALL.Dictionary.Controls.HelpTaskDuesTags")} target="TooltipRuleDueTag" />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <label>{t("ALL.Tasks.Generator.DaysDue")}*</label>
                                                    <input name="startToDueDays" type="number" ref={register} className={`form-control ${errors.startToDueDays ? 'is-invalid' : ''}`} />
                                                    <div className="invalid-feedback">{errors.startToDueDays?.message}</div>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <label>{t("ALL.Tasks.Generator.TimeDue")}*</label>
                                                    <Controller
                                                        render={() => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                            <TimePicker
                                                                // label="dueTime"
                                                                ref={register}
                                                                value={getValues("dueTime")}
                                                                onChange={value => setValue("dueTime", value)}
                                                                // moment(value).format('HH:mm')
                                                                ampm={false}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>)}
                                                        name="dueTime"
                                                        control={control}

                                                    />

                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <label>{t("ALL.Tasks.Generator.TargetContact")}</label>
                                                    <Controller
                                                        control={control}
                                                        name="multipleSelect"
                                                        render={() => {
                                                            const labelArr = contacts.map((element, index) => {
                                                                return element.replace(/\s+/g, '')
                                                            });
                                                            return (
                                                                <Select
                                                                    style={{ width: "100%", fontSize: "12px" }}
                                                                    multiple
                                                                    value={labelArr}
                                                                    onClose={(event) => {
                                                                        // onBlur(event);
                                                                        setContactsControllOpen(false);
                                                                    }}
                                                                    onOpen={() => setContactsControllOpen(true)}
                                                                    onChange={handleContactChange}
                                                                    open={contactsControllOpen}
                                                                    displayEmpty={true}
                                                                    {...MenuProps}
                                                                    variant={"outlined"}
                                                                    renderValue={(selected) => {
                                                                        return (
                                                                            selected?.map((option) => option).join(", ") ||
                                                                            "Select some options"
                                                                        );
                                                                    }}
                                                                >
                                                                    <MenuItem value="all">
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                classes={{ indeterminate: classes.indeterminateColor }}
                                                                                checked={resipientsOptionsTempCamOnly.length > 0 && labelArr.length === resipientsOptionsTempCamOnly.length}
                                                                                indeterminate={
                                                                                    labelArr.length > 0 && labelArr.length < resipientsOptionsTempCamOnly.length
                                                                                }
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            classes={{ primary: classes.selectAllText }}
                                                                            primary="Select All"
                                                                        />
                                                                    </MenuItem>
                                                                    {resipientsOptionsTempCamOnly.map((option) => (
                                                                        <MenuItem key={option} value={option}>
                                                                            <ListItemIcon>
                                                                                <Checkbox checked={labelArr.indexOf(option) > -1} />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={option} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            );
                                                        }}
                                                    />
                                                    <div className="invalid-feedback">{errors.targetContact?.message}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="form-group row justify-content-center">
                                        <div className="form-group col-sm-4">
                                            <button type="submit" disabled={isSubmitting} className="btn btn-action">
                                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                {t("ALL.Button.Save")}
                                            </button>
                                        </div>
                                        <div className="form-group col-sm-4">
                                            <button className="btn btn-grey" onClick={(event) => {
                                                event.preventDefault();
                                                reset();
                                                cleanSelectedNode({});
                                                close(false)
                                            }}>
                                                {t("ALL.Button.Cancel")}
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div >
        </div>

    );
}


export { AddEditTaskRules };
