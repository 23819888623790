import { useState, useEffect, useContext } from 'react';
import { adminService, alertService,   contactService } from '../../services/Index';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'moment/min/locales';
import "bootstrap/js/src/collapse.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { faChevronRight, faPen, faTrash} from '@fortawesome/pro-light-svg-icons'
import { ListItem, ListItemText } from '@mui/material';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

import { LoggedUserDataContext } from '../../../src/ctx/LoggedUserDataProvider';

function ContactManagement() {
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const [contacts, setContacts] = useState<any[]>();
    useEffect(() => {
        contactService.getAllContactsWithAssignement().then(
            (x) => {
                setContacts(x)
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }, []);
    function deleteContact(contactId:number) {
        setContacts(contacts!.map(x => {
            if (x.id === contactId) { x.isDeleteing = true; }
            return x;
        }));
        adminService.deleteContact(contactId).then(() => {
            alertService.success(t("ALL.Contact.Removed"), { keepAfterRouteChange: true });
            setContacts(m => m!.filter(x => x.id === contactId));
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true })
                setContacts(contacts!.map(x => {
                    if (x.id === contactId) { x.isDeleteing = false; }
                    return x;
                }));
            }
        );
    }
    return (
        <div className="container">
            <p className="component-header">{t("ALL.Contact.Overview")}</p>
            <hr></hr>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{t("ALL.Contact.Firstname")}</th>
                        <th>{t("ALL.Contact.Lastname")}</th>
                        <th>{t("ALL.Contact.EMail")}</th>
                        <th>{t("ALL.Contact.Role")}</th>
                        <th className="text-center">{t("ALL.Button.Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts && contacts.map(c =><>
                        <tr key={c.contactData.id}
                        className="collapsed"
                        data-toggle="collapse"
                        data-target={".multi-collapse" + c.contactData.id.toString()}
                        aria-controls={"multiCollapse" + c.contactData.id.toString()}>
                            <td>{c.contactData.name?c.contactData.name: "-"}</td>
                            <td>{c.contactData.surname ? c.contactData.surname : "-"}</td>
                            <td>{c.contactData.email}</td>
                            <td>{c.contactData.role}</td>
                            <td style={{ whiteSpace: 'nowrap'}}>
                                <Link to={`/${authUserData.currUserData.roleType.toLowerCase()}/edit-contact/${c.contactData.id}`} className="btn btn-md btn-icon-green mr-1"><FontAwesomeIcon icon={faPen} /></Link>
                                <button onClick={() => deleteContact(c.contactData.id)} className="btn btn-md btn-icon-red" disabled={c.contactData.isDeleteing}>
                                    <FontAwesomeIcon icon={faTrash} /> {c.contactData.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                </button>
                                <span className="btn btn-md mr-1"><FontAwesomeIcon icon={faChevronRight} /></span>
                            </td>

                        </tr>
                        <tr></tr>
                        <tr className={"collapse multi-collapse" + c.contactData.id.toString()} id={"multiCollapse" + c.contactData.id.toString()}>
                            <td colSpan={5}><p>{t("CAM.Case.CaseAssignments")}</p>
                            {c.casesNames && c.casesNames.length>0 && c.casesNames.map(a =>
                            <ListItem>
                                <ListItemText primary={a} />
                            </ListItem>
                            )}
                            {c.casesNames && c.casesNames.length==0 &&<p>-</p>}
                            </td>
                        </tr>
                        </>
                    )}
                    {!contacts &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {contacts && !contacts.length &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}

export { ContactManagement };