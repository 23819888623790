import { useState, useEffect, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { CaseInfoCard } from '../../components/CaseInfoCard';

import { alertService, caseManagerService } from '../../services/Index';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

const Dashboard=()=> {
    const [caseItem, setCase] = useState<any>();
    const {authUserData} = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const { setCaseData } = useContext(CaseDataContext);
    useEffect(() => {
        caseManagerService.getSingleCaseWithDetails(authUserData.currUserData.relatedData[0].id).then((c) => {
            setCase(c);
            setCaseData(
                {
                    selectedCaseId: c.caseData.id,
                    caseInsurerReference:c.caseData.referenceNumberOfInsurance,
                    caseClienName:c.clientData.displayName,
                    caseClient:c.clientData,
                    caseDoctor:c.doctorData,
                    caseEmployeer:c.employeerData,
                    caseCaseManager:c.caseManagerData,
                    caseNumber:c.caseNumber,
                    insurerProfileImage:c.insurerProfileImage,
                    insurerId:c.caseData.insurerId
                });
        
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }, []);
    return (<>
        <div className="container">
            {!caseItem && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {caseItem && <><CaseInfoCard caseItem={caseItem} />
            </>}
        </div></>
    );
}


export { Dashboard };