import BaseService from '../../BaseService';
import Response from "../../../models/Response";

import Note from '../../../models/Note';

export const notesService = {
    getAllNotes,
    postNewNote,
    postUpdateNote,
    getContactNotes,
    getContactCaseNotes,
    deleteNote,
    getSharedNotesCaseNotes,
    getSingleNote
};
async function postNewNote(data: any) {
    let res = BaseService.create<any>("Note/CreateNote", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateNote(data: any) {
    let res = BaseService.update<any>("Note/UpdateNote", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleNote(noteId: number): Promise<Note> {
    let res = BaseService.get<Note>("Note/GetSingleNote?noteId=", noteId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteNote(noteId: number): Promise<any> {
    let res = BaseService.delete("Note/RemoveNotes?noteId=", noteId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getContactNotes(contactADId:string): Promise<any[]> {
    let res=BaseService.get<any>("Note/GetContactNotes",`?contactADId=${contactADId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSharedNotesCaseNotes(contactADId:string,caseId:number): Promise<any[]> {
    let res=BaseService.get<any>("Note/GetContactSharedNotesInCase",`?contactADId=${contactADId}&caseId=${caseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getContactCaseNotes(contactADId:string,caseId:number): Promise<any[]> {
    let res=BaseService.get<any>("Note/GetContactCaseNotes",`?contactADId=${contactADId}&caseId=${caseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllNotes(token:string): Promise<any[]> {
    let res=BaseService.getAll<any>("Note/GetAllNotes").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

