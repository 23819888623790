import BaseService from '../../BaseService';
import Response from "../../../models/Response";
import FormTemplate from '../../../models/FormTemplate';
import { Form, FormEntityIdentifier } from '../../../models/Form';

export const formTemplateService = {
    getAllFormTemplates,
    postNewFormTemplate,
    postUpdateFormTemplate,
    getInsurerFormTemplatesAsync,
    deleteFormTemplate,
    getSingleFormTemplate,
    publishFormTemplate,
    postNewFormInstance,
    postUpdateFormInstance,
    getSingleFormInstance,
    getEntityForms,
    deleteFormInstance,
    postUpdateFormTemplateContent
};
async function postNewFormTemplate(data: any) {
    let res = BaseService.create<any>("FormTemplate/CreateFormTemplate", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateFormTemplate(data: any) {
    let res = BaseService.update<any>("FormTemplate/UpdateFormTemplate", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateFormTemplateContent(data: any) {
    let res = BaseService.update<any>("FormTemplate/UpdateFormTemplateContentAsString", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleFormTemplate(templateId: number): Promise<FormTemplate> {
    let res = BaseService.get<FormTemplate>("FormTemplate/GetSingelFormTemplate?templateId=", templateId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteFormTemplate(templateId: number): Promise<any> {
    let res = BaseService.delete("FormTemplate/RemoveFormTemplates?templateId=", templateId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function publishFormTemplate(data: any): Promise<any> {
    let res = BaseService.update("FormTemplate/PublishFormTemplate", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getInsurerFormTemplatesAsync(insurerId:string): Promise<any[]> {
    let res=BaseService.get<any>("FormTemplate/GetInsurerFormTemplates",`?insurerId=${insurerId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllFormTemplates(token:string): Promise<any[]> {
    let res=BaseService.getAll<any>("FormTemplate/GetAllFormTemplates").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postNewFormInstance(data: Form) {
    let res = BaseService.create<any>("FormTemplate/CreateFormInstance", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateFormInstance(data: any) {
    let res = BaseService.update<any>("FormTemplate/UpdateFormInstance", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleFormInstance(templateTypeId: number,entityId:number): Promise<FormTemplate> {
    let res = BaseService.get<FormTemplate>("FormTemplate/GetFormInstance",`?templateTypeId=${templateTypeId}&entityId=${entityId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteFormInstance(formId: number): Promise<any> {
    let res = BaseService.delete("FormTemplate/DeleteFormInstance?formId=", formId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getEntityForms(query:FormEntityIdentifier): Promise<any[]> {
    let res=BaseService.post<any>("FormTemplate/GetEntityFormsAcync",query).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

