import IconButton from 'react-rte/lib/ui/IconButton';
import { Component } from 'react';
import RichTextEditor from 'react-rte';

import { ButtonGroup, Dropdown } from 'reactstrap';


interface IProps {
  onChange: any,
  body: string,
  t: any,

}
export class CustomRichTextEditor extends Component<IProps> {

  state = {
    value: RichTextEditor.createValueFromString(this.props.body, 'html'),
    readOnly: true,
  }

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(
        value.toString('html', { blockStyleFn: RichTextEditor.getTextAlignStyles })
      );
    }
  };

  onChangeSource = (event: any) => {
    let source = event.target.value;
    let oldValue = this.state.value;
    this.setState({
      value: oldValue.setContentFromString(source, 'html', { customBlockFn: RichTextEditor.getTextAlignBlockMetadata }),
    });
    if (this.props.onChange) {
      this.props.onChange(
        source.toString('html', { blockStyleFn: RichTextEditor.getTextAlignStyles })
      );
    }
  }

  onChangeFormat = (event: any) => {
    this.setState({ format: event.target.value });
  }

  render() {
    return (<>
      <RichTextEditor
        value={this.state.value}
        onChange={this.onChange}
        readOnly={this.state.readOnly}

        customControls={[
          // eslint-disable-next-line no-unused-vars
          (setValue, getValue, editorState) => {
            let choices = new Map([
              ['1', { label: '1' }],
              ['2', { label: '2' }],
              ['3', { label: '3' }],
            ]);
            return (
              <ButtonGroup key={1}>
                <Dropdown
                  choices={choices}
                  selectedKey={getValue('my-control-name')}
                  onChange={(value) => setValue('my-control-name', value)}
                />
              </ButtonGroup>
            );
          },
          <ButtonGroup key={2}>
            <IconButton
              label="Remove Link"
              iconName="remove-link"
              focusOnClick={false}
              onClick={() => console.log('You pressed a button')}
            />
          </ButtonGroup>,
        ]}
      />
      <label style={{ marginTop: "20px;" }}>{this.props.t("ALL.Settings.TemplateHTML")}</label>
      <textarea
        className="source-editor form-control"
        placeholder="Editor Source"
        value={this.state.value.toString('html', { blockStyleFn: RichTextEditor.getTextAlignStyles })}
        onChange={this.onChangeSource}
      />
    </>
    );
  }
}