import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { webService, alertService } from '../../services/Index';

import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useNavigate } from 'react-router-dom';

function WebEditSetting() {
    let navigate = useNavigate();
    const [settingId, setSettingId] = useState<number>(-1);
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        webService.getActiveCalculationProperties().then(i => {
            setValue("calculationPropertiesId", i.calculationPropertiesId);
            setValue("caseCalculatorPercentageFaster", i.caseCalculatorPercentageFaster);
            setValue("caseCalculatorDoctorStandardReportTimeHours", i.caseCalculatorDoctorStandardReportTimeHours);
            setValue("caseCalculatorDoctorStandardHourlyRateCHF", i.caseCalculatorDoctorStandardHourlyRateCHF);
            setValue("caseCalculatorDoctorCaseDeskReportTimeHours", i.caseCalculatorDoctorCaseDeskReportTimeHours);
            setValue("caseCalculatorMonthlyCoordinationCostCHF", i.caseCalculatorMonthlyCoordinationCostCHF);
            setValue("caseCalculatorMonthlyRateCaseProCHF", i.caseCalculatorMonthlyRateCaseProCHF);
            setValue("caseCalculatorMaximumDailyAllownce", i.caseCalculatorMaximumDailyAllownce);
            setValue("daysYear", i.daysYear);
            setValue("daysMonth", i.daysMonth);
            setSettingId(i.calculationPropertiesId);
        }).catch(
            (rp: any) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );

    }, []);

    const validationSchema = Yup.object().shape({
        caseCalculatorPercentageFaster: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t("ALL.Validation.Title")),
        caseCalculatorDoctorStandardReportTimeHours: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t('ALL.Validation.Title')),
        caseCalculatorDoctorStandardHourlyRateCHF: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t('ALL.Validation.Title')),
        caseCalculatorDoctorCaseDeskReportTimeHours: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t('ALL.Validation.Title')),
        caseCalculatorMonthlyCoordinationCostCHF: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t('ALL.Validation.Title')),
        caseCalculatorMonthlyRateCaseProCHF: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t('ALL.Validation.Title')),
        caseCalculatorMaximumDailyAllownce: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t('ALL.Validation.Title')),
        daysYear: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t('ALL.Validation.Title')),
        daysMonth: Yup.number().positive(t('ALL.Validation.ValueGreaterThenZero')).required(t('ALL.Validation.Title')),


    });

    const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data: any) {
        return updateSetting(settingId, data);
    }

    function updateSetting(id: number, data: any) {
        return webService.postCreateCalculationProperties(data)
            .then(() => {
                alertService.success(t("ALL.Settings.WebSite.Updated"), { keepAfterRouteChange: true });
                navigate(".")
            })
            .catch(
                (rp: any) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    return (<>{!settingId &&
        <div className="spinner-border spinner-border-lg align-center"></div>
    }
        {settingId &&
            <div className="container">
                <p className="component-header">{t("ALL.Settings.WebSite.Edit")}</p>
                <hr></hr>
                <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.PercentageFaster")}</label>
                            <input name="caseCalculatorPercentageFaster" type="text" ref={register} className={`form-control ${errors.caseCalculatorPercentageFaster ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.caseCalculatorPercentageFaster?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.DoctorStandardReportTimeHours")}</label>
                            <input name="caseCalculatorDoctorStandardReportTimeHours" type="text" ref={register} className={`form-control ${errors.caseCalculatorDoctorStandardReportTimeHours ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.caseCalculatorDoctorStandardReportTimeHours?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.DoctorStandardHourlyRateCHF")}</label>
                            <input name="caseCalculatorDoctorStandardHourlyRateCHF" type="text" ref={register} className={`form-control ${errors.caseCalculatorDoctorStandardHourlyRateCHF ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.caseCalculatorDoctorStandardHourlyRateCHF?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.DoctorCaseDeskReportTimeHours")}</label>
                            <input name="caseCalculatorDoctorCaseDeskReportTimeHours" type="text" ref={register} className={`form-control ${errors.caseCalculatorDoctorCaseDeskReportTimeHours ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.caseCalculatorDoctorCaseDeskReportTimeHours?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.MonthlyCoordinationCostCHF")}</label>
                            <input name="caseCalculatorMonthlyCoordinationCostCHF" type="text" ref={register} className={`form-control ${errors.caseCalculatorMonthlyCoordinationCostCHF ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.caseCalculatorMonthlyCoordinationCostCHF?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.MonthlyRateCaseProCHF")}</label>
                            <input name="caseCalculatorMonthlyRateCaseProCHF" type="text" ref={register} className={`form-control ${errors.caseCalculatorMonthlyRateCaseProCHF ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.caseCalculatorMonthlyRateCaseProCHF?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.MaximumDailyAllownce")}</label>
                            <input name="caseCalculatorMaximumDailyAllownce" type="text" ref={register} className={`form-control ${errors.caseCalculatorMaximumDailyAllownce ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.caseCalculatorMaximumDailyAllownce?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.DaysYear")}</label>
                            <input name="daysYear" type="text" ref={register} className={`form-control ${errors.daysYear ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.daysYear?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label>{t("ALL.Settings.WebSite.Calculator.DaysMonth")}</label>
                            <input name="daysMonth" type="text" ref={register} className={`form-control ${errors.daysMonth ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.daysMonth?.message}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-sm-4 justify-content-md-center">
                            <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                {t("ALL.Button.Save")}
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        }</>
    );
}

export { WebEditSetting };