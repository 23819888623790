import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Tabs, Tab } from "react-bootstrap";
import { Upload } from './upload/Upload';
import { alertService, caseManagerService, documentService, notificationService } from '../../services/Index';

import { ListDocuments } from './ListDocuments';
import { useNavigate, useParams } from 'react-router-dom';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';
import { AppAccessContext } from '../../ctx/AppAccessProvider ';


interface IDocumentManagementProps {
    role: string,
    caseId?: number
}
const DocumentManagement = ({ role, caseId }: IDocumentManagementProps) => {
    const [caseDocuments, setCaseDocuments] = useState<Array<any>>();
    const { notificationsDetails, setNotifications } = useContext(NotificationContext);
    const [contactDocuments, setContactDocuments] = useState<Array<any>>();
    const { authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    const { setCaseData } = useContext(CaseDataContext);
    const [clearUpload, setClear] = useState(false);
    let { id, notId } = useParams();
    const docCaseId = !id ? caseId! : parseInt(id);
    const [tabKey, setTabKey] = useState('Case');
    const { t } = useContext(MultiLanguageSupportContext);
    const { setAppAccess } = useContext(AppAccessContext);
    let navigate = useNavigate();
    useEffect(() => {
        if (authUserData.currUserData.roleType.toLowerCase() !== "client") {
            setAppAccess({
                hasAccessToPath: (authUserData.currUserData.relatedData.filter(data => data.id === docCaseId && (authUserData.currUserData.roleType.toLowerCase()==="casemanager"?data.inCaseManagerRole === "Yes":true)).length > 0),
            });
        }
        if (docCaseId === undefined) {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }
        else {
            documentService.getContactDocuments(authUserData.currUserData.profileId)
                .then((x) => {
                    setContactDocuments(x);
                })
                .catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );
            documentService.getCaseDocuments(docCaseId)
                .then((x) => {
                    setCaseDocuments(x);
                })
                .catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );
            if (notId !== "No" && notId !== undefined) {
                notificationService.changeNotificationStatus(parseInt(notId!), "Read")
                setNotifications({
                    ...notificationsDetails,
                    notificationNumber: notificationsDetails.notificationNumber - 1
                })
                caseManagerService.getSingleCaseWithDetails(parseInt(id!)).then(
                    (rp) => {
                        setAuthUserData(
                            {
                                ...authUserData,
                                selectedCaseId: parseInt(id!),
                                referenceNumberOfInsurance: rp.referenceNumberOfInsurance!
                            });
                        setCaseData(
                            {
                                selectedCaseId: parseInt(id!),
                                caseNumber: rp.caseNumber,
                                caseClienName: rp.clientData ? rp.clientData.displayName : '',
                                caseClient: rp.clientData,
                                caseDoctor: rp.doctorData,
                                caseEmployeer: rp.employeerData,
                                caseCaseManager: rp.caseManagerData,
                                caseInsurerReference: rp.caseData.referenceNumberOfInsurance,
                                insurerProfileImage: rp.insurerProfileImage,
                            });
                    }
                ).catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );

            }
        }
    }, [notId]);

    function updateDocList(resp: any) {
        if (tabKey === 'Case') {
            if (!resp.data.isUpdate)
                setCaseDocuments((docList: any) => [resp.data, ...docList]);
        }
        else {
            if (!resp.data.isUpdate)
                setContactDocuments((docList: any) => [resp.data, ...docList]);
        }
    }

    function downloadDocument(documentId: number, fileName: string) {
        if (tabKey === 'Case') {
            setCaseDocuments(caseDocuments!.map(x => {
                if (x.id === documentId) { x.isDownload = true; }
                return x;
            }));
        } else {
            setContactDocuments(contactDocuments!.map(x => {
                if (x.id === documentId) { x.isDownload = true; }
                return x;
            }));
        }
        documentService.downloadDocument(documentId).then((rp) => {
            alertService.success(t("ALL.DMS.Downloaded"), { keepAfterRouteChange: true });
            const url = window.URL.createObjectURL(new Blob([rp]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            if (tabKey === 'Case') {
                setCaseDocuments(caseDocuments!.map(x => {
                    if (x.id === documentId) { x.isDownload = false; }
                    return x;
                }));
            } else {
                setContactDocuments(contactDocuments!.map(x => {
                    if (x.id === documentId) { x.isDownload = false; }
                    return x;
                }));
            }
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true })
                if (tabKey === 'Case') {
                    setCaseDocuments(caseDocuments!.map(x => {
                        if (x.id === documentId) { x.isDownload = false; }
                        return x;
                    }));
                } else {
                    setContactDocuments(contactDocuments!.map(x => {
                        if (x.id === documentId) { x.isDownload = false; }
                        return x;
                    }));
                }
            }
        );
    }
    function deleteDocument(documentId: number) {
        if (tabKey === 'Case') {
            setCaseDocuments(caseDocuments!.map(x => {
                if (x.id === documentId) { x.isDownload = true; }
                return x;
            }));
        } else {
            setContactDocuments(contactDocuments!.map(x => {
                if (x.id === documentId) { x.isDownload = true; }
                return x;
            }));
        }
        documentService.deleteDocument(documentId).then((rp) => {
            alertService.success(t("ALL.DMS.FileRemoved"), { keepAfterRouteChange: true });
            if (tabKey === 'Case') {
                setCaseDocuments(m => m!.filter(x => x.id !== documentId));
            } else {
                setContactDocuments(m => m!.filter(x => x.id !== documentId));
            }
        }).catch(
            (rp) => {

                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                if (tabKey === 'Case') {
                    setCaseDocuments(caseDocuments!.map(x => {
                        if (x.id === documentId) { x.isDownload = false; }
                        return x;
                    }));
                } else {
                    setContactDocuments(contactDocuments!.map(x => {
                        if (x.id === documentId) { x.isDownload = false; }
                        return x;
                    }));
                }
            }
        );
    }
    return (
        <div className="container">
            <p className="component-header">{t("ALL.DMS.Title")}</p>
            <hr></hr>

            <Tabs activeKey={tabKey}
                onSelect={(k: any) => { setTabKey(k); setClear(true) }}>
                <Tab eventKey="Case" title={t("ALL.DMS.DocTypeCase")}>
                    <ListDocuments deleteDoc={deleteDocument} downloadDoc={downloadDocument} documents={caseDocuments} documentType="Case" role={role} tabKey={tabKey} />
                </Tab>
                <Tab eventKey="Contact" title={t("ALL.DMS.DocTypeContact")}>
                    <ListDocuments deleteDoc={deleteDocument} downloadDoc={downloadDocument} documents={contactDocuments} documentType="Contact" role={role} tabKey={tabKey} />
                </Tab>
            </Tabs>
            {(role === "CaseManager" || (role !== "CaseManager" && tabKey === "Contact")) &&
                <div className="white-container">
                    <div className="upload-container">
                        <Upload updateList={updateDocList} uploadType={tabKey} caseId={docCaseId} clear={clearUpload} docList={tabKey === "Contact" ? contactDocuments : caseDocuments} />
                    </div>
                </div>
            }
        </div>

    );

}

export { DocumentManagement };