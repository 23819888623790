
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
import moment from 'moment';
import { useTable } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { AddEditTaskRules } from './AddEditTaskRules';
import { Modal, ModalBody } from 'reactstrap';
import { useContext, useEffect, useMemo, useState } from 'react';
import TaskGeneratorRule from '../../../models/TaskGeneratorRule';
import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

export interface ITaskRulesProps {
    deleteTaskRule: (id: number) => void,
    add: (taskRuleDet: TaskGeneratorRule) => void,
    edit: (taskRuleDet: TaskGeneratorRule) => void,
    taskRuleData?: Array<TaskGeneratorRule>,
    loading?: boolean,
    ruleTemplateId?: number,
    // insurer: string,
    planTags?: any,
    ruleTemplates?: any
    selectedRuleTemp: number
}

const ListTaskRules = ({ planTags, ruleTemplateId, selectedRuleTemp, taskRuleData, loading, add, edit, deleteTaskRule, ruleTemplates }: ITaskRulesProps) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [open, setOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState<any>({})

    const [ruleDataList, setRuleDataList] = useState<any>([]);

    const editTask = (taskRuleDet: TaskGeneratorRule) => {
        setSelectedTag(taskRuleDet);
        setOpen(true);
    }

    const addTask = () => {
        setSelectedTag({});
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: t("ALL.Tasks.Generator.Rules.Title"),
                accessor: 'ruleTitle',
            },
            {
                Header: t("ALL.Tasks.Generator.Rules.StartTag"),
                accessor: 'startTagTitle',

            },
            {
                Header: t("ALL.Dictionary.Topic.Options.2"),
                accessor: 'dueTagTitle',

            },
            {
                Header: t("ALL.Tasks.Generator.DaysDue"),
                accessor: 'startToDueDays',
            },
            {
                Header: t("ALL.Tasks.Generator.TimeDue"),
                accessor: 'dueTime',
                Cell: row => moment(row.row.original.dueTime).format('HH:mm')
            },
            {
                Header: t("ALL.Tasks.Generator.TargetContact"),
                accessor: 'targetContact',
            },
            {
                Header: t("ALL.Button.ShowMore"),
                accessor: 'action',
                Cell: row => (<div>
                    <button
                        onClick={() => editTask(row.row.original)}
                        className="btn btn-md btn-icon-grey">
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                        onClick={() => removeTaskRule(row.row.original)}
                        className="btn btn-md btn-icon-red">
                        <FontAwesomeIcon icon={faTrash} />{row.row.original.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    </button>
                </div>
                ),
            },

        ],
        []
    );
    // const data = useMemo(() => [...taskRuleData?taskRuleData:[]], [taskRuleData?taskRuleData:[]])
    // const data = useMemo(() => [...taskRuleData?taskRuleData.filter(obj => {
    //     if(selectedRuleTemp===-1) {
    //         return !obj.taskGeneratorRuleTemplateId
    //     } else {
    //         return obj.taskGeneratorRuleTemplateId == selectedRuleTemp
    //     }        
    // }):[]], [taskRuleData?taskRuleData.filter(obj => {
    //     return selectedRuleTemp===-1 ? !obj.taskGeneratorRuleTemplateId : obj.taskGeneratorRuleTemplateId == selectedRuleTemp;
    // }):[]])
    // const unassigned_data = useMemo(() => [...taskRuleData?taskRuleData.filter(obj => {
    //     return !obj.taskGeneratorRuleTemplateId ;
    // }):[]], [taskRuleData?taskRuleData.filter(obj => {
    //     return !obj.taskGeneratorRuleTemplateId;
    // }):[]])


    // const data = useMemo(() => [...taskRuleData?taskRuleData.filter(obj => {
    //     return obj.taskGeneratorRuleTemplateId == selectedRuleTemp
    // }):[]], [taskRuleData?taskRuleData.filter(obj => {
    //     return obj.taskGeneratorRuleTemplateId == selectedRuleTemp
    // }):[]])

    const data = useMemo(() => [...taskRuleData ? taskRuleData.filter(obj => {
        return obj.taskGeneratorRuleTemplateId == selectedRuleTemp
    }) : []], [selectedRuleTemp, taskRuleData])



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: data,
    });

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }));


    const removeTaskRule = (taskRuleDet) => {
        console.log("taskRuleData 1111", taskRuleData);
        console.log("data 1111", data);
        console.log("ruleDataList", ruleDataList);
        deleteTaskRule(taskRuleDet.id);
        //setOpen(false);
    }

    useEffect(() => {
        console.log("taskRuleData onload", taskRuleData);
        let backupRuleData = JSON.parse(JSON.stringify(taskRuleData))
        setRuleDataList(backupRuleData);
    }, [taskRuleData])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header card-header-tabs card-header-primary header-top">
                        <div className="card-category">
                            <div className="btn-group">
                                <button type="submit" className="btn btn-md btn-icon-grey mr-1"
                                    onClick={addTask}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />{t("ALL.Tasks.Generator.Rules.Add")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">

                        <div className="table-responsive">
                            <table className="table table-striped" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {loading ?
                                        <tr>
                                            <td colSpan={7} className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr> :
                                        (rows.length > 0 && rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                            );
                                        })) ||
                                        <tr>
                                            <td colSpan={7} className="text-center">
                                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                                            </td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Modal isOpen={open} style={{ maxWidth: "1000px" }}>
                        <ModalBody>
                            <AddEditTaskRules planTags={planTags} taskRuleData={selectedTag} ruleTemplateId={ruleTemplateId}
                                close={handleClose}
                                add={add}
                                edit={edit}
                                view={false}
                                cleanSelectedNode={setSelectedTag}
                                selectedRuleTemp={selectedRuleTemp}
                                taskTemplatesDropdownData={ruleTemplates}
                            />
                        </ModalBody>
                    </Modal>
                </div>
            </div></div>

    );

}

export { ListTaskRules };