import { useState, useEffect, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useLocation, useParams } from 'react-router-dom';
import { CaseInfoCard } from '../../components/CaseInfoCard';
import Case from '../../models/Case';
import { alertService, caseManagerService } from '../../services/Index';
import CryptoJS from "crypto-js";
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { AppAccessContext } from '../../ctx/AppAccessProvider ';

const Dashboard=()=> {
    const {authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    const {id} = useParams();
    const [caseItem, setCase] = useState<Case>();
    const { t } = useContext(MultiLanguageSupportContext);
    const { caseData, setCaseData } = useContext(CaseDataContext);
    const { setAppAccess } = useContext(AppAccessContext);
    useEffect(() => {
        const caseId=parseInt(id!);//parseInt(CryptoJS.AES.decrypt(id,"mXKMwNfrobMykpy9JofD").toString(CryptoJS.enc.Utf8));
        setAppAccess({
            hasAccessToPath: (authUserData.currUserData.relatedData.filter(data => data.id ===caseId).length > 0),
        });

        caseManagerService.getSingleCaseWithDetails(caseId).then(
            (rp) => {
                setCase(rp);
                setAuthUserData(
                    {
                        ...authUserData,
                        selectedCaseId:caseId,
                        referenceNumberOfInsurance: rp.referenceNumberOfInsurance!
                    });
                    setCaseData(
                        {
                            ...caseData,
                            selectedCaseId: rp.caseData.id,
                            caseNumber: rp.caseNumber,
                            caseClienName: rp.clientData ? rp.clientData.displayName : '',
                            caseClient: rp.clientData,
                            caseDoctor: rp.doctorData,
                            caseEmployeer: rp.employeerData,
                            caseCaseManager: rp.caseManagerData,
                            caseInsurerReference: rp.caseData.referenceNumberOfInsurance,
                            insurerProfileImage: rp.insurerProfileImage,
                            insurerId:rp.caseData.insurerId
                        });

            }

        ).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        

    }, [id]);

    return (
        <div className="container">
            {!caseItem && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {caseItem && <CaseInfoCard caseItem={caseItem} />}
        </div>
    );

}

export { Dashboard };