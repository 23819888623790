import { createContext, FC, ReactNode, useEffect, useState } from 'react';

interface IAppAccess{
    hasAccessToPath:boolean 
}
const defaultAppAccess : IAppAccess = {
    hasAccessToPath:true
};

export interface IAppAccessProps {
    accessDetails: IAppAccess,
    setAppAccess: (access: IAppAccess) => void;
}

const AppAccessContext = createContext<IAppAccessProps>
    ({
        accessDetails:defaultAppAccess,
        setAppAccess:(access:IAppAccess) => console.log(access),
    
    });

type Props = {
    children?: ReactNode
};
const AppAccessProvider: FC<Props> = ({ children }) => {
    const [accessDetails, setAppAccess] = useState(defaultAppAccess);

    useEffect(() => {
        setAppAccess(accessDetails);
    }, [accessDetails]);

    return (
        <AppAccessContext.Provider value={{ accessDetails, setAppAccess }}>
            {children}
        </AppAccessContext.Provider>
    );
};

export { AppAccessProvider, AppAccessContext };
