import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { faBell } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText
} from 'reactstrap';

import { alertService, notificationService } from '../../services/Index';
import moment from 'moment';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Badge } from '@mui/material';
import { AppSettingsContext } from '../../ctx/AppSettingsProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';

export const Notification = () => {
    //const [notificationNumber, setNotificationNumber] = useState<number>(0);
    const {notificationsDetails, setNotifications} = useContext(NotificationContext);
    const {authUserData} = useContext(LoggedUserDataContext);
    //const [notifications, setNotifications] = useState<any[]>([]);
    const { t } = useContext(MultiLanguageSupportContext);
    const { appSettings} = useContext(AppSettingsContext);
    let time = appSettings.length>0?appSettings.filter(s => s.key === "NotificationTimer")[0]?.valueNumber:10000;
    useEffect(() => {
        setInterval(() => {
            notificationService.getReceiverContactNewNotifications(authUserData.currUserData.profileId).then(rp => {
                //setNotificationNumber(rp.length);
                setNotifications({  
                        notifications:rp,
                        notificationNumber:rp.length                  
                });
            }
            ).catch(
                (rp) => {
                    alertService.error(t(rp), { keepAfterRouteChange: true });
                }
            );
        }, time);
    }, []);

    return (
        <UncontrolledDropdown>
            <DropdownToggle nav>
                <Badge badgeContent={notificationsDetails.notificationNumber} color="secondary">
                    <FontAwesomeIcon icon={faBell} className="fa-lg" />
                </Badge>
            </DropdownToggle>
            {notificationsDetails.notificationNumber> 0 &&
                <DropdownMenu >
                    <DropdownItem header>{t("ALL.Notifications.InfoNew")}</DropdownItem>
                    {notificationsDetails.notifications.map(n => <>
                        <DropdownItem key={n.id}>
                            <Link to={`/${n.notificationItemUrl}`} className="btn btn-md btn-icon" style={{ padding: "0px", width: "100%" }}>
                                <Card>
                                    <CardBody>
                                        <CardTitle tag="h5">{n.notificationTitle}</CardTitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted">{moment(n.notificationDate).format("L")}</CardSubtitle>
                                        <CardText>{t("ALL.Notifications.From")}:{n.senderContactDisplayName}</CardText>
                                    </CardBody>
                                </Card>
                            </Link>
                        </DropdownItem>
                        <DropdownItem divider />
                    </>
                    )}
                </DropdownMenu>
            }
        </UncontrolledDropdown>
    );


}

