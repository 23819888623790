import BaseService from '../../BaseService';
import Response from "../../../models/Response";
import AppDocument from '../../../models/AppDocument';

export const documentService = {
    uploadNewDocument,
    getAllDocuments,
    downloadDocument,
    deleteDocument,
    getContactDocuments,
    getCaseDocuments

};

async function uploadNewDocument(data: any) {
    let res = BaseService.create<any>("Document/UploadFile", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllDocuments(): Promise<AppDocument[]> {
    let res=BaseService.getAll<AppDocument>("Document/GetAllDocuments").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getContactDocuments(contactADId:string): Promise<AppDocument[]> {
    let res=BaseService.get<AppDocument>("Document/GetContactDocuments",`?contactADId=${contactADId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseDocuments(caseId:number): Promise<AppDocument[]> {
    let res=BaseService.get<AppDocument>("Document/GetCaseDocuments",`?caseId=${caseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function downloadDocument(docId:number): Promise<Blob> {
    let res=BaseService.downloadFile<any>('Document/DownloadFile',`?documentId=${docId}`).then(   
    (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function deleteDocument(documentId: number): Promise<any> {
    let res = BaseService.delete("Document/DeleteFile?documentId=", documentId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
