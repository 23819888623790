import moment from "moment";

export const AppraisalListData = [{
    id:1,
    appraisalTitle: "Apprisal 1",
    limitationsRespected: "Yes",
    approvalDateEmployer: moment("11/23/2022"),
    approvalDatePatient: moment("11/23/2022"),
    approvalDoctor: moment("11/23/2022"),
    accidenceRisk: "Yes",
    jobDescription: "Description 1",
    wrokplaceDescription: "Home",
    wrokplaceFramework: "Framework 1",
    integrationAcceptanceCriteria: "Criteria 1",
    InformationToDoctorOnly: "Info 1",
    status:"Pending"
},
{
    id:2,
    appraisalTitle: "Apprisal 2",
    limitationsRespected: "Yes",
    approvalDateEmployer: moment("11/24/2022"),
    approvalDatePatient: moment("11/26/2022"),
    approvalDoctor: moment("11/28/2022"),
    accidenceRisk: "Yes",
    jobDescription: "Description 2",
    wrokplaceDescription: "Home",
    wrokplaceFramework: "Framework 2",
    integrationAcceptanceCriteria: "Criteria 2",
    InformationToDoctorOnly: "Info 2",
    status:"Approved"
},
{
    id:3,
    appraisalTitle: "Apprisal 3",
    limitationsRespected: "Yes",
    approvalDateEmployer: moment("12/23/2022"),
    approvalDatePatient: moment("12/23/2022"),
    approvalDoctor: moment("12/23/2022"),
    accidenceRisk: "Yes",
    jobDescription: "Description 3",
    wrokplaceDescription: "Home",
    wrokplaceFramework: "Framework 3",
    integrationAcceptanceCriteria: "Criteria 3",
    InformationToDoctorOnly: "Info 3",
    status:"Approved"
}

];