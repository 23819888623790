import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { adminService, alertService } from '../../services/Index';

import EventScheduler from '../../models/EventScheduler';
import moment from 'moment';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPlane } from '@fortawesome/pro-light-svg-icons';

const EventSchedulerManagement = () => {
    const { path } = useParams();
    const [eventSchedulers, setEventSchedulers] = useState<any[]>();
    const { authUserData } = useContext(LoggedUserDataContext);
    moment(authUserData.currUserData.profileLanguage);
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        adminService.getAllEventSchedulers().then(
            (x) => {
                setEventSchedulers(x);
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }, [path]);

    function runWebJob(webJobName: string, id: number) {
        setEventSchedulers(eventSchedulers!.map(x => {
            if (x.id === id) { x.isSubmitting = true; }
            return x;
        }));
        adminService.runWebJob(webJobName).then((rp) => {
            setEventSchedulers(eventSchedulers!.map(x => {
                if (x.id === id) { x.isSubmitting = false; }
                return x;
            }));
        }).catch(
            (rp) => {

                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setEventSchedulers(eventSchedulers!.map(x => {
                    if (x.id === id) { x.isSubmitting = false; }
                    return x;
                }));

            }
        );
    }
    return (
        <div className="container">
            <p className="component-header">{t("ALL.Scheduler.Overview")}</p>
            <hr></hr>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '15%' }}>{t("ALL.Scheduler.Title")}</th>
                        <th style={{ width: '20%' }}>{t("ALL.Scheduler.LastRunDate")}</th>
                        <th style={{ width: '15%' }}>{t("ALL.Scheduler.NextRunDate")}</th>
                        <th style={{ width: '10%' }}>{t("ALL.Scheduler.RunPeriodHours")}</th>
                        <th style={{ width: '10%' }}>{t("ALL.Scheduler.RunTime")}</th>
                        <th style={{ width: '20%' }}>{t("ALL.Scheduler.Enabled")}</th>
                        <th style={{ width: '20%' }}>{t("ALL.Button.Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {eventSchedulers && eventSchedulers.map(s =>
                        <tr key={s.id}>
                            <td>{s.title}</td>
                            <td>{moment(s.lastRunDate).format("L")}</td>
                            <td>{moment(s.nextRunDate).format("L")}</td>
                            <td>{s.intervalInHours}</td>
                            <td>{moment(s.runTime).format("HH:mm")}</td>
                            <td>{s.enabled ? t("ALL.Button.Yes") : t("ALL.Button.No")}</td>
                            {s.title !== "Master Job" && <td style={{ whiteSpace: 'nowrap' }}>
                                <button onClick={() => runWebJob(s.title,s.id)} className="btn btn-md btn-icon-grey" disabled={s.isSubmitting}>
                                    <FontAwesomeIcon icon={faPaperPlane} /> Test {s.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                </button>
                            </td>}
                        </tr>
                    )}
                    {!eventSchedulers &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {eventSchedulers && !eventSchedulers.length &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}

export { EventSchedulerManagement };