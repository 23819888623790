import { useContext, useEffect, useRef, useState } from 'react';
import { alertService, clientService } from '../../services/Index';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import moment from 'moment';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2'
ChartJS.register(...registerables);


export const options = {
  scales: {
    yAxes: {
      ticks: {
        //beginAtZero: true,
      },
    },
  },
  responsive: true,
};
interface IProps {
  chartRefresh?: any
}
const DashboardLineChart = ({ chartRefresh }: IProps) => {
  const { t } = useContext(MultiLanguageSupportContext);
  const { caseData } = useContext(CaseDataContext);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState<{ datasets: any,labels:any}>({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    if (caseData.selectedCaseId !== undefined) {
      clientService.getCaseReflectionsDescendingDateReflection(caseData.selectedCaseId)
        .then((rp) => {
          var labels = rp.map(c => (moment(c.reflectionDate).format("L")))
          var data1 = rp.map(c => (c.healthStateDurnigWork))
          var data2 = rp.map(c => (c.healthStateAfterWork))
          const chart = chartRef.current;

          if (chart) {
            setChartData({
              labels: labels,
              datasets: [
                {
                  label: t("CLN.Reflection.Chart.LabelHealthStateDuringWork"),
                  data: data1,
                  fill: false,
                  backgroundColor: 'rgb(171,71,188)',
                  borderColor: 'rgb(171,71,188)',
                  yAxisID: 'yAxes',
                },
                {
                  label: t("CLN.Reflection.Chart.LabelHealthStateAfterWork"),
                  data: data2,
                  fill: false,
                  backgroundColor: 'rgb(239,83,80)',
                  borderColor: 'rgb(239,83,80)',
                  yAxisID: 'yAxes',
                },
              ],
            })
          }

        })
        .catch(
          (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
    }
  }, [caseData.selectedCaseId]);
  return (
    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="card-header card-header-tabs card-header-primary header-top">
          <h4 className="card-title">{t("CLN.Reflection.Chart.Header")}</h4>
        </div>
        <div className="card-body">
        <Chart
              ref={chartRef}
              type='line'
              options={options}
              data={chartData}
            />
        </div>
      </div>
    </div>

  )
};

export default DashboardLineChart;