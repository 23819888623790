import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { doctorService, alertService, notificationService } from '../../services/Index';

import Assessement from '../../models/Assessement';
import moment from 'moment';
import 'moment/min/locales';
import { helperFunctions } from '../../helpers/HelperFunctions';
import { TextField } from '@mui/material';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';

const AddEditAssessement = () => {
    const { authUserData } = useContext(LoggedUserDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    const { caseData } = useContext(CaseDataContext);
    let { id } = useParams();
    let navigate = useNavigate();
    const { locale, t, mask } = useContext(MultiLanguageSupportContext);
    const isAddMode: boolean = !id;
    const [assessementItem, setAssessement] = useState<Assessement>();
    const [dateValidFrom, setDateValidFrom] = useState<Date | null>(null);
    const [nextAssessement, setNextAssessement] = useState<Date | null>(null);
    useEffect(() => {
        if (caseData.caseNumber === undefined) {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }
        else {
            if (!isAddMode) {
                doctorService.getSingleAssessement(parseInt(id!)).then(i => {
                    setAssessement({
                        caseId: i.caseId,
                        dateValidFrom: i.dateValidFrom,
                        presence: i.presence,
                        capacity: i.capacity,
                        remarks: i.remarks,
                        nextAssessement: i.nextAssessement,
                    });
                    setNextAssessement(new Date(i.nextAssessement));
                    setDateValidFrom(new Date(i.dateValidFrom));
                    setValue("dateValidFrom", moment.parseZone(new Date(i.dateValidFrom)));
                    setValue("presence", i.presence);
                    setValue("capacity", i.capacity);
                    setValue("remarks", i.remarks);
                    setValue("nextAssessement", moment.parseZone(new Date(i.nextAssessement)));

                }).catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );
            }
            else {
                setDateValidFrom(new Date());
                setNextAssessement(new Date());
                reset();
            }
        }
    }, [id]);

    const validationSchema = Yup.object().shape({
        dateValidFrom: Yup.string()
            .required(t("DOC.Assessment.Validation.DateFrom")),
        presence: Yup.string()
            .required(t("DOC.Assessment.Validation.Presence")),
        capacity: Yup.string()
            .required(t("DOC.Assessment.Validation.Capacity")),


    });

    const { register, handleSubmit, reset, setValue, getValues, errors, control, formState } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            dateValidFrom: moment.parseZone(new Date()),
            presence: '',
            capacity: '',
            remarks: '',
            nextAssessement: moment.parseZone(new Date())
        }
    });

    function onSubmit(data: Assessement) {
        return isAddMode
            ? createAssessement(data)
            : updateAssessement(parseInt(id!), data);
    }

    function createAssessement(data: Assessement) {
        if (moment(data.dateValidFrom) > moment()) {
            return false;
        }
        var forbidenWordsArray = [
            caseData.caseClient.name?.toLowerCase(), caseData.caseClient.surname?.toLowerCase(), , caseData.caseClient.mail?.toLowerCase(),
            caseData.caseDoctor.name?.toLowerCase(), caseData.caseDoctor.surname?.toLowerCase(), caseData.caseDoctor.mail?.toLowerCase(),
            caseData.caseEmployeer.name?.toLowerCase(), caseData.caseEmployeer.surname?.toLowerCase(), caseData.caseEmployeer.mail?.toLowerCase(),
            caseData.caseCaseManager.name?.toLowerCase(), caseData.caseCaseManager.surname?.toLowerCase(), caseData.caseCaseManager.mail?.toLowerCase()
        ]
        if (helperFunctions.nameContentCheckerIsInvalid(data.remarks, forbidenWordsArray)) {
            alertService.error(t("ALL.Validation.NameDetection"), { keepAfterRouteChange: true })
            return false;
        }
        data.caseId = caseData.selectedCaseId!;
        data.createdBy = authUserData.currUserData.profileId;
        data.dateValidFrom = new Date(data.dateValidFrom).toISOString();
        data.nextAssessement = new Date(data.nextAssessement).toISOString();
        return doctorService.postNewAssessement(data)
            .then((rp) => {
                alertService.success(t("DOC.Assessment.Added"), { keepAfterRouteChange: true });
                navigate(`../show-assessements`);
                notificationService.postNewNotifications(
                    {
                        caseId: caseData.selectedCaseId,
                        senderContactId: authUserData.currUserData.profileId,
                        notificationStatus: "New",
                        notificationTitle: t("ALL.Notifications.TitleAssessment"),
                        notificationMessage: t("ALL.Notifications.MessageAssessment"),
                        notificationItemUrl: `view-assessement/${rp.Data.id}`,
                        notificationDate: moment(),
                        notificationSettingDictionaryId: 3,
                        scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: caseData.insurerId }, { scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId! }],
                        templateId: 603

                    })
            })
            .catch(
                (rp) => {

                    console.log(rp + "|" + t(rp))
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                }
            );
    }

    function updateAssessement(id: number, data: Assessement) {
        if (moment(data.dateValidFrom) > moment()) {
            alertService.error(t("DOC.Validation.DateRangeMax"), { keepAfterRouteChange: true })
            return false;
        }
        var forbidenWordsArray = [
            caseData.caseClient.name?.toLowerCase(), caseData.caseClient.surname?.toLowerCase(), , caseData.caseClient.mail?.toLowerCase(),
            caseData.caseDoctor.name?.toLowerCase(), caseData.caseDoctor.surname?.toLowerCase(), caseData.caseDoctor.mail?.toLowerCase(),
            caseData.caseEmployeer.name?.toLowerCase(), caseData.caseEmployeer.surname?.toLowerCase(), caseData.caseEmployeer.mail?.toLowerCase(),
            caseData.caseCaseManager.name?.toLowerCase(), caseData.caseCaseManager.surname?.toLowerCase(), caseData.caseCaseManager.mail?.toLowerCase()
        ]
        if (helperFunctions.nameContentCheckerIsInvalid(data.remarks, forbidenWordsArray)) {
            alertService.error(t("ALL.Validation.NameDetection"), { keepAfterRouteChange: true })
            return false;
        }
        data.id = id;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.dateValidFrom = new Date(data.dateValidFrom).toISOString();
        data.nextAssessement = new Date(data.nextAssessement).toISOString();
        return doctorService.postUpdateAssessement(data)
            .then(() => {
                alertService.success(t("DOC.Assessment.Updated"), { keepAfterRouteChange: true });
                navigate(`../show-assessements`);
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    return (
        <div className="container">
            {!assessementItem && !isAddMode &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {(assessementItem && !isAddMode || isAddMode) &&
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{isAddMode ? t("DOC.Assessment.Add") : t("DOC.Assessment.Edit")}</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("DOC.Assessment.Date")}*</label>
                                        <div className="col-sm-6">
                                            <Controller
                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                    <DatePicker
                                                        mask={mask}
                                                        maxDate={isAddMode ? new Date() : undefined || undefined}
                                                        value={dateValidFrom}
                                                        onChange={value => {
                                                            setValue("dateValidFrom", value);
                                                            setDateValidFrom(value);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>)}
                                                name="dateValidFrom"
                                                control={control}

                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("DOC.Assessment.Presence")}*</label>
                                        <div className="col-sm-6">
                                            <input name="presence" type="number" min="0" max="100" ref={register} className={`form-control ${errors.presence ? 'is-invalid' : ''}`} />
                                            <div className="invalid-assessement">{errors.presence?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("DOC.Assessment.Capacity")}*</label>
                                        <div className="col-sm-6">

                                            <input name="capacity" type="number" min="0" max="100" ref={register} className={`form-control ${errors.capacity ? 'is-invalid' : ''}`} />
                                            <div className="invalid-assessement">{errors.capacity?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("DOC.Assessment.Remark")}</label>
                                        <div className="col-sm-6">

                                            <textarea name="remarks" rows={5} ref={register} className={`form-control ${errors.remarks ? 'is-invalid' : ''}`} />
                                            <div className="invalid-assessement">{errors.remarks?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("DOC.Assessment.Next")}</label>
                                        <div className="col-sm-6">
                                            <Controller
                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                    <DatePicker
                                                        mask={mask}
                                                        minDate={isAddMode ? new Date() : undefined || undefined}
                                                        value={nextAssessement}
                                                        onChange={value => {
                                                            setValue("nextAssessement", value);
                                                            setNextAssessement(value);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>)}
                                                name="nextAssessement"
                                                control={control}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row justify-content-center">
                                        <div className="col-sm-6">
                                            <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                {t("ALL.Button.Save")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    );
}

export { AddEditAssessement };