import { EditContactControl } from '../../components/contact/EditContactControl';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { useContext, useEffect } from 'react';
import { NotificationSettingsManagement } from '../../components/notifications/NotificationSettingsManagement';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';


function ManageCAMNotificationSettings() {
    let { type } = useParams();
    let navigate = useNavigate();
    const { t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const { caseData } = useContext(CaseDataContext);
    useEffect(() => {
        if (caseData.caseNumber === undefined&&type == "case") {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }
    }, []);
    return (
        <div className="container">
            {type == "case" &&
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title"><FontAwesomeIcon icon={faBell} /> {t("ALL.Notifications.Settings")}</h4>
                                <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={caseData.selectedCaseId!=undefined ? `/${authUserData.currUserData.roleType.toLowerCase()}/dashboard/${caseData.selectedCaseId}` : `/${authUserData.currUserData.roleType.toLowerCase()}`} className="back-btn">{t("ALL.Button.Back")}</Link></p>
                
                            </div>
                            <div className="card-body">
                                <NotificationSettingsManagement allScopes={[{scopeTypeId:0,scopeEntityId:-1},{scopeTypeId:1,scopeEntityId:authUserData.currUserData.insurerId},{scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId!}]} scope={{ scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId! }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {type == "user" &&
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title"><FontAwesomeIcon icon={faBell} /> {t("ALL.Notifications.MySettings")}</h4>
                            </div>
                            <div className="card-body">
                                <NotificationSettingsManagement allScopes={[{scopeTypeId:0,scopeEntityId:-1},{scopeTypeId:1,scopeEntityId:caseData.insurerId},{scopeTypeId:2,scopeEntityId:caseData.selectedCaseId},{scopeTypeId:3,scopeEntityId:authUserData.currUserData.contactId}]} scope={{ scopeTypeId: 3, scopeEntityId: authUserData.currUserData.contactId }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export { ManageCAMNotificationSettings };