import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Case from '../../models/Case';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { caseManagerService, alertService, notificationService } from '../../services/Index';
import moment from 'moment';
import 'moment/min/locales';
import { faChevronLeft, faMoneyBills, faBullseyeArrow, faList } from '@fortawesome/pro-light-svg-icons'
import { CaseGoalManagement } from './casegoal/CaseGoalManagement';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { AppBar, Tab, Tabs } from '@mui/material';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';
import { AddEditCaseBookingSettins } from './AddEditCaseBookingSettins';
import { AppAccessContext } from '../../ctx/AppAccessProvider ';
import { CaseProperties } from './CaseProperties';

interface IEditCaseProps {
    preselectedTab: number;
}
const EditCase = ({preselectedTab}:IEditCaseProps) => {
    let navigate = useNavigate();
    let { id, onCase, notId } = useParams();
    const { authUserData } = useContext(LoggedUserDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const [caseItem, setCase] = useState<Case>();
    const { caseData, setCaseData } = useContext(CaseDataContext);
    const [loading, setLoading] = useState<any>(true);
    const { notificationsDetails, setNotifications } = useContext(NotificationContext);
    const [selectedTab, setSelectedTab] = useState(preselectedTab);
    const { setAppAccess } = useContext(AppAccessContext);
    useEffect(() => {
        setAppAccess({
            hasAccessToPath: (authUserData.currUserData.relatedData.filter(data => data.id === parseInt(id!)).length > 0),
        });
        caseManagerService.getSingleCase(parseInt(id!)).then(i => {
            setCase({
                id: i.id,
                insurerId: i.insurerId,
                mainGoal: i.mainGoal,
                referenceNumberOfInsurance: i.referenceNumberOfInsurance,
                delayDays: i.delayDays,
                dailyAllowanceAmount: i.dailyAllowanceAmount,
                incapacityOfWork: i.incapacityOfWork,
                endOfIntegration: i.endOfIntegration,
                startOfIntegration: i.startOfIntegration,
                endDailyAllowances: i.endDailyAllowances,
                endOfCaseDesk: i.endOfCaseDesk,
                caseDeskEnded: i.caseDeskEnded,
                clnGroupOpenId: i.clnGroupOpenId,
                docGroupOpenId: i.docGroupOpenId,
                empGroupOpenId: i.empGroupOpenId,
                camGroupOpenId: i.camGroupOpenId,
                reflectionRemiderEnabled: i.reflectionRemiderEnabled
            });
            setLoading(false);
        }).catch(
            (rp) => {
                setLoading(false);
                alertService.error(t(rp.message), { keepAfterRouteChange: true })
            }
        );
        if (notId !== undefined) {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber: notificationsDetails.notificationNumber - 1
            })
        }
    }, [notId, selectedTab]);


    function updateCase(data: Case) {
        data.id = caseItem?.id;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.incapacityOfWork = new Date(data.incapacityOfWork).toISOString();
        data.endDailyAllowances = data.endDailyAllowances === null ? null : new Date(data.endDailyAllowances).toISOString();
        data.endOfIntegration = data.endOfIntegration === null ? null : new Date(data.endOfIntegration).toISOString();
        data.startOfIntegration = new Date(data.startOfIntegration).toISOString();
        data.reflectionRemiderEnabled = "True";
        return caseManagerService.postUpdateCase(data)
            .then((rp) => {
                alertService.success(t("CAM.Case.Updated"), { keepAfterRouteChange: true });
                navigate(onCase === "Yes" ? `/${authUserData.currUserData.roleType.toLowerCase()}/dashboard/${id}` : `/`);
                setCaseData(
                    {
                        ...caseData,
                        startOfIntegration: new Date(rp.startOfIntegration),
                        incapacityOfWork: new Date(rp.incapacityOfWork)
                    });
                notificationService.postNewNotifications(
                    {
                        caseId: id,
                        senderContactId: authUserData.currUserData.profileId,
                        notificationStatus: "New",
                        notificationTitle: t(""),
                        notificationMessage: t(""),
                        supportedKeyWords: [{ Name: "#CaseName", Value: `${data.referenceNumberOfInsurance}` }, { Name: "#ModifiedBy", Value: !authUserData.currUserData.name ? `${authUserData.currUserData.name} ${authUserData.currUserData.name}` : authUserData.currUserData.profileEmail }],
                        notificationItemUrl: `view-case/${id}`,
                        notificationDate: moment(),
                        notificationSettingDictionaryId: 9,
                        scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId! }, { scopeTypeId: 2, scopeEntityId: id }],
                        templateId: 609


                    });
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-md-12">
                    <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={onCase === "Yes" ? `/${authUserData.currUserData.roleType.toLowerCase()}/dashboard/${id}` : `/${authUserData.currUserData.roleType.toLowerCase()}`} className="back-btn">{t("ALL.Button.Back")}</Link></p>
                </div>
                <hr></hr>
            </div>
            <div className="row">
                <AppBar position="static">
                    <Tabs value={selectedTab}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { background: 'White' } }}
                        textColor="inherit"
                        aria-label="full width tabs example" centered >
                        <Tab icon={<FontAwesomeIcon icon={faList} style={{ fontSize: "24px" }} />} />
                        <Tab icon={<FontAwesomeIcon icon={faBullseyeArrow} style={{ fontSize: "24px" }} />} />
                        <Tab icon={<FontAwesomeIcon icon={faMoneyBills} style={{ fontSize: "24px" }} />} />
                    </Tabs>
                </AppBar>
            </div>
            {loading &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {!loading && <>
                {selectedTab === 0 &&
                    <div className="row">
                        <CaseProperties caseDetails={caseItem!} updateCase={updateCase} />
                    </div>
                }
                {selectedTab === 1 &&
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <CaseGoalManagement caseId={caseItem!.id!} referenceNumberOfInsurance={caseItem!.referenceNumberOfInsurance}
                                insurerId={caseItem!.insurerId} caseDetails={caseItem!} />
                        </div>
                    </div>
                }
                {selectedTab === 2 &&
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                {!loading &&
                                    <AddEditCaseBookingSettins caseId={caseItem!.id!} />
                                }
                            </div>
                        </div>
                    </div>
                }
            </>
            }
        </div >
    );
}

export { EditCase };