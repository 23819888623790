import { useContext, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { adminService, alertService} from '../../services/Index';
import 'moment/min/locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck} from '@fortawesome/pro-light-svg-icons'
import { Dictionary } from '../../models/Dictionary';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

interface IDictionaryTranslationEditProps {
    translation: Dictionary
    setSelectedItem: (data: Dictionary) => void;
    closeModal:(close:boolean)=>void;
}
export const DictionaryTranslationEdit = ({ translation, setSelectedItem, closeModal }: IDictionaryTranslationEditProps) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const validationSchemaIsNumber = Yup.object().shape({
        title: Yup.string()
                .required(t("ALL.Dictionary.Validation.Title"))
                .matches(/^\d+(\.\d+)?$/,t("ALL.Dictionary.Messages.TitleTypeErrorNumber"))              
    });
    const validationSchemaIsText = Yup.object().shape({
        title: Yup.string().required(t("ALL.Dictionary.Validation.Title"))
    });
    const { register, handleSubmit, control, reset, setValue, errors, formState } = useForm({
        resolver: yupResolver(translation.dicTopicId===6?validationSchemaIsNumber:validationSchemaIsText)
    });
    useEffect(() => {
        setValue("title", translation.title);
        setValue("description", translation.description);


    }, []);
    const updateTranslation = (data: Dictionary) => {   
        translation.title=data.title;
        translation.description=data.description;

        adminService.postNewDictionaryItemTranslation(translation)
        .then((rp) => {
            alertService.success(t("ALL.Dictionary.Messages.TranslationAdded"), { keepAfterRouteChange: true });
            setSelectedItem(rp.Data);
            closeModal(true);
        })
        .catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true })
            }
        );
      };
    return (
        <div className="container-fluid" style={{ fontSize: "12px" }}>
            <div className="row">
                <div className="col-md-12">
                    <form className="row form" onSubmit={handleSubmit(updateTranslation)}>
                        <div className="col-md-1 justify-content-center align-self-center">
                        {translation.dicItemLng}
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label>{t("ALL.Dictionary.Item")}</label>
                                <input name="title"  ref={register} className={`form-control ${errors.title ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.title?.message}</div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label>{t("ALL.Dictionary.Description")}</label>
                                <input name="description" ref={register} className={`form-control ${errors.description ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.description?.message}</div>
                            </div>
                        </div>
                        <div className="col-md-1 justify-content-center align-self-center">
                            <button type="submit" disabled={formState.isSubmitting} className="btn btn-icon-background-green">
                                <FontAwesomeIcon icon={faCheck} />
                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            </button>
                        </div>
                    </form >
                </div>
            </div >
        </div >

    );
}
