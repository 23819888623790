import { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useForm } from "react-hook-form";
import "@fortawesome/fontawesome-svg-core/styles.css";
import 'react-form-builder2/dist/app.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBurgerSoda, faCar, faHeadSideMedical, faTag } from '@fortawesome/pro-light-svg-icons';
import { ICaseBookingViewModel } from '../../models/CaseBooking';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { adminService, alertService, caseManagerService } from '../../services/Index';
import MultiTagsAutoComplete from '../MultiTagsAutoComplete';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ICaseBookingSettingViewModel } from '../../models/CaseBookingSetting';
import ContactAutoComplete from '../contact/ContactAutoComplete';
import { CaseDataContext } from '../../ctx/CaseDataProvider';

interface IAddAddEditBookingProps {
    bookingType?: number
    bookingTags?: any[],
    taxTags?: any[],
    titleTags?: any[],
    categoryTags?: any[],
    booking?: ICaseBookingViewModel,
    bookingSettings?: ICaseBookingSettingViewModel,
    setSelectedTab: (tab: number) => void
}
function AddEditBooking({ bookingType, booking, taxTags, bookingTags, titleTags, categoryTags, bookingSettings, setSelectedTab }: IAddAddEditBookingProps) {
    const isAddMode = booking === undefined;
    const [infoExtended, setExpandedInfoExtended] = useState<string | false>(false);
    const { authUserData } = useContext(LoggedUserDataContext);
    const { caseData } = useContext(CaseDataContext)
    const [bookingItemTags, setBookingItemTags] = useState<any[]>([]);
    const [resetAutocomplete, setResetAutocomplete] = useState((new Date()).toString());
    const bookingTagsOptions = bookingTags !== undefined ? bookingTags : [];
    const taxTagsOptions = taxTags !== undefined ? taxTags : [];
    const bookingCategoryTagsOptions = categoryTags !== undefined ? categoryTags : [];
    const bookingTitleTagsOptions = titleTags !== undefined ? titleTags : [];
    const { locale, t } = useContext(MultiLanguageSupportContext);
    const [travelCalculationType, setTravelCalculationType] = useState(0);
    const [titleTagPossibleAdd, setTitleTagPossibleAdd] = useState(false);

    const validationSchemaBookingType0 = Yup.object().shape({
        currency: Yup.number()
            .required(t("ALL.Bookings.Validation.Currency")),
        totalHours: Yup.number()
            .min(
                0,
                t("ALL.Bookings.Validation.TotalHours")
            )
            .typeError("ALL.Bookings.Validation.TotalHoursError")
            .required(t("ALL.Bookings.Validation.TotalHoursError")),
        chargedMinutes: Yup.number()
            .min(
                1,
                t("ALL.Bookings.Validation.ChargedMinutes")
            )
            .typeError("ALL.Bookings.Validation.ChargedMinutesError")
            .required(t("ALL.Bookings.Validation.ChargedMinutesError")),
        title: Yup.string()
            .required(t("ALL.Bookings.Validation.BookingTitle")),
        bookingCategoryTag: Yup.string()
            .required(t("ALL.Bookings.Validation.Category"))

    });
    const validationSchemaBookingType1 = Yup.object().shape({
        currency: Yup.number()
            .required(t("ALL.Bookings.Validation.Currency")),
        amount: Yup.number()
            .min(
                1,
                t("ALL.Bookings.Validation.Amount")
            )
            .typeError("ALL.Bookings.Validation.AmountError")
            .required(t("ALL.Bookings.Validation.AmountError")),
        quantity: Yup.number()
            .min(
                1,
                t("ALL.Bookings.Validation.Quantity")
            )
            .typeError("ALL.Bookings.Validation.QuantityError")
            .required(t("ALL.Bookings.Validation.QuantityError")),
        title: Yup.string()
            .required(t("ALL.Bookings.Validation.BookingTitle")),
        bookingCategoryTag: Yup.string()
            .required(t("ALL.Bookings.Validation.Category"))

    });
    const validationSchemaBookingType2 = Yup.object().shape({
        currency: Yup.number()
            .required(t("ALL.Bookings.Validation.Currency")),
        amount: Yup.number().when("travelCalculationType", {
            is: "0",
            then: Yup.number()
                .min(
                    1,
                    t("ALL.Bookings.Validation.Amount")
                ).typeError("ALL.Bookings.Validation.AmountError")
                .required(t("ALL.Bookings.Validation.AmountError"))

        }),
        chargedDistance: Yup.number().when("travelCalculationType", {
            is: "1",
            then: Yup.number()
                .min(
                    1,
                    t("ALL.Bookings.Validation.ChargedDistance")
                ).typeError("ALL.Bookings.Validation.ChargedDistanceError")
                .required(t("ALL.Bookings.Validation.ChargedDistanceError"))
        }),
        title: Yup.string()
            .required(t("ALL.Bookings.Validation.BookingTitle")),
        bookingCategoryTag: Yup.string()
            .required(t("ALL.Bookings.Validation.Category"))

    });

    useEffect(() => {
        setValue("currency", bookingSettings!?.defaultCurrency);
        setValue("taxTag", bookingSettings!?.defaultTaxTag);
        setValue("pricePerDistance", bookingSettings!?.defaultPricePerDistance);
        setValue("hourlyRate", bookingSettings!?.defaultHourlyRate);
        setValue("bookingType", bookingType);
        if (!isAddMode) {
            setValue("id", booking.id);
            setValue("caseId", booking.caseId);
            setValue("bookingType", booking.bookingType);
            setValue("travelCalculationType", booking.travelCalculationType);
            setValue("title", booking.title);
            setValue("description", booking.description);
            setValue("bookingDate", booking.bookingDate);
            setValue("chargedMinutes", booking.chargedMinutes);
            setValue("chargedDistance", booking.chargedDistance);
            setValue("quantity", booking.quantity);
            setValue("bookingTags", booking.bookingTags);
            setValue("amount", booking.amount);
            setValue("bookingCategoryTag", booking.bookingCategoryTag);
            setValue("sumOfAmounts", booking.sumOfAmounts);
            setValue("sumOfTaxes", booking.sumOfTaxes);
            setValue("hourlyRate", booking.hourlyRate);
            setValue("totalHours", booking.totalHours);
            setValue("currency", booking.currency);
            setValue("taxTag", booking.taxTag);
            setValue("pricePerDistance", booking.pricePerDistance);
            setValue("distanceUnit", booking.distanceUnit);
            setValue("userEmail", booking.owner.email);
            setBookingItemTags(bookingTagsOptions!.filter((t) => booking.bookingTags?.includes(t.dicGuid)).map(n => {
                return n;
            }));
            setTravelCalculationType(booking.travelCalculationType);


        }
    }, []);

    const { register, handleSubmit, reset, watch, control, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(bookingType === 0 ? validationSchemaBookingType0 :
            bookingType === 1 ? validationSchemaBookingType1 :
                validationSchemaBookingType2),
        defaultValues: {
            id: 0,
            caseId: 0,
            bookingType: 0,
            travelCalculationType: 0,
            title: "",
            description: "",
            bookingDate: new Date(),
            bookingDateString: "",
            chargedMinutes: 0,
            chargedDistance: 0,
            quantity: 1,
            totalHours: 0,
            bookingTags: [],
            bookingTagsTitles: "",
            amount: 0,
            currency: 0,
            taxTag: "",
            taxTagString: "",
            bookingCategoryTag: "",
            bookingCategoryTagString: "",
            sumOfAmounts: 0,
            sumOfTaxes: 0,
            hourlyRate: 0,
            pricePerDistance: 0,
            distanceUnit: 0,
            userEmail: undefined,

        }

    });
    const watchAllFields = watch();
    useEffect(() => {
        let totalHours = getValues('totalHours');
        let chargedMinutes = getValues('chargedMinutes');
        if ((totalHours === 0 || totalHours < chargedMinutes / 60) && chargedMinutes !== 0) {
            setValue('totalHours', (Number(chargedMinutes) / 60).toFixed(2));
        }

    }, [watch]);
    function onSubmit(data: ICaseBookingViewModel) {
        return !isAddMode
            ? updateCaseBooking(data)
            : createCaseBooking(data)

    }
    function createCaseBooking(data: ICaseBookingViewModel) {
        data.bookingType = bookingType!;
        data.bookingDate = data.bookingDate === null || data.bookingDate === undefined ? new Date() : data.bookingDate;
        data.caseId = caseData.selectedCaseId!
        data.createdBy = { id: authUserData.currUserData.contactId!, userActveDirectoryId: authUserData.currUserData.profileId };
        data.owner = { id: undefined, email: getValues('userEmail') !== undefined ? getValues('userEmail') : caseData.caseCaseManager.mail };
        data.taxTagString = taxTagsOptions.find(t => t.dicGuid === getValues("taxTag"))?.title;
        data.bookingTags = bookingItemTags.map((tt) => (tt.dicGuid));
        return caseManagerService.postNewCaseBooking(data)
            .then((rp) => {
                alertService.success(t("ALL.Bookings.Messages.Created"), { keepAfterRouteChange: true });
                setSelectedTab(-1);
            })
            .catch((rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    function updateCaseBooking(data: ICaseBookingViewModel) {
        data.bookingType = bookingType!;
        data.id = booking!.id!;
        data.caseId = booking!.caseId!;
        data.modifiedBy = { id: authUserData.currUserData.contactId!, userActveDirectoryId: authUserData.currUserData.profileId };
        data.createdBy = { id: authUserData.currUserData.contactId!, userActveDirectoryId: authUserData.currUserData.profileId };
        data.bookingTags = bookingItemTags.map((tt) => (tt.dicGuid));
        data.owner = { id: undefined, email: getValues('userEmail') !== undefined ? getValues('userEmail') : caseData.caseCaseManager.mail };
        data.taxTagString = taxTagsOptions.find(t => t.dicGuid === getValues("taxTag"))?.title;
        return caseManagerService.postUpdateCaseBooking(data)
            .then(() => {
                alertService.success(t("ALL.Bookings.Messages.Updated"), { keepAfterRouteChange: true });
                setSelectedTab(-1);
            })
            .catch((rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    const handleChangeInfoExtended =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedInfoExtended(isExpanded ? panel : false);
        };
    const handleTitleTagSubmit = async () => {
        let dicData = {
            "title": getValues("title"),
            "sort": 1,
            "scopeTypeId": 1,
            "scopeEntityId": authUserData.currUserData.insurerId!,
            "insurer": authUserData.currUserData.insurerId,
            "dicItemLng": (authUserData.currUserData.profileLanguage).toUpperCase(),
            "dicTopicId": 5,
            "dicItemColorId": "#767676",
            "description": t("ALL.Bookings.AutoGeneratedFromTitle"),
            "createdBy": authUserData.currUserData.profileId
        }

        await adminService.postNewDictionaryItem(dicData)
            .then((rp) => {
                alertService.success(t("ALL.Dictionary.Messages.LabelAdded"), { keepAfterRouteChange: true });
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                }
            );
    }
    const travelCalculationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const travelType = Number((event.target as HTMLInputElement).value);
        setTravelCalculationType(travelType);
        setValue("travelCalculationType", travelType)
        if (travelType === 1) {
            setValue("chargedDistance", booking?.chargedDistance);
        }
        else {
            setValue("amount", booking?.amount);
        }
    };
    return (
        <div className="container">
            {!booking && !isAddMode &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {(booking && !isAddMode || isAddMode) &&
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                {bookingType === 0 &&
                                    <div className="row justify-content-between">
                                        <label className="col-sm-4 col-form-label">{t("ALL.Bookings.TitleAddWorkingHours")}</label>
                                        <div className="col-sm-2">
                                            <FontAwesomeIcon icon={faHeadSideMedical} style={{ fontSize: "32px", color: "white" }} />
                                        </div>
                                    </div>
                                }
                                {bookingType === 1 &&
                                    <div className="row justify-content-between">
                                        <label className="col-sm-4 col-form-label">{t("ALL.Bookings.TitleAddExpenses")}</label>
                                        <div className="col-sm-2">
                                            <FontAwesomeIcon icon={faBurgerSoda} style={{ fontSize: "32px", color: "white" }} />
                                        </div>
                                    </div>
                                }
                                {bookingType === 2 &&
                                    <div className="row justify-content-between">
                                        <label className="col-sm-4 col-form-label">{t("ALL.Bookings.TitleAddTravelCost")}</label>
                                        <div className="col-sm-2">
                                            <FontAwesomeIcon icon={faCar} style={{ fontSize: "32px", color: "white" }} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="form-group col-sm-8">
                                            <label>{t("ALL.Bookings.PositionTitle")}*</label>
                                            <div className="row">
                                                <div className="form-group col-sm-10">
                                                    <Controller
                                                        render={(props) => (
                                                            <Autocomplete
                                                                freeSolo
                                                                disableClearable
                                                                value={booking?.title} options={bookingTitleTagsOptions.map((option) => option.title)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={t("ALL.Bookings.TitleSelectOrAdd")}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            type: 'search',
                                                                        }}
                                                                        helperText={errors.title?.message}
                                                                        error={errors.title!==undefined}
                                                                    />
                                                                )}
                                                                onChange={(_event, data) => {
                                                                    setValue('title', data);
                                                                    setTitleTagPossibleAdd(false);
                                                                }
                                                                }
                                                                onInputChange={(_event, data) => {
                                                                    setValue('title', data);
                                                                    setTitleTagPossibleAdd(true);
                                                                }
                                                                }
                                                            />
                                                        )}
                                                        name="title"
                                                        control={control}
                                                    />
                                                    <div className="invalid-feedback">{errors.title?.message}</div>
                                                </div>
                                                {titleTagPossibleAdd &&
                                                    <div className="form-group col-sm-2">
                                                        <button type="button" onClick={handleTitleTagSubmit} disabled={formState.isSubmitting} className="btn btn-action">
                                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                            <FontAwesomeIcon icon={faAdd} style={{ color: "white" }} /><FontAwesomeIcon icon={faTag} style={{ color: "white" }} />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-sm-6">
                                            <label>{t("ALL.Bookings.PositionDescription")}</label>
                                            <textarea name="description" rows={7} ref={register} className={`form-control ${errors.description ? 'is-invalid' : ''}`} />
                                            <div className="invalid-feedback">{errors.description?.message}</div>
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <div className="form-row">
                                                <div className="form-group col-sm-12">
                                                    <label>{t("ALL.Bookings.PositionDate")}*</label>
                                                    <Controller
                                                        render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                            <DatePicker
                                                                className="form-control"
                                                                value={getValues("bookingDate")}
                                                                onChange={value => setValue("bookingDate", value)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>)}
                                                        name="bookingDate"
                                                        control={control}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                {bookingType === 0 &&
                                                    <div className="form-group col-sm-12">
                                                        <label>{t("ALL.Bookings.ChargedMinutes")}*</label>
                                                        <select name="chargedMinutes" ref={register} className={`form-control ${errors.currency ? 'is-invalid' : ''}`}>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.0"))}>{t("ALL.Bookings.ChargedMinutesOptions.0")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.1"))}>{t("ALL.Bookings.ChargedMinutesOptions.1")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.2"))}>{t("ALL.Bookings.ChargedMinutesOptions.2")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.3"))}>{t("ALL.Bookings.ChargedMinutesOptions.3")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.4"))}>{t("ALL.Bookings.ChargedMinutesOptions.4")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.5"))}>{t("ALL.Bookings.ChargedMinutesOptions.5")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.6"))}>{t("ALL.Bookings.ChargedMinutesOptions.6")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.7"))}>{t("ALL.Bookings.ChargedMinutesOptions.7")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.8"))}>{t("ALL.Bookings.ChargedMinutesOptions.8")}</option>
                                                            <option value={Number(t("ALL.Bookings.ChargedMinutesOptions.9"))}>{t("ALL.Bookings.ChargedMinutesOptions.9")}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.chargedMinutes?.message}</div>
                                                    </div>}
                                                {bookingType === 2 &&
                                                    <div className="form-group col-sm-12">
                                                        <div className="form-group row">
                                                            <div className={`col-sm-12 ${errors.travelCalculationType ? 'is-invalid-radio' : ''}`}>
                                                                <Controller
                                                                    render={({ name, onBlur, onChange }) => (
                                                                        <RadioGroup
                                                                            row
                                                                            defaultValue={0}
                                                                            value={travelCalculationType}
                                                                            onBlur={onBlur}
                                                                            onChange={(e) => {
                                                                                travelCalculationChange(e);
                                                                            }}>
                                                                            <FormControlLabel value={0} control={<Radio />} label={t("ALL.Bookings.TravelType.0")} />
                                                                            <FormControlLabel value={1} control={<Radio />} label={t("ALL.Bookings.TravelType.1")} />
                                                                        </RadioGroup>
                                                                    )}
                                                                    name="travelCalculationType"
                                                                    control={control}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className={`form-group col-sm-12 ${(bookingType === 1 || (bookingType === 2 && travelCalculationType === 0)) ? '' : 'hidden'}`}>
                                                    <label>{t("ALL.Bookings.Amount")}*</label>
                                                    <input name="amount" type="number" ref={register} min={1} className={`form-control ${errors.amount ? 'is-invalid' : ''}`} />
                                                    <div className="invalid-feedback">{errors.amount?.message}</div>
                                                </div>
                                                <div className={`form-group col-sm-12 ${bookingType === 2 && travelCalculationType === 1 ? '' : 'hidden'}`}>
                                                    <label>{t("ALL.Bookings.ChargedDistance")}*</label>
                                                    <input name="chargedDistance" type="number" ref={register} className={`form-control ${errors.chargedDistance ? 'is-invalid' : ''}`} />
                                                    <div className="invalid-feedback">{errors.chargedDistance?.message}</div>
                                                </div>

                                            </div>
                                            {bookingType === 0 &&
                                                <div className="form-row">
                                                    <div className="form-group col-sm-12">
                                                        <label>{t("ALL.Bookings.Settings.TotalHours")}*</label>
                                                        <input name="totalHours" type="text" ref={register} className={`form-control ${errors.totalHours ? 'is-invalid' : ''}`} />
                                                        <div className="invalid-feedback">{errors.totalHours?.message}</div>
                                                    </div>
                                                </div>
                                            }
                                            {bookingType === 1 &&
                                                <div className="form-row">
                                                    <div className="form-group col-sm-12">
                                                        <label>{t("ALL.Bookings.Quantity")}*</label>
                                                        <input name="quantity" type="text" ref={register} className={`form-control ${errors.quantity ? 'is-invalid' : ''}`} />
                                                        <div className="invalid-feedback">{errors.quantity?.message}</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="form-row">
                                                <div className="form-group col-sm-6">
                                                    <label>{t("ALL.Bookings.Amount")}</label>
                                                    {(() => {
                                                        switch (bookingType) {
                                                            case 0:
                                                                return <p>{getValues("chargedMinutes") !== undefined ? (Number(getValues("chargedMinutes")) / 60 * Number(getValues("hourlyRate"))).toFixed(2) : 0}</p>
                                                            case 1:
                                                                return <p>{getValues("amount") !== undefined ? (Number(getValues("amount")) * Number(getValues("quantity"))).toFixed(2) : 0}</p>
                                                            case 2:
                                                                return travelCalculationType === 0 ? <p>{getValues("amount") !== undefined ? Number(getValues("amount")).toFixed(2) : 0}</p>
                                                                    : <p>{getValues("chargedDistance") !== undefined ? (Number(getValues("chargedDistance")) * Number(getValues("pricePerDistance"))).toFixed(2) : 0}</p>
                                                        }
                                                    })()}

                                                </div>
                                                <div className="form-group col-sm-6">
                                                    <label>{t("ALL.Bookings.AmountTax")}</label>
                                                    {(() => {
                                                        switch (bookingType) {
                                                            case 0:
                                                                return <p>{getValues("taxTag") ? (getValues("chargedMinutes") / 60 * Number(getValues("hourlyRate")) * Number(taxTagsOptions.find(t => t.dicGuid === getValues("taxTag")).title) / 100).toFixed(2) : 0}</p>
                                                            case 1:
                                                                return <p>{getValues("taxTag") ? (getValues("amount") * Number(getValues("quantity")) * Number(taxTagsOptions.find(t => t.dicGuid === getValues("taxTag")).title) / 100).toFixed(2) : 0}</p>
                                                            case 2:
                                                                return travelCalculationType === 0 ? <p>{getValues("taxTag") ? (getValues("amount") * Number(taxTagsOptions.find(t => t.dicGuid === getValues("taxTag")).title) / 100).toFixed(2) : 0}</p>
                                                                    : <p>{getValues("taxTag") ? (getValues("chargedDistance") * Number(getValues("pricePerDistance")) * Number(taxTagsOptions.find(t => t.dicGuid === getValues("taxTag")).title) / 100).toFixed(2) : 0}</p>
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-sm-12">
                                                    <label>{t("ALL.Bookings.BookingTags")}</label>
                                                    <MultiTagsAutoComplete control={control} tagsOptions={bookingTagsOptions} controlName="boo" setTags={setBookingItemTags} tags={bookingItemTags} />

                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-sm-12">
                                                    <label>{t("ALL.Bookings.Category")}*</label>
                                                    <select name="bookingCategoryTag" ref={register} className={`form-control ${errors.bookingCategoryTag ? 'is-invalid' : ''}`} >
                                                        <option value=""></option>
                                                        {bookingCategoryTagsOptions.length > 0 ? bookingCategoryTagsOptions.map((option) => (
                                                            <option value={option.dicGuid}>{option.title}</option>
                                                        )) : <option value="ALL.Dictionary.Controls.NoItemAvailable" disabled >{t("ALL.Dictionary.Controls.NoItemAvailable")}</option>}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.bookingCategoryTag?.message}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-sm-12">
                                            <Accordion expanded={infoExtended === 'infoExtended'} onChange={handleChangeInfoExtended('infoExtended')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="infoExtendedbh-content"
                                                    id="infoExtendedbh-header"
                                                    className="header-top"
                                                >
                                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                        {t("ALL.Bookings.InfoExtended")}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div>
                                                        {bookingType === 2 &&
                                                            <div className="form-row">
                                                                <div className="form-group col-sm-6">
                                                                    <label>{t("ALL.Bookings.PricePerDistance")}*</label>
                                                                    <input name="pricePerDistance" type="text" ref={register} className={`form-control ${errors.pricePerDistance ? 'is-invalid' : ''}`} />
                                                                    <div className="invalid-feedback">{errors.pricePerDistance?.message}</div>
                                                                </div>
                                                                <div className="form-group col-sm-6">
                                                                    <label>{t("ALL.Bookings.Settings.DefaultDistanceUnit")}*</label>
                                                                    <select name="distanceUnit" ref={register} className={`form-control ${errors.distanceUnit ? 'is-invalid' : ''}`}>
                                                                        <option value={0}>{t("ALL.Bookings.Settings.DistanceUnitItems.0")}</option>
                                                                        <option value={1}>{t("ALL.Bookings.Settings.DistanceUnitItems.1")}</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">{errors.distanceUnit?.message}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="form-row">
                                                            {bookingType === 0 &&
                                                                <div className="form-group col-sm-6">
                                                                    <label>{t("ALL.Bookings.Settings.HourlyRate")}*</label>
                                                                    <input name="hourlyRate" type="text" ref={register} className={`form-control ${errors.hourlyRate ? 'is-invalid' : ''}`} />
                                                                    <div className="invalid-feedback">{errors.hourlyRate?.message}</div>
                                                                </div>
                                                            }
                                                            <div className="form-group col-sm-6">
                                                                <label>{t("ALL.Bookings.CurrencyName")}*</label>
                                                                <select name="currency" ref={register} className={`form-control ${errors.currency ? 'is-invalid' : ''}`}>
                                                                    <option value={0}>{t("ALL.Bookings.Currency.0")}</option>
                                                                    <option value={1}>{t("ALL.Bookings.Currency.1")}</option>
                                                                    <option value={2}>{t("ALL.Bookings.Currency.2")}</option>
                                                                    <option value={3}>{t("ALL.Bookings.Currency.3")}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.currency?.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-sm-6">
                                                                <label>{t("ALL.Bookings.Owner")}</label>
                                                                <ContactAutoComplete key={resetAutocomplete} role={"Case Manager"} caseId={caseData.selectedCaseId!} control={control} caseContact={true} error={false} insurerId={authUserData.currUserData.insurerId}
                                                                    contactValue={
                                                                        isAddMode ?
                                                                            { title: caseData.caseCaseManager.mail, display: caseData.caseCaseManager.displayName }
                                                                            : { title: booking?.owner.email, display: booking?.owner.displayName }
                                                                    } />
                                                                <div className="invalid-feedback">{errors.userEmail?.message}</div>
                                                            </div>

                                                            <div className="form-group col-sm-6">
                                                                <label>{t("ALL.Bookings.Tax")}%</label>
                                                                <select name="taxTag" ref={register} className={`form-control ${errors.taxTag ? 'is-invalid' : ''}`} >
                                                                    <option value=""></option>
                                                                    {taxTagsOptions.length > 0 ? taxTagsOptions.map((option) => (
                                                                        <option value={option.dicGuid}>{option.title}</option>
                                                                    )) : <option value="ALL.Dictionary.Controls.NoItemAvailable" disabled >{t("ALL.Dictionary.Controls.NoItemAvailable")}</option>}
                                                                </select>

                                                                <div className="invalid-feedback">{errors.taxTag?.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="form-group row justify-content-center">
                                        <div className="form-group col-sm-4">
                                            <button type="button" onClick={handleSubmit(onSubmit)} disabled={formState.isSubmitting} className="btn btn-action">
                                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                {t("ALL.Button.Save")}
                                            </button>
                                        </div>

                                        <div className="form-group col-sm-4">
                                            <button className="btn btn-grey" onClick={(event) => {
                                                event.preventDefault();
                                                setSelectedTab(-1);
                                            }}>
                                                {t("ALL.Button.Cancel")}
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            }
        </div >

    );
}

export { AddEditBooking };