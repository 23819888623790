import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { adminService } from '../../services/casedesk/admin/AdminService';
import { IListDictionaryProps, DictionaryList } from './DictionaryList';
import { Dictionary } from '../../models/Dictionary';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { alertService } from '../../services/AlertService';
import { helperFunctions } from '../../helpers/HelperFunctions';

interface IDictionaryManagementProps {
    scopeId: number,
    scopeEntityId?: number,
    title: string,
}
export const DictionaryManagement = ({ scopeId, scopeEntityId, title }: IDictionaryManagementProps) => {
    let { id } = useParams();
    let entityId = id === undefined ? scopeEntityId : parseInt(id);
    let navigate = useNavigate();

    const [dictionary, setDictionary] = useState<any>();
    const [generalDictionary, setGeneralDictionary] = useState<any>();
    const [loading, setLoading] = useState(true);
    const { language, t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [selectedTopic, setSelectedTopic] = useState<number>(0)

    moment.locale(language);

    useEffect(() => {
        if (entityId === undefined) navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`)
        setLoading(true);
        adminService.GetSingleScopedDictionaries(entityId!, scopeId).then((data) => {
            setDictionary(helperFunctions.getUniqueDictionaryPerLanguage(data, "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
            setLoading(false);
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        if (scopeEntityId !== -1) {
            adminService.GetSingleScopedDictionaries(-1, 0).then((data) => {
                setGeneralDictionary(helperFunctions.getUniqueDictionaryPerLanguage(data, "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
    }, [title])

    const addDictionaryData = (data: Dictionary) => {
        setDictionary(dicData => [data, ...dicData!]);
        //setDictionary(helperFunctions.getUniqueDictionaryPerLanguage(updatedData, "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));
    }

    function filterTopic(evnt) {
        console.log("evnt.target.value", evnt.target.value);
        setSelectedTopic(evnt.target.value);

    }
    const editDictionaryData = (data: Dictionary) => {
        setDictionary(dataDic => dataDic!.map(x => {
            if (x.id === data.id) {
                return data
            }
            return x;
        }));
    }

    const deleteDictionaryData = async (dicId: number, dicGuid: string) => {
        setDictionary(dictionary!.map(x => {
            if (x.id === dicId) { x.isDeleting = true; }
            return x;
        }));
        await adminService.deleteDictionaryItem(dicGuid)
            .then((deleteRp) => {
                alertService.success(t("ALL.Dictionary.Messages.ItemDeleted"), { keepAfterRouteChange: true });
                setDictionary(dataDic => dataDic!.filter(x => x.id !== dicId));

            }).catch((rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setDictionary(dictionary!.map(x => {
                    if (x.id === dicId) { x.isDeleting = false; }
                    return x;
                }));
            }

            );
    }

    const updateColor = async (colorCode, dictionaryLabel) => {
        let tempData = {
            "id": dictionaryLabel.id,
            "title": dictionaryLabel.title,
            "sort": 1,
            "scopeTypeId": scopeId,
            "scopeEntityId": parseInt(dictionaryLabel.scopeEntityId!),
            "dicGuid": dictionaryLabel.dicGuid,
            "dicItemLng": (authUserData.currUserData.profileLanguage).toUpperCase(),
            "dicTopicId": dictionaryLabel.dicTopicId ? dictionaryLabel.dicTopicId : 0,
            "dicItemColorId": colorCode,
            "description": dictionaryLabel.description,
            "modifiedBy": authUserData.currUserData.profileId,
            "modified": new Date()
        }

        await adminService.postUpdateDictionaryItem(tempData)
            .then((rp) => {
                alertService.success(t("ALL.Dictionary.Messages.ItemUpdated"), { keepAfterRouteChange: true });
                document.body.click();
                setDictionary(dataDic => dataDic!.map(x => {
                    if (x.dicGuid === rp.dicGuid) {
                        return rp
                    }
                    return x;
                }));
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                }

            );
    }

    const listDictionaryProps: IListDictionaryProps = {
        deleteItem: deleteDictionaryData,
        add: addDictionaryData,
        edit: editDictionaryData,
        dictionaryData: dictionary!,
        selectedTopic: selectedTopic,
        loading: loading,
        updateColorData: updateColor,
        scopeEntityId: entityId!,
        scopeId: scopeId,
    }

    return (
        <div key={(new Date()).getTime()}>
            {!dictionary && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {dictionary &&
                <div className="container">
                    <p className="component-header">{title}</p>
                    <hr></hr>
                    <Grid item xs={12} md={3} lg={2} >
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-start", margin: "3% 5% -3%" }}>
                            {/*insurerPrefix*/}
                        </Grid>
                        <Grid item xs={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <FormControl sx={{ m: 1, minWidth: 250 }}>
                                        <InputLabel id="select-small">{t("ALL.Tasks.Generator.TemplateTitle")}</InputLabel>
                                        <Select
                                            labelId="select-small"
                                            id="select-small"
                                            value={selectedTopic}
                                            label={t("ALL.Tasks.Generator.TemplateTitle")}
                                            autoWidth
                                            onChange={filterTopic}>
                                            <MenuItem value={0} key="Option0">{t("ALL.Dictionary.Topic.Options.0")}</MenuItem>
                                            <MenuItem value={1} key="Option1">{t("ALL.Dictionary.Topic.Options.1")}</MenuItem>
                                            <MenuItem value={2} key="Option2">{t("ALL.Dictionary.Topic.Options.2")}</MenuItem>
                                            <MenuItem value={3} key="Option3">{t("ALL.Dictionary.Topic.Options.3")}</MenuItem>
                                            <MenuItem value={4} key="Option4">{t("ALL.Dictionary.Topic.Options.4")}</MenuItem>
                                            <MenuItem value={5} key="Option5">{t("ALL.Dictionary.Topic.Options.5")}</MenuItem>
                                            <MenuItem value={6} key="Option6">{t("ALL.Dictionary.Topic.Options.6")}</MenuItem>
                                            <MenuItem value={7} key="Option7">{t("ALL.Dictionary.Topic.Options.7")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="col-md-12">
                        <DictionaryList {...listDictionaryProps} isEditable={true} />
                    </div>
                    {scopeEntityId !== -1 &&
                        <div className="col-md-12">
                            <label style={{fontWeight:"bold"}}>{t('ALL.Dictionary.GlobalDictionaries')}</label>
                            <hr/>
                            <DictionaryList dictionaryData={generalDictionary} scopeEntityId={-1} scopeId={0} isEditable={false} selectedTopic={selectedTopic} />
                        </div>
                    }
                </div>
            }</div>
    );
}
