import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Assessement from '../../models/Assessement';
import { alertService, caseManagerService, doctorService, notificationService } from '../../services/Index';
import { addDays, addMonths } from 'date-fns';
import moment from 'moment';
import "bootstrap/js/src/collapse.js";
import { faChevronRight, faList, faEye } from '@fortawesome/pro-light-svg-icons'
import { Tabs, Tab } from "react-bootstrap";
import { helperFunctions } from '../../helpers/HelperFunctions';
import { StandardListHeader } from '../../components/list/StandardListHeader';
import { StandardReportHeader } from '../../components/list/StandardReportHeader';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useNavigate } from "react-router-dom";
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';
const ListAssessements = () => {
    const { authUserData } = useContext(LoggedUserDataContext);
    const { caseData, setCaseData } = useContext(CaseDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    const ref = React.createRef<HTMLDivElement>();
    let { id, notId } = useParams();
    let navigate = useNavigate();
    const isNoteMode: boolean = !id;
    const [assessements, setAssessements] = useState<Assessement[]>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [isLoading, setLoading] = useState<boolean>(false);
    const {notificationsDetails, setNotifications} = useContext(NotificationContext);
    const caseId=id===undefined?caseData.selectedCaseId!:parseInt(id);
    const [calendarState, setCalendarState] = useState([
        {
            startDate: addMonths(new Date(), -3),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    ]);
    useEffect(() => {
        if (!isNoteMode) {
            setCaseData({
                ...caseData,
                selectedCaseId: parseInt(id!)
            });
        }
        if (caseData.selectedCaseId === undefined && id===undefined) {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }
        else {
            doctorService.getCaseAssessementsInDateRange(caseId, moment(calendarState[0].startDate).format('DD.MM.YYYY'), moment(calendarState[0].endDate).format('DD.MM.YYYY')).then(x => setAssessements(x)).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
        }
        if (notId !== undefined) {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber:notificationsDetails.notificationNumber-1
            })
        }
    }, [notId]);
    function changeDateRange(ranges: any) {
        console.log(ranges);
        doctorService.getCaseAssessementsInDateRange(caseData.selectedCaseId!, moment(ranges.selection.startDate).format('DD.MM.YYYY'), moment(ranges.selection.endDate).format('DD.MM.YYYY')).then(x => setAssessements(x)).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        setCalendarState([ranges.selection]);
    }

    function generateReport(checked) {
        setLoading(true);
        var content = document.getElementById("assessements")!.outerHTML;
        caseManagerService.generateRaport(content, checked, authUserData.currUserData.profileLanguage).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'AssessementReport.pdf');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        });

    }
    return (<div className="container">
        <Tabs defaultActiveKey="home">
            <Tab eventKey="home" title={t("ALL.Common.ViewList")}>
                {!assessements &&
                    <div className="loader">{t("ALL.Common.Loading")}</div>
                }
                {assessements && <>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <StandardListHeader headerClass="header-top-doc" changeDateRange={changeDateRange} calendarState={calendarState} headerTitle="DOC.Assessment.Title" />
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>{t("DOC.Assessment.Date")}</th>
                                                    <th>{t("DOC.Assessment.Next")}</th>
                                                    <th>{t("DOC.Assessment.Presence")}</th>
                                                    <th>{t("DOC.Assessment.Capacity")}</th>
                                                    <th>{t("ALL.Button.Actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {assessements && assessements.map(a => <>
                                                    <tr key={a.id}
                                                        className="collapsed"
                                                        data-toggle="collapse"
                                                        data-target={".multi-collapse" + a.id!.toString()}
                                                        aria-controls={"multiCollapse" + a.id!.toString()}>
                                                        <td>{moment(a.dateValidFrom).format("L")}</td>
                                                        <td>{moment(a.nextAssessement).format("L")}</td>
                                                        <td>{a.presence}</td>
                                                        <td>{a.capacity}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>
                                                            <Link to={`/doctor/edit-assessement/${a.id}`} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faList} /></Link>
                                                            <Link to={`/doctor/view-assessement/${a.id}/No`} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faEye} /></Link>
                                                            <FontAwesomeIcon icon={faChevronRight} />
                                                        </td>
                                                    </tr>
                                                    <tr></tr>
                                                    <tr className={"collapse multi-collapse" + a.id!.toString()} id={"multiCollapse" + a.id!.toString()}>
                                                        <td colSpan={5}><p>{t("DOC.Assessment.Remark")}</p><p>{a.remarks}</p></td>

                                                    </tr></>
                                                )}
                                                {!assessements &&
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                                        </td>
                                                    </tr>
                                                }
                                                {assessements && !assessements.length &&
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </Tab>
            <Tab eventKey="report" title={t("ALL.Common.ViewReport")}>{!assessements &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
                {assessements && <>
                    <StandardReportHeader changeDateRange={changeDateRange} calendarState={calendarState} isLoading={isLoading} generateReport={generateReport} />

                    <div className="report-preview" ref={ref} style={{ backgroundColor: "white", padding: "15px" }}>
                        <div className="row">
                            <div className="col-sm-6">
                                <p>{t("ALL.Common.ReportCreated")}:<b>{moment().format("L")}</b></p>
                            </div>
                            <div className="col-sm-6 text-right" style={{ textAlign: 'right' }}>
                                <img src={helperFunctions.getLogoBase64Url()}
                                    width="170px"
                                    height="48px"
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-sm-6">
                                <p>{t("CLN.Reflection.DateRange")}:<b> {moment(calendarState[0].startDate).format('DD.MM.YYYY')}-{moment(calendarState[0].endDate).format('DD.MM.YYYY')}</b></p>
                                <p>{t("CLN.Reflection.ClientName")}: <b>{caseData.caseClienName}</b></p>
                                <p>{t("CLN.Reflection.WeekNumber")}: <b>{(moment(calendarState[0].startDate).week() === moment(calendarState[0].endDate).week()) ? moment(calendarState[0].endDate).week() : `${moment(calendarState[0].startDate).week()} - ${moment(calendarState[0].endDate).week()}`}</b></p>
                                <p>{t("INS.Common.InsurerRefNumber")}: <b>{caseData.caseInsurerReference}</b></p>
                                <p>{t("CAM.Case.CaseDeskNumber")}: <b>{caseData.caseNumber}</b></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="white-container col-12">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '30%' }}>{t("DOC.Assessment.Date")}</th>
                                                <th style={{ width: '20%' }}>{t("DOC.Assessment.Next")}</th>
                                                <th style={{ width: '20%' }}>{t("DOC.Assessment.Presence")}</th>
                                                <th style={{ width: '20%' }}>{t("DOC.Assessment.Capacity")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {assessements && assessements.map(a => <>
                                                <tr
                                                    key={a.id}
                                                    className="collapsed"
                                                    data-toggle="collapse"
                                                    data-target={".multi-collapse" + a.id!.toString()}
                                                    aria-controls={"multiCollapse" + a.id!.toString()}>
                                                    <td>{moment(a.dateValidFrom).format("L")}</td>
                                                    <td>{moment(a.nextAssessement).format("L")}</td>
                                                    <td>{a.presence}</td>
                                                    <td>{a.capacity}</td>
                                                    <td><FontAwesomeIcon icon={faChevronRight} /></td>
                                                </tr>
                                                <tr></tr>
                                                <tr className={"collapse multi-collapse" + a.id!.toString()} id={"multiCollapse" + a.id!.toString()}>
                                                    <td colSpan={5}><p>{t("DOC.Assessment.Remark")}</p><p>{a.remarks}</p></td>

                                                </tr></>
                                            )}
                                            {!assessements &&
                                                <tr>
                                                    <td colSpan={5} className="text-center">
                                                        <div className="spinner-border spinner-border-lg align-center"></div>
                                                    </td>
                                                </tr>
                                            }
                                            {assessements && !assessements.length &&
                                                <tr>
                                                    <td colSpan={4} className="text-center">
                                                        <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </Tab>

        </Tabs>
    </div>
    );

}

export { ListAssessements };