import BaseService from '../../BaseService';
import Assessement from '../../../models/Assessement';
import Response from "../../../models/Response";

export const doctorService = {
    getCaseAssessements,
    postNewAssessement,
    getSingleAssessement,
    postUpdateAssessement,
    getCaseAssessementMajorInfo,
    getCaseAssessementsInDateRange,
    getCasesAssessementMajorInfo

};

async function postNewAssessement(data: Assessement) {
    let res = BaseService.create<Assessement>("Assessement/CreateAssessement", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateAssessement(data: Assessement) {
    let res = BaseService.update<Assessement>("Assessement/UpdateAssessement", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseAssessements(caseId: number): Promise<Array<Assessement>> {
    let res = BaseService.get<Assessement>("Assessement/GetCaseAssessements?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseAssessementsInDateRange(caseId: number,startDate:string,endDate:string): Promise<Array<Assessement>> {
    let res = BaseService.get<Assessement>("Assessement/GetCaseAssessementsInDateRange?",`caseId=${caseId}&startDateIn=${startDate}&endDateIn=${endDate}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseAssessementMajorInfo(caseId: number): Promise<any> {
    let res = BaseService.get<any>("Assessement/GetCaseAssessementMajorInfo?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCasesAssessementMajorInfo(casesId: string): Promise<any> {
    let res = BaseService.get<any>("Assessement/GetCasesAssessementMajorInfo?",casesId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleAssessement(assessementId: number): Promise<Assessement> {
    let res = BaseService.get<Assessement>("Assessement/GetSingelAssessement?assessementId=", assessementId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
