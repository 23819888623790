import { useContext, } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { DictionaryTranslationEdit } from './DictionaryTranslationEdit';


const DictionaryTranslationManagement = ({ updateSelectedDictionary, translationList, closeModal }) => {
  const { t } = useContext(MultiLanguageSupportContext);
  return (
    <div className="container">
      <div className="row table-row" >
        <div className="col-md-12">

          <table className="table table-striped">
            <tbody>

              {translationList && translationList.map(d => <>
                <tr key={d.id}>
                  <td>
                    <DictionaryTranslationEdit translation={d} setSelectedItem={updateSelectedDictionary} closeModal={closeModal}/>
                  </td>
                </tr>
              </>
              )}
              {!translationList &&
                <tr key="spinner">
                  <td colSpan={5} className="text-center">
                    <div className="spinner-border spinner-border-lg align-center"></div>
                  </td>
                </tr>
              }
              {translationList && !translationList.length &&
                <tr key="no-data">
                  <td colSpan={5} className="text-center">
                    <div className="p-2">{t("ALL.Common.NoData")}</div>
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
};


export default DictionaryTranslationManagement;
