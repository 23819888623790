import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Tabs, Tab } from "react-bootstrap";
import { alertService, notificationService } from '../../services/Index';

import { ListNotifications } from './ListNotifications';
import { useParams, useNavigate } from 'react-router-dom';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

interface INotificationManagementProps {
    caseId?: number,
    role: string
}
function NotificationManagement({ caseId }: INotificationManagementProps) {
    const { caseData } = useContext(CaseDataContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [recieverNotifications, setRecieverNotifications] = useState<Array<any>>();
    const [senderNotifications, setSenderNotifications] = useState<Array<any>>();
    let navigate = useNavigate();
    const noCaseId = caseId === undefined ? caseData.selectedCaseId : caseId;
    const [tabKey, setTabKey] = useState('Reciever');
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {
        if (noCaseId === undefined) navigate('..');
        if (noCaseId !== undefined) {
            notificationService.getReceiverCaseContactNotifications(noCaseId, authUserData.currUserData.profileId).then(rp => {
                setRecieverNotifications(rp);
            }
            ).catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true });
                }
            );
            notificationService.getSenderCaseContactNotifications(noCaseId, authUserData.currUserData.profileId).then(rp => {
                setSenderNotifications(rp);
            }
            ).catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true });
                }
            );
        }
    }, [noCaseId]);
    function changeNotificationtSatus(notificationnId: number) {
        if (tabKey === 'Reciever') {
            setRecieverNotifications(recieverNotifications!.map(x => {
                if (x.id === notificationnId) { x.inProgress = true; }
                return x;
            }));
        } else {
            setSenderNotifications(senderNotifications!.map(x => {
                if (x.id === notificationnId) { x.inProgress = true; }
                return x;
            }));
        }
        notificationService.changeNotificationStatus(notificationnId, "Read").then(rp => {
            if (tabKey === 'Reciever') {
                setRecieverNotifications(recieverNotifications!.map(x => {
                    if (x.id === notificationnId) { x.notificationStatus = "Read"; }
                    return x;
                }));
            } else {
                setSenderNotifications(senderNotifications!.map(x => {
                    if (x.id === notificationnId) { x.notificationStatus = "Read"; }
                    return x;
                }));
            }
            //authUserData.manageUserNotifications();
        }
        ).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
            }
        );
        if (tabKey === 'Reciever') {
            setRecieverNotifications(recieverNotifications!.map(x => {
                if (x.id === notificationnId) { x.inProgress = false; }
                return x;
            }));
        } else {
            setSenderNotifications(senderNotifications!.map(x => {
                if (x.id === notificationnId) { x.inProgress = false; }
                return x;
            }));
        }

    }

    function deleteNotification(notificationnId: number) {
        if (tabKey === 'Reciever') {
            setRecieverNotifications(recieverNotifications!.map(x => {
                if (x.id === notificationnId) { x.isDeleteing = true; }
                return x;
            }));
        } else {
            setSenderNotifications(senderNotifications!.map(x => {
                if (x.id === notificationnId) { x.isDeleteing = true; }
                return x;
            }));
        }
        notificationService.deleteNotification(notificationnId).then((rp) => {
            alertService.success(t("ALL.Notifications.Deleted"), { keepAfterRouteChange: true });
            if (tabKey === 'Reciever') {
                setRecieverNotifications(m => m!.filter(x => x.id !== notificationnId));
            } else {
                setSenderNotifications(m => m!.filter(x => x.id !== notificationnId));
            }
        }).catch(
            (rp) => {

                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                if (tabKey === 'Reciever') {
                    setRecieverNotifications(recieverNotifications!.map(x => {
                        if (x.id === notificationnId) { x.isDeleteing = false; }
                        return x;
                    }));
                } else {
                    setSenderNotifications(senderNotifications!.map(x => {
                        if (x.id === notificationnId) { x.isDeleteing = false; }
                        return x;
                    }));
                }
            }
        );
    }
    return (
        <div className="container">
            <Tabs activeKey={tabKey}
                onSelect={(k: any) => { setTabKey(k); }}>
                <Tab eventKey="Reciever" title={t("ALL.Notifications.Receiver")} key="tab-reciever">
                    <ListNotifications setNotificationAsRead={changeNotificationtSatus} deleteNotification={deleteNotification} notifications={recieverNotifications} tabKey="reciver" />
                </Tab>
                <Tab eventKey="Sender" title={t("ALL.Notifications.Sender")} key="tab-sender">
                    <ListNotifications setNotificationAsRead={changeNotificationtSatus} deleteNotification={deleteNotification} notifications={senderNotifications} tabKey="sender" />
                </Tab>
            </Tabs>
        </div>

    );

}

export { NotificationManagement };