import moment from "moment";
import { boolean } from "yup/lib/locale";
import {MajorContactInfo} from "./Contact";

export default class TaskGeneratorRule {
    public id?:number;
    public startTagId?:string;
    public dueTagId?:string;
    public ruleTitle?:string;
    public startToDueDays?:number;
    public dueTime?:Date;
    public targetContact?:string[];
    public scopeTypeId:number;
    public scopeEntityId:number;
    public taskGeneratorRuleTemplateId!: number;
    
  

    public createdBy?: string;
    public modifiedBy?: string;
    public created?: Date;
    public modified?: Date;

    public isDeleting?:boolean;

    constructor(id:number,scopeTypeId:number,scopeEntityId:number,startTagId?:string,dueTagId?:string,ruleTitle?:string,startToDueDays?:number,dueTime?:Date,targetContact?:string[],
        created?: Date, modifiedBy?: string, createdBy?: string, modified?: Date,caseId?:number, taskGeneratorRuleTemplateId?:number){        
        this.id=id;
        this.startTagId=startTagId;
        this.dueTagId=dueTagId;
        this.ruleTitle=ruleTitle;
        this.startToDueDays=startToDueDays;
        this.dueTime=dueTime;
        this.targetContact=targetContact;
        this.scopeTypeId=scopeTypeId;
        this.scopeEntityId=scopeEntityId;
        
        
        
        this.createdBy=createdBy;
        this.created=created;
        this.modifiedBy=modifiedBy;
        this.modified=modified;


    }

}
export interface ITaskGeneratorViewModel {
    ruleTemplateIds?:number[];
    taskTemplateIds?:number[];
    caseId:number;
    contactId:number;
    incapacityOfWorkTags?:string[];
    startOfIntegrationTags?:string[];
    incapacityOfWorkDate:Date;
    startOfIntegrationDate:Date;

}

export interface ITaskGeneratorRuleViewModel {
    id?: number;
    startTagId?: string;
    dueTagId?: string;
    startTagTitle?: string;
    dueTagTitle?: string;
    ruleTitle?: string;
    startToDueDays?: number;
    dueTime?: Date;
    dueTimeString?: string,
    targetContact?: string[];
    scopeTypeId: number;
    scopeEntityId: number;
    taskGeneratorRuleTemplateId: number;
    isDeleting?: boolean;

    created?: Date;
    createdBy?: MajorContactInfo;
    modified?: Date;
    modifiedBy?: MajorContactInfo;
}

export const mapTaskGeneratorRuleToViewModel = (data: any, t: (str: string) => string, taskPlanOptions: any, userLng: string): ITaskGeneratorRuleViewModel => {
    var startTag = taskPlanOptions!.filter((tag) => data.startTagId === tag.dicGuid);
    var startTagTitle = startTag.length > 0 ? startTag[0].title : "";
    var dueTag = taskPlanOptions!.filter((tag) => data.dueTagId === tag.dicGuid);
    var dueTagTitle = dueTag.length > 0 ? dueTag[0].title : "";
    moment.locale(userLng);
    return {
        id: data.id,
        startTagId: data.startTagId,
        dueTagId: data.dueTagId,
        startTagTitle: startTagTitle,
        dueTagTitle: dueTagTitle,

        ruleTitle: data.ruleTitle,
        startToDueDays: data.startToDueDays,
        dueTime: data.dueTime,
        dueTimeString: moment(data.dueTime).format("L"),
        targetContact: data.targetContact,
        scopeTypeId: data.scopeTypeId,
        scopeEntityId: data.scopeEntityId,
        taskGeneratorRuleTemplateId: data.taskGeneratorRuleTemplateId,
        isDeleting: data.isDeleting,

        createdBy: data.createdBy,
        created: data.created,
        modifiedBy: data.modifiedBy,
        modified: data.modified,
    }
}