import BaseService from '../../BaseService';
import Insurer from '../../../models/Insurer';
import LogedUserData from '../../../models/LogedUserData';
import Case from '../../../models/Case';

import { ApiError } from '../../../helpers/ApiError';
import Contact from '../../../models/Contact';
import Term from '../../../models/Term';
export const contactService = {
    getContactGroups,
    removeContactFromGroupAsync,
    getSingleContact,
    postUpdateContact,
    getInsurerRoleContacts,
    checkContactAcceptedTermAsyncAsync,
    acceptTermAgreement,
    getContactRoleData,
    getContactRelatedCases,
    getInsurerCaseRoleContacts,
    getSingleContactByEmail,
    removeInsurerContactFromGroupAsync,
    getAllContacts,
    postUploadProfilePicture,
    getAllContactsWithAssignement,
    checkContactAcceptedTermByContactIdAsync
};

async function getContactRoleData(userEmail:string,groupIds: Array<string>) {
    let res = BaseService.get<LogedUserData>("Contact/GetContactRoleData?", `contactEmail=${userEmail}&contactGroupIds=${groupIds}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getContactRelatedCases(casesIndex: Array<string>) {
    let res = BaseService.get<Array<Case>>("Contact/GetContactRelatedCases?casesIndex=", casesIndex).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function getContactGroups(groupIds: Array<string>) {
    let res = BaseService.get<LogedUserData>("Contact/GetContactRoleData?contactGroupIds=", groupIds).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function postUpdateContact(data:Contact) {      
    let res=BaseService.update<Contact>("Contact/UpdateContact", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
    };
    async function postUploadProfilePicture(data:any) {      
        let res=BaseService.update<Contact>("Contact/UploadProfilePicture", data).then(
            (rp) => {
                if (rp.Status) {
                    return rp.Data;
                } else {
                    throw new Error(rp.Exception.response.data);
                }
            }
        );
        return res;
        };

async function getSingleContact(contactId:number): Promise<Contact> {
    let res=BaseService.get<Contact>("Contact/GetSingleContact?contactId=",contactId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleContactByEmail(email:string): Promise<Contact> {
    let res=BaseService.get<Contact>("Contact/GetSingleContactByEmail?email=",email).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getInsurerCaseRoleContacts(role:string,caseId:number): Promise<Array<Contact>> {
    let res=BaseService.get<Contact>("Contact/GetInsurerRoleContacts",`?role=${role.replace(/\s/g, '')}&caseId=${caseId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllContacts(): Promise<Array<Contact>> {
    let res=BaseService.getAll<Contact>("Contact/GetAllContacts").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getAllContactsWithAssignement(): Promise<Contact[]> {
    let res=BaseService.getAll<Contact>("Contact/GetAllContactsWithAssignement").then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getInsurerRoleContacts(role:string,insurerId:number): Promise<Contact[]> {
    let res=BaseService.get<Case>("Contact/GetInsurerRoleContacts",`?role=${role.replace(/\s/g, '')}&insurerId=${insurerId}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function removeContactFromGroupAsync(data: any) {

    let res = BaseService.update<Insurer>("Contact/RemoveContactFromGroup", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function removeInsurerContactFromGroupAsync(data: any) {

    let res = BaseService.update<Insurer>("Insurer/RemoveContactFromGroup", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};

async function checkContactAcceptedTermAsyncAsync(contactEmail:string,browserLng:string) {
    let res = BaseService.get<Term>("Contact/CheckContactAcceptedTerm",`?contactEmail=${contactEmail}&contactLanguage=${browserLng}` ).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new ApiError(rp.Exception.response.statusText,rp.Exception.response.status,rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function checkContactAcceptedTermByContactIdAsync(contactId:string,browserLng:string) {
    let res = BaseService.get<Term>("Contact/CheckContactAcceptedTermByContactId",`?contactId=${contactId}&contactLanguage=${browserLng}` ).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new ApiError(rp.Exception.response.statusText,rp.Exception.response.status,rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function acceptTermAgreement(data:any) {      
    let res=BaseService.update<Contact>("Contact/AcceptTermAgreement", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
    };