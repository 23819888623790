import { Route, Routes } from 'react-router-dom';
import { InsurerList } from './InsurerList';
import { AddEditInsurer } from './AddEditInsurer';
import { ManageOwner } from './ManageOwner';
import { EditContact } from '../casemanager/EditContact';
import { CaseManagement } from './CaseManagement';
import { SettingsManagement } from './SettingsManagement';
import { EditSetting } from './EditSetting';
import { ManageAdmins } from './ManageAdmins';
import { ContactManagement } from './ContactManagement';
import { ManageContacts } from '../casemanager/ManageContacts';
import { TemplateManagement } from './TemplateManagement';
import { WebEditSetting } from './WebEditSetting';
import { WebListCalculation } from './WebListCalculations';
import { EditTemplate } from './EditTemplate';
import { LicenseManagement } from './license/LicenseManagement';
import { AddEditLicense } from './license/AddEditLicense';
import { EventSchedulerManagement } from './EventSchedulerManagement';
import { DictionaryManagement } from '../../components/dictionary/DictionaryManagement';
import {NotificationSettingsManagement} from '../../components/notifications/NotificationSettingsManagement';
import { WorkplaceManagement } from '../../components/workplace/WorkplaceManagement';
import AppraisalInfo from '../../components/workplace/AppraisalInfo';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useContext } from 'react';

const AdminPanel = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    return (
        <Routes>
            
            <Route path={`/`} element={<InsurerList />} />
            <Route path={`/all-cases`} element={<CaseManagement role="admin" />} />
            <Route path={`/all-settings`} element={<SettingsManagement />} />
            <Route path={`/all-schedulers`} element={<EventSchedulerManagement />} />
            <Route path={`/all-templates`} element={<TemplateManagement />} />
            <Route path={`/edit-template/:id`} element={<EditTemplate />} />
            <Route path={`/edit-setting/:id`} element={<EditSetting />} />
            <Route path={`/all-contacts`} element={<ContactManagement />} />
            <Route path={`/add-insurer`} element={<AddEditInsurer />} />
            <Route path={`/edit-insurer/:id`} element={<AddEditInsurer />} />
            <Route path={`/add-license`} element={<AddEditLicense />} />
            <Route path={`/edit-license/:id`} element={<AddEditLicense />} />
            <Route path={`/manage-contacts/:id`} element={<ManageContacts />} />
            <Route path={`/www-edit-settings`} element={<WebEditSetting />} />
            <Route path={`/www-list-calculations`} element={<WebListCalculation />} />
            <Route path={`/edit-contact/:id`} element={ <EditContact returnPath='../all-contacts' />} />
            <Route path={`/add-owners/:id`} element={<ManageOwner />} />
            <Route path={`/add-admin`} element={<ManageAdmins />} />
            <Route path={`/workplace`} element={<WorkplaceManagement/>}/>
            <Route path={`/appraisal-info/:id`} element={<AppraisalInfo/>}/>
            <Route path={`/manage-license`} element={<LicenseManagement />} />
            <Route path="/manage-dictionary/:id" element={<DictionaryManagement scopeId={1} title={t("ALL.Dictionary.ServiceProviderTitle")}/>}/>
            <Route path="/manage-global-dictionary" element={<DictionaryManagement scopeId={0} scopeEntityId={-1} title={t("ALL.Dictionary.GlobalTitle")}/>} />
            <Route path={`/manage-notification-settings`} element={<NotificationSettingsManagement allScopes={[{scopeTypeId:0,scopeEntityId:-1}]} scope={{scopeTypeId:0,scopeEntityId:-1}} />} />
        </Routes>
    );
}

export { AdminPanel };

