import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import Feedback from '../../models/Feedback';
import { alertService, employeerService, notificationService } from '../../services/Index';
import moment from 'moment';
import 'moment/min/locales';
import { helperFunctions } from '../../helpers/HelperFunctions';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { TextField } from '@mui/material';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';

const AddEditFeedback = () => {
    const { authUserData } = useContext(LoggedUserDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    const { caseData } = useContext(CaseDataContext);
    let { id } = useParams();
    let navigate = useNavigate();
    const isAddMode: boolean = !id;
    const [feedbackItem, setFeedback] = useState<Feedback>();
    const { locale, t, mask } = useContext(MultiLanguageSupportContext);
    const [dateOfFeedback, setDateOfFeedback] = useState<Date | null>(null);
    useEffect(() => {
        if (caseData.selectedCaseId === undefined) {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }


        else {
            if (!isAddMode) {
                employeerService.getSingleFeedback(parseInt(id!)).then(i => {
                    setFeedback({
                        caseId: i.caseId,
                        dateOfFeedback: i.dateOfFeedback,
                        taskQuality: i.taskQuality,
                        limitationRespected: i.limitationRespected,
                        remarks: i.remarks,
                        performed: i.performed,

                    });
                    setDateOfFeedback(new Date(i.dateOfFeedback));
                    setValue("dateOfFeedback", moment.parseZone(new Date(i.dateOfFeedback)));
                    setValue("taskQuality", i.taskQuality);
                    setValue("limitationRespected", i.limitationRespected);
                    setValue("remarks", i.remarks);
                    setValue("performed", i.performed);

                }).catch(
                    (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
                );

            }
            else {
                setDateOfFeedback(new Date());
                reset();
            }
        }
    }, [id]);

    const validationSchema = Yup.object().shape({
        taskQuality: Yup.string().required(t("EMP.Feedback.Validation.TaskQuality")),
        limitationRespected: Yup.string().required(t("EMP.Feedback.Validation.LimitationRespected")),
        remarks: Yup.string().required(t("EMP.Feedback.Validation.Remark")),
        //dateOfFeedback:Yup.date().max(moment(), t("EMP.Validation.DateRangeMax"))

    });

    const { register, handleSubmit, reset, setValue, getValues, control, errors, formState } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            dateOfFeedback: moment.parseZone(new Date()),
            taskQuality: '',
            limitationRespected: '',
            remarks: '',
            performed: ''
        }
    });

    function onSubmit(data: Feedback) {
        return isAddMode
            ? createFeedback(data)
            : updateFeedback(parseInt(id!), data);
    }

    function createFeedback(data: Feedback) {
        if (moment(data.dateOfFeedback) > moment()) {
            alertService.error(t("EMP.Validation.DateRangeMax"), { keepAfterRouteChange: true })
            return false;
        }
        var forbidenWordsArray = [
            caseData.caseClient.name?.toLowerCase(), caseData.caseClient.surname?.toLowerCase(), , caseData.caseClient.mail?.toLowerCase(),
            caseData.caseDoctor.name?.toLowerCase(), caseData.caseDoctor.surname?.toLowerCase(), caseData.caseDoctor.mail?.toLowerCase(),
            caseData.caseEmployeer.name?.toLowerCase(), caseData.caseEmployeer.surname?.toLowerCase(), caseData.caseEmployeer.mail?.toLowerCase(),
            caseData.caseCaseManager.name?.toLowerCase(), caseData.caseCaseManager.surname?.toLowerCase(), caseData.caseCaseManager.mail?.toLowerCase()
        ]
        if (helperFunctions.nameContentCheckerIsInvalid(data.remarks, forbidenWordsArray)) {
            alertService.error(t("ALL.Validation.NameDetection"), { keepAfterRouteChange: true })
            return false;
        }
        data.caseId = caseData.selectedCaseId!;
        data.createdBy = authUserData.currUserData.profileId;
        data.dateOfFeedback = new Date(data.dateOfFeedback).toISOString();
        return employeerService.postNewFeedback(data)
            .then((rp) => {
                alertService.success(t("EMP.Feedback.Added"), { keepAfterRouteChange: true });
                navigate(`../show-feedbacks`);
                notificationService.postNewNotifications(
                    {
                        caseId: caseData.selectedCaseId!,
                        senderContactId: authUserData.currUserData.profileId,
                        notificationStatus: "New",
                        notificationTitle: t("ALL.Notifications.TitleFeedback"),
                        notificationMessage: t("ALL.Notifications.MessageReflection"),
                        notificationItemUrl: `view-feedback/${rp.Data.id}`,
                        notificationDate: moment(),
                        notificationSettingDictionaryId: 5,
                        scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: caseData.insurerId }, { scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId! }],
                        templateId: 605


                    })
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    function updateFeedback(id: number, data: Feedback) {
        if (moment(data.dateOfFeedback) > moment()) {
            alertService.error(t("EMP.Validation.DateRangeMax"), { keepAfterRouteChange: true })
            return false;
        }
        var forbidenWordsArray = [
            caseData.caseClient.name?.toLowerCase(), caseData.caseClient.surname?.toLowerCase(), , caseData.caseClient.mail?.toLowerCase(),
            caseData.caseDoctor.name?.toLowerCase(), caseData.caseDoctor.surname?.toLowerCase(), caseData.caseDoctor.mail?.toLowerCase(),
            caseData.caseEmployeer.name?.toLowerCase(), caseData.caseEmployeer.surname?.toLowerCase(), caseData.caseEmployeer.mail?.toLowerCase(),
            caseData.caseCaseManager.name?.toLowerCase(), caseData.caseCaseManager.surname?.toLowerCase(), caseData.caseCaseManager.mail?.toLowerCase()
        ]
        if (helperFunctions.nameContentCheckerIsInvalid(data.remarks, forbidenWordsArray)) {
            alertService.error(t("ALL.Validation.NameDetection"), { keepAfterRouteChange: true })
            return false;
        }
        data.id = id;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.dateOfFeedback = new Date(data.dateOfFeedback).toISOString();
        return employeerService.postUpdateFeedback(data)
            .then(() => {
                alertService.success(t("EMP.Feedback.Updated"), { keepAfterRouteChange: true });
                navigate(`../show-feedbacks`);
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    return (
        <div className="container">
            {!feedbackItem && !isAddMode &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {(feedbackItem && !isAddMode || isAddMode) &&
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{isAddMode ? t("EMP.Feedback.Add") : t("EMP.Feedback.Edit")}</h4>
                            </div>
                            <div className="card-body">

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("EMP.Feedback.Title")}*</label>
                                        <div className="col-sm-6">
                                            <Controller
                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                    <DatePicker
                                                        maxDate={isAddMode ? new Date() : undefined || undefined}
                                                        value={dateOfFeedback}
                                                        onChange={value => {
                                                            setDateOfFeedback(value);
                                                            setValue("dateOfFeedback", value)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        mask={mask}
                                                    />
                                                </LocalizationProvider>)}
                                                name="dateOfFeedback"
                                                control={control}

                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("EMP.Feedback.TaskQuality")}*</label>
                                        <div className="col-sm-6">
                                            <select name="taskQuality" ref={register} className={`form-control ${errors.taskQuality ? 'is-invalid' : ''}`}>
                                                <option value={10}>{t("EMP.Feedback.TaskQualityOption.10")}</option>
                                                <option value={9}>{t("EMP.Feedback.TaskQualityOption.9")}</option>
                                                <option value={8}>{t("EMP.Feedback.TaskQualityOption.8")}</option>
                                                <option value={7}>{t("EMP.Feedback.TaskQualityOption.7")}</option>
                                                <option value={6}>{t("EMP.Feedback.TaskQualityOption.6")}</option>
                                                <option value={5}>{t("EMP.Feedback.TaskQualityOption.5")}</option>
                                                <option value={4}>{t("EMP.Feedback.TaskQualityOption.4")}</option>
                                                <option value={3}>{t("EMP.Feedback.TaskQualityOption.3")}</option>
                                                <option value={2}>{t("EMP.Feedback.TaskQualityOption.2")}</option>
                                                <option value={1}>{t("EMP.Feedback.TaskQualityOption.1")}</option>

                                            </select>
                                            <div className="invalid-feedback">{errors.taskQuality?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("EMP.Feedback.Limitations")}*</label>
                                        <div className={`col-sm-6 ${errors.limitationRespected ? 'is-invalid-radio' : ''}`}>
                                            <div className="form-check form-check-inline">
                                                <input name="limitationRespected" type="radio" value="True" ref={register} />
                                                <label className="form-check-label">{t("ALL.Button.Yes")}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input name="limitationRespected" type="radio" value="False" ref={register} />
                                                <label className="form-check-label">{t("ALL.Button.No")}</label>
                                                <div className="invalid-feedback">{errors.limitationRespected?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("EMP.Feedback.Remark")}*</label>
                                        <div className="col-sm-6">
                                            <textarea name="remarks" rows={5} ref={register} className={`form-control ${errors.remarks ? 'is-invalid' : ''}`} />
                                            <div className="invalid-feedback">{errors.remarks?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-group row justify-content-center">
                                        <div className="col-sm-6">
                                            <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                {t("ALL.Button.Save")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    );
}


export { AddEditFeedback };