import { Route, Routes } from 'react-router-dom';
import { CaseManagement } from './CaseManagement';
import { ManageContacts } from './ManageContacts';
import { Dashboard } from './Dashboard';
import { ListReflections } from './ListReflections';
import { ListFeedback } from './ListFeedback';
import { ListAssessements } from './ListAssessements';
import { EditCase } from './EditCase';
import { EditContact } from './EditContact';
import { AddCase } from './AddCase';
import { DocumentManagement } from '../../components/document/DocumentManagement';
import { ViewFeedback } from '../employeer/ViewFeedback';
import { ViewReflection } from '../client/ViewReflection';
import { ViewAssessement } from '../doctor/ViewAssessment.';
import { NotificationManagement } from '../../components/notifications/NotificationManagement';
import { NotesManagement } from '../../components/notes/NotesManagement';
import { ViewNote } from '../../components/notes/ViewNote';
import  ViewCase from './ViewCase';
import { LicenseReport } from '../admin/license/LicenseReport';
import TaskTabComponent from '../../components/tasks/TaskTabComponent';
import { useContext } from 'react';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { ViewTask } from '../../components/tasks/ViewTask';
import { ManageCAMNotificationSettings } from './ManageCAMNotificationSettings';
import BookingsTabComponent from '../../components/bookings/BookingsTabComponent';


const CaseManagerPanel=()=> {
    const { caseData} = useContext(CaseDataContext);
    return (
        <Routes>       
            <Route path={`/`} element={ <CaseManagement allCasesMode={false}/>} />
            <Route path={`/all-cases`} element={ <CaseManagement allCasesMode={true}/>} />
            <Route path={`/add-case`} element={<AddCase/>}/>
            <Route path={`/my-cases`} element={ <CaseManagement allCasesMode={false}/>} />
            <Route path={`/dashboard/:id`} element={<Dashboard/>}/>
            <Route path="/show-reflections">
                <Route path={`:id/:notId`} element={<ListReflections/>} />
                <Route index element={<ListReflections />} />
            </Route>
            <Route path="/show-feedbacks">
                <Route path={`:id/:notId`} element={<ListFeedback />} />
                <Route index element={<ListFeedback />} />
            </Route>
            <Route path="/show-assessements">
                <Route path={`:id/:notId`} element={<ListAssessements />} />
                <Route index element={<ListAssessements />} />
            </Route>
            <Route path={`/edit-case`} >
                <Route path={`:id/:onCase`} element={<EditCase preselectedTab={0}/>}/>
                <Route path={`:id/:onCase/:notId`} element={<EditCase preselectedTab={0}/>}/>
            </Route>
            <Route path={`/edit-case-goals/:id`}  element={<EditCase preselectedTab={1}/>}/>
            <Route path={`/view-case/:id/:notId`} element={<ViewCase/>}/>
            <Route path={`/manage-case-contacts`} element={<ManageContacts/>}/>
            <Route path={`/notification-management`} element={ <NotificationManagement role="CaseManager" caseId={caseData.selectedCaseId} />} />
            <Route path={`/edit-contact/:id`} element={<EditContact/>}/>
            <Route path={`/manage-contacts/:id`} element={<ManageContacts/>}/>
            <Route path={`/view-feedback/:id/:notId`} element={<ViewFeedback/>}/>
            <Route path={`/view-reflection/:id/:notId`} element={<ViewReflection/>}/>
            <Route path={`/view-assessement/:id/:notId`} element={<ViewAssessement/>}/>
            <Route path={`/license-report/:insurerId`} element={ <LicenseReport />} />
            <Route path={`/view-note/:id/:notId`} element={<ViewNote/>}/>
            <Route path={`/view-task/:id/:notId`} element={<ViewTask/>}/>
            <Route path="/notes-management">
                <Route path={`:id`} element={<NotesManagement  role="CaseManager" caseId={caseData.selectedCaseId} />} />
                <Route index element={<NotesManagement  role="CaseManager" caseId={caseData.selectedCaseId} />} />
            </Route>
            <Route path={`/tasks-management`} element={<TaskTabComponent/>} />
            <Route path={`/manage-bookings`} element={<BookingsTabComponent/>} />
            <Route path="/document-management">
                <Route path={`:id/:notId`} element={<DocumentManagement role="CaseManager"  />} />
                <Route path={`:id`} element={<DocumentManagement role="CaseManager"  />} />
                <Route path={`:notId`} element={<DocumentManagement role="CaseManager"  />} />
                <Route index element={<DocumentManagement role="CaseManager"  />} />
            </Route>
            <Route path={`/manage-notification-settings/:type`} element={<ManageCAMNotificationSettings />} />
            </Routes>
    );
}
export { CaseManagerPanel };