import { useContext, useEffect, useState } from 'react';

import { Controller, useForm } from "react-hook-form";
import "@fortawesome/fontawesome-svg-core/styles.css";
import 'react-form-builder2/dist/app.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faEdit, faHeadSideMedical, faTrash } from '@fortawesome/pro-light-svg-icons';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

function AddEditAppraisal() {
    const { authUserData } = useContext(LoggedUserDataContext);
    const { locale, t, mask } = useContext(MultiLanguageSupportContext);
    const validationSchema = Yup.object().shape({});
    const { register, handleSubmit, reset, control, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const changePossible = authUserData.currUserData.rolePath === "employeer";
    function onSubmit() {

    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <div className="row  justify-content-between">
                                <div className="col-sm-10">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">{t("CLN.ResilienceProfile.ProfileSelection")}</label>
                                        <div className="col-sm-1">
                                <FontAwesomeIcon icon={faBold} style={{ fontSize: "32px", color: "blue" }} />
                            </div>
                                        <div className="col-sm-6">
                                            <select name="rate" ref={register} className={`form-control ${errors.rate ? 'is-invalid' : ''}`} >
                                                <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.1")}</option>
                                                <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.2")}</option>
                                                <option value={1}>{t("CLN.ResilienceProfile.ProfileItems.3")}</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.rate?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                {changePossible &&
                                    <div className="col-sm-2">
                                        <button className="btn btn-md btn-icon-grey">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button className="btn btn-md btn-icon-red">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CLN.ResilienceProfile.AppraisalTitle")}</label>
                                        <input name="titleAppraisal" type="text" ref={register} className={`form-control ${errors.titleAppraisal ? 'is-invalid' : ''}`} disabled={!changePossible} />
                                        <div className="invalid-feedback">{errors.titleAppraisal?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CLN.ResilienceProfile.JobDescription")}</label>
                                        <input name="jobDesc" type="text" ref={register} className={`form-control ${errors.jobDesc ? 'is-invalid' : ''}`} disabled={!changePossible} />
                                        <div className="invalid-feedback">{errors.jobDesc?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CLN.ResilienceProfile.LimitationsRespected")}*</label>
                                        <div className={`col-sm-6 ${errors.limitationRespected ? 'is-invalid-radio' : ''}`}>
                                            <div className="form-check form-check-inline">
                                                <input name="limitationRespected" type="radio" value="True" ref={register} disabled={!changePossible} />
                                                <label className="form-check-label">{t("ALL.Button.Yes")}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input name="limitationRespected" type="radio" value="False" ref={register} disabled={!changePossible} />
                                                <label className="form-check-label">{t("ALL.Button.No")}</label>
                                                <div className="invalid-feedback">{errors.limitationRespected?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <div className="form-row" style={{ paddingLeft: "2px" }}>
                                            <div className="form-group col-sm-6">
                                                <label>{t("CLN.ResilienceProfile.WorkplaceDescription")}</label>
                                                <input name="jobDesc" type="text" ref={register} className={`form-control ${errors.jobDesc ? 'is-invalid' : ''}`} disabled={!changePossible} />
                                                <div className="invalid-feedback">{errors.jobDesc?.message}</div>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label>{t("CLN.ResilienceProfile.WorkplaceFramework")}</label>
                                                <input name="jobDesc" type="text" ref={register} className={`form-control ${errors.jobDesc ? 'is-invalid' : ''}`} disabled={!changePossible} />
                                                <div className="invalid-feedback">{errors.jobDesc?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("CLN.ResilienceProfile.ApprovalDateEMP")}</label>
                                        <Controller
                                            render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                <DatePicker
                                                    className="form-control"
                                                    value={getValues("endOfIntegration")}
                                                    onChange={value => setValue("endOfIntegration", value)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    disabled={!(authUserData.currUserData.rolePath === "employeer")}
                                                />
                                            </LocalizationProvider>)}
                                            name="endOfIntegration"
                                            control={control}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CLN.ResilienceProfile.AcceptanceCriteria")}</label>
                                        <textarea name="description" rows={4} ref={register} className={`form-control ${errors.description ? 'is-invalid' : ''}`} disabled={!changePossible} />
                                        <div className="invalid-feedback">{errors.description?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <div className="form-row" style={{ paddingLeft: "2px" }}>
                                            <label>{t("CLN.ResilienceProfile.ApprovalDateDOC")}</label>
                                            <Controller
                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                    <DatePicker
                                                        className="form-control"
                                                        value={getValues("endOfIntegration")}
                                                        onChange={value => setValue("endOfIntegration", value)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        disabled={!(authUserData.currUserData.rolePath === "doctor")}
                                                    />
                                                </LocalizationProvider>)}
                                                name="endOfIntegration"
                                                control={control}
                                            />
                                        </div>
                                        <div className="form-row" style={{ paddingLeft: "2px" }}>
                                            <label>{t("CLN.ResilienceProfile.ApprovalDateCLN")}</label>
                                            <Controller
                                                render={(props) => (<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                                                    <DatePicker
                                                        className="form-control"
                                                        value={getValues("endOfIntegration")}
                                                        onChange={value => setValue("endOfIntegration", value)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        disabled={!(authUserData.currUserData.rolePath === "client")}
                                                    />
                                                </LocalizationProvider>)}
                                                name="endOfIntegration"
                                                control={control}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("CLN.ResilienceProfile.DoctorOnlyInfo")}</label>
                                        <textarea name="description" rows={7} ref={register} className={`form-control ${errors.description ? 'is-invalid' : ''}`} disabled={!changePossible} />
                                        <div className="invalid-feedback">{errors.description?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("EMP.Feedback.Limitations")}*</label>
                                        <div className={`col-sm-6 ${errors.limitationRespected ? 'is-invalid-radio' : ''}`}>
                                            <div className="form-check form-check-inline">
                                                <input name="limitationRespected" type="radio" value="True" ref={register} disabled={!(authUserData.currUserData.rolePath === "doctor")} />
                                                <label className="form-check-label">{t("ALL.Button.Yes")}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input name="limitationRespected" type="radio" value="False" ref={register} disabled={!(authUserData.currUserData.rolePath === "doctor")} />
                                                <label className="form-check-label">{t("ALL.Button.No")}</label>
                                                <div className="invalid-feedback">{errors.limitationRespected?.message}</div>
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-6">
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row justify-content-center">
                                    <div className="form-group col-sm-4">
                                        <button type="button" onClick={handleSubmit(onSubmit)} disabled={formState.isSubmitting} className="btn btn-action">
                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            {t("ALL.Button.Save")}
                                        </button>
                                    </div>

                                    <div className="form-group col-sm-4">
                                        <button className="btn btn-grey" onClick={(event) => {
                                            event.preventDefault();
                                        }}>
                                            {t("ALL.Button.Cancel")}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >

    );
}

export { AddEditAppraisal };