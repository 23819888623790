import moment from "moment";
import { MajorContactInfo } from "./Contact";

export class Task {
    public id: number;
    public caseId: number;
    public taskTitle: string;
    public taskType?: number;
    public dueTime: Date;
    public startTime: Date;
    public owner: MajorContactInfo;
    public assigned: MajorContactInfo;
    public taskStatus?: number;
    public taskDescription: string;
    public taskTags: string[];
    public reminderDate?: Date;
    public reminderIntervallHours?: number;
    public reminderTemplateId?: number;
    public priority?: number;
    public casePhaseTag?: string;
    public planTag?: string;
    public templateSourceId?: number;


    public createdBy?: MajorContactInfo;
    public modifiedBy?: MajorContactInfo;
    public created?: Date;
    public modified?: Date;
    public templateId?: number;


    constructor(id: number, caseId: number, taskTitle: string, dueTime: Date, owner: MajorContactInfo, assigned: MajorContactInfo, taskDescription: string, taskTags: string[], reminderDate: Date, reminderIntervallHours: number,
        startTime: Date, reminderTemplateId: number, taskType?: number, taskStatus?: number, planTag?: string, priority?: number, casePhaseTag?: string, templateSourceId?: number, created?: Date, modifiedBy?: MajorContactInfo, createdBy?: MajorContactInfo, modified?: Date) {
        this.id = id;
        this.caseId = caseId;
        this.taskTitle = taskTitle;
        this.taskType = taskType;
        this.dueTime = dueTime;
        this.startTime = startTime;
        this.owner = owner;
        this.assigned = assigned;
        this.taskStatus = taskStatus;
        this.taskDescription = taskDescription;
        this.taskTags = taskTags;
        this.reminderDate = reminderDate;
        this.reminderIntervallHours = reminderIntervallHours;
        this.reminderTemplateId = reminderTemplateId;
        this.priority = priority;
        this.casePhaseTag = casePhaseTag;
        this.planTag = planTag;
        this.templateSourceId = templateSourceId;

        this.createdBy = createdBy;
        this.created = created;
        this.modifiedBy = modifiedBy;
        this.modified = modified;


    }

}
export interface ITaskViewModel {
    id?: number;
    caseId: number;
    taskTitle: string;
    taskType?: number;
    taskTypeString?: string;
    dueTimeString: string;
    dueTime: Date
    startTime: Date;
    startTimeString: string;
    owner: MajorContactInfo;
    assigned: MajorContactInfo;
    taskStatusString?: string;
    taskDescription: string;
    taskTags: string[];
    taskStatus?: number;
    reminderDate?: Date;
    reminderIntervallHours?: number;
    reminderTemplateId?: number;
    priorityString?: string;
    casePhaseTagString?: string;
    priority?: number;
    casePhaseTag?: string;
    planTag?: string;
    planTagString?: string;
    templateSourceId?: number;
    isDeleting?: boolean;
    created?: Date;
    createdBy?: MajorContactInfo;
    modified?: Date;
    modifiedBy?: MajorContactInfo;
}

export const mapTaskToVieModel = (data: any, t: (str: string) => string, taskTagsOptions: any, casePhaseOptions: any, taskPlanOptions: any, userLng:string): ITaskViewModel => {
    var taskPlan = taskPlanOptions!.filter((tag) => data.planTag === tag.dicGuid);
    var taskPlanTitle = taskPlan.length > 0 ? taskPlan[0].title : "";
    var casePhase = casePhaseOptions!.filter((tag) => {
        if(data.casePhaseTag === tag.dicGuid && userLng === (tag.dicItemLng).toLowerCase()) {
            return tag;
        }
    });
    var casePhaseTitle = casePhase.length > 0 ? casePhase[0].title : "";
    moment.locale(userLng);
    return {
        id: data.id,
        caseId: data.caseId,
        taskTitle: data.taskTitle,
        taskType: data.taskType,
        taskTypeString: t(`ALL.Tasks.Type.Options.${data.taskType}`),
        dueTimeString: moment(data.dueTime).format("L"),
        startTime: data.startTime,
        startTimeString: moment(data.startTime).format("L"),
        dueTime: data.dueTime,
        owner: data.owner,
        assigned: data.assigned,
        taskStatusString: data.taskStatus!==null?t(`ALL.Tasks.Status.Options.${data.taskStatus}`):"",
        taskStatus: data.taskStatus,
        taskDescription: data.taskDescription,
        taskTags: data.taskTags,
        reminderDate: data.reminderDate,
        reminderIntervallHours: data.reminderIntervallHours,
        reminderTemplateId: data.reminderTemplateId,
        priority: data.priority,
        priorityString: t(`ALL.Tasks.Priority.Options.${data.priority}`),
        casePhaseTagString: casePhaseTitle,
        casePhaseTag: data.casePhaseTag,
        planTagString: taskPlanTitle,
        planTag: data.planTag,
        createdBy: data.createdBy,
        created: data.created,
        modifiedBy: data.modifiedBy,
        modified: data.modified,
        templateSourceId: data.templateSourceId
    }
}

