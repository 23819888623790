import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Case from '../../models/Case';
import { caseManagerService, alertService, licenseService, notificationService } from '../../services/Index';
import { ManageGroupMembers } from './ManageGroupMembers';
import { Alert } from 'reactstrap';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import moment from 'moment';

const AddCase=()=> {
    const { t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [caseItem, setCase] = useState<any>();
    const [isLoading, setLoader] = useState<boolean>(true);
    const [caseAddNotPosible, setCaseAddNotPosible] = useState<boolean>(false);
    const validationSchema = Yup.object().shape({
        referenceNumberOfInsurance: Yup.string()
            .required(t("INS.Validation.Validation.InsurerRefNumber")),

    });
    const isInsurer = authUserData.currUserData.roleType === 'Insurer'
    const { register, handleSubmit, reset, errors, formState: { isSubmitting } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data: Case) {
        return createCase(data);
    }

    function createCase(data: Case) {
        data.createdBy = authUserData.currUserData.profileId;
        data.insurerId = authUserData.currUserData.insurerId!;
        data.inCaseManagerRole = !isInsurer ? data.inCaseManagerRole : "No";
        return caseManagerService.postNewCase(data)
            .then((rp) => {
                alertService.success(t("CAM.Case.Added"), { keepAfterRouteChange: true });
                setCase(rp);
                reset();
                // notificationService.postNewNotifications(
                //     {
                //         caseId: rp.Data.id,
                //         senderContactId: authUserData.currUserData.profileId,
                //         notificationStatus: "New",
                //         notificationTitle: t("ALL.Notifications.CaseCreationTitle"),
                //         notificationMessage: t("ALL.Notifications.CaseCreationMessageNote"),
                //         notificationItemUrl: `view-case/${rp.Data.id}`,
                //         notificationDate: moment(),
                //         notificationSettingDictionaryId: 7,
                //         scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId! }, { scopeTypeId: 2, scopeEntityId: rp.Data.id }],
                //         templateId: 607


                //     })
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    useEffect(() => {
        licenseService.getInsurerActiveLicensesAsync(authUserData.currUserData.insurerId!).then(
            (x) => {
                if (x.length === (x.filter(x => x.edition === "DEMO")).length) {
                    setCaseAddNotPosible(true);

                }
                setLoader(false);

            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }, []);
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        {!isLoading && <>
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{t("CAM.Case.NewCaseCreation")}</h4>
                            </div>
                            <div className="card-body">
                                {caseAddNotPosible ? <Alert color="warning">{t("ALL.License.DemoOnly")}</Alert>
                                    : <>
                                        {!caseItem &&
                                            <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                                                <div className="form-row">
                                                    <div className="form-group col-sm-3">
                                                        <label>{t("INS.Common.InsurerRefNumber")}*</label>
                                                        <input name="referenceNumberOfInsurance" type="text" ref={register} className={`form-control ${errors.referenceNumberOfInsurance ? 'is-invalid' : ''}`} />
                                                        <div className="invalid-feedback">{errors.referenceNumberOfInsurance?.message}</div>
                                                    </div>
                                                    {!isInsurer &&
                                                        <div className="form-group col-sm-3">
                                                            <label>{t("CAM.Case.AddCaseManagerToGroup")}</label>
                                                            <select name="inCaseManagerRole" ref={register} className={`form-control ${errors.inCaseManagerRole ? 'is-invalid' : ''}`}>
                                                                <option value="Yes">{t("ALL.Button.Yes")}</option>
                                                                <option value="No">{t("ALL.Button.No")}</option>
                                                            </select>
                                                            <div className="invalid-feedback">{errors.title?.message}</div>
                                                        </div>
                                                    }
                                                    <div className="form-group col-sm-3">
                                                        <button type="submit" disabled={isSubmitting} className="btn btn-action btn-simple">
                                                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                            {t("CAM.Button.CreateCase")}
                                                        </button>
                                                    </div>

                                                </div>

                                            </form>
                                        }
                                        {caseItem &&
                                            <>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card">
                                                            <div className='card-header card-header-warning dark-header-top-doc'>
                                                                <h4 className="card-title">{t('ALL.Dictionary.Role.Doctor')}</h4>
                                                            </div>
                                                            <ManageGroupMembers groupId={caseItem!.Data.docGroupOpenId!} groupName="Doctor" caseId={caseItem!.Data.id}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card">
                                                            <div className='card-header card-header-warning dark-header-top-emp'>
                                                                <h4 className="card-title">{t('ALL.Dictionary.Role.Employeer')}</h4>
                                                            </div>
                                                            <ManageGroupMembers groupId={caseItem!.Data.empGroupOpenId!} groupName="Employeer" caseId={caseItem!.Data.id}  />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card">
                                                            <div className='card-header card-header-warning dark-header-top-cam'>
                                                                <h4 className="card-title">{t('ALL.Dictionary.Role.CaseManager')}</h4>
                                                            </div>
                                                            <ManageGroupMembers groupId={caseItem!.Data.camGroupOpenId!} groupName="Case Manager" caseId={caseItem!.Data.id}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="card">
                                                            <div className='card-header card-header-warning dark-header-top-cln'>
                                                                <h4 className="card-title">{t('ALL.Dictionary.Role.Client')}</h4>
                                                            </div>
                                                            <ManageGroupMembers groupId={caseItem!.Data.clnGroupOpenId!} groupName="Client" caseId={caseItem!.Data.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>}
                            </div></>}
                    </div>
                </div>
            </div>
        </div>

    );
}

export { AddCase };