import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Contact from '../../models/Contact';
import { adminService, alertService, contactService } from '../../services/Index';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

import { ProfilePictureCustom } from './ProfilePicture';
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolTip } from '../ToolTip';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import i18n from '../../constants/multi-lang-support';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { useNavigate } from 'react-router-dom';
import { AppAccessContext } from '../../ctx/AppAccessProvider ';
interface IEditContactProps {
    id: number;
    returnAction: (contact?: Contact) => void;
    closeModal?: any;
}
export const EditContactControl = ({ id, returnAction, closeModal }: IEditContactProps) => {
    let navigate = useNavigate();
    const [contactItem, setContact] = useState<Contact>();
    const { authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    const [insurers, setInsurers] = useState<any>();
    const [loading, setLoading] = useState<any>(true);
    const { t, setLanguage } = useContext(MultiLanguageSupportContext);
    const { setAppAccess } = useContext(AppAccessContext);
    useEffect(() => {
        var getInsurers = adminService.getInsurers();
        var getSingleContact = contactService.getSingleContact(id);
        Promise.all([getInsurers, getSingleContact]).then(async (responses) => {
            setInsurers(responses[0]);
            var contactInfo = responses[1];
            setAppAccess({
                hasAccessToPath:authUserData.currUserData.roleType.toLowerCase()==="admin" ||
                (['doctor','client','employeer','insurer'].includes(authUserData.currUserData.roleType.toLowerCase())&& authUserData.currUserData.contactId===id) ||
                 (authUserData.currUserData.relatedData.filter(data =>contactInfo.contactAssignementCaseIds?.includes(data.id)).length > 0),
            });
            setLoading(false);
            setValue("phone", contactInfo.phone);
            setValue("email", contactInfo.email);
            setValue("secondEmail", contactInfo.secondEmail);
            setValue("companyName", contactInfo.companyName);
            setValue("street", contactInfo.street);
            setValue("postCode", contactInfo.postCode);
            setValue("city", contactInfo.city);
            setValue("country", contactInfo.country)
            setValue("salutation", contactInfo.salutation)
            setValue("remarks", contactInfo.remarks);
            setValue("name", contactInfo.name);
            setValue("surname", contactInfo.surname);
            setValue("role", contactInfo.role);
            setValue("defaultLanguage", contactInfo.defaultLanguage);
            setValue("reminderType", contactInfo.notificationType);
            setValue("insurerId", contactInfo.insurerId);
            setContact({
                id: contactInfo.id,
                insurerId: contactInfo.insurerId,
                phone: contactInfo.phone,
                isAdGroupMember: contactInfo.isAdGroupMember,
                email: contactInfo.email,
                secondEmail: contactInfo.secondEmail,
                companyName: contactInfo.companyName,
                street: contactInfo.street,
                postCode: contactInfo.postCode,
                city: contactInfo.city,
                remarks: contactInfo.remarks,
                name: contactInfo.name,
                surname: contactInfo.surname,
                country: contactInfo.country,
                salutation: contactInfo.salutation,
                role: contactInfo.role,
                defaultLanguage: contactInfo.defaultLanguage,
                notificationType: contactInfo.notificationType,
                profileImage: contactInfo.profileImage,
                contactAssignementCaseIds:contactInfo.contactAssignementCaseIds,
            });

        }).catch(
            (rp) => {
                setLoading(false);
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
            });

    }, [id]);
    const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/

    const validationSchema = Yup.object().shape({
        phone: Yup.string().matches(phoneRegExp, t("ALL.Contact.Validation.Phone")),
        street: Yup.string().required(t("ALL.Contact.Validation.Street")),
        postCode: Yup.string().required(t("ALL.Contact.Validation.PostCode")),
        city: Yup.string().required(t("ALL.Contact.Validation.City")),
        salutation: Yup.string().required(t("ALL.Contact.Validation.Salutation")),
        country: Yup.string().required(t("ALL.Contact.Validation.Country")),
        name: Yup.string().required(t("ALL.Contact.Validation.Firstname")),
        surname: Yup.string().required(t("ALL.Contact.Validation.Lastname"))
    });

    const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data: Contact) {
        setLanguage(data.defaultLanguage);
        i18n.changeLanguage(data.defaultLanguage);
        return updateContact(id, data);
    }
    function updateContact(id: number, data: Contact) {
        data.role = (authUserData.currUserData.roleType == "Admin") ? data.role : contactItem!.role;
        data.insurerId = !(contactItem!.role === "Doctor" || authUserData.currUserData.roleType !== "Admin" || (contactItem && authUserData.currUserData.roleType === "Admin" && contactItem!.isAdGroupMember)) ? data.insurerId : contactItem!.insurerId;
        data.modifiedBy = authUserData.currUserData.profileId;
        data.id = id;
        data.email = contactItem!.email;
        return contactService.postUpdateContact(data)
            .then((rp) => {
                alertService.success(t("ALL.Contact.Updated"), { keepAfterRouteChange: true });
                if (authUserData.currUserData.profileEmail === data.email) {
                    setAuthUserData(
                        {
                            ...authUserData,
                            currUserData: {
                                ...authUserData.currUserData!,
                                name: data.name,
                                profileLanguage: data.defaultLanguage,
                                surname: data.surname,
                            },
                            referenceNumberOfInsurance: rp.referenceNumberOfInsurance!
                        });
                }
                returnAction(rp);
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }


    return (<>
        {loading && <div className="loader">{t("ALL.Common.Loading")}</div>}
        {!loading &&
            <div className="container">
                <div className="row">
                    <div className={contactItem?.email === authUserData.currUserData.profileEmail ? "col-sm-8" : "col-sm-12"}>
                        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Common.DefaultLanguage")}</label>
                                <div className="col-sm-6">
                                    <select name="defaultLanguage" ref={register} className={`form-control ${errors.defaultLanguage ? 'is-invalid' : ''}`}>
                                        <option value="en">EN</option>
                                        <option value="de">DE</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.defaultLanguage?.message}</div>
                                </div>
                            </div>
                            {/* <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Dictionary.ReminderType.Title")}</label>
                                <div className="col-sm-6">
                                    <select name="reminderType" ref={register} className={`form-control ${errors.reminderType ? 'is-invalid' : ''}`}>
                                        <option value="EmailNotification">{t("ALL.Dictionary.ReminderType.NotificationAndEMail")}</option>
                                        <option value="Notification">{t("ALL.Dictionary.ReminderType.NotificationOnly")}</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.reminderType?.message}</div>
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.Salutation")}</label>
                                <div className="col-sm-6">
                                    <select name="salutation" ref={register} className={`form-control ${errors.salutation ? 'is-invalid' : ''}`}>
                                        <option value="Mr.">{t("ALL.Contact.SalutationMR")}</option>
                                        <option value="Mrs.">{t("ALL.Contact.SalutationMRS")}</option>
                                        <option value="Dr. Med">{t("ALL.Contact.SalutationDrMed")}</option>
                                        <option value="Prof. Dr. Med">{t("ALL.Contact.SalutationProfDrMed")}</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.salutation?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.Firstname")}</label>
                                <div className="col-sm-6">
                                    <input name="name" type="text" ref={register} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.Lastname")}</label>
                                <div className="col-sm-6">
                                    <input name="surname" type="text" ref={register} className={`form-control ${errors.surname ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.surname?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.Phone")}</label>
                                <div className="col-sm-6">
                                    <input name="phone" type="text" ref={register} className={`form-control ${errors.phone ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.phone?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.Role")}</label>
                                <div className="col-sm-6">
                                    <select name="role" ref={register} className={`form-control ${errors.role ? 'is-invalid' : ''}`} disabled={authUserData.currUserData.roleType !== "Admin"}>
                                        <option value="Doctor">Doctor</option>
                                        <option value="Client">Client</option>
                                        <option value="Employeer">Employeer</option>
                                        <option value="CaseManager">CaseManager</option>
                                        <option value="Insurer">Insurer</option>
                                        <option value="Administrator">Administrator</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.role?.message}</div>
                                </div>
                            </div>
                            {contactItem && contactItem.role !== "Doctor" && <>
                                <div className="form-group row ">
                                    <label className="col-sm-4 col-form-label">{t("ALL.Contact.InsurerAssignment")}</label>
                                    <div className="col-sm-6">
                                        <select name="insurerId" ref={register} className={`form-control ${errors.insurerId ? 'is-invalid' : ''}`}
                                            disabled={authUserData.currUserData.roleType !== "Admin" || (contactItem && authUserData.currUserData.roleType === "Admin" && contactItem!.isAdGroupMember)}>

                                            {insurers! && insurers.map((item) =>
                                                <option key={item.id} value={item.id} selected={item.id===contactItem.insurerId}>{item.insurerPrefix}</option>
                                            )}
                                        </select>
                                        <div className="invalid-feedback">{errors.insurerId?.message}</div>
                                    </div>
                                    <FontAwesomeIcon icon={faInfoCircle} id="TooltipInsurerChange" className="info-icon" />
                                    <ToolTip infoText={t("ALL.Contact.InsurerChange")} target="TooltipInsurerChange" />

                                </div>
                            </>
                            }
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.EMail")}</label>
                                <div className="col-sm-6">
                                    <input name="email" type="text" ref={register} className={`form-control ${errors.email ? 'is-invalid' : ''}`} disabled />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                            </div>
                            <div className={`form-group row ${(contactItem && contactItem!.role !== "Client") ? 'is-hidden' : ''}`}>
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.EMailNotification")}</label>
                                <div className="col-sm-6">
                                    <input name="secondEmail" type="text" ref={register} className={`form-control ${errors.secondEmail ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.secondEmail?.message}</div>
                                </div>
                            </div>
                            {/* <div className={`form-group row ${(contactItem && contactItem!.role !== "Client") ? 'is-hidden' : ''}`}> */}
                            <div className="form-group row">

                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.Company")}</label>
                                <div className="col-sm-6">
                                    <input name="companyName" type="text" ref={register} className={`form-control ${errors.companyName ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.companyName?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.Street")}</label>
                                <div className="col-sm-6">
                                    <input name="street" type="text" ref={register} className={`form-control ${errors.street ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.street?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.PostCode")}</label>
                                <div className="col-sm-6">
                                    <input name="postCode" type="text" ref={register} className={`form-control ${errors.postCode ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.postCode?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.City")}</label>
                                <div className="col-sm-6">
                                    <input name="city" type="text" ref={register} className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.city?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.Country")}</label>
                                <div className="col-sm-6">
                                    <select name="country" ref={register} className={`form-control ${errors.defaultLanguage ? 'is-invalid' : ''}`}>
                                        <option value="Switzerland">{t("ALL.Country.Switzerland")}</option>
                                        <option value="Germany">{t("ALL.Country.Germany")}</option>
                                        <option value="France">{t("ALL.Country.France")}</option>
                                        <option value="Italy">{t("ALL.Country.Italy")}</option>
                                        <option value="Bahamas">{t("ALL.Country.Bahamas")}</option>
                                        <option value="Bahrain">{t("ALL.Country.Bahrain")}</option>
                                        <option value="Bangladesh">{t("ALL.Country.Bangladesh")}</option>
                                        <option value="Barbados">{t("ALL.Country.Barbados")}</option>
                                        <option value="Belarus">{t("ALL.Country.Belarus")}</option>
                                        <option value="Belgium">{t("ALL.Country.Belgium")}</option>
                                        <option value="Belize">{t("ALL.Country.Belize")}</option>
                                        <option value="Benin">{t("ALL.Country.Benin")}</option>
                                        <option value="Bermuda">{t("ALL.Country.Bermuda")}</option>
                                        <option value="Bhutan">{t("ALL.Country.Bhutan")}</option>
                                        <option value="Bolivia">{t("ALL.Country.Bolivia")}</option>
                                        <option value="BosniaandHerzegovina">{t("ALL.Country.BosniaandHerzegovina")}</option>
                                        <option value="Botswana">{t("ALL.Country.Botswana")}</option>
                                        <option value="Brazil">{t("ALL.Country.Brazil")}</option>
                                        <option value="BruneiDarussalam">{t("ALL.Country.BruneiDarussalam")}</option>
                                        <option value="Bulgaria">{t("ALL.Country.Bulgaria")}</option>
                                        <option value="BurkinaFaso">{t("ALL.Country.BurkinaFaso")}</option>
                                        <option value="Burundi">{t("ALL.Country.Burundi")}</option>
                                        <option value="Cambodia">{t("ALL.Country.Cambodia")}</option>
                                        <option value="Cameroon">{t("ALL.Country.Cameroon")}</option>
                                        <option value="Canada">{t("ALL.Country.Canada")}</option>
                                        <option value="CapeVerde">{t("ALL.Country.CapeVerde")}</option>
                                        <option value="CaymanIslands">{t("ALL.Country.CaymanIslands")}</option>
                                        <option value="CentralAfricanRepublic">{t("ALL.Country.CentralAfricanRepublic")}</option>
                                        <option value="Chad">{t("ALL.Country.Chad")}</option>
                                        <option value="Chile">{t("ALL.Country.Chile")}</option>
                                        <option value="China">{t("ALL.Country.China")}</option>
                                        <option value="China-HongKong/Macau">{t("ALL.Country.China-HongKong/Macau")}</option>
                                        <option value="Colombia">{t("ALL.Country.Colombia")}</option>
                                        <option value="Comoros">{t("ALL.Country.Comoros")}</option>
                                        <option value="Congo">{t("ALL.Country.Congo")}</option>
                                        <option value="Congo,DemocraticRepublicof(DRC)">{t("ALL.Country.Congo,DemocraticRepublicof(DRC)")}</option>
                                        <option value="CostaRica">{t("ALL.Country.CostaRica")}</option>
                                        <option value="Croatia">{t("ALL.Country.Croatia")}</option>
                                        <option value="Cuba">{t("ALL.Country.Cuba")}</option>
                                        <option value="Cyprus">{t("ALL.Country.Cyprus")}</option>
                                        <option value="CzechRepublic">{t("ALL.Country.CzechRepublic")}</option>
                                        <option value="Denmark">{t("ALL.Country.Denmark")}</option>
                                        <option value="Djibouti">{t("ALL.Country.Djibouti")}</option>
                                        <option value="Dominica">{t("ALL.Country.Dominica")}</option>
                                        <option value="DominicanRepublic">{t("ALL.Country.DominicanRepublic")}</option>
                                        <option value="Ecuador">{t("ALL.Country.Ecuador")}</option>
                                        <option value="Egypt">{t("ALL.Country.Egypt")}</option>
                                        <option value="ElSalvador">{t("ALL.Country.ElSalvador")}</option>
                                        <option value="EquatorialGuinea">{t("ALL.Country.EquatorialGuinea")}</option>
                                        <option value="Eritrea">{t("ALL.Country.Eritrea")}</option>
                                        <option value="Estonia">{t("ALL.Country.Estonia")}</option>
                                        <option value="Eswatini">{t("ALL.Country.Eswatini")}</option>
                                        <option value="Ethiopia">{t("ALL.Country.Ethiopia")}</option>
                                        <option value="Fiji">{t("ALL.Country.Fiji")}</option>
                                        <option value="Finland">{t("ALL.Country.Finland")}</option>
                                        <option value="FrenchGuiana">{t("ALL.Country.FrenchGuiana")}</option>
                                        <option value="Gabon">{t("ALL.Country.Gabon")}</option>
                                        <option value="Gambia,RepublicofThe">{t("ALL.Country.Gambia,RepublicofThe")}</option>
                                        <option value="Georgia">{t("ALL.Country.Georgia")}</option>
                                        <option value="Ghana">{t("ALL.Country.Ghana")}</option>
                                        <option value="GreatBritain">{t("ALL.Country.GreatBritain")}</option>
                                        <option value="Greece">{t("ALL.Country.Greece")}</option>
                                        <option value="Grenada">{t("ALL.Country.Grenada")}</option>
                                        <option value="Guadeloupe">{t("ALL.Country.Guadeloupe")}</option>
                                        <option value="Guatemala">{t("ALL.Country.Guatemala")}</option>
                                        <option value="Guinea">{t("ALL.Country.Guinea")}</option>
                                        <option value="Guinea-Bissau">{t("ALL.Country.Guinea-Bissau")}</option>
                                        <option value="Guyana">{t("ALL.Country.Guyana")}</option>
                                        <option value="Haiti">{t("ALL.Country.Haiti")}</option>
                                        <option value="Honduras">{t("ALL.Country.Honduras")}</option>
                                        <option value="Hungary">{t("ALL.Country.Hungary")}</option>
                                        <option value="Iceland">{t("ALL.Country.Iceland")}</option>
                                        <option value="India">{t("ALL.Country.India")}</option>
                                        <option value="Indonesia">{t("ALL.Country.Indonesia")}</option>
                                        <option value="Iran">{t("ALL.Country.Iran")}</option>
                                        <option value="Iraq">{t("ALL.Country.Iraq")}</option>
                                        <option value="IsraelandtheOccupiedTerritories">{t("ALL.Country.IsraelandtheOccupiedTerritories")}</option>
                                        <option value="IvoryCoast(Coted'Ivoire)">{t("ALL.Country.IvoryCoast(Coted'Ivoire)")}</option>
                                        <option value="Jamaica">{t("ALL.Country.Jamaica")}</option>
                                        <option value="Japan">{t("ALL.Country.Japan")}</option>
                                        <option value="Jordan">{t("ALL.Country.Jordan")}</option>
                                        <option value="Kazakhstan">{t("ALL.Country.Kazakhstan")}</option>
                                        <option value="Kenya">{t("ALL.Country.Kenya")}</option>
                                        <option value="Korea,DemocraticRepublicof(NorthKorea)">{t("ALL.Country.Korea,DemocraticRepublicof(NorthKorea)")}</option>
                                        <option value="Korea,Republicof(SouthKorea)">{t("ALL.Country.Korea,Republicof(SouthKorea)")}</option>
                                        <option value="Kosovo">{t("ALL.Country.Kosovo")}</option>
                                        <option value="Kuwait">{t("ALL.Country.Kuwait")}</option>
                                        <option value="KyrgyzRepublic(Kyrgyzstan)">{t("ALL.Country.KyrgyzRepublic(Kyrgyzstan)")}</option>
                                        <option value="Laos">{t("ALL.Country.Laos")}</option>
                                        <option value="Latvia">{t("ALL.Country.Latvia")}</option>
                                        <option value="Lebanon">{t("ALL.Country.Lebanon")}</option>
                                        <option value="Lesotho">{t("ALL.Country.Lesotho")}</option>
                                        <option value="Liberia">{t("ALL.Country.Liberia")}</option>
                                        <option value="Libya">{t("ALL.Country.Libya")}</option>
                                        <option value="Liechtenstein">{t("ALL.Country.Liechtenstein")}</option>
                                        <option value="Lithuania">{t("ALL.Country.Lithuania")}</option>
                                        <option value="Luxembourg">{t("ALL.Country.Luxembourg")}</option>
                                        <option value="Madagascar">{t("ALL.Country.Madagascar")}</option>
                                        <option value="Malawi">{t("ALL.Country.Malawi")}</option>
                                        <option value="Malaysia">{t("ALL.Country.Malaysia")}</option>
                                        <option value="Maldives">{t("ALL.Country.Maldives")}</option>
                                        <option value="Mali">{t("ALL.Country.Mali")}</option>
                                        <option value="Malta">{t("ALL.Country.Malta")}</option>
                                        <option value="Martinique">{t("ALL.Country.Martinique")}</option>
                                        <option value="Mauritania">{t("ALL.Country.Mauritania")}</option>
                                        <option value="Mauritius">{t("ALL.Country.Mauritius")}</option>
                                        <option value="Mayotte">{t("ALL.Country.Mayotte")}</option>
                                        <option value="Mexico">{t("ALL.Country.Mexico")}</option>
                                        <option value="Moldova,Republicof">{t("ALL.Country.Moldova,Republicof")}</option>
                                        <option value="Monaco">{t("ALL.Country.Monaco")}</option>
                                        <option value="Mongolia">{t("ALL.Country.Mongolia")}</option>
                                        <option value="Montenegro">{t("ALL.Country.Montenegro")}</option>
                                        <option value="Montserrat">{t("ALL.Country.Montserrat")}</option>
                                        <option value="Morocco">{t("ALL.Country.Morocco")}</option>
                                        <option value="Mozambique">{t("ALL.Country.Mozambique")}</option>
                                        <option value="Myanmar/Burma">{t("ALL.Country.Myanmar/Burma")}</option>
                                        <option value="Namibia">{t("ALL.Country.Namibia")}</option>
                                        <option value="Nepal">{t("ALL.Country.Nepal")}</option>
                                        <option value="Netherlands">{t("ALL.Country.Netherlands")}</option>
                                        <option value="NewZealand">{t("ALL.Country.NewZealand")}</option>
                                        <option value="Nicaragua">{t("ALL.Country.Nicaragua")}</option>
                                        <option value="Niger">{t("ALL.Country.Niger")}</option>
                                        <option value="Nigeria">{t("ALL.Country.Nigeria")}</option>
                                        <option value="NorthMacedonia,Republicof">{t("ALL.Country.NorthMacedonia,Republicof")}</option>
                                        <option value="Norway">{t("ALL.Country.Norway")}</option>
                                        <option value="Oman">{t("ALL.Country.Oman")}</option>
                                        <option value="PacificIslands">{t("ALL.Country.PacificIslands")}</option>
                                        <option value="Pakistan">{t("ALL.Country.Pakistan")}</option>
                                        <option value="Panama">{t("ALL.Country.Panama")}</option>
                                        <option value="PapuaNewGuinea">{t("ALL.Country.PapuaNewGuinea")}</option>
                                        <option value="Paraguay">{t("ALL.Country.Paraguay")}</option>
                                        <option value="Peru">{t("ALL.Country.Peru")}</option>
                                        <option value="Philippines">{t("ALL.Country.Philippines")}</option>
                                        <option value="Poland">{t("ALL.Country.Poland")}</option>
                                        <option value="Portugal">{t("ALL.Country.Portugal")}</option>
                                        <option value="PuertoRico">{t("ALL.Country.PuertoRico")}</option>
                                        <option value="Qatar">{t("ALL.Country.Qatar")}</option>
                                        <option value="Reunion">{t("ALL.Country.Reunion")}</option>
                                        <option value="Romania">{t("ALL.Country.Romania")}</option>
                                        <option value="RussianFederation">{t("ALL.Country.RussianFederation")}</option>
                                        <option value="Rwanda">{t("ALL.Country.Rwanda")}</option>
                                        <option value="SaintKittsandNevis">{t("ALL.Country.SaintKittsandNevis")}</option>
                                        <option value="SaintLucia">{t("ALL.Country.SaintLucia")}</option>
                                        <option value="SaintVincentandtheGrenadines">{t("ALL.Country.SaintVincentandtheGrenadines")}</option>
                                        <option value="Samoa">{t("ALL.Country.Samoa")}</option>
                                        <option value="SaoTomeandPrincipe">{t("ALL.Country.SaoTomeandPrincipe")}</option>
                                        <option value="SaudiArabia">{t("ALL.Country.SaudiArabia")}</option>
                                        <option value="Senegal">{t("ALL.Country.Senegal")}</option>
                                        <option value="Serbia">{t("ALL.Country.Serbia")}</option>
                                        <option value="Seychelles">{t("ALL.Country.Seychelles")}</option>
                                        <option value="SierraLeone">{t("ALL.Country.SierraLeone")}</option>
                                        <option value="Singapore">{t("ALL.Country.Singapore")}</option>
                                        <option value="SlovakRepublic(Slovakia)">{t("ALL.Country.SlovakRepublic(Slovakia)")}</option>
                                        <option value="Slovenia">{t("ALL.Country.Slovenia")}</option>
                                        <option value="SolomonIslands">{t("ALL.Country.SolomonIslands")}</option>
                                        <option value="Somalia">{t("ALL.Country.Somalia")}</option>
                                        <option value="SouthAfrica">{t("ALL.Country.SouthAfrica")}</option>
                                        <option value="SouthSudan">{t("ALL.Country.SouthSudan")}</option>
                                        <option value="Spain">{t("ALL.Country.Spain")}</option>
                                        <option value="SriLanka">{t("ALL.Country.SriLanka")}</option>
                                        <option value="Sudan">{t("ALL.Country.Sudan")}</option>
                                        <option value="Suriname">{t("ALL.Country.Suriname")}</option>
                                        <option value="Sweden">{t("ALL.Country.Sweden")}</option>
                                        <option value="Syria">{t("ALL.Country.Syria")}</option>
                                        <option value="Tajikistan">{t("ALL.Country.Tajikistan")}</option>
                                        <option value="Tanzania">{t("ALL.Country.Tanzania")}</option>
                                        <option value="Thailand">{t("ALL.Country.Thailand")}</option>
                                        <option value="TimorLeste">{t("ALL.Country.TimorLeste")}</option>
                                        <option value="Togo">{t("ALL.Country.Togo")}</option>
                                        <option value="TrinidadandTobago">{t("ALL.Country.TrinidadandTobago")}</option>
                                        <option value="Tunisia">{t("ALL.Country.Tunisia")}</option>
                                        <option value="Turkey">{t("ALL.Country.Turkey")}</option>
                                        <option value="Turkmenistan">{t("ALL.Country.Turkmenistan")}</option>
                                        <option value="TurksandCaicosIslands">{t("ALL.Country.TurksandCaicosIslands")}</option>
                                        <option value="Uganda">{t("ALL.Country.Uganda")}</option>
                                        <option value="Ukraine">{t("ALL.Country.Ukraine")}</option>
                                        <option value="UnitedArabEmirates">{t("ALL.Country.UnitedArabEmirates")}</option>
                                        <option value="UnitedStatesofAmerica(USA)">{t("ALL.Country.UnitedStatesofAmerica(USA)")}</option>
                                        <option value="Uruguay">{t("ALL.Country.Uruguay")}</option>
                                        <option value="Uzbekistan">{t("ALL.Country.Uzbekistan")}</option>
                                        <option value="Venezuela">{t("ALL.Country.Venezuela")}</option>
                                        <option value="Vietnam">{t("ALL.Country.Vietnam")}</option>
                                        <option value="VirginIslands(UK)">{t("ALL.Country.VirginIslands(UK)")}</option>
                                        <option value="VirginIslands(US)">{t("ALL.Country.VirginIslands(US)")}</option>
                                        <option value="Yemen">{t("ALL.Country.Yemen")}</option>
                                        <option value="Zambia">{t("ALL.Country.Zambia")}</option>
                                        <option value="Zimbabwe">{t("ALL.Country.Zimbabwe")}</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.country?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t("ALL.Contact.ContactInfo")}</label>
                                <div className="col-sm-6">
                                    <textarea name="remarks" rows={5} ref={register} className={`form-control ${errors.remarks ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.remarks?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row justify-content-center">
                                <div className="form-group col-sm-4">
                                    <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                        {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        {t("ALL.Button.Save")}
                                    </button>
                                </div>
                                <div className="form-group col-sm-4">
                                    <button className="btn btn-grey" onClick={(e) => {
                                        e.preventDefault();
                                        if (closeModal === undefined) {
                                            navigate(-1);
                                        }
                                        else {
                                            closeModal();
                                        }

                                    }}>
                                        {t("ALL.Button.Cancel")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {contactItem?.email === authUserData.currUserData.profileEmail &&
                        <div className="col-sm-4">
                            <div className="row justify-content-md-center">
                                <div className="col-md-12 text-center">

                                    <ProfilePictureCustom pictureType="user" image={contactItem.profileImage} profileId={contactItem.id!} pictureFormat="circle" />

                                </div>
                            </div>
                            <hr></hr>
                        </div>
                    }
                </div>
            </div>
        }
    </>
    );
}
