import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faTrash } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment';
import { faFile } from '@fortawesome/pro-light-svg-icons'
import { useContext } from 'react';

interface IListDocumentsProps {
    downloadDoc: (id: number, fileName: string) => void,
    deleteDoc: (id: number) => void,
    documents?: Array<any>,
    documentType: string,
    role: string,
    tabKey:string
}
export const ListDocuments=({ role, documentType, deleteDoc, documents, downloadDoc,tabKey }: IListDocumentsProps)=> {
    const { t } = useContext(MultiLanguageSupportContext);
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                <div className="card-header card-header-primary header-top">
                        <h4><FontAwesomeIcon icon={faFile} /></h4>
                        </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="text-primary">
                                    <tr>
                                        <th style={{ width: '30%' }}>{t("ALL.DMS.DocDate")}</th>
                                        <th style={{ width: '30%' }}>{t("ALL.DMS.DocName")}</th>
                                        <th style={{ width: '10%' }}>{t("ALL.Button.Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents && documents.map(d => <>
                                        <tr key={`${tabKey}-${d.id}`}>
                                            <td>{moment(d.modified).format("L")}</td>
                                            <td>{d.documentName}</td>
                                            <td>
                                                <button onClick={() => downloadDoc(d.id, d.documentName)} className="btn btn-md btn-icon" disabled={d.isDownload}>
                                                    <FontAwesomeIcon icon={faFileDownload} /> {d.isDownload && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                </button>
                                                {(documentType === "Contact" || role === "CaseManager") &&
                                                    <button onClick={() => deleteDoc(d.id)} className="btn btn-md btn-icon-red" disabled={d.isDeleteing}>
                                                        <FontAwesomeIcon icon={faTrash} /> {d.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    </button>}
                                            </td>
                                        </tr>
                                    </>
                                    )}
                                    {!documents &&
                                        <tr>
                                            <td colSpan={4} className="text-center">
                                                <div className="spinner-border spinner-border-lg align-center"></div>
                                            </td>
                                        </tr>
                                    }
                                    {documents && !documents.length &&
                                        <tr>
                                            <td colSpan={4} className="text-center">
                                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
