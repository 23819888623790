import { useContext, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { alertService, notesService, notificationService } from '../../services/Index';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import Note from '../../models/Note';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faShieldAlt, faStethoscope, faUserInjured } from '@fortawesome/pro-light-svg-icons'
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import moment from 'moment';
interface IAddEditNoteProps {
    note?: any;
    close: any,
    edit: any,
    add: any,
    cleanSelectedNode: any
}
export const AddEditNote=({ cleanSelectedNode, add, note, close, edit }: IAddEditNoteProps) =>{
    const { authUserData } = useContext(LoggedUserDataContext);
    const { caseData } = useContext(CaseDataContext);
    const { t } = useContext(MultiLanguageSupportContext);
    const isAddMode: boolean = Object.entries(note).length === 0 //===undefined?true:false;
    useEffect(() => {
        if (!isAddMode) {
            setValue("noteTitle", note.noteTitle);
            setValue("noteText", note.noteText);

            setValue("sharedToCLN", note.recipients.includes("CLN") ? true : false);
            setValue("sharedToEMP", note.recipients.includes("EMP") ? true : false);
            setValue("sharedToDOC", note.recipients.includes("DOC") ? true : false);
            setValue("sharedToCAM", note.recipients.includes("CAM") ? true : false);
            /* Replace with same logic we have in NotificationSettingEdit Component but insted of DLL we have CB here
            setValue("sharedToCLN", note.sharedToCLN);
            setValue("sharedToEMP", note.sharedToEMP);
            setValue("sharedToDOC", note.sharedToDOC);
            setValue("sharedToCAM", note.sharedToCAM);*/
        }
    }, [note]);

    const validationSchema = Yup.object().shape({
        noteTitle: Yup.string().required(t("ALL.Notes.ValidateTitle")),
        noteText: Yup.string().required(t("ALL.Notes.ValidateMessage"))
    });

    const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            noteTitle: '',
            noteText: '',
            sharedToCLN: false,
            sharedToEMP: false,
            sharedToDOC: false,
            sharedToCAM: false,
        }
    });

    async function onSubmit(data: Note) {
        return isAddMode
            ? await createNote(data)
            : await updateNote(data);
    }

    async function createNote(data: Note) {
        var notificationRecipientsRole = new Array<string>();
        let recipientsArr: any[] = [];

        if(getValues("sharedToCLN")) recipientsArr.push("CLN");
        if(getValues("sharedToDOC")) recipientsArr.push("DOC");
        if(getValues("sharedToEMP")) recipientsArr.push("EMP");
        if(getValues("sharedToCAM")) recipientsArr.push("CAM");
        /*/* Replace with same logic we have in NotificationSettingEdit Component but insted of DLL we have CB here
        if (data.sharedToCAM) notificationRecipientsRole.push("CaseManager");
        if (data.sharedToEMP) notificationRecipientsRole.push("Employeer");
        if (data.sharedToDOC) notificationRecipientsRole.push("Doctor");
        if (data.sharedToCLN) notificationRecipientsRole.push("Client");*/
        data.caseId = caseData.selectedCaseId!;
        data.createdBy = authUserData.currUserData.profileId;
        data.noteDate = new Date();
        data.recipients = recipientsArr;
        delete(data["sharedToCAM"]);
        delete(data["sharedToDOC"]);
        delete(data["sharedToEMP"]);
        delete(data["sharedToCLN"]);

        cleanSelectedNode({});
        await  notesService.postNewNote(data)
            .then((rp) => {
                alertService.success(t("ALL.Notes.Added"), { keepAfterRouteChange: true });
                add(rp.Data);
                reset();
                close(false);
                notificationService.postNewNotifications(
                    {
                        caseId: caseData.selectedCaseId,
                        senderContactId: authUserData.currUserData.profileId,
                        notificationStatus: "New",
                        notificationTitle: t("ALL.Notifications.TitleNote"),
                        notificationMessage: t("ALL.Notifications.MessageNote"),
                        notificationItemUrl: `view-note/${rp.Data.id}`,
                        notificationDate: moment(),
                        notificationSettingDictionaryId: 15,
                        scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: caseData.insurerId }, { scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId! }],
                        templateId: 615



                    })
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }
            );
    }
    async function updateNote(data: Note) {
        data.id = note.id;
        data.modifiedBy = authUserData.currUserData.profileId;
        let recipientsArr: any[] = [];

        if(getValues("sharedToCLN")) recipientsArr.push("CLN");
        if(getValues("sharedToDOC")) recipientsArr.push("DOC");
        if(getValues("sharedToEMP")) recipientsArr.push("EMP");
        if(getValues("sharedToCAM")) recipientsArr.push("CAM");
        data.recipients = recipientsArr;
        delete(data["sharedToCAM"]);
        delete(data["sharedToDOC"]);
        delete(data["sharedToEMP"]);
        delete(data["sharedToCLN"]);

        cleanSelectedNode({});
       await  notesService.postUpdateNote(data)
            .then((rp) => {
                alertService.success(t("ALL.Notes.Updated"), { keepAfterRouteChange: true });
                edit(rp);
                reset();
                close(false);
                notificationService.postNewNotifications(
                    {
                        caseId: caseData.selectedCaseId,
                        senderContactId: authUserData.currUserData.profileId,
                        notificationStatus: "New",
                        notificationTitle: t("ALL.Notifications.TitleNote"),
                        notificationMessage: t("ALL.Notifications.MessageNote"),
                        notificationItemUrl: `view-note/${rp.id}`,
                        notificationDate: moment(),
                        notificationSettingDictionaryId: 15,
                        scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: caseData.insurerId }, { scopeTypeId: 2, scopeEntityId: caseData.selectedCaseId! }],
                        templateId: 615



                    })
            })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    reset();
                    close(false);
                }

            );
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <h4 className="card-title">{isAddMode ? t("ALL.Notes.Add") : t("ALL.Notes.Edit")}</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Notes.Title")}</label>
                                        <input name="noteTitle" type="text" ref={register} className={`form-control ${errors.noteTitle ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.noteTitle?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-8">
                                        <label>{t("ALL.Notes.Text")}*</label>
                                        <textarea name="noteText" rows={5} ref={register} className={`form-control ${errors.noteText ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.noteText?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className='legend'>
                                        <div className='legend-title'>{t("ALL.Notes.SharedWith")}</div>
                                        <div className='legend-scale'>
                                            <ul className='legend-labels'>
                                                {authUserData.currUserData.roleType !== "Client" &&
                                                    <li>
                                                        <span className="btn-sm btn-icon-cln"><FontAwesomeIcon icon={faUserInjured} /></span>
                                                        <input name="sharedToCLN" type="checkbox" onChange={(event) => { setValue("sharedToCLN", event.target.checked) }} ref={register} className={`${errors.sharedToCLN ? 'is-invalid' : ''}`} />
                                                        <label className="form-check-label">{t("ALL.Notes.SharedWithCLN")}</label>
                                                    </li>
                                                }
                                                {authUserData.currUserData.roleType !== "Doctor" &&
                                                    <li>
                                                        <span className="btn-sm btn-icon-doc"><FontAwesomeIcon icon={faStethoscope} /></span>
                                                        <input name="sharedToDOC" type="checkbox" onChange={(event) => { setValue("sharedToDOC", event.target.checked) }} ref={register} className={`${errors.sharedToDOC ? 'is-invalid' : ''}`} />
                                                        <label className="form-check-label">{t("ALL.Notes.SharedWithDOC")}</label>
                                                    </li>}
                                                {authUserData.currUserData.roleType !== "CaseManager" &&
                                                    <li>
                                                        <span className="btn-sm btn-icon-cam"><FontAwesomeIcon icon={faShieldAlt} /></span>
                                                        <input name="sharedToCAM" type="checkbox" onChange={(event) => { setValue("sharedToCAM", event.target.checked) }} ref={register} className={`${errors.sharedToCAM ? 'is-invalid' : ''}`} />
                                                        <label className="form-check-label">{t("ALL.Notes.SharedWithCAM")}</label>
                                                    </li>}
                                                {authUserData.currUserData.roleType !== "Employeer" &&
                                                    <li>
                                                        <span className="btn-sm btn-icon-emp"><FontAwesomeIcon icon={faIndustry} /></span>
                                                        <input name="sharedToEMP" type="checkbox" onChange={(event) => { setValue("sharedToEMP", event.target.checked) }} ref={register} className={`${errors.sharedToEMP ? 'is-invalid' : ''}`} />
                                                        <label className="form-check-label">{t("ALL.Notes.SharedWithEMP")}</label>
                                                    </li>}

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group row justify-content-center">
                                    <div className="form-group col-sm-4">
                                        <button type="submit" disabled={formState.isSubmitting} className="btn btn-action">
                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            {t("ALL.Button.Save")}
                                        </button>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <button className="btn btn-grey" onClick={(event) => {
                                            event.preventDefault();
                                            reset();
                                            cleanSelectedNode({});
                                            close(false)
                                        }}>
                                            {t("ALL.Button.Cancel")}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
