import i18next from 'i18next';
import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from "../constants/local-storage.keys";
import { defaultLanguage } from "../constants/supported-languages";
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
const storedLangValue = localStorage.getItem(LocalStorageKeys.appLang);

interface MultiLanguageSupportProps {
    locale: Locale;
    language: string;
    mask:string;
    setLanguage: (lang: string) => void;
    t: (key: string, ...args: any) => string;
}
const maskMap = {
    en: '__/__/____',
    de: '__.__.____',
};
const lokaleMap={
    en:enLocale,
    de:deLocale
}
const MultiLanguageSupportContext = createContext<MultiLanguageSupportProps>({
    locale: lokaleMap[storedLangValue!] || lokaleMap[defaultLanguage!],
    language: storedLangValue || defaultLanguage,
    mask:maskMap[storedLangValue!] || maskMap[defaultLanguage!],
    setLanguage: (lang: string) => console.log(lang),
    t: () => '',
});
type Props = {
    children?: ReactNode
};
const MultiLanguageSupportProvider: FC<Props> = ({ children }) => {
    const [language, setLanguage] = useState(
        storedLangValue || defaultLanguage
    );
    const setLocale = (param: string) => {
        switch (param) {
            case 'en':
                return enLocale;
            case 'de':
                return deLocale;
            default:
                return enLocale;
        }
    }
    const locale=setLocale(language);
    const [mask, setLMask] = useState(
        storedLangValue || defaultLanguage
    );
    const { t } = useTranslation();

    useEffect(() => {
        setLanguage(language);
        i18next.changeLanguage(language);
        localStorage.setItem(LocalStorageKeys.appLang, language);
    }, [language]);

    return (
        <MultiLanguageSupportContext.Provider
            value={{ language, setLanguage, t, locale, mask }}
        >
            {children}
        </MultiLanguageSupportContext.Provider>
    );
};

export { MultiLanguageSupportProvider, MultiLanguageSupportContext };
