import { faCalendarAlt, faFileDownload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { DateRangePicker } from "react-date-range"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FormControlLabel, Switch } from '@mui/material';
interface IProps {
    calendarState: any;
    changeDateRange: any;
    isLoading: boolean;
    generateReport: any
}

export const StandardReportHeader = ({ calendarState, changeDateRange, isLoading, generateReport }: IProps) => {
    const { t, locale} = useContext(MultiLanguageSupportContext);
    const [calendarIsShown, setCalendarVisibility] = useState<boolean>(false);

    const [checked, setChecked] = useState(false);
    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };
    return (<>
        <div className="row report-card" style={{ paddingTop: "10px",paddingBottom: "10px" }}>
            <div className="col-sm-12 text-right">
                <FormControlLabel
                    control={<Switch size="small" checked={checked} onChange={toggleChecked} color="primary" />}
                    label={t("ALL.Common.FormatLandscape")} />
                <button className="btn btn-sm btn-icon-marine report-icon" onClick={() => generateReport(checked)} disabled={isLoading}><FontAwesomeIcon icon={faFileDownload} /> {t("ALL.Button.DownloadReport")}
                    {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                </button>
                {/*<button className="btn btn-calendar" onClick={() => setCalendarVisibility(!calendarIsShown)}><FontAwesomeIcon icon={faCalendarAlt} /></button>*/}
            </div>
        </div>
        <Modal isOpen={calendarIsShown} style={{ maxWidth: "700px" }}>
            <ModalBody>
                <DateRangePicker
                    onChange={changeDateRange}
                    ////showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={calendarState}
                    direction="horizontal"
                    locale={locale}
                />
            </ModalBody>
            <ModalFooter>
                <button onClick={() => setCalendarVisibility(!calendarIsShown)} className="btn" style={{ color: "#fff", margin: "0 auto", backgroundColor: "#091C53" }}>
                    {t("ALL.Button.Close")}
                </button>
            </ModalFooter>
        </Modal>
    </>

    );
}