export default class LogedUserProps {

    public static graphToken:any;
    public static apiToken:any;
    // public static currUserData:LogedUserData;
    // public static currUserGroups:Array<string>;
    // public static selectedCaseId:number;
    // public static referenceNumberOfInsurance:string;
    // public static manageUserInfoControl:any;
    // public static manageLeftNav:any;
    // public static manageTopNavInsurerPicture:any;
    // public static manageTopNavProfilePicture:any
    // public static manageUserNotifications:any;
  
}