import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { AppAccessContext } from '../ctx/AppAccessProvider ';
import { LoggedUserDataContext } from '../ctx/LoggedUserDataProvider';


export const PrivateRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { authUserData } = useContext(LoggedUserDataContext);
  const { accessDetails, setAppAccess } = useContext(AppAccessContext);
  const { pathname } = useLocation();
  if (isAuthenticated && authUserData.currUserData !== undefined && pathname.indexOf(authUserData.currUserData.roleType.toLowerCase()) > -1) {
    if (accessDetails.hasAccessToPath) {
      return children
    }
    else {
      setAppAccess({
        hasAccessToPath: true,
      });
      return <Navigate to={`/${authUserData.currUserData.roleType.toLowerCase()}`} />
    }
  }
  return <Navigate to="/" />
}