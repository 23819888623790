export const adminTheme = {
    body: '#e2e2e2',
    text: '#363537',
    colors:{
      gradiendStart:'#F6DEDA',
      gradiendEnd:'#C04331',
      actionButton:'#C04331',
      sideBar:'#F2CFCA',
      boxShadow: "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(194, 194, 193 / 40%)",
      tabColor:'#F3BE9B',
      tabBGColor:'#76ABBC'
    }
  }
  
  export const insurerTheme = {
    body: '#363537',
    text: '#FAFAFA',
    colors:{
      gradiendStart:'#FCF9E8',
      gradiendEnd:'#EDD250',
      actionButton:'#EDD250',
      sideBar:'#F6EAAC',
      boxShadow: "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(242 207 202 / 40%)",
      tabColor:'#F3BE9B',
      tabBGColor:'#76ABBC'
    }
  }
  export const caseManagerTheme = {
    body: '#363537',
    text: '#FAFAFA',
    colors:{
      gradiendStart:'#D7D5D5',
      gradiendEnd:'#7F7E7C',
      actionButton:'#7F7E7C',
      sideBar:'#C2C2C1',
      boxShadow: "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(194 194 193 / 40%)",
      tabColor:'#17191A',
      tabBGColor:'#D7D5D5'
    }
  }
  export const clientTheme = {
    body: '#363537',
    text: '#FAFAFA',
    colors:{
      gradiendStart:'#FEF0E7',
      sideBar:'#F9E2D2',
      boxShadow: "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(249 226 210 / 40%)",
      gradiendEnd:'#E97932',
      actionButton:'#E97932',
      tabColor:'#17191A',
      tabBGColor:'#F3BE9B'
    }
  }
  export const employeerTheme = {
    body: '#363537',
    text: '#FAFAFA',
    colors:{
      gradiendStart:'#EEF5F6',
      sideBar:'#D3E4E8',
      boxShadow: "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(211 228 232 / 40%)",
      gradiendEnd:'#76ABBC',
      actionButton:'#76ABBC',
      tabColor:'#17191A',
      tabBGColor:'#E1EDEF'
    }
  }
  export const doctorTheme = {
    body: '#363537',
    text: '#FAFAFA',
    colors:{
      gradiendStart:'#F6F8ED',
      gradiendEnd:'#B0C15B',
      actionButton:'#B0C15B',
      sideBar:'#E2E9C4',
      boxShadow: "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(26 233 196 / 40%)",
      tabColor:'#17191A',
      tabBGColor:'#E2E9C4'
    }
  }
  export const defaultTheme = {
    body: '#363537',
    text: '#FAFAFA',
    colors:{
      gradiendStart:'#F6F8ED',
      gradiendEnd:'#B0C15B',
      actionButton:'#B0C15B',
      sideBar:'#E2E9C4',
      boxShadow: "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(26 233 196 / 40%)",
      tabColor:'#F3BE9B',
      tabBGColor:'#76ABBC'
    }
  }