import { useContext, useState } from 'react';
import logo from '../../assets/images/logo.png';
import {
  Card, CardText, CardBody, CardLink,
  Modal, ModalBody, CardImg, ModalHeader
} from 'reactstrap';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { AppSettingsContext } from '../../ctx/AppSettingsProvider';

const CaseDeskInfo = () => {
  const { appSettings } = useContext(AppSettingsContext);
  const { authUserData } = useContext(LoggedUserDataContext);
  const termsSiteUrlKey = `TermsConditions${authUserData.currUserData.profileLanguage.toUpperCase()}`
  const siteUrlKey = `SiteUrl${authUserData.currUserData.profileLanguage.toUpperCase()}`
  const docUrlKey = `DocUrl${authUserData.currUserData.profileLanguage.toUpperCase()}`
  const [modalIsOpen, setModalOpen] = useState(false);
  const toggle = () => setModalOpen(!modalIsOpen);
  const { t } = useContext(MultiLanguageSupportContext);

  return (<>
    {appSettings.length > 0 && <>
      <div className="center"><span onClick={toggle} className="btn btn-text" style={{ textDecoration: "underline" }}>
        {t("ALL.About.Menu")}</span>
      </div>
      <Modal isOpen={modalIsOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("ALL.About.Title")}</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <CardImg top src={logo} alt="Card image cap" />
              <CardText>{appSettings.filter(s => s.key === "CaseDeskVersion")[0].valueText}</CardText>
              <CardText>{appSettings.filter(s => s.key === "CaseDeskVersionPublishDate")[0].valueText}</CardText>
              <CardText><CardLink href={`${appSettings.filter(s => s.key === siteUrlKey).length === 0 ? "SiteUrlEN" : appSettings.filter(s => s.key === siteUrlKey)[0].valueText}`}>{t("ALL.About.UrlWebSite")}</CardLink></CardText>
              <CardText><CardLink href={`${appSettings.filter(s => s.key === termsSiteUrlKey).length === 0 ? "TermsConditionsEN" : appSettings.filter(s => s.key === termsSiteUrlKey)[0].valueText}`} >{t("ALL.About.UrlTerms")}</CardLink></CardText>
              <CardText><CardLink href={`${appSettings.filter(s => s.key === docUrlKey).length === 0 ? "DocUrlEN" : appSettings.filter(s => s.key === docUrlKey)[0].valueText}`} >{t("ALL.About.UrlHelp")}</CardLink></CardText>            
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
    }
  </>
  );

}

export { CaseDeskInfo };
