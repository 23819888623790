import { useContext, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

import { IListTasksProps, ListTasks } from './ListTasks';

import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { PaperProps } from "./TaskUtils";
import moment from 'moment';
import TaskIcon from '@mui/icons-material/Task';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Settings from '@mui/icons-material/Settings';
import { ListTaskTemplates } from './ListTaskTemplates';
import Grid from '@mui/material/Grid';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';

import { TaskGeneratorForm } from './TaskGeneratorForm';


export const TasksManagement = ({ addMultipleTasks, data, editTask, addTask, deleteTask, taskTagsOptions, casePhaseOptions, taskPlanOptions, loading,taskTableOnly }) => {
    const [templateModalOpen, setTemplateModalOpen] = useState(false);
    const [generatorModalOpen, setGeneratorModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { language, t } = useContext(MultiLanguageSupportContext);
    moment.locale(language);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const listTasksProps: IListTasksProps = {
        deleteTask: deleteTask,
        add: addTask,
        edit: editTask,
        tasks: data!,
        taskTagsOptions: taskTagsOptions,
        casePhaseOptions: casePhaseOptions,
        taskPlanOptions: taskPlanOptions,
        addMultipleTasks: addMultipleTasks
    }
    return (<div>
        {!data && <div className="loader">{t("ALL.Common.Loading")}</div>}
        {data &&
            <div className="container">
                {taskTableOnly===false && <div>
                <div style={{ marginTop: '2%' }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={5} lg={5}>
                            <p className="component-header">{t("ALL.Tasks.Title")}</p>
                        </Grid>
                        <Grid item xs={12} md={3} lg={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Tooltip title={t("ALL.Tasks.Templates.Title")}>
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Settings fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={PaperProps}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                                <MenuItem onClick={() => { setTemplateModalOpen(true) }}>
                                    <ListItemIcon>
                                        <TaskIcon fontSize="small" />
                                    </ListItemIcon>
                                    {t("ALL.Tasks.Templates.Manage")}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setGeneratorModalOpen(true) }}>
                                    <ListItemIcon>
                                        <AddTaskIcon fontSize="small" />
                                    </ListItemIcon>
                                    {t("ALL.Tasks.Generator.Generate")}
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </div>
                <hr></hr>
                </div>
                    }
                <Box sx={{ width: '100%' }}>
                    <ListTasks {...listTasksProps} />
                </Box>
                <Modal toggle={() => { setTemplateModalOpen(!templateModalOpen) }} isOpen={templateModalOpen} style={{ maxWidth: "1000px" }}>
                    <ModalHeader style={{ display: "block" }}>
                        {t("ALL.Tasks.Templates.Title")}
                        <span style={{ justifyContent: "right" }}>
                            <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-right" onClick={() => { setTemplateModalOpen(!templateModalOpen) }}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </button>
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <ListTaskTemplates taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions} />
                    </ModalBody>
                </Modal>
                <Modal toggle={() => { setGeneratorModalOpen(!generatorModalOpen) }} isOpen={generatorModalOpen} style={{ maxWidth: "1000px" }}>
                    <ModalHeader style={{ display: "block" }}>
                        {t("ALL.Tasks.Generator.Title")}
                        <span style={{ justifyContent: "right" }}>
                            <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-right" onClick={() => { setGeneratorModalOpen(!generatorModalOpen) }}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </button>
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <TaskGeneratorForm
                            close={setGeneratorModalOpen}
                            addMultipleTasks={addMultipleTasks}
                            {...listTasksProps}
                        />
                    </ModalBody>
                </Modal>

            </div>
        }</div>
    );
}
