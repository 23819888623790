import { createContext,FC, ReactNode, useEffect, useState } from 'react';
import { defaultSettings, ISettings, ISettingsProps } from '../interfaces/IAppSettings';

const AppSettingsContext = createContext<ISettingsProps>({
    appSettings: defaultSettings,
    setAppSettings: (settings: ISettings[]) => console.log(settings),

});
type Props = {
    children?: ReactNode
};
const AppSettingsProvider:FC<Props> = ({children}) => {
    const [appSettings, setAppSettings] = useState(defaultSettings);

    useEffect(() => {
        setAppSettings(appSettings);
    }, [appSettings]);

    return (
        <AppSettingsContext.Provider value={{ appSettings, setAppSettings}}>
            {children}
        </AppSettingsContext.Provider>
    );
};

export { AppSettingsProvider, AppSettingsContext };