import { useContext, useEffect, useState } from 'react';

import { useForm } from "react-hook-form";
import "@fortawesome/fontawesome-svg-core/styles.css";
import 'react-form-builder2/dist/app.css';
import { adminService, alertService, caseManagerService } from '../../services/Index';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CaseBookingSetting, { ICaseBookingSettingViewModel } from '../../models/CaseBookingSetting'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ToolTip } from '../../components/ToolTip';
import { helperFunctions } from '../../helpers/HelperFunctions';
interface IAddEditCaseBookingsProps {
    caseId: number
}
function AddEditCaseBookingSettins({ caseId }: IAddEditCaseBookingsProps) {
    const { authUserData } = useContext(LoggedUserDataContext);
    const [isAddMode, setIsAddMode] = useState<boolean>(true);
    const [bookingSettingData, setBookingSettingData] = useState<CaseBookingSetting>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [isLoading, setLoading] = useState<any>(true);
    const [budgetType, setBudgetType] = useState<number>(0);
    const [taxTagsOptions, setTaxTagsOptions] = useState<any[]>([]);

    useEffect(() => {
        adminService.getScopedDictionaries(6, [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId }], authUserData.currUserData.profileLanguage.toUpperCase()).then((tagDicResponse) => {
            setTaxTagsOptions(helperFunctions.getUniqueDictionaryPerLanguage(tagDicResponse, "dicGuid", authUserData.currUserData.profileLanguage.toUpperCase()));

            caseManagerService.getSingleCaseBookingSetting(caseId).then(data => {
                if (data) {
                    setValue("id", data.id);
                    setValue("defaultHourlyRate", data.defaultHourlyRate);
                    setValue("defaultPricePerDistance", data.defaultPricePerDistance);
                    setValue("sumOfAmounts", data.sumOfAmounts);
                    setValue("sumOfTaxes", data.sumOfTaxes);
                    setValue("caseId", data.caseId);
                    setValue("defaultBookingTimeMinutes", data.defaultBookingTimeMinutes);
                    setValue("defaultCurrency", data.defaultCurrency);
                    setValue("defaultDistanceUnit", data.defaultDistanceUnit);
                    setValue("defaultTaxTag", data.defaultTaxTag);
                    setValue("budgetType", data.budgetType);
                    setValue("budgetAmount", data.budgetAmount);
                    setValue("budgetAmountAlert", data.budgetAmountAlert);
                    setValue("budgetHours", data.budgetHours);
                    setValue("budgetHoursAlert", data.budgetHoursAlert);
                    setValue("sumOfHoursTotal", data.sumOfHoursTotal);
                    setValue("sumOfHoursCharged", data.sumOfHoursCharged);

                    setBookingSettingData(data);
                    setBudgetType(data.budgetType);
                    setIsAddMode(false);
                }

            }).catch(
                (rp) => {
                    setIsAddMode(true);
                    //alertService.error(t(rp.message), { keepAfterRouteChange: true })
                }
            );
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
                setLoading(false);
            }
        );
    }, [caseId]);

    const handleBudgetTypeChange = (event) => {
        const value = event.target.value;
        setBudgetType(parseInt(value));
    };
    const validationSchema = Yup.object().shape({
        defaultHourlyRate: Yup.number()
            .min(
                1,
                t("ALL.Bookings.Validation.HourlyRate")
            )
            .typeError("ALL.Bookings.Validation.HourlyRateError")
            .required(t("ALL.Bookings.Validation.HourlyRateError")),
        budgetAmount: Yup.number().when("budgetType", {
            is: "0" || "2",
            then: Yup.number()
                .min(
                    1,
                    t("ALL.Bookings.Validation.BudgetAmount")
                )
                .typeError("ALL.Bookings.Validation.BudgetAmountError")
                .required(t("ALL.Bookings.Validation.BudgetAmountError"))

        }),
        budgetAmountAlert: Yup.number().when("budgetType", {
            is: "0" || "2",
            then: Yup.number()
                .min(
                    1,
                    t("ALL.Bookings.Validation.AlertAmount")
                )
                .typeError("ALL.Bookings.Validation.AlertAmountError")
                .required(t("ALL.Bookings.Validation.AlertAmountError"))

        }),
        budgetHours: Yup.number().when("budgetType", {
            is: "1" || "2",
            then: Yup.number()
                .min(
                    1,
                    t("ALL.Bookings.Validation.BudgetHours")
                )
                .typeError("ALL.Bookings.Validation.BudgetHoursError")
                .required(t("ALL.Bookings.Validation.BudgetHoursError"))

        }),
        budgetHoursAlert: Yup.number().when("budgetType", {
            is: "1" || "2",
            then: Yup.number()
                .min(
                    1,
                    t("ALL.Bookings.Validation.AlertHours")
                )
                .typeError("ALL.Bookings.Validation.AlertHoursError")
                .required(t("ALL.Bookings.Validation.AlertHours"))

        }),
        defaultBookingTimeMinutes: Yup.number()
            .required(t("ALL.Bookings.Settings.DefaultBookingTimeMinutes")),
        defaultCurrency: Yup.number()
            .required(t("ALL.Bookings.Settings.DefaultCurrency")),
        defaultDistanceUnit: Yup.number()
            .required(t("ALL.Bookings.Settings.DefaultDistanceUnit")),
        defaultTaxTag: Yup.string()
            .required(t("ALL.Bookings.Settings.DefaultTax")),
        defaultPricePerDistance: Yup.number()
            .typeError("ALL.Bookings.Validation.DefaultPricePerDistance")
            .required(t("ALL.Bookings.Settings.DefaultPricePerDistance")),

    });
    const { register, handleSubmit, reset, control, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            sumOfHoursTotal: 0,
            sumOfHoursCharged: 0,
            limitationRespected: 0,
            sumOfAmounts: 0,
            sumOfTaxes: 0,
            id: undefined,
            caseId: caseId,
            defaultCurrency: undefined,
            defaultTaxTag: undefined,
            defaultHourlyRate: undefined,
            defaultPricePerDistance: undefined,
            defaultDistanceUnit: undefined,
            defaultBookingTimeMinutes: undefined,
            budgetType: undefined,
            budgetAmount: 0,
            budgetAmountAlert: 0,
            budgetHours: 0,
            budgetHoursAlert: 0,
        }
    });

    function onSubmit(data: ICaseBookingSettingViewModel) {
        return !isAddMode
            ? updateCaseBookingSetting(data)
            : createCaseBookingSetting(data)

    }

    function createCaseBookingSetting(data: ICaseBookingSettingViewModel) {
        data.caseId = caseId;
        data.createdBy = { id: authUserData.currUserData.contactId!, userActveDirectoryId: authUserData.currUserData.profileId };
        return caseManagerService.postNewCaseBookingSetting(data)
            .then((rp) => {
                alertService.success(t("ALL.Booking.Messages.CreatedSettings"), { keepAfterRouteChange: true });
                setBookingSettingData(rp.Data);
                setIsAddMode(false);
            })
            .catch((rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }

    function updateCaseBookingSetting(data: ICaseBookingSettingViewModel) {
        data.id = bookingSettingData!.id!;
        data.caseId = bookingSettingData!.caseId!;
        data.modifiedBy = { id: authUserData.currUserData.contactId!, userActveDirectoryId: authUserData.currUserData.profileId };
        data.createdBy = { id: authUserData.currUserData.contactId!, userActveDirectoryId: authUserData.currUserData.profileId };
        return caseManagerService.postUpdateCaseBookingSetting(data)
            .then(() => {
                alertService.success(t("ALL.Bookings.Messages.UpdatedSettings"), { keepAfterRouteChange: true });

            })
            .catch((rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    return (<div className="container">
        {(isAddMode || (!isAddMode && bookingSettingData)) &&

            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-warning header-top">
                            <h4 className="card-title">{t("ALL.Bookings.Settings.Title")}</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Settings.BudgetType")}*</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipBudgetType" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.BudgetType")} target="TooltipBudgetType" />
                                        <select name="budgetType" ref={register} onChange={handleBudgetTypeChange} className={`form-control ${errors.budgetType ? 'is-invalid' : ''}`}>
                                            <option value={0}>{t("ALL.Bookings.BudgetType.0")}</option>
                                            <option value={1}>{t("ALL.Bookings.BudgetType.1")}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.budgetType?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Settings.DefaultHourlyRate")}*</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipDefaultHourlyRate" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.HourlyRate")} target="TooltipDefaultHourlyRate" />

                                        <input name="defaultHourlyRate" type="text" ref={register} className={`form-control ${errors.defaultHourlyRate ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.defaultHourlyRate?.message}</div>
                                    </div>
                                </div>
                                <div className={`form-row ${budgetType === 0 || budgetType === 2 ? '' : 'hidden'}`}>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.BudgetAmount")}</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipBudgetAmount" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.BudgetAmount")} target="TooltipBudgetAmount" />

                                        <input name="budgetAmount" type="text" ref={register} className={`form-control ${errors.budgetAmount ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.budgetAmount?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.AmountAlert")}</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipBudgetAmountAlert" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.AmountAlert")} target="TooltipBudgetAmountAlert" />

                                        <input name="budgetAmountAlert" type="text" ref={register} className={`form-control ${errors.budgetAmountAlert ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.budgetAmountAlert?.message}</div>
                                    </div>
                                </div>
                                <div className={`form-row ${budgetType === 1 || budgetType === 2 ? '' : 'hidden'}`}>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.BudgetHours")}</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipBudgetHours" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.BudgetHours")} target="TooltipBudgetHours" />

                                        <input name="budgetHours" type="text" ref={register} className={`form-control ${errors.budgetHours ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.budgetHours?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.HoursAlert")}</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipBudgetHoursAlert" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.TooltipsHoursAlert")} target="TooltipBudgetHoursAlert" />

                                        <input name="budgetHoursAlert" type="text" ref={register} className={`form-control ${errors.budgetHoursAlert ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.budgetHoursAlert?.message}</div>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Settings.DefaultBookingTimeMinutes")}*</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipDefaultBookingTimeMinutes" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.DefaultBookingTimeMinutes")} target="TooltipDefaultBookingTimeMinutes" />

                                        <select name="defaultBookingTimeMinutes" ref={register} className={`form-control ${errors.defaultBookingTimeMinutes ? 'is-invalid' : ''}`}>
                                            <option value={0}>{t("ALL.Bookings.Settings.DefaultBookingTime.0")}</option>
                                            <option value={1}>{t("ALL.Bookings.Settings.DefaultBookingTime.1")}</option>
                                            <option value={2}>{t("ALL.Bookings.Settings.DefaultBookingTime.2")}</option>
                                            <option value={3}>{t("ALL.Bookings.Settings.DefaultBookingTime.3")}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.defaultBookingTimeMinutes?.message}</div>
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Settings.DefaultDistanceUnit")}*</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipDefaultDistanceUnit" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.DefaultDistanceUnit")} target="TooltipDefaultDistanceUnit" />

                                        <select name="defaultDistanceUnit" ref={register} className={`form-control ${errors.defaultDistanceUnit ? 'is-invalid' : ''}`}>
                                            <option value={0}>{t("ALL.Bookings.Settings.DistanceUnitItems.0")}</option>
                                            <option value={1}>{t("ALL.Bookings.Settings.DistanceUnitItems.1")}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.defaultDistanceUnit?.message}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Settings.DefaultCurrency")}*</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipDefaultCurrency" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.DefaultCurrency")} target="TooltipDefaultCurrency" />

                                        <select name="defaultCurrency" ref={register} className={`form-control ${errors.defaultCurrency ? 'is-invalid' : ''}`}>
                                            <option value={0}>{t("ALL.Bookings.Currency.0")}</option>
                                            <option value={1}>{t("ALL.Bookings.Currency.1")}</option>
                                            <option value={2}>{t("ALL.Bookings.Currency.2")}</option>
                                            <option value={3}>{t("ALL.Bookings.Currency.3")}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.defaultCurrency?.message}</div>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Settings.DefaultPricePerDistance")}*</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipDefaultPricePerDistance" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.DefaultPricePerDistance")} target="TooltipDefaultPricePerDistance" />

                                        <input name="defaultPricePerDistance" type="text" ref={register} className={`form-control ${errors.defaultPricePerDistance ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.defaultPricePerDistance?.message}</div>
                                    </div>

                                </div>
                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Settings.DefaultTax")}%</label>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TooltipTaxValues" className="info-icon align-middle" />
                                        <ToolTip infoText={t("ALL.Bookings.Tooltips.DefaultTax")} target="TooltipTaxValues" />
                                        <select name="defaultTaxTag" ref={register} className={`form-control ${errors.defaultTaxTag ? 'is-invalid' : ''}`} >
                                            <option value=""></option>
                                            {taxTagsOptions.length > 0 ? taxTagsOptions.map((option) => (
                                                <option value={option.dicGuid}>{option.title}</option>
                                            )) : <option value="ALL.Dictionary.Controls.NoItemAvailable" disabled >{t("ALL.Dictionary.Controls.NoItemAvailable")}</option>}
                                        </select>

                                        <div className="invalid-feedback">{errors.defaultTaxTag?.message}</div>
                                    </div>
                                </div>
                                <div className={`form-row ${budgetType === 0 || budgetType === 2 ? '' : 'hidden'}`}>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.BookingAmount")}</label>
                                        <input name="sumOfAmounts" type="text" ref={register} className="form-control" disabled={true} />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.RemainingAmount")}</label>
                                        <input name="remainingAmount" type="text" value={bookingSettingData ? bookingSettingData?.budgetAmount! - bookingSettingData?.sumOfAmounts! : 0} ref={register} className="form-control" disabled={true} />

                                    </div>
                                </div>
                                <div className={`form-row ${budgetType === 1 || budgetType === 2 ? '' : 'hidden'}`}>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.BookingHours")}</label>
                                        <input name="sumOfHoursTotal" type="text" ref={register} className="form-control" disabled={true} />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.RemainingHours")}</label>
                                        <input name="remainingHours" value={bookingSettingData ? bookingSettingData?.budgetHours! - bookingSettingData?.sumOfHoursTotal! : 0} type="text" ref={register} className="form-control" disabled={true} />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-sm-6">
                                        <label>{t("ALL.Bookings.Sum.BookingTax")}</label>
                                        <input name="sumOfTaxes" type="text" ref={register} className="form-control" disabled={true} />
                                    </div>
                                </div>
                                <div className="form-group row justify-content-center">
                                    <div className="form-group col-sm-6">
                                        <button type="button" onClick={handleSubmit(onSubmit)} disabled={formState.isSubmitting} className="btn btn-action">
                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            {t("ALL.Bookings.UpdateBookingSettings")}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div >


        }

    </div >
    );
}

export { AddEditCaseBookingSettins };