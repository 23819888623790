import { useContext, useMemo, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useTable, useSortBy } from 'react-table'


import moment from 'moment';

import { Tabs, Tab } from "react-bootstrap";

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpArrowDown, faBurgerSoda, faCar, faEdit, faHeadSideMedical, faTrash } from '@fortawesome/pro-light-svg-icons';
import { ICaseBookingViewModel } from '../../models/CaseBooking';
import { ICaseBookingSettingViewModel } from '../../models/CaseBookingSetting';
import SumDetails from './SumDetails';
import { helperFunctions } from '../../helpers/HelperFunctions';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { StandardListHeader } from '../list/StandardListHeader';
import { StandardReportHeader } from '../list/StandardReportHeader';
interface IBookingsManagementProps {
    bookings: Array<ICaseBookingViewModel>,
    loading: boolean,
    bookingSettings?: ICaseBookingSettingViewModel,
    deleteBooking: (id: number) => void,
    setSelectedBooking: (data: ICaseBookingViewModel) => void,
    setSelectedTab: (tab: number) => void,
    calendarState: any[],
    changeDateRange: any,
    generateReport:any
}

export const BookingsManagement = ({ bookings, loading, bookingSettings, deleteBooking, setSelectedBooking, setSelectedTab, calendarState, changeDateRange,generateReport }: IBookingsManagementProps) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const ref = React.createRef<HTMLDivElement>();
    const { caseData } = useContext(CaseDataContext);
    const [key, setKey] = useState<string>('home');
    const columns = React.useMemo(
        () => [
            {
                Header: t("ALL.Bookings.OverviewTable.Date"),
                accessor: 'bookingDateString',
                sortType: customSortFn
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Position"),
                accessor: 'title',
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Tags"),
                accessor: 'bookingTagsTitles',
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Minutes"),
                accessor: 'chargedMinutes',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.chargedMinutes / 60 + sum, 0),
                        [info.rows]
                    )

                    return <p>{t("ALL.Bookings.Sum.TotalHoursSum")}: {total}</p>
                },
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Amount"),
                accessor: 'sumOfAmounts',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.sumOfAmounts + sum, 0),
                        [info.rows]
                    )

                    return <p>{t("ALL.Bookings.Sum.TotalAmount")}: {total}</p>
                },

            },
            {
                Header: t("ALL.Bookings.OverviewTable.Type"),
                accessor: 'bookingType',
                Cell: row => (<div>
                    {{
                        0: <FontAwesomeIcon icon={faHeadSideMedical} style={{ fontSize: "20px" }} />,
                        1: <FontAwesomeIcon icon={faBurgerSoda} style={{ fontSize: "20px" }} />,
                        2: <FontAwesomeIcon icon={faCar} style={{ fontSize: "20px" }} />
                    }[row.row.original.bookingType]}
                </div>
                ),
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Actions"),
                accessor: 'action',
                disableSortBy: true,
                Cell: row => (<div>
                    <button onClick={() => editBooking(row.row.original)} className="btn btn-md btn-icon-grey mr-1">
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button onClick={() => removeBooking(row.row.original)} className="btn btn-md btn-icon-red">
                        <FontAwesomeIcon icon={faTrash} />{row.row.original.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    </button>
                </div>
                ),
            },

        ],
        []
    );
    const columnsReport = React.useMemo(
        () => [
            {
                Header: t("ALL.Bookings.OverviewTable.Date"),
                accessor: 'bookingDateString',
                sortType: customSortFn
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Position"),
                accessor: 'title',
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Tags"),
                accessor: 'bookingTagsTitles',
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Minutes"),
                accessor: 'chargedMinutes',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.chargedMinutes / 60 + sum, 0),
                        [info.rows]
                    )

                    return <p>{t("ALL.Bookings.Sum.TotalHoursSum")}: {total}</p>
                },
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Amount"),
                accessor: 'sumOfAmounts',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.sumOfAmounts + sum, 0),
                        [info.rows]
                    )

                    return <p>{t("ALL.Bookings.Sum.TotalAmount")}: {total}</p>
                },

            },
            {
                Header: t("ALL.Bookings.OverviewTable.Type"),
                accessor: 'bookingType',
                Cell: row => (<div>
                    {{
                        0: t('ALL.Bookings.ReportWorkingHours'),
                        1: t('ALL.Bookings.ReportExpenses'),
                        2: t('ALL.Bookings.ReportTravelCost')
                    }[row.row.original.bookingType]}
                </div>
                ),
            },
            {
                Header: t("ALL.Bookings.OverviewTable.Actions"),
                accessor: 'action',
                disableSortBy: true,
                Cell: row => (<div>
                    <button onClick={() => editBooking(row.row.original)} className="btn btn-md btn-icon-grey mr-1">
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button onClick={() => removeBooking(row.row.original)} className="btn btn-md btn-icon-red">
                        <FontAwesomeIcon icon={faTrash} />{row.row.original.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    </button>
                </div>
                ),
            },

        ],
        []
    );
    const data = useMemo(() => [...bookings ? bookings : []], [bookings ? bookings : []])
    const removeBooking = (booking: ICaseBookingViewModel) => {
        deleteBooking(booking.id!);
    }
    const initialState = { hiddenColumns: key==='home' ? [] : ['action'] };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns: key==='home'?columns:columnsReport,
        initialState,
        data,
    },
        useSortBy)

    const editBooking = (data: any) => {
        setSelectedBooking(data);
        setSelectedTab(data.bookingType);
    }
    function customSortFn(a, b) {
        var a1 = new Date(a.original.dueTime).getTime();
        var b1 = new Date(b.original.dueTime).getTime();
        if (a1 < b1)
            return 1;
        else if (a1 > b1)
            return -1;
        else
            return 0;
    }
    return (
        <div className="container">
            <div className="row justify-content-end">
                <SumDetails bookingSettings={bookingSettings} />
            </div>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k!)}>
                <Tab eventKey="home" title={t("ALL.Common.ViewList")}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <StandardListHeader headerClass="header-top" changeDateRange={changeDateRange} calendarState={calendarState} headerTitle="ALL.Bookings.Overview" />
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped" {...getTableProps()}>
                                            <thead>
                                                {headerGroups.map(headerGroup => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map(column => (
                                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                                {column.render('Header')}
                                                                <span>
                                                                    {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : column.Header !== "Task Aktion" && column.Header !== "Task Action" ? <FontAwesomeIcon icon={faArrowUpArrowDown} /> : ""}
                                                                </span>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {loading ?
                                                    <tr>
                                                        <td colSpan={7} className="text-center">
                                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                                        </td>
                                                    </tr> :
                                                    (rows.length > 0 && rows.map((row, i) => {
                                                        prepareRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()}>
                                                                {row.cells.map(cell => {
                                                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                                })}
                                                            </tr>
                                                        );
                                                    })
                                                    ) ||
                                                    <tr>
                                                        <td colSpan={7} className="text-center">
                                                            <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                            <tfoot>
                                                {footerGroups.map(group => (
                                                    <tr {...group.getFooterGroupProps()}>
                                                        {group.headers.map(column => (
                                                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="report" title={t("ALL.Common.ViewReport")}>
                <StandardReportHeader changeDateRange={changeDateRange} calendarState={calendarState} isLoading={loading} generateReport={generateReport} />
                    <div className="report-preview" id="bookings" ref={ref} style={{ backgroundColor: "white", padding: "15px", color: "#000" }}>
                        <div className="row">
                            <div className="col-sm-6">
                                <p>{t("ALL.Common.ReportCreated")}:<b>{moment().format("L")}</b></p>
                            </div>
                            <div className="col-sm-6  text-right" style={{ textAlign: 'right' }}>
                                <img
                                    src={helperFunctions.getLogoBase64Url()}
                                    width="170px"
                                    height="48px"
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-sm-6">
                                <p>{t("ALL.Bookings.ReportDateRange")}:<b> {moment(calendarState[0].startDate).format('DD.MM.YYYY')}-{moment(calendarState[0].endDate).format('DD.MM.YYYY')}</b></p>
                                <p>{t("ALL.Bookings.WeekNumber")}: <b>{(moment(calendarState[0].startDate).week() === moment(calendarState[0].endDate).week()) ? moment(calendarState[0].endDate).week() : `${moment(calendarState[0].startDate).week()} - ${moment(calendarState[0].endDate).week()}`}</b></p>
                                <p>{t("CLN.Reflection.ClientName")}: <b>{caseData.caseClienName}</b></p>
                                <p>{t("INS.Common.InsurerRefNumber")}: <b>{caseData.caseInsurerReference}</b></p>
                                <p>{t("CAM.Case.CaseDeskNumber")}: <b>{caseData.caseNumber}</b></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="white-container col-12">
                                <div className="table-responsive">
                                    <table className="table table-striped" {...getTableProps()}>
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                            {column.render('Header')}
                                                            <span>
                                                                {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : column.Header !== "Task Aktion" && column.Header !== "Task Action" ? <FontAwesomeIcon icon={faArrowUpArrowDown} /> : ""}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {loading ?
                                                <tr>
                                                    <td colSpan={7} className="text-center">
                                                        <div className="spinner-border spinner-border-lg align-center"></div>
                                                    </td>
                                                </tr> :
                                                (rows.length > 0 && rows.map((row, i) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            {row.cells.map(cell => {
                                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            })}
                                                        </tr>
                                                    );
                                                })
                                                ) ||
                                                <tr>
                                                    <td colSpan={7} className="text-center">
                                                        <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                        <tfoot>
                                            {footerGroups.map(group => (
                                                <tr {...group.getFooterGroupProps()}>
                                                    {group.headers.map(column => (
                                                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tfoot>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>

                </Tab>
            </Tabs>
        </div>
    );
}
