import React, { useState, useContext, useEffect } from 'react';
import '@asseinfo/react-kanban/dist/styles.css';
import Board from '@asseinfo/react-kanban';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle} from '@fortawesome/pro-light-svg-icons';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Modal, ModalBody } from 'reactstrap';
import { AddEditTask } from './AddEditTask';
import { Task, ITaskViewModel, mapTaskToVieModel } from '../../models/Task';
import { alertService, notificationService, tasksService } from '../../services/Index';
import Grid from '@mui/material/Grid';
import moment from 'moment';

import Chips from "./Chips";
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

function KanbanBoard ( {data, chipSelected, editTask, addTask,taskTagsOptions, casePhaseOptions, taskPlanOptions}) {
  const {language, t } = useContext(MultiLanguageSupportContext);
  moment.locale(language);
  const [boardData, setBoardData] = useState<any>({})
  const [rerender, setRerender] = useState<boolean>(false)
  const [open, setOpen] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [taskToView, setTaskToView] = useState<any>({})
  
  const [, setSelectedTask] = useState<any>({})
  const [selectedChip, setSelectedChip] = useState<String>("Task_Status");
  const { authUserData } = useContext(LoggedUserDataContext);

  type kanbanDataType = { 
    id: number, 
    title: any
  };

  const cardType = [
    {chipLabel: t("ALL.Tasks.Status.StatusTitle"), name: "Task_Status", param: "taskStatus"},
    {chipLabel: t("CAM.Case.Phases.PhaseTitle"), name: "Case_Phase", param: "casePhaseTag"},
  ]
 
  useEffect(() => {
    setRerender(true)
  }, [boardData]);

  // const handleDialogClose = () => {
  //   setOpen(true)
  // };

  function assignTaskToCard(selectedChipName, taskData) {
    const filterObj = cardType.filter((e) => e.name === selectedChipName);
    const tempTaskState = filterObj[0].param==="taskStatus" ? (taskData[filterObj[0].param] - 1) : boardData.columns.findIndex(x => x.id ===(taskData[filterObj[0].param]));
    let tempTaskObj = {
      "id": taskData.id,
      "caseID": taskData.caseId,
      "casePhaseTag": taskData.casePhaseTag,
      "title":<div onClick={() => handleCardClick(taskData)}>{taskData.taskTitle+" - "+taskData.dueTimeString}</div>,
      "description": <div onClick={() => handleCardClick(taskData)}>{taskData.taskDescription}</div>,
    }
    boardData.columns[tempTaskState].cards.push(tempTaskObj);
  }
  
  function changeCardStatus(_card, cardData, source, destination) {
    //_card contains updated array data - after drag
    //source - Obj of task that is dragged
    
    let tempKanbanObj : any = {columns: _card}
    setBoardData(tempKanbanObj)

    if(selectedChip==="Task_Status") {
      let tempStatusUpdate = {
        id: cardData.id,
        caseId : cardData.caseId,
        taskStatus : destination.toColumnId,
        modified : new Date(),
        modifiedBy : { 
          id: authUserData.currUserData.contactId!, 
          userActveDirectoryId: authUserData.currUserData.profileId 
        }
      }
      tasksService.postUpdateTaskStatus(tempStatusUpdate).then((res) => {
        alertService.success(t("ALL.Tasks.Messages.TaskUpdated"), { keepAfterRouteChange: true });
        assignTaskToCard(selectedChip, res);
        editTask(res, selectedChip);
        notificationService.postNewNotifications(
          {
              caseId: cardData.caseId,
              senderContactId: authUserData.currUserData.profileId,
              notificationStatus: "New",
              notificationTitle: t(""),
              notificationMessage: t(""),
              notificationItemUrl: `view-task/${data.id}`,
              notificationDate: moment(),
              notificationSettingDictionaryId: 11,
              scopes: [{ scopeTypeId: 0, scopeEntityId: -1 }, { scopeTypeId: 1, scopeEntityId: authUserData.currUserData.insurerId! }, { scopeTypeId: 2, scopeEntityId: cardData.caseId }],
              templateId: 611
          });
      }).catch(
        (res) =>  {
          alertService.error(t(res.message), { keepAfterRouteChange: true })
        }
      );
    } else {
      let tempPhaseTagUpdate = {
        id: cardData.id,
        caseId : cardData.caseId,
        casePhaseTag : destination.toColumnId,
        modified : new Date(),
        modifiedBy : { 
          id: authUserData.currUserData.contactId!, 
          userActveDirectoryId: authUserData.currUserData.profileId 
        }
      }
      tasksService.postUpdateTaskCasePhaseTag(tempPhaseTagUpdate).then((res) => {
        alertService.success(t("ALL.Tasks.Messages.TaskUpdated"), { keepAfterRouteChange: true });
        assignTaskToCard(selectedChip, res)
        editTask(res, selectedChip)
      }).catch(
        (res) => alertService.error(t(res.message), { keepAfterRouteChange: true })
      );
    }

  }

  const addTaskFromKanban = () => {
    setOpen(true);
    setViewMode(false)
    setTaskToView({})
  }

  function handleCardClick(item: any) {
    setOpen(true)
    setTaskToView(item)
    setViewMode(true)
  }

  const handleClose = () => {
      setOpen(false);
  };

  const add = (taskData: Task) => {
    let cardData = mapTaskToVieModel(taskData, t,taskTagsOptions,casePhaseOptions,taskPlanOptions,authUserData.currUserData.profileLanguage)
    const filterObj = cardType.filter((e) => e.name === chipSelected);
    const tempTaskState = taskData[filterObj[0].param];
    let tempTaskObj = {
      "id": taskData.id,
      "caseID": taskData.caseId,
      "title":<div onClick={() => handleCardClick(taskData)}>{taskData.taskTitle}</div>,
      "description": <div onClick={() => handleCardClick(taskData)}>{taskData.taskDescription}</div>,
    }
    boardData.columns[tempTaskState].cards.push(tempTaskObj);
    addTask(taskData)
  }

  function getYs(data, key){
    return data.map(d => d[key]);
  }

  const generateCardData = (arrData, selectedChip) => {
    if(arrData && arrData.length) {
      const filterObj = cardType.filter((e) => e.name === selectedChip);
      let selectedKey = filterObj[0].param;
      let kanbanData : Array<kanbanDataType> = [];
      var totalBoards = selectedChip==="Task_Status" ? 3 : casePhaseOptions.length;

      [...Array(totalBoards).keys()].map((key) => {
        const filterTasks = (taskArray, obj) => taskArray.filter(e => e[Object.keys(obj)[0]]===obj[Object.keys(obj)[0]]);
        let tasksOfCase = selectedChip==="Task_Status" ? filterTasks(arrData, {[selectedKey]: key+1}) : filterTasks(arrData, {[selectedKey]: casePhaseOptions[key].dicGuid});
        let taskArr : Array<kanbanDataType> = [];

        tasksOfCase.map((item, ind) => {
          let tempTaskObj = {
            "id": item.id,
            "caseId": item.caseId,
            "casePhaseTag": item.casePhaseTag,
            "title":<div onClick={() => handleCardClick(item)}>{item.taskTitle}</div>,
            "description": <div onClick={() => handleCardClick(item)}>{moment(item.dueTime).format("L")}</div>,
          }
          taskArr.push(tempTaskObj);
        });
       
        let tempCardData = {
          "id": selectedChip==="Task_Status" ? key+1 : casePhaseOptions[key].dicGuid,
          "title": selectedChip==="Task_Status" ? t(`ALL.Tasks.Status.Options.${key+1}`) : casePhaseOptions[key].title,
          "cards": taskArr
        }

        kanbanData.push(tempCardData);
      });
      setBoardData(prevState => ({
        ...prevState,
        columns : kanbanData
      }))
    } else {
      setBoardData(prevState => ({
        ...prevState,
        columns : []
      }))
    }
  }

  useEffect(() => {
    generateCardData(data, chipSelected);
    setSelectedChip(chipSelected)
  }, [])

  useEffect(() => {
    setRerender(false);
    generateCardData(data, selectedChip);
  }, [data])

  function handleChipClick(item: any) {
    setRerender(false)
    setSelectedChip(item)
    generateCardData(data, item);
  }

  return (
    <>
     {
       rerender && boardData && boardData.columns ?
       <>
       <div style={{marginTop: '2%'}}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={5}>
            </Grid>
            <Grid item xs={5}>
              <Chips data={cardType} handleChipClick={(val)=> handleChipClick(val)} selected={selectedChip} />
            </Grid>
            <Grid item xs>
              {/* <CardTypeSelector/> */}
            </Grid>
          </Grid>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
                <div className="card-header card-header-tabs card-header-primary header-top">
                    <div className="card-category">
                        <div className="btn-group">
                            <button type="submit" className="btn btn-md btn-icon-grey mr-1" onClick={addTaskFromKanban}>
                                <FontAwesomeIcon icon={faPlusCircle} />{t("ALL.Tasks.Button.AddTask")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                  <div className="App" style={{marginTop: '2%', backgroundColor: "#1010101f"}}>
                    {boardData ? 
                      <Board initialBoard={boardData} 
                        // allowAddCard={{ on: "top" }}
                        onNewCardConfirm={draftCard => ({
                          id: new Date().getTime(),
                          ...draftCard
                        })}
                        onCardNew={console.log} 
                        onCardDragEnd={changeCardStatus}
                        disableColumnDrag
                      />
                    : <h6 style={{ backgroundColor: "#fff"}}>No Data</h6>}
                  </div>
                </div>
            </div>
          </div>
        </div>
        <Modal isOpen={open} style={{ maxWidth: "1000px" }}>
          <ModalBody>
              <AddEditTask task={taskToView} close={handleClose} add={add} edit={editTask} view={viewMode} cleanSelectedNode={setSelectedTask}
              taskTagsOptions={taskTagsOptions} casePhaseOptions={casePhaseOptions} taskPlanOptions={taskPlanOptions} selectedChip={selectedChip}/>
          </ModalBody>
        </Modal>
       </>
       : null
     }
    </>
  );
}

export default KanbanBoard;
