import { useContext, useEffect, useState } from 'react';

import Insurer from '../../models/Insurer';
import { msalConfig } from '../../msal/Config';
import { adminService, alertService, contactService } from '../../services/Index';
import { getGroupMembers } from '../../services/graph/GraphService'
import Membership from '../../models/Membership';
import { ManageAddingContact } from '../../components/contact/ManageAddingContact';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';

interface RouteInfo {
    id: string;
}

function ManageOwner() {
    let { id } = useParams();
    const [insurer, setInsurer] = useState<Insurer>({
        insurerName: '',
        insurerPrefix: '',
        insurerADMGroupOpenId: '',
        insurerCAMGroupOpenId: '',
        profileImage: '',
        createdBy: ''
    });
    const [members, setMembers] = useState<Array<any>>([]);
    const [loadingMembers, setLoadingMembers] = useState<boolean>(true);
    const { t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    useEffect(() => {
        adminService.getSingleInsurer(parseInt(id!)).then(i => {
            setInsurer({
                insurerName: i.insurerName,
                insurerPrefix: i.insurerPrefix,
                insurerADMGroupOpenId: i.insurerADMGroupOpenId,
                insurerCAMGroupOpenId: i.insurerCAMGroupOpenId,
                profileImage: i.profileImage,
                createdBy: i.createdBy
            });
            var interval = setInterval(function(){  
                getGroupMembers(i.insurerADMGroupOpenId).then(rp => {
                    setMembers(rp.value.map((item: { id: any; mail: any; }) => ({
                        contact: { id: item.id, userActveDirectoryId: item.id, email: item.mail, isDeleting: false },
                        welcomeSent: false
                    })));
                    setLoadingMembers(false);
                    clearInterval(interval);
                }).catch(
                    (rp) => {
                        if(rp.code!=="Request_ResourceNotFound"){
                            clearInterval(interval);
                            alertService.error(t(rp.message), { keepAfterRouteChange: true });
                            setLoadingMembers(false);
                        } 

                    }
                );
            }, 2000); 

        });

    }, []);
    function onSubmit(data: any, action: string) {
        switch (action) {
            case 'add':
                return addInsurerOwner(data);
            case 'delete':
                return deleteInsurerOwner(data.adId);
        }
    }

    function addInsurerOwner(data: Membership) {
        data.insurerId = parseInt(id!);
        data.roleType = "Insurer";
        data.groupId = insurer.insurerADMGroupOpenId;
        data.redirectUrl = msalConfig.auth.redirectUri;
        data.createdBy = authUserData.currUserData.profileId;
        data.modifiedBy = authUserData.currUserData.profileId;
        return adminService.addUserToInsurerGroup(data)
            .then(rp => {
                alertService.success(t("INS.Common.AdminAdded"), { keepAfterRouteChange: true });
                var newMember = { contact: { id: rp.id, userActveDirectoryId: rp.userActveDirectoryId, email: rp.email }, welcomeSent: false };
                setMembers(groupMembres => [newMember, ...groupMembres]);
            })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    function deleteInsurerOwner(id: string) {
        setMembers(members!.map(x => {
            if (x.contact.id === id) { x.isDeleting = true; }
            return x;
        }));
        var data =
        {
            UserActveDirectoryId: id,
            groupId: insurer.insurerADMGroupOpenId
        }
        contactService.removeInsurerContactFromGroupAsync(data).then(() => {
            alertService.success(t("INS.Common.AdminRemoved"), { keepAfterRouteChange: true });
            setMembers(m => m.filter(x => x.contact.userActveDirectoryId !== id));
        })
            .catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );
    }
    return (
        <div className="container">
            <p className="component-header">{t("ALL.Dictionary.Role.InsurerAdmin")}</p>
            <hr></hr>
            <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
            <span className="info-text" >{t("ALL.Messages.InsurerOnlySingleAdminAllowed")}</span>
            <hr></hr>
            <div className="white-container">
                    <div>
                        <ManageAddingContact groupName="Insurer" role="Insurer" insurerId={parseInt(id!)}
                            groupId={insurer.insurerADMGroupOpenId} requiered={false} caseContact={false} action={onSubmit}
                            members={members}
                            loadingMembers={loadingMembers } />
                    </div>
                
            </div>
        </div>
    );
}

export { ManageOwner };