export const caseDefault:ICase=
    {
        selectedCaseId:undefined,
        caseNumber:undefined,
        caseClienName:undefined,
        caseClient:'',
        caseDoctor:{},
        caseEmployeer:{},
        caseCaseManager:{},
        caseInsurerReference:'',
        insurerProfileImage:'',
        insurerId:undefined,
        cryptoPass:"mXKMwNfrobMykpy9JofD"
    }

export interface ICase {
    selectedCaseId?:number,
    caseNumber?:string;
    caseClienName?:string;
    caseClient?:any;
    caseDoctor?:any;
    caseEmployeer?:any;
    caseCaseManager?:any;
    caseInsurerReference?:string;
    insurerProfileImage?:string;
    incapacityOfWork?:Date;
    startOfIntegration?:Date;
    insurerId?:number;
    cryptoPass?:string;

}
export interface ICaseProps {
    caseData: ICase,
    setCaseData: (user: ICase) => void;
}