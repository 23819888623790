import { useState, useEffect, useContext } from 'react';
import { licenseService, alertService,  clientService } from '../../../services/Index';
import { faCreditCard, faTrash, faList } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/min/locales';
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';

interface ILicenseManagementAssignmentProps {
    caseId?:number;
    setActiveLicenseAssignement?:any;
    reloadCases:any,
    close:any
}
const LicenseManagementAssignment=({caseId,setActiveLicenseAssignement,reloadCases, close}: ILicenseManagementAssignmentProps)=> {

    const { t } = useContext(MultiLanguageSupportContext);
    const [licenseAssignements, setLicenseAssignements] = useState<any[]>();

    function deleteLicense(licenseId:number) {
        setLicenseAssignements(licenseAssignements!.map(x => {
            if (x.id === licenseId) { x.isDeleteing = true; }
            return x;
        }));
        licenseService.deleteLicenseAssignement(licenseId).then(() => {
            alertService.success(t("ALL.License.AssignmentRemoved"), { keepAfterRouteChange: true });
            setLicenseAssignements(m => m!.filter(x => x.id !== licenseId));
            reloadCases();
            close();
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true })
                setLicenseAssignements(licenseAssignements!.map(x => {
                        if (x.id === licenseId) { x.isDeleteing = false; }
                        return x;
                    }));
            }
        );
    }
    function getLicenses(){
        licenseService.getCaseLicenseAssignement(caseId!).then(
            (x) => {
                    setLicenseAssignements(x)    

            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }
    function noTimeDate(dateTime) {
        var date = new Date(dateTime);
        date.setHours(0, 0, 0, 0);
        return date;
    }
    useEffect(() => {
        getLicenses();
    }, []);
    return (
        <div className="container">
            <p className="component-header">{t("ALL.License.CaseLicenseOverview")}</p>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th>{t("ALL.License.Title")}</th>
                        <th>{t("ALL.License.Validation.DateStart")}</th>
                        <th>{t("ALL.License.Validation.DateEnd")}</th>
                        <th className="text-center">{t("ALL.Button.Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {licenseAssignements && licenseAssignements.map(l =><>
                                        <tr key={l.id}>
                                            <td>{noTimeDate(l.assignmentStartDate)<=noTimeDate(new Date()) && noTimeDate(l.assignmentEndDate)>=noTimeDate(new Date())?<>
                                            {l.edition==="DEMO"?<FontAwesomeIcon icon={faCreditCard} className={`btn-icon-light-blue`} />:<FontAwesomeIcon icon={faCreditCard} className={`btn-icon-green`} />}
                                            </>
                                            :<FontAwesomeIcon icon={faCreditCard} className="btn-icon-red" />}</td>
                                            <td>{`${l.caseName.split('-')[0]}-${l.licenseId}`}</td>
                                            <td>{moment(l.assignmentStartDate).format("L")}</td>
                                            <td>{moment(l.assignmentEndDate).format("L")}</td>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                <button onClick={() => setActiveLicenseAssignement(l.id)} className="btn btn-md btn-icon-grey">
                                                    <FontAwesomeIcon icon={faList} />
                                                </button>
                                                <button onClick={() => deleteLicense(l.id)} className="btn btn-md btn-icon-red" disabled={l.isDeleteing}>
                                                    <FontAwesomeIcon icon={faTrash} /> {l.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                </button>
                                                </td>
                                        </tr>
</>
                    )}
                    {!licenseAssignements &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {licenseAssignements && !licenseAssignements.length &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}

export { LicenseManagementAssignment };