export const defaultUserData:ILoggedUserData={
    currUserData:{
        roleEnum:"",
        roleType:"",
        rolePath:"",
        relatedData:undefined,
        insurerId:undefined,
        contactId:undefined,
        profileId:"",
        profileEmail:"",
        profileLanguage:"",
        name:"",
        surname:"",
        profileImg:"",
    },
    notificationNumber:0,
    currUserGroups:[],
    selectedCaseId:undefined,
    referenceNumberOfInsurance:""
};
export interface IUserProperties{
    roleType:string;
    roleEnum:string;
    rolePath:string;
    relatedData:any;
    insurerId:number|undefined;
    contactId:number|undefined;
    profileId:string;
    profileEmail:string;
    profileLanguage:string;
    name:string;
    surname:string;
    profileImg:string;
    
}
export interface ILoggedUserData {
    currUserData:IUserProperties;
    currUserGroups:Array<string>;
    selectedCaseId:number|undefined;
    notificationNumber:number|undefined;
    referenceNumberOfInsurance:string;
}
export interface ILoggedUserProps {
    authUserData: ILoggedUserData,
    setAuthUserData: (user: ILoggedUserData) => void;
}