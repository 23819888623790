import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Feedback from '../../models/Feedback';
import { alertService, employeerService, notificationService } from '../../services/Index';
import moment from 'moment';
import 'moment/min/locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { NotificationContext } from '../../ctx/NotificationProvider';

function ViewFeedback() {
    const { caseData } = useContext(CaseDataContext);
    const { notificationsDetails, setNotifications } = useContext(NotificationContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    moment.locale(authUserData.currUserData.profileLanguage);
    const { id, notId } = useParams();
    const [feedbackItem, setFeedback] = useState<Feedback>();
    const { t } = useContext(MultiLanguageSupportContext);
    useEffect(() => {

        employeerService.getSingleFeedback(parseInt(id!)).then(i => {
            setFeedback({
                caseId: i.caseId,
                dateOfFeedback: i.dateOfFeedback,
                taskQuality: i.taskQuality,
                limitationRespected: i.limitationRespected,
                remarks: i.remarks,
                performed: i.performed,

            });
        }).catch(
            (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
        );
        if (notId !== "No") {
            notificationService.changeNotificationStatus(parseInt(notId!), "Read");
            setNotifications({
                ...notificationsDetails,
                notificationNumber: notificationsDetails.notificationNumber - 1
            })
            //LogedUserProps.manageUserNotifications();
        }

    }, [notId]);


    return (
        <div className="container">
            {!feedbackItem &&
                <div className="loader">{t("ALL.Common.Loading")}</div>
            }
            {feedbackItem && <>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-header card-header-warning header-top">
                                <h4 className="card-title">{t("EMP.Feedback.Title")}</h4>

                                <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={notId !== "No" && caseData.caseNumber === undefined ? `/${authUserData.currUserData.rolePath}` : `/${authUserData.currUserData.rolePath}/dashboard/${feedbackItem.caseId}`} className="back-btn">{t("ALL.Button.Back")}</Link></p>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-sm-3">
                                        <label className="col-form-label-bold">{t("EMP.Feedback.Title")}</label>
                                        <div className="form-group col-sm-12">{moment(feedbackItem.dateOfFeedback).format("L")}</div>
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label className="col-form-label-bold">{t("EMP.Feedback.TaskQuality")}</label>
                                        <div className="form-group col-sm-12">{t(`EMP.Feedback.TaskQualityOption.${feedbackItem.taskQuality}`)}</div>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <label className="col-form-label-bold">{t("EMP.Feedback.Limitations")}</label>
                                    <div className="form-group col-sm-12">
                                        <label>{feedbackItem.limitationRespected === "True" ? t("ALL.Button.Yes") : t("ALL.Button.No")}</label>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <label className="col-form-label-bold">{t("EMP.Feedback.Remark")}</label>
                                    <div className="form-group col-sm-12">
                                        <label>{feedbackItem.remarks}</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    );
}


export { ViewFeedback };