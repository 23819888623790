import { useState, useEffect, useContext } from 'react';
import { licenseService, alertService,  clientService } from '../../../services/Index';

import { faChevronRight, faTrash, faList } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/min/locales';
import { Link } from 'react-router-dom';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons'
import { MultiLanguageSupportContext } from '../../../ctx/MultiLanguageSupportProvider';
interface ILicenseManagementProps {
    insurerId?:number;
}
function LicenseManagement({insurerId}:ILicenseManagementProps) {

    const { t } = useContext(MultiLanguageSupportContext);
    const [licenses, setLicenses] = useState<any[]>();
    console.log(insurerId);
    function deleteLicense(licenseId:number) {
        setLicenses(licenses!.map(x => {
            if (x.id === licenseId) { x.isDeleteing = true; }
            return x;
        }));
        licenseService.deleteLicense(licenseId).then(() => {
            alertService.success(t("CAM.License.Removed"), { keepAfterRouteChange: true });
            setLicenses(licenses => licenses!.filter(x => x.id !== licenseId));
        }).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true })
                    setLicenses(licenses!.map(x => {
                        if (x.id === licenseId) { x.isDeleteing = false; }
                        return x;
                    }));
            }
        );
    }
    function getLicenses(){
        licenseService.getLicenses().then(
            (x) => {
                if(insurerId!==undefined){
                    setLicenses(x.filter((c:any) => c.insurerId === insurerId));
                }
                else{
                    setLicenses(x)
                }

            }).catch(
                (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
            );

    }
    function noTimeDate(dateTime) {
        var date = new Date(dateTime);
        date.setHours(0, 0, 0, 0);
        return date;
    }
    useEffect(() => {
        getLicenses();
    }, [insurerId]);
    return (
        <div className="container">
            <p className="component-header">{t("ALL.License.Overview")}</p>
            <table className="table table-striped" style={{  textAlign: "center" }}>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t("ALL.License.ID")}</th>
                        <th>{t("ALL.License.Edition")}</th>
                        <th>{t("ALL.License.DateStart")}</th>
                        <th>{t("ALL.License.DateEnd")}</th>
                        <th className="text-center">{t("ALL.Button.Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {licenses && licenses.map(l =><>
                                        <tr key={l.id}
                                            className="collapsed"
                                            data-toggle="collapse"
                                            data-target={".multi-collapse" + l.id!.toString()}
                                            aria-controls={"multiCollapse" + l.id!.toString()}>
                                            <td>{noTimeDate(l.licenseValidFrom)<=noTimeDate(new Date()) && noTimeDate(l.licenseValidTo)>=noTimeDate(new Date())?<FontAwesomeIcon icon={faCreditCard} className="btn-icon-green" />:<FontAwesomeIcon icon={faCreditCard} className="btn-icon-red" />}</td>
                                            <td>{`${l.insurerPrefix}-${l.id}`}</td>
                                            <td>{l.edition}</td>
                                            <td>{moment(l.licenseValidFrom).format("L")}</td>
                                            <td>{moment(l.licenseValidTo).format("L")}</td>
                                            <td  style={{ whiteSpace: 'nowrap', textAlign: "center" }}>                                                
                                                <Link to={`/admin/edit-license/${l.id}`} className="btn btn-md btn-icon-grey mr-1"><FontAwesomeIcon icon={faList} /></Link>
                                                <button onClick={() => deleteLicense(l.id)} className="btn btn-md btn-icon-red" disabled={l.isDeleteing}>
                                                    <FontAwesomeIcon icon={faTrash} /> {l.isDeleteing && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                </button>
                                                <span className="btn btn-md mr-1"><FontAwesomeIcon icon={faChevronRight} /></span>
                                                
                                            </td>
                                        </tr>
                                        <tr></tr>
                                        <tr className={"collapse multi-collapse" + l.id!.toString()} id={"multiCollapse" + l.id!.toString()}>
                                            <td colSpan={2}><p>{t("ALL.License.AmountTotal")}</p>
                                                <p>{l.amountTotal}</p></td>
                                            <td colSpan={2}><p>{t("ALL.License.Curency")}</p><p>{l.currency}</p></td>
                                            <td colSpan={2}><p>{t("ALL.License.DateOrder")}</p><p>{moment(l.orderDate).format("L")}</p></td>
                                        </tr></>
                    )}
                    {!licenses &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {licenses && !licenses.length &&
                        <tr>
                            <td colSpan={6} className="text-center">
                                <div className="p-2">{t("ALL.Common.NoData")}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );

}

export { LicenseManagement };