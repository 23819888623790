import BaseService from '../../BaseService';
import Feedback from '../../../models/Feedback';
import Response from "../../../models/Response";

export const employeerService = {
    getCaseFeedbacks,
    postNewFeedback,
    getSingleFeedback,
    postUpdateFeedback,
    getCaseFeedbackMajorInfo,
    getCaseFeedbacksInDateRange,
    getCasesFeedbackMajorInfo

};

async function postNewFeedback(data: Feedback) {
    let res = BaseService.create<Feedback>("Feedback/CreateFeedback", data).then(
        (rp) => {
            if (rp.Status) {
                return new Response(true, rp.Data, "Success", "");
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function postUpdateFeedback(data: Feedback) {
    let res = BaseService.update<Feedback>("Feedback/UpdateFeedback", data).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseFeedbacks(caseId: number): Promise<Array<Feedback>> {
    let res = BaseService.get<Feedback>("Feedback/GetCaseFeedbacks?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseFeedbacksInDateRange(caseId: number,startDate:string,endDate:string): Promise<Array<Feedback>> {
    let res = BaseService.get<Feedback>("Feedback/GetCaseFeedbacksInDateRange?",`caseId=${caseId}&startDateIn=${startDate}&endDateIn=${endDate}`).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCaseFeedbackMajorInfo(caseId: number): Promise<any> {
    let res = BaseService.get<any>("Feedback/GetCaseFeedbackMajorInfo?caseId=",caseId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getCasesFeedbackMajorInfo(casesId: string): Promise<any> {
    let res = BaseService.get<any>("Feedback/GetCasesFeedbackMajorInfo?",casesId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
async function getSingleFeedback(FeedbackId: number): Promise<Feedback> {
    let res = BaseService.get<Feedback>("Feedback/GetSingelFeedback?FeedbackId=", FeedbackId).then(
        (rp) => {
            if (rp.Status) {
                return rp.Data;
            } else {
                throw new Error(rp.Exception.response.data);
            }
        }
    );
    return res;
};
