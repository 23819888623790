import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { alertService, contactService } from '../../services/Index';
import logo from '../../assets/images/logoIcon.png';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons'
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { Tab, Tabs } from 'react-bootstrap';
import { NotificationManagement } from '../../components/notifications/NotificationManagement';

const CaseManagment = () => {
    const { pathname } = useLocation();
    const [cases, setCases] = useState<any[]>();
    const [tab, setTab] = useState('list');
    const { t } = useContext(MultiLanguageSupportContext);
    const { authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    moment(authUserData.currUserData.profileLanguage);
    const { caseData, setCaseData } = useContext(CaseDataContext);
    useEffect(() => {
        const casesId: string[] = []
        authUserData.currUserData.relatedData.forEach(function (item: any, key: any) {
            casesId.push(item['id'])
        });
        contactService.getContactRelatedCases(casesId).then(c => {
            setCases(c);
            setCaseData({
                ...caseData,
                caseInsurerReference: authUserData.currUserData.relatedData.insurerPrefix,
                caseNumber: undefined,
                caseClienName: undefined,
                insurerProfileImage: undefined,
                selectedCaseId: undefined,

            });
            setAuthUserData({
                ...authUserData,
                selectedCaseId: undefined

            });

        }
        ).catch(
            (rp) => {
                alertService.error(t(rp.message), { keepAfterRouteChange: true });
            }
        );
    }, []);
    const changeActiveTab = (tabName) => {
        setTab(tabName);

    }
    return (
        <div className="container">
            <Tabs
                activeKey={tab}
                onSelect={(t) => changeActiveTab(t)}>
                <Tab eventKey="list" title={t("ALL.Common.CaseList")}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header card-header-primary header-top">
                                    <h4 className="card-title ">{t("CAM.Case.My")}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead className="text-primary">
                                                <tr>
                                                    <th style={{ width: '15%' }}>{t("INS.Common.InsurerRefNumber")}</th>
                                                    <th style={{ width: '20%' }}>{t("CAM.Case.ClientName")}</th>
                                                    <th style={{ width: '15%' }}>{t("CAM.Case.CaseDeskNumber")}</th>
                                                    <th style={{ width: '10%' }}>{t("CAM.Case.CreateDate")}</th>
                                                    <th style={{ width: '20%' }}>{t("ALL.Button.Actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cases && cases.map(c =>
                                                    <tr key={c.caseData.id}>
                                                        <td>{c.caseData.referenceNumberOfInsurance}</td>
                                                        <td>{c.clientData ? c.clientData.displayName : "-"}</td>
                                                        <td>{c.caseNumber}</td>
                                                        <td>{moment(c.caseData.created).format("L")}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>
                                                            {c.validLicense &&
                                                                <Link to={`${pathname}/dashboard/${c.caseData.id}`} className="btn btn-md btn-icon-blue mr-1"><img src={logo} alt="Logo" className="svg-inline--fa fa-users fa-w-20" /></Link>}
                                                            {!c.validLicense ? <FontAwesomeIcon icon={faCreditCard} className="btn-icon-red" /> : <>
                                                                {c.validLicenseEdition === "DEMO" ? <FontAwesomeIcon icon={faCreditCard} className="btn-icon-light-blue" /> : <FontAwesomeIcon icon={faCreditCard} className="btn-icon-green" />}
                                                            </>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                {!cases &&
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <div className="spinner-border spinner-border-lg align-center"></div>
                                                        </td>
                                                    </tr>
                                                }
                                                {cases && !cases.length &&
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="notifications" title={t("ALL.Notifications.Overview")}>
                    <div className="container">
                        <div className="row table-row" >
                            <div className="col-md-12">
                                {tab === "notifications" &&
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <tbody>

                                                {cases && cases.map(c =>
                                                    <tr key={c.caseData.id}>
                                                        <td>{c.caseNumber}</td>
                                                        <td>
                                                            <NotificationManagement role="CaseManager" caseId={c.caseData.id} />
                                                        </td>
                                                    </tr>
                                                )}
                                                {cases && !cases.length &&
                                                    <tr key="no-data">
                                                        <td colSpan={5} className="text-center">
                                                            <div className="p-2">{t("ALL.Common.NoData")}</div>
                                                        </td>
                                                    </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>


    );

}

export { CaseManagment };