import { useContext, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Contact from '../../models/Contact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPencil } from '@fortawesome/pro-light-svg-icons'
import { EditContactControl } from './EditContactControl';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
interface IDisplayContactProps {
    contact: Contact,
    editable: boolean,
    groupName?: string

}
export const DisplayContact = ({ contact, editable, groupName }: IDisplayContactProps) => {
    const [toggled, setToggled] = useState<boolean>(false);
    const [contactItem, setContactItem] = useState<Contact>(contact);
    const toggle = () => setToggled(!toggled);
    const { t } = useContext(MultiLanguageSupportContext);
    const retunAction = (updateContact?: Contact) => {
        setContactItem(updateContact!);
        toggle();
    }
    const close = () => {
        toggle();
    }
    return (
        <>
            <hr></hr>
            <div className="form-row">
            <div className="form-group col-sm-8" style={{minHeight:"50px"}}></div>
                {editable &&
                    <div className="form-group col-sm-4 justify-content-end">
                        <button onClick={toggle} className={`btn btn-action-${groupName} mr-1`}>
                            <FontAwesomeIcon icon={faPencil} />&nbsp;{t("ALL.Button.Edit")}
                        </button></div>}

            </div>
            <div>
                {(!contactItem.name || contactItem.name === undefined || contactItem.name === "") &&
                    <p className="card-text">{t("ALL.Common.NoData")}</p>
                }
                {!(!contactItem.name || contactItem.name === undefined || contactItem.name === "") && <>
                    <p className="card-text">{contactItem.salutation} {contactItem.name} {contactItem.surname} {contactItem.companyName === "" ? contactItem.companyName : ` / ${contactItem.companyName}`}</p>
                    <p className="card-text">{contactItem.phone}</p>
                    <p className="card-text">{contactItem.street}</p>
                    <p className="card-text">{contactItem.postCode} {contactItem.city}{contactItem.country?`, ${contactItem.country}`:""}</p>
                    {contactItem.remarks && <>
                        <p className="card-text" style={{fontWeight:"bold", paddingTop:"10px"}}>{t("ALL.Contact.ContactInfo")}</p>
                        <p className="card-text">{contactItem.remarks}</p>
                    </>}
                </>}
            </div>
            <hr></hr>
            {
                editable &&
                <Modal isOpen={toggled} toggle={toggle} style={{ maxWidth: "1000px" }}>
                    <ModalHeader style={{ display: "block" }}>
                        {t("ALL.Contact.Edit")}
                        <span style={{ justifyContent: "right" }}>
                            <button type="submit" className="btn btn-md btn-icon-grey mr-1 float-right" onClick={toggle}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </button>
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <EditContactControl id={contactItem.id!} returnAction={retunAction} closeModal={toggle}/>
                    </ModalBody>
                </Modal>
            }
        </>
    );
}

