import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBurgerSoda, faCar, faCircleXmark, faEdit, faEye, faHeadSideMedical, faTrash } from '@fortawesome/pro-light-svg-icons';
import { AppraisalListData } from './ApprisalListData';
import { Link } from 'react-router-dom';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { Tab, Tabs } from 'react-bootstrap';
import { AppraisalList } from './AppraisalList';
export const WorkplaceManagement = () => {
    const { language, t } = useContext(MultiLanguageSupportContext);
    const { authUserData } = useContext(LoggedUserDataContext);
    const [tab, setTab] = useState('pending');
    const [pending] = useState<any[]>(AppraisalListData.filter(s => s.status === "Pending"));
    const [approved] = useState<any[]>(AppraisalListData.filter(s => s.status === "Approved"));

    moment.locale(language);
    const changeActiveTab = (tabName) => {
        setTab(tabName);

    }
    return (
        <div className="container">
            <Tabs
                activeKey={tab}
                onSelect={(t) => changeActiveTab(t)}>
                <Tab eventKey="pending" title={t("CLN.ResilienceProfile.Status.Pending")}>
                    <AppraisalList list={pending} />
                </Tab>
                <Tab eventKey="approved" title={t("CLN.ResilienceProfile.Status.Approved")}>
                    <AppraisalList list={approved} />
                </Tab>
            </Tabs>
        </div>


    );
}
