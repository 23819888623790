import { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,faBullseyeArrow,
    faPenNib, faCreditCard, faCalculator, faSlidersV, faSlidersH, faCalculatorAlt, faBell, faStickyNote, faUser, faFolderPlus, faUserCrown,
    faText, faListAlt, faCalendarPlus, faLightbulbOn, faThumbsUp, faBallotCheck,
    faAddressBook, faAddressCard, faBriefcase, faFolders, faPlusCircle, faClock, faListCheck, faPenRuler, faListUl, faMoneyBills,faChartLine,faTags,
    faCircleInfo, faBold

} from '@fortawesome/pro-light-svg-icons'
import logo from '../../assets/images/LogoCaseDesk.png';
import logoIcon from '../../assets/images/logoIcon.png';
import '../../assets/css/left-nav.css';
import { CaseDeskInfo } from './CaseDeskInfo';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';


const LeftNav =({ toggled, handleToggleSidebar }) => {
    let location = useLocation();
    const { authUserData } = useContext(LoggedUserDataContext);
    let visible = authUserData.selectedCaseId !== undefined;
    let rolePath = authUserData.currUserData?.rolePath;

    const { t } = useContext(MultiLanguageSupportContext);
    return (<div>
        <ProSidebar
            toggled={toggled}
            breakPoint="md"
            onToggle={handleToggleSidebar}
            className="sidebar">
            <SidebarHeader>
                <div className="center">
                    <Link to={`/${rolePath}`} ><img src={logo} className="app-logo" alt="Logo" /></Link>
                </div>
            </SidebarHeader>
            <SidebarContent>
                <Menu>
                    <hr></hr>
                    {(() => {
                        switch (rolePath) {
                            case "admin": return <div>
                                <MenuItem icon={<img src={logoIcon} className="svg-inline--fa fa-users fa-w-20" alt="Logo" />} active={location.pathname === `/${rolePath}`}>{t("INS.Common.Title")}<Link to={`/${rolePath}`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faFolders} />} active={location.pathname === `/${rolePath}/all-cases`}>{t("CAM.Case.List")}<Link to={`/${rolePath}/all-cases`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faSlidersH} />} active={location.pathname === `/${rolePath}/all-settings`}>{t("ALL.Settings.Title")}<Link to={`/${rolePath}/all-settings`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faClock} />} active={location.pathname === `/${rolePath}/all-schedulers`}>{t("ALL.Scheduler.Title")}<Link to={`/${rolePath}/all-schedulers`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faText} />} active={location.pathname === `/${rolePath}/all-templates`}>{t("ALL.Templates.Title")}<Link to={`/${rolePath}/all-templates`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faListAlt} />} active={location.pathname === `/${rolePath}/all-contacts`}>{t("ALL.Contact.List")}<Link to={`/${rolePath}/all-contacts`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faUserCrown} />} active={location.pathname === `/${rolePath}/add-admin`}>{t("INS.Common.CaseDeskAdminManage")}<Link to={`/${rolePath}/add-admin`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faCreditCard} />} active={location.pathname === `/${rolePath}/manage-license`}>{t("ALL.License.Management")}<Link to={`/${rolePath}/manage-license`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faBell} />} active={location.pathname === `/${rolePath}/manage-notification-settings`}>{t("ALL.Alerts.Management")}<Link to={`/${rolePath}/manage-notification-settings`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faTags} />} active={location.pathname === `/${rolePath}/manage-global-dictionary`}>{t("ALL.Dictionary.Management")}<Link to={`/${rolePath}/manage-global-dictionary`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faBold} style={{ color: "blue" }} />} active={location.pathname === `/${rolePath}/workplace`}>{t("CLN.ResilienceProfile.LeftNav")}<Link to={`/${rolePath}/workplace`} /></MenuItem>
                                  
                                <hr></hr>
                                <MenuItem icon={<FontAwesomeIcon icon={faSlidersV} />} active={location.pathname === `/${rolePath}/www-edit-settings`}>{t("ALL.Settings.WebSite.MenuSettings")}<Link to={`/${rolePath}/www-edit-settings`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faCalculatorAlt} />} active={location.pathname === `/${rolePath}/www-list-calculations`}>{t("ALL.Settings.WebSite.MenuCalculator")}<Link to={`/${rolePath}/www-list-calculations`} /></MenuItem>
                            </div>
                            case "insurer": return <div>
                                <MenuItem icon={<img src={logoIcon} className="svg-inline--fa fa-users fa-w-20" alt="Logo" />} active={location.pathname === `/${rolePath}`}>{t("CAM.Case.List")}<Link to={`/${rolePath}`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faPlusCircle} />} active={location.pathname === `/${rolePath}/add-case`}>{t("CAM.Case.New")}<Link to={`/${rolePath}/add-case`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faUserCrown} />} active={location.pathname === `/${rolePath}/manage-owners`}>{t("INS.Common.AdminManage")}<Link to={`/${rolePath}/manage-owners`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faUser} />} active={location.pathname === `/${rolePath}/manage-members`}>{t("INS.Common.CaseManagerManage")}<Link to={`/${rolePath}/manage-members`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faCalculator} />} active={location.pathname === `/${rolePath}/license-report/${authUserData.currUserData?.relatedData[0].id}`}>{t("ALL.License.ReportTitle")}<Link to={`/${rolePath}/license-report/${authUserData.currUserData?.relatedData[0].id}`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faPenNib} />} active={location.pathname === `/${rolePath}/manage-form-templates`}>{t("ALL.FormTemplates.Manage")}<Link to={`/${rolePath}/manage-form-templates`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faPenRuler} />} active={location.pathname === `/${rolePath}/manage-rule`}>{t("ALL.Tasks.Generator.Rules.Title")}<Link to={`/${rolePath}/task-rules`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faBell} />} active={location.pathname === `/${rolePath}/manage-notification-settings`}>{t("ALL.Alerts.Management")}<Link to={`/${rolePath}/manage-notification-settings`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faTags} />} active={location.pathname === `/${rolePath}/manage-dictionary`}>{t("ALL.Dictionary.Management")}<Link to={`/${rolePath}/manage-dictionary`} /></MenuItem>
                                
                            </div>
                            case "client": return <div>
                                <MenuItem icon={<img src={logoIcon} className="svg-inline--fa fa-users fa-w-20" alt="Logo" />} active={location.pathname === `/${rolePath}`}>{t("ALL.Common.DashboardOverview")}<Link to={`/${rolePath}`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faCalendarPlus} />} active={location.pathname === `/${rolePath}/add-reflection`}>{t("CLN.Reflection.Add")}<Link to={`/${rolePath}/add-reflection`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faLightbulbOn} />} active={location.pathname === `/${rolePath}/show-reflections`}>{t("CLN.Reflection.Show")}<Link to={`/${rolePath}/show-reflections`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faBell} />} active={location.pathname === `/${rolePath}/notification-management`}>{t("ALL.Notifications.Title")}<Link to={`/${rolePath}/notification-management`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faStickyNote} />} active={location.pathname === `/${rolePath}/notes-management`}>{t("ALL.Notes.List")}<Link to={`/${rolePath}/notes-management`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faFolderPlus} />} active={location.pathname === `/${rolePath}/document-management`}>{t("ALL.DMS.Title")}<Link to={`/${rolePath}/document-management`} /></MenuItem>
                                      
                                <hr></hr>
                                <MenuItem icon={<FontAwesomeIcon icon={faBallotCheck} />} active={location.pathname === `/${rolePath}/show-assessements`}>{t("DOC.Assessment.List")}<Link to={`/${rolePath}/show-assessements`} /></MenuItem>
                                <MenuItem icon={<FontAwesomeIcon icon={faAddressBook} />} active={location.pathname === `/${rolePath}/show-contacts`}>{t("ALL.Contact.List")}<Link to={`/${rolePath}/show-contacts`} /></MenuItem>

                            </div>
                            case "employeer": return <div>
                                {visible &&
                                    <div>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBriefcase} />} active={location.pathname === `/${rolePath}`}>{t("CAM.Case.Overview")}<Link to={`/${rolePath}`} /></MenuItem>
                                        <hr></hr>
                                        <MenuItem icon={<img src={logoIcon} className="svg-inline--fa fa-users fa-w-20" alt="Logo" />} active={location.pathname === `/${rolePath}/dashboard/${authUserData.selectedCaseId}`}>{t('CAM.Case.Title')}<Link to={`/${rolePath}/dashboard/${authUserData.selectedCaseId}`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faThumbsUp} />} active={location.pathname === `/${rolePath}/add-feedback`}>{t('EMP.Feedback.Add')}<Link to={`/${rolePath}/add-feedback`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faThumbsUp} />} active={location.pathname === `/${rolePath}/show-feedbacks`}>{t('EMP.Feedback.Show')}<Link to={`/${rolePath}/show-feedbacks`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faFolderPlus} />} active={location.pathname === `/${rolePath}/document-management`}>{t("ALL.DMS.Title")}<Link to={`/${rolePath}/document-management`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBell} />} active={location.pathname === `/${rolePath}/notification-management`}>{t("ALL.Notifications.Title")}<Link to={`/${rolePath}/notification-management`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faStickyNote} />} active={location.pathname === `/${rolePath}/notes-management`}>{t("ALL.Notes.List")}<Link to={`/${rolePath}/notes-management`} /></MenuItem>
                                        <hr></hr>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBallotCheck} />} active={location.pathname === `/${rolePath}/show-assessements`}>{t("DOC.Assessment.Show")}<Link to={`/${rolePath}/show-assessements`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faAddressBook} />} active={location.pathname === `/${rolePath}/show-contacts`}>{t("ALL.Contact.List")}<Link to={`/${rolePath}/show-contacts`} /></MenuItem>

                                    </div>
                                }
                            </div>
                            case "doctor": return <div>
                                {visible &&
                                    <div>

                                        <MenuItem icon={<FontAwesomeIcon icon={faBriefcase} />} active={location.pathname === `/${rolePath}`}>{t("CAM.Case.Overview")}<Link to={`/${rolePath}`} /></MenuItem>
                                        <hr></hr>
                                        <MenuItem icon={<img src={logoIcon} className="svg-inline--fa fa-users fa-w-20" alt="Logo" />} active={location.pathname === `/${rolePath}/dashboard/${authUserData.selectedCaseId}`}>{t("CAM.Case.Title")}<Link to={`/${rolePath}/dashboard/${authUserData.selectedCaseId}`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBallotCheck} />} active={location.pathname === `/${rolePath}/add-assessement`}>{t("DOC.Assessment.Add")}<Link to={`/${rolePath}/add-assessement`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBallotCheck} />} active={location.pathname === `/${rolePath}/show-assessements`}>{t("DOC.Assessment.Show")}<Link to={`/${rolePath}/show-assessements`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faFolderPlus} />} active={location.pathname === `/${rolePath}/document-management`}>{t("ALL.DMS.Title")}<Link to={`/${rolePath}/document-management`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBell} />} active={location.pathname === `/${rolePath}/notification-management`}>{t("ALL.Notifications.Title")}<Link to={`/${rolePath}/notification-management`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faStickyNote} />} active={location.pathname === `/${rolePath}/notes-management`}>{t("ALL.Notes.List")}<Link to={`/${rolePath}/notes-management`} /></MenuItem>
                                        <hr></hr>
                                        <MenuItem icon={<FontAwesomeIcon icon={faLightbulbOn} />} active={location.pathname === `/${rolePath}/show-reflections`}>{t("CLN.Reflection.List")}<Link to={`/${rolePath}/show-reflections`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faThumbsUp} />} active={location.pathname === `/${rolePath}/show-feedbacks`}>{t("EMP.Feedback.Title")}<Link to={`/${rolePath}/show-feedbacks`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faAddressBook} />} active={location.pathname === `/${rolePath}/show-contacts`}>{t("ALL.Contact.List")}<Link to={`/${rolePath}/show-contacts`} /></MenuItem>

                                    </div>
                                }</div>
                            case "casemanager": return <div>
                                {visible &&
                                    <div>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBriefcase} />} active={location.pathname === `/${rolePath}`}>{t("CAM.Case.Overview")}<Link to={`/${rolePath}`} /></MenuItem>
                                        <hr></hr>
                                        <MenuItem icon={<img src={logoIcon} className="svg-inline--fa fa-users fa-w-20" alt="Logo" />} active={location.pathname === `/${rolePath}/dashboard/${authUserData.selectedCaseId}`}>{t("CAM.Case.InfoCard")}<Link to={`/${rolePath}/dashboard/${authUserData.selectedCaseId}`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faListUl} />} active={location.pathname === `/${rolePath}/edit-case/${authUserData.selectedCaseId}/Yes`}>{t("CAM.Case.Properties")}<Link to={`/${rolePath}/edit-case/${authUserData.selectedCaseId}/Yes`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faAddressCard} />} active={location.pathname === `/${rolePath}/manage-case-contacts`}>{t("ALL.Contact.List")}<Link to={`/${rolePath}/manage-case-contacts`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faFolderPlus} />} active={location.pathname === `/${rolePath}/document-management/${authUserData.selectedCaseId}/No`}>{t("ALL.DMS.Title")}<Link to={`/${rolePath}/document-management/${authUserData.selectedCaseId}/No`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBell} />} active={location.pathname === `/${rolePath}/notification-management`}>{t("ALL.Notifications.Title")}<Link to={`/${rolePath}/notification-management`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faStickyNote} />} active={location.pathname === `/${rolePath}/notes-management`}>{t("ALL.Notes.List")}<Link to={`/${rolePath}/notes-management`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faListCheck} />} active={location.pathname === `/${rolePath}/tasks-management`}>{t("ALL.Tasks.NavigationItem")}<Link to={`/${rolePath}/tasks-management`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBullseyeArrow} />} active={location.pathname === `/${rolePath}/edit-case-goals/${authUserData.selectedCaseId}`}>{t("CAM.Case.Goals.NavigationItem")}<Link to={`/${rolePath}/edit-case-goals/${authUserData.selectedCaseId}`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faMoneyBills} />} active={location.pathname === `/${rolePath}/manage-bookings`}>{t("ALL.Bookings.LeftNav")}<Link to={`/${rolePath}/manage-bookings`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBell} />} active={location.pathname === `/${rolePath}/manage-notification-settings/case`}>{t("ALL.Alerts.Management")}<Link to={`/${rolePath}/manage-notification-settings/case`} /></MenuItem>
                                        <hr></hr>
                                        <MenuItem icon={<FontAwesomeIcon icon={faLightbulbOn} />} active={location.pathname === `/${rolePath}/show-reflections`}>{t("CLN.Reflection.List")}<Link to={`/${rolePath}/show-reflections`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faThumbsUp} />} active={location.pathname === `/${rolePath}/show-feedbacks`}>{t("EMP.Feedback.Title")}<Link to={`/${rolePath}/show-feedbacks`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBallotCheck} />} active={location.pathname === `/${rolePath}/show-assessements`}>{t("DOC.Assessment.List")}<Link to={`/${rolePath}/show-assessements`} /></MenuItem>
                                    </div>
                                }
                                {!visible &&
                                    <div>
                                        <MenuItem icon={<FontAwesomeIcon icon={faBriefcase} />} active={location.pathname === `/${rolePath}/my-cases` || location.pathname === `/${rolePath}`}>{t("CAM.Case.My")}<Link to={`/${rolePath}/my-cases`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faFolders} />} active={location.pathname === `/${rolePath}/all-cases`}>{t("CAM.Case.List")}<Link to={`/${rolePath}/all-cases`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faPlusCircle} />} active={location.pathname === `/${rolePath}/add-case`}>{t("CAM.Case.New")}<Link to={`/${rolePath}/add-case`} /></MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faCalculator} />} active={location.pathname === `/${rolePath}/license-report/${authUserData.currUserData?.insurerId}`}>{t("ALL.License.ReportTitle")}<Link to={`/${rolePath}/license-report/${authUserData.currUserData?.insurerId}`} /></MenuItem>

                                    </div>
                                }
                            </div>
                            default: return <div></div>
                        }
                    })()}
                </Menu>
            </SidebarContent>
            <SidebarFooter>
                <CaseDeskInfo />
            </SidebarFooter>
        </ProSidebar>
        </div>
    );

}
export default LeftNav;