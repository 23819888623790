
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Link, useNavigate, useParams, } from 'react-router-dom';
import Case from '../../models/Case';
import { alertService, caseManagerService } from '../../services/Index';
import { ManageGroupMembers } from './ManageGroupMembers';
import { CaseDataContext } from '../../ctx/CaseDataProvider';
import { LoggedUserDataContext } from '../../ctx/LoggedUserDataProvider';
import { AppAccessContext } from '../../ctx/AppAccessProvider ';

function ManageContacts() {
    let { id } = useParams();
    let navigate = useNavigate();
    const { authUserData } = useContext(LoggedUserDataContext);
    const isCaseAdmin: boolean = id === undefined;
    const [caseItem, setCase] = useState<Case>();
    const { t } = useContext(MultiLanguageSupportContext);
    const { caseData } = useContext(CaseDataContext);
    const caseId = !id ? caseData.selectedCaseId! : parseInt(id);
    const { setAppAccess } = useContext(AppAccessContext);

    useEffect(() => {
        
        if (caseId === undefined) {
            navigate(`/${authUserData.currUserData.roleType.toLowerCase()}`);
        }
        caseManagerService.getSingleCase(!isCaseAdmin ? parseInt(id!) : caseData.selectedCaseId!).then(i => {
            setAppAccess({
                hasAccessToPath: (authUserData.currUserData.roleType.toLowerCase()==="admin" 
                || (authUserData.currUserData.roleType.toLowerCase()==="insurer" && authUserData.currUserData.insurerId===i.insurerId)
                || authUserData.currUserData.relatedData.filter(data => data.id === caseId).length > 0)
                
            });
            setCase({
                id: i.id,
                insurerId: i.insurerId,
                mainGoal: i.mainGoal,
                referenceNumberOfInsurance: i.referenceNumberOfInsurance,
                delayDays: i.delayDays,
                dailyAllowanceAmount: i.dailyAllowanceAmount,
                incapacityOfWork: i.incapacityOfWork,
                endOfIntegration: i.endOfIntegration,
                startOfIntegration: i.startOfIntegration,
                endDailyAllowances: i.endDailyAllowances,
                endOfCaseDesk: i.endOfCaseDesk,
                caseDeskEnded: i.caseDeskEnded,
                clnGroupOpenId: i.clnGroupOpenId,
                docGroupOpenId: i.docGroupOpenId,
                empGroupOpenId: i.empGroupOpenId,
                camGroupOpenId: i.camGroupOpenId,
                reflectionRemiderEnabled: i.reflectionRemiderEnabled

            });
        })
            .catch(
                (rp) => {
                    alertService.error(t(rp.message), { keepAfterRouteChange: true });
                    navigate("../")
                }
            );

    }, [id, isCaseAdmin]);

    return (
        <div className="container">

            <div className="row">
                <div className="col-lg-10 col-md-12">
                    <p className="component-header">{t("ALL.Contact.List")}</p>
                    <p><FontAwesomeIcon icon={faChevronLeft} /><Link to={caseData.selectedCaseId != undefined ? `/${authUserData.currUserData.roleType.toLowerCase()}/dashboard/${caseData.selectedCaseId}` : `/${authUserData.currUserData.roleType.toLowerCase()}`} className="back-btn">{t("ALL.Button.Back")}</Link></p>
                </div>
                <hr></hr>
            </div>
            <hr></hr>
            {!caseItem && <div className="loader">{t("ALL.Common.Loading")}</div>}
            {caseItem !== undefined && <>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="card">
                            <div className='card-header card-header-warning dark-header-top-doc'>
                                <h4 className="card-title">{t('ALL.Dictionary.Role.Doctor')}</h4>
                            </div>
                            <ManageGroupMembers groupId={caseItem!.docGroupOpenId!} groupName="Doctor" caseId={caseItem!.id} insurerId={caseItem.insurerId} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="card">
                            <div className='card-header card-header-warning dark-header-top-emp'>
                                <h4 className="card-title">{t('ALL.Dictionary.Role.Employeer')}</h4>
                            </div>

                            <ManageGroupMembers groupId={caseItem!.empGroupOpenId!} groupName="Employeer" caseId={caseItem!.id} insurerId={caseItem.insurerId} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="card">
                            <div className='card-header card-header-warning dark-header-top-cam'>
                                <h4 className="card-title">{t('ALL.Dictionary.Role.CaseManager')}</h4>
                            </div>
                            <ManageGroupMembers groupId={caseItem!.camGroupOpenId!} groupName="Case Manager" caseId={caseItem!.id} insurerId={caseItem.insurerId} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="card">
                            <div className='card-header card-header-warning dark-header-top-cln'>
                                <h4 className="card-title">{t('ALL.Dictionary.Role.Client')}</h4>
                            </div>
                            <ManageGroupMembers groupId={caseItem!.clnGroupOpenId!} groupName="Client" caseId={caseItem!.id} insurerId={caseItem.insurerId} />
                        </div>
                    </div>
                </div>

            </>}

        </div >

    );
}

export { ManageContacts };