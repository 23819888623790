import { Role } from "../helpers/Roles";

export default class LogedUserData{
    public roleType:string;
    public rolePath:string;
    public roleEnum:string;
    public relatedData:any;
    public insurerId:number;
    public contactId:number;
    public profileId:string;
    public profileEmail:string;
    public profileLanguage:string;
    public name:string;
    public surname:string;
    public profileImg:string
    constructor(roleType: string,relatedData:any,profileId:string,insurerId:number,profileEmail:string,contactId:number,profileLanguage:string,name:string,surname:string,profileImg:string) {
        this.roleType=roleType;
        this.roleEnum=mapRoleEnum(roleType);
        this.rolePath=roleType.toLowerCase();
        this.relatedData=relatedData;
        this.profileId=profileId;
        this.contactId=contactId;
        this.profileEmail=profileEmail;
        this.insurerId=insurerId;
        this.profileLanguage=profileLanguage;
        this.surname=surname;
        this.name=name;
        this.profileImg=profileImg;
    }


}
function mapRoleEnum(role){
    var roleEnum="";
    switch (role) {
        case 'Admin':
            roleEnum="ADM"
            break;
        case 'Client':
            roleEnum="CLN"
            break;
        case 'CaseManager':
            roleEnum="CAM"
          break;
        case 'Doctor':
            roleEnum="DOC"
          break;
        case 'Employeer':
            roleEnum="EMP"
          break;
        case 'Insurer':
            roleEnum="INS"
          break;

      }
      return roleEnum;
}