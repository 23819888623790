import { useContext, useEffect, useRef, useState } from 'react';
import { alertService, clientService } from '../../services/Index';

import moment from 'moment';
import { MultiLanguageSupportContext } from '../../ctx/MultiLanguageSupportProvider';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2'
ChartJS.register(...registerables);

interface IProps {
  chartRefresh?: any,
  caseId: number
}
const CaseTrendLineChart = ({ chartRefresh, caseId }: IProps) => {
  const { t } = useContext(MultiLanguageSupportContext);
  const options = {
    responsive: true,
    scales: {
      yAxes: {
        ticks: {
          //beginAtZero: true,
          callback: function (val, index) {
            return Number.isInteger(val) ? t(`CLN.Reflection.HealthState.${val}`) : "";
          },
        },
      },
      x: {
        ticks: {
          beginAtZero: true,
          callback: function (val, index) {
            return "";
          },
        },
      },


    }

  };
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState<{ datasets: any,labels:any}>({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    clientService.getCaseReflectionHealthStateTrendAsync(caseId)
      .then((rp) => {
        var labels = rp.map(r => (moment(r.reflection.reflectionDate).format("DD.MM.YYYY")))
        var data1 = rp.map(r => r.reflectionTrend);
        const chart = chartRef.current;
        if (chart) {
          setChartData({
          labels: labels,
          datasets: [
            {
              label: t("ALL.DashBoard.ChartTrendReflectionLegend"),
              data: data1,
              fill: false,
              backgroundColor: 'rgb(233,121,50)',
              borderColor: 'rgb(233,121,50)',
              yAxisID: 'yAxes',
            },
          ],
        })
      }

      })
      .catch(
        (rp) => alertService.error(t(rp.message), { keepAfterRouteChange: true })
      );

  }, [chartRefresh]);
  return (
    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="card-header card-header-tabs card-header-primary header-top">
          <h4 className="card-title">{t("ALL.DashBoard.ChartTrendReflectionHeader")}</h4>
          <p className="card-category">{t("ALL.DashBoard.ChartTrendReflectionHeaderSub")}</p>
        </div>
        <div className="card-body">
        <Chart
              ref={chartRef}
              type='line'
              options={options}
              data={chartData}
            />
        </div>
      </div>
    </div>

  )
};

export default CaseTrendLineChart;