import { useContext, useEffect, useState } from 'react';
import '../assets/css/app.css';
import { apiConfig, apiTokenRequest, graphTokenRequest } from '../msal/Config';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './layouts/Layout';
import { AdminPanel } from '../containers/admin/Index';
import { InsurerPanel } from '../containers/insurer/Index';
import { Alert } from '../components/Index';
import { getUserGroups } from '../services/graph/GraphService'
import { contactService } from '../services/casedesk/contacts/ContactService'
import { PrivateRoute } from '../components/PrivateRoute'
import LogedUserData from '../models/LogedUserData';
import { CaseManagerPanel } from './casemanager/Index';
import { AccessDenied } from '../components/AccessDenied';
import { ClientPanel } from './client/Index';
import { EmployeerPanel } from './employeer/Index';
import { DoctorPanel } from './doctor/Index';
import Term from '../models/Term';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { alertService } from '../services/Index';

import parse from 'html-react-parser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { MultiLanguageSupportContext } from '../ctx/MultiLanguageSupportProvider';
import i18n, { lng } from '../constants/multi-lang-support';
import { LoggedUserDataContext } from '../ctx/LoggedUserDataProvider';
import { CaseDataProvider } from '../ctx/CaseDataProvider';
import { AppSettingsContext } from '../ctx/AppSettingsProvider';
import { defaultUserData, ILoggedUserData } from '../interfaces/ILoggedUser';
import LogedUserProps from '../services/props/LogedUserProps';
import { AppAccessContext } from '../ctx/AppAccessProvider ';


interface IAppState {
    loading: boolean,
    termAccepting: boolean
    licenseValid: boolean,
    requestStatusCode: number,
    respError: any,
    errorMessege: string,
    termModalOpen: boolean,
    termAgreement: Term,
    hasAccess: boolean,
}

const Index = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    let userProperties: ILoggedUserData = defaultUserData;
    const { authUserData, setAuthUserData } = useContext(LoggedUserDataContext);
    const { setAppSettings } = useContext(AppSettingsContext);
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const { accessDetails, setAppAccess } = useContext(AppAccessContext);
    const [initialAppData, setInitialAppData] = useState<IAppState>({
        loading: true,
        licenseValid: true,
        requestStatusCode: 200,
        errorMessege: "",
        respError: null,
        termModalOpen: true,
        termAgreement: {},
        hasAccess: false,
        termAccepting: false
    });
    useEffect(() => {
        let userName = accounts[0].username;
        let userGuid = accounts[0].localAccountId;
        let licenseValid = true;
        const fetchUserInitialData = async () => {
            try {
                LogedUserProps.graphToken = await instance.acquireTokenSilent({
                    ...graphTokenRequest,
                    account: accounts[0],
                }).then((response) => {
                    return response.accessToken;
                }).catch(function (error) {
                    instance.loginRedirect(graphTokenRequest).catch(e => {
                        console.log(e);
                        return;
                    });
                });
                LogedUserProps.apiToken = await instance.acquireTokenSilent({
                    ...apiTokenRequest,
                    account: accounts[0],
                }).then((response) => {
                    return response.accessToken;
                }).catch(function (error) {
                    instance.loginRedirect(apiTokenRequest).catch(e => {
                        console.log(e);
                        return;
                    });
                });

                userProperties.selectedCaseId = undefined;
                let groupResponse = await getUserGroups();
                let checkTermAgreement = await contactService.checkContactAcceptedTermAsyncAsync(userName, lng);
                userProperties.currUserGroups = groupResponse.value;
                var isAdmin = (userProperties.currUserGroups.length !== 0 && userProperties.currUserGroups.includes(apiConfig.adminGroup)) ? true : false;

                setAppSettings(checkTermAgreement.settings);
                if (isAdmin) {
                    userProperties.currUserData = new LogedUserData('Admin', {}, userGuid, -1, userName, checkTermAgreement.contactId, lng, checkTermAgreement.name, checkTermAgreement.surname, checkTermAgreement.profileImage)
                }
                else if (userProperties.currUserGroups.length > 0) {
                    let contactRoles = await contactService.getContactRoleData(userName, userProperties.currUserGroups);
                    userProperties.currUserData = new LogedUserData(contactRoles.roleType, contactRoles.relatedData, userGuid, contactRoles.insurerId, userName, checkTermAgreement.contactId, lng, checkTermAgreement.name, checkTermAgreement.surname, checkTermAgreement.profileImage)

                    if (contactRoles.roleType === "Client" || contactRoles.relatedData.lenght === 1) {
                        userProperties.selectedCaseId = contactRoles.relatedData[0].id;
                        licenseValid = contactRoles.validLicense;
                    }
                }
                setInitialAppData(
                    {
                        loading: false,
                        licenseValid: licenseValid,
                        requestStatusCode: 200,
                        errorMessege: "",
                        respError: null,
                        termModalOpen: checkTermAgreement.termAccepted ? false : true,
                        termAgreement: checkTermAgreement.termAccepted ? {} : checkTermAgreement.agreement,
                        hasAccess: userProperties.currUserGroups.length > 0 && userProperties.currUserData.roleType !== 'NoRole',
                        termAccepting: false
                    }
                )

                if (checkTermAgreement.defaultLanguage !== null && checkTermAgreement.defaultLanguage !== "") {
                    i18n.changeLanguage(checkTermAgreement.defaultLanguage);
                    userProperties.currUserData.profileLanguage = checkTermAgreement.defaultLanguage;
                }
                setAuthUserData({
                    ...authUserData,
                    currUserData: userProperties.currUserData,
                    currUserGroups: userProperties.currUserGroups,
                    selectedCaseId: userProperties.selectedCaseId

                });

            } catch (error: any) {
                alertService.error(error.errorMessage, { keepAfterRouteChange: true })
                console.log(error + error.statusCode)
                setInitialAppData(
                    {
                        ...initialAppData,
                        loading: false,
                        licenseValid: licenseValid,
                        requestStatusCode: error.statusCode,
                        respError: error,
                        errorMessege: error.errorMessage,
                        termModalOpen: false,
                        hasAccess: false,

                    }
                )
            }
        }
        fetchUserInitialData();
    }, [isAuthenticated]);

    const acceptTerms = () => {
        setInitialAppData({
            ...initialAppData,
            termAccepting: true
        })
        var data = {
            termId: initialAppData.termAgreement.id,
            contactEmail: accounts[0].username
        }
        contactService.acceptTermAgreement(data)
            .then(() => {
                alertService.success(`Terms accepted`, { keepAfterRouteChange: true });
                setInitialAppData({
                    ...initialAppData,
                    termAccepting: false,
                    termModalOpen: false,
                    hasAccess: true,
                })
            })
            .catch((error) => {
                alertService.error(t(error), { keepAfterRouteChange: true })
                setInitialAppData({
                    ...initialAppData,
                    respError: error,
                    termAccepting: false,
                    termModalOpen: false,
                    hasAccess: false,
                    loading: false
                })
            });

    };

    return (
        <>
            {(initialAppData.loading || initialAppData.termModalOpen) &&
                <div className="loader">Loading...</div>
            }
            {
                !initialAppData.hasAccess && !initialAppData.loading &&
                <AccessDenied appMessage={initialAppData.errorMessege !== undefined ? initialAppData.errorMessege : initialAppData.respError} licenseValid={initialAppData.licenseValid} hasAccess={initialAppData.hasAccess} statusCode={initialAppData.requestStatusCode} />
            }
            {
                !initialAppData.licenseValid && !initialAppData.loading &&
                <AccessDenied appMessage={initialAppData.errorMessege} licenseValid={initialAppData.licenseValid} hasAccess={initialAppData.hasAccess} statusCode={initialAppData.requestStatusCode} />
            }

            {!initialAppData.loading &&
                <Modal isOpen={initialAppData.termModalOpen} style={{ maxWidth: "700px" }}>
                    <ModalBody>
                        {!initialAppData.termAgreement && <div className="spinner-border spinner-border-lg align-center"></div>}
                        {initialAppData.termAgreement.termAgreementValue && parse(initialAppData.termAgreement.termAgreementValue!)}
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={acceptTerms} disabled={initialAppData.termAccepting} className="btn" style={{ color: "#fff", float: "right", backgroundColor: "#091C53" }}>
                            {initialAppData.termAccepting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            {t("ALL.Common.AcceptTerms")}

                        </button>
                    </ModalFooter>
                </Modal>
            }
            {initialAppData.hasAccess && initialAppData.licenseValid && !initialAppData.termModalOpen &&
                <CaseDataProvider>
                    <Layout>
                        <Alert />
                            <Routes>
                                <Route path='/' element={<Navigate to={`${userProperties.currUserData.rolePath}`} />} />
                                <Route
                                    path="admin/*"
                                    element={
                                        <PrivateRoute>
                                            <AdminPanel />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="insurer/*"
                                    element={
                                        <PrivateRoute>
                                            <InsurerPanel />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="casemanager/*"
                                    element={
                                        <PrivateRoute>
                                            <CaseManagerPanel />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="client/*"
                                    element={
                                        <PrivateRoute>
                                            <ClientPanel />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/employeer/*"
                                    element={
                                        <PrivateRoute>
                                            <EmployeerPanel />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/doctor/*"
                                    element={
                                        <PrivateRoute>
                                            <DoctorPanel />
                                        </PrivateRoute>
                                    }
                                />
                            </Routes>
                    </Layout>
                </CaseDataProvider>
            }
        </>
    );
}

export default Index;